import { useMutation, useQuery } from 'react-query'
import API from '../services/API'

export default function useVoucherPDF(voucherId) {
  const {
    data: mutateRes,
    isLoading: mutateLoading,
    mutate
  } = useMutation('', (email) => API.sendVoucherToGivenEmail(voucherId, email))
  const { data, isLoading, error } = useQuery(['voucher-pdf', voucherId], () => API.getVoucherPDF(voucherId), {
    keepPreviousData: true
  })

  return {
    data,
    error,
    sendEmail: mutate,
    sendEmailResponse: mutateRes,
    sendEmailLoading: mutateLoading,
    isLoading
  }
}
