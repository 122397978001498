import React, { Component } from 'react'
import debug from 'debug'
import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { setLocationSearchItems, startLocationSearch } from '../../../redux/slices'
import Downshift from 'downshift'
import { loadLocationItems } from '../../../util/searchArgs'
import { CarlinPropTypes } from '../../../entities'
import { FadeModal } from '../../Modal'
import { El, FlexEl } from '../../layout'
import { Location, Plane } from '../../svg'
import { connect } from 'react-redux'
import { func, string } from 'prop-types'
import {
  LabelDS,
  MenuDS,
  MenuPlaceholderDS,
  ModalContainerDS,
  ModalHeaderDS,
  ModalInputDS,
  ModalItemsContainer,
  SelectContainerDS,
  SelectDS
} from '../../CarlaSelect'
import Loading from '../../Loading'
import debounce from 'lodash/debounce'
import trim from 'lodash/trim'

const log = debug('carla:search')

const RawLocationSelect = styled(
  class extends Component {
    state = {
      inputValue: ''
    }

    static propTypes = {
      value: CarlinPropTypes.SelectItem,
      onChange: func.isRequired,
      label: string.isRequired
    }

    // call item loading functions with 300ms delay / @TODO DRY
    requestAPI = debounce((input) => {
      if (!input || !input.trim()) return
      this.props.startLocationSearch()
      loadLocationItems(input)
        .then((items) => {
          if (items && items.length > 0) {
            this.props.setLocationSearchItems(items)
          } else {
            log('no location found', items)
            this.props.setLocationSearchItems(null)
          }
        })
        .catch((err) => {
          log('error during car search', err)
          this.props.setLocationSearchItems(null)
        })
    }, 300)

    // @TODO DRY
    handleInputChange = (inputValue, { selectedItem, closeMenu }) => {
      this.setState({ inputValue })
      const input = trim(inputValue)
      const inputAlreadySelected = selectedItem && selectedItem.label === inputValue

      if (inputAlreadySelected || input === 'Same location') {
        closeMenu()
        return
      }

      this.requestAPI(input)
    }

    // @TODO DRY
    getLocationInput = (inputValue) => {
      if (!this.props.compareIfSameWith) return inputValue
      if (this.props.value && this.props.value.label !== inputValue) return inputValue
      const { value, compareIfSameWith } = this.props

      if (value.label === compareIfSameWith.label) {
        return 'Same Location'
      }
      return inputValue
    }

    renderModalHeader = ({ inputValue, closeMenu, getInputProps }) => (
      <ModalHeaderDS label={this.props.label} closeMenu={closeMenu}>
        <ModalInputDS
          placeholder='Enter city or airport'
          autoFocus
          {...getInputProps({
            value: this.state.inputValue,
            onFocus: () => this.setState({ inputValue: '' })
          })}
        />
      </ModalHeaderDS>
    )

    renderModalContent = ({ isLoading, error, items, getItemProps, optimize }) => (
      <ModalItemsContainer>
        <El>
          {isLoading && (
            <MenuPlaceholderDS>
              <Loading />
            </MenuPlaceholderDS>
          )}
          {error && <MenuPlaceholderDS>No location found</MenuPlaceholderDS>}
          {items && items.length > 0 && (
            <ul className='items'>
              {items.map((item, index) => (
                <FlexEl as='li' className='item' {...getItemProps({ key: item.value, item, index })}>
                  <El>{item.label}</El>
                  <El>{item.type === 'airport' ? <Plane /> : <Location />}</El>
                </FlexEl>
              ))}
            </ul>
          )}
        </El>
      </ModalItemsContainer>
    )

    render() {
      const { value, label, onChange, items, isLoading, error, optimize, ...props } = this.props

      return (
        <Downshift
          onChange={onChange}
          items={items}
          itemToString={(item) => (item ? item.label : '')}
          selectedItem={value}
          onInputValueChange={this.handleInputChange}
          {...props}
        >
          {({
            getLabelProps,
            getRootProps,
            getInputProps,
            getToggleButtonProps,
            getMenuProps,
            getItemProps,
            inputValue,
            isOpen,
            selectedItem,
            closeMenu
          }) => {
            return (
              <SelectContainerDS {...getRootProps()} {...props}>
                <LabelDS {...getLabelProps()}>{label}</LabelDS>
                <SelectDS type='button' {...getToggleButtonProps()}>
                  {selectedItem ? this.getLocationInput(selectedItem.label) : 'Enter city or airport'}
                </SelectDS>
                <MenuDS {...getMenuProps()} isOpen={isOpen}>
                  <FadeModal isOpen={isOpen} onClose={closeMenu}>
                    <ModalContainerDS>
                      {this.renderModalHeader({ inputValue, closeMenu, getInputProps })}
                      {this.renderModalContent({ isLoading, error, items, getItemProps, optimize })}
                    </ModalContainerDS>
                  </FadeModal>
                </MenuDS>
              </SelectContainerDS>
            )
          }}
        </Downshift>
      )
    }
  }
)`
  .items {
    margin: 0;
    .item {
      display:flex;
      flex-wrap: nowrap;
      justify-content:space-between;
      align-items:center;
      height: 60px;
      padding: 10px 15px;
      .location { fill: ${themeGet('colors.blueGrey.6')};
      .plane { fill: ${themeGet('colors.blueGrey.6')}; }
    }
  }
`

const LocationSelect = connect(
  (state) => ({ optimize: state.optimize }), // deciding to show near me locations feature
  { startLocationSearch, setLocationSearchItems }
)(RawLocationSelect)

export default LocationSelect
