import React from 'react'
import { Dialog } from '@material-ui/core'
import IconWarning from '../assets/icon-warning.svg'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Button, Text } from '../styles'

const ModalContainer = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 18px;
  border-radius: 12px;
  border: 1px solid #dfdfe7;
  background: var(--White, #fff);
  text-align: center;
`

export default function FailedModificationModal({ open, onClick }) {
  const { t: TRANSLATOR } = useTranslation()
  return (
    <Dialog PaperComponent={ModalContainer} open={open}>
      <img src={IconWarning} alt='icon-warning' />
      <Text>{TRANSLATOR('reservations:modification:fail')}</Text>
      <Button onClick={onClick}>{TRANSLATOR('homePage:contactUs')}</Button>
    </Dialog>
  )
}
