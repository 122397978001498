import { useEffect } from 'react'

export default function usePopStateRedirect(redirectUrl = '/') {
  useEffect(() => {
    const handleRedirect = () => {
      window.location.href = redirectUrl
    }
    window.addEventListener('popstate', handleRedirect)

    return () => {
      window.removeEventListener('popstate', handleRedirect)
    }
  }, [redirectUrl])
}
