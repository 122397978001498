import * as Sentry from '@sentry/browser'
import util from '../util'

/**
 * initialize sentry if running in browser
 */
export const init = () => {
  if (util.isLocal()) return

  let dsn
  if (util.isBrowser()) dsn = 'https://4e64a27c592b4490aac79f477fac3ccf@sentry.io/1372680'
  if (util.isServer()) dsn = 'https://c9aa1bfac2b742afa4ee8272744f7b16@sentry.io/1372931'

  let environment
  if (util.isDevelopment()) environment = 'development'
  if (util.isProduction()) environment = 'production'

  const debug = process.env.DEBUG
  const release = process.env.CARLA_VERSION

  Sentry.init({ dsn, environment, debug, release })
}

/**
 * pack error captured in render method by componentDidCatch
 */
export const handleReactError = (error, errorInfo) => {
  if (util.isLocal()) return
  Sentry.withScope((scope) => {
    for (let key in errorInfo) {
      scope.setExtra(key, errorInfo[key])
    }
    Sentry.captureException(error)
  })
}

/**
 * send error to sentry
 */
export const handleError = (error) => {
  if (util.isLocal()) return
  Sentry.captureException(error)
}

export default {
  init,
  handleError,
  handleReactError
}
