import React from 'react'
import loadable from '@loadable/component'

import 'rc-slider/assets/index.css'

const RCSliderLoader = loadable.lib(() => import('rc-slider'))

function RCSlider(props) {
  return <RCSliderLoader fallback={null}>{({ default: RCSlider }) => <RCSlider {...props} />}</RCSliderLoader>
}

export default RCSlider
