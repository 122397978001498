import { store as reduxStore } from '../redux/store'
import util from './index'
import { setSearchDropOffLocation, setSearchPickupLocation, setUserDetails } from '../redux/slices'
import random from 'lodash/random'
import merge from 'lodash/merge'

const dev = {
  fillLocation() {
    reduxStore.dispatch(
      setSearchPickupLocation({
        value: '7193',
        label: 'San Francisco International Airport',
        type: 'airport'
      })
    )
    reduxStore.dispatch(
      setSearchDropOffLocation({
        value: '7193',
        label: 'San Francisco International Airport',
        type: 'airport'
      })
    )
  },

  async searchCar() {
    window.dev.fillLocation()
    await util.sleep(250)
    document.getElementById('car-search-button').click()
  },

  async selectRandomCar() {
    const bookBtnClassName = 'book-button'
    let length
    do {
      console.log('trying to find car result')
      await util.sleep(500)
      length = document.getElementsByClassName(bookBtnClassName).length
    } while (length === 0)

    const buttons = document.getElementsByClassName(bookBtnClassName)
    const randomIndex = random(length - 1)
    buttons[randomIndex].click()
  },

  fillDriverInfo() {
    reduxStore.dispatch(
      setUserDetails({
        firstName: 'Kagan',
        lastName: 'Sari',
        email: 'kagan42@rentcarla.com',
        phoneNumber: '5071776220'
      })
    )
  },

  _fillCreditCardInfo() {
    this.setState((prevState) =>
      merge({}, prevState, {
        creditCardInfo: {
          name: 'Kagan Sari',
          number: '4242-4242-4242-4242',
          expiryDate: '12/23',
          cvv: '123'
        }
      })
    )
  },

  async completeXO1() {
    do {
      console.log('waiting for rental cover/priceline calls')
      await util.sleep(500)
    } while (document.getElementsByClassName('loading').length)

    await util.sleep(250)
    window.dev.fillDriverInfo()
    document.getElementById('xo1-next-button').click()
  },

  async completeXO2() {
    do {
      await util.sleep(500)
      console.log('waiting for xo2')
    } while (!document.getElementById('cc-form'))

    window.dev.fillCreditCardInfo()
    await util.sleep(250)
    document.getElementById('xo2-next-button').click()
  },

  async makeReservation() {
    await this.searchCar()
    await util.sleep(250)
    await this.selectRandomCar()
    await util.sleep(250)
    await this.completeXO1()
    await util.sleep(250)
    await this.completeXO2()
  }
}

export default dev
