import styled from 'styled-components'
import { InputDS } from '../../CarlaSelect'

export const IconStyled = styled('img')`
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-12px);
  width: 24px;
  z-index: 10;
`

export const DateInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  background-color: white;
  background-color: ${(props) => (props.isDateInputSelected ? '#EFF1F8' : 'white')};
  position: relative;
  border: ${(props) => (props.isPageDynamic ? '1px solid #dfdfe7' : 'none')};
  cursor: pointer;

  &::before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: '';
    bottom: 0;
    left: 0;
    border-radius: 0;
    z-index: -5;
  }
`

export const InputArea = styled(InputDS)`
  cursor: pointer;
  width: 100%;
  padding-left: 42px;
  border-radius: 0;
  font-size: 16px;
  border: 2px solid transparent;
  border-right: 1px solid #dfdfe7;
  border-left: 1px solid #dfdfe7;
  background: #fff;
  font-weight: 500;
  height: auto;
  padding-top: 16px;
  padding-bottom: 16px;
  &:focus {
    border: solid 2px #ff7d0e;
  }
`

export const GroupTitle = styled('label')`
  margin-bottom: 5px;
  display: block;
  font-size: 14px;
  font-weight: 600;
`

export const BottomButtonWrapper = styled.div`
  width: 100%;
  position: relative;
  margin-top: 12px;
  padding: 25px;
  display: flex;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  justify-content: flex-end;
  align-items: center;
  background-color: #eff1f8;
  box-shadow: rgba(0, 0, 0, 0.12) 0px -4px 8px;
`

export const SubmitButton = styled.button`
  background-color: #ff7d0e;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 120px;
  padding: 12px;
  font-size: 16px;
  color: white;
  border-radius: 8px;
`
