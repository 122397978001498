/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import util from '../../util'
import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { SearchBoxForm } from './SearchBoxForm'
import { connect } from 'react-redux'
import { bool, func, string } from 'prop-types'
import { CarlinPropTypes } from '../../entities'
import SeeOnMap from './SeeOnMap'
import { FilterMenu, MobileFilterMenu } from './Filters'
import { setTagFilters, setCarSearchFilters } from './../../redux/slices'

import debug from 'debug'
import debounce from 'lodash/debounce'
import promoCodeLogo from '../../util/images/promo-code-double-icon.svg'
import { MobileHeaderBar } from './MobileHeaderBar'
import { OverMobileScreen } from './../../components/common/Screens'
import FilterTags from './../../components/FilterTags'

import CarInfoCard from '../../components/CarInfoCard'
import CarList from './../../components/CarList/CarList'
import CarSortDropdown from './../../components/CarSortDropdown/CarSortDropdown'

import './CarSearchResults.css'
import i18next from 'i18next'

const initialSelectedFilters = {
  carType: [],
  fuel: [],
  transmission: [],
  seats: [],
  cancellationPolicy: [],
  supplier: []
}

const log = debug('carla:order')

const RawCarSearchResults = styled(
  ({
    carSearchFilters,
    filterTags,
    sortingOption,
    sameLocation,
    pickupLocation,
    dropOffLocation,
    history,
    loading,
    error,
    data,
    searchArgs,
    promoCode,
    showSearchBox,
    searchExpanded,
    onBook,
    onSearchClicked,
    onToggleSearch,
    productHash,
    ...props
  }) => {
    //!!!!Filter tags is an array of pairs representing [[category, subcategory]]
    const [filters, setFilters] = useState(initialSelectedFilters)
    //For the numbers next to the subcategories
    const [filterCounts, setFilterCounts] = useState({})

    //Controlling whether the header is visible or not
    // eslint-disable-next-line
    const [headerOpen, setHeaderOpen] = useState(true)
    //Controls whether or not the sorting menu is open

    const [refreshAffirmUiOnce, setRefreshAffirmUiOnce] = useState(false)

    //Used for price per day.
    const howManyDays = util.howManyDays(searchArgs.pickupDateStr, searchArgs.dropOffDateStr)

    //when clicked on the checbox, if the subcategory is on the selected filters remove it, if it is not, add it
    //Do the same for the filter tags
    const selectFilter = (category, subcategory) => {
      let newFilter = carSearchFilters[category].map((e) => e)
      let newFilterTags = filterTags.map((e) => e)

      if (newFilter.includes(subcategory)) {
        //remove element from selectedFilters
        newFilter = newFilter.filter((item) => item !== subcategory)
        //Remove element from filterTags
        newFilterTags = newFilterTags.filter(([itemCat, itemSubCat]) => itemSubCat !== subcategory)
      } else {
        newFilter.push(subcategory)
        newFilterTags.push([category, subcategory])
      }

      let temp = { ...carSearchFilters }

      temp[category] = newFilter
      props.setCarSearchFilters(temp)
      props.setTagFilters(newFilterTags)
    }

    const clearAllFilters = () => {
      props.setCarSearchFilters(initialSelectedFilters)
      props.setTagFilters([])
    }

    useEffect(() => {
      const allFilters = {
        carType: [],
        fuel: [],
        transmission: [],
        seats: [],
        cancellationPolicy: [],
        supplier: []
      }

      // Get all avaliable filters from the array of cars so that we don't show any unrelevant filters
      if (data) {
        data.cars.map((carDets) => {
          if (!allFilters.carType.includes(carDets.car.typeDescription)) {
            allFilters.carType.sort()
            allFilters.carType.push(carDets.car.typeDescription)
          }
          if (!allFilters.fuel.includes(carDets.car.fuelDescription)) {
            allFilters.fuel.push(carDets.car.fuelDescription)
          }
          if (!allFilters.transmission.includes(carDets.car.transmissionDescription)) {
            allFilters.transmission.push(carDets.car.transmissionDescription)
          }
          if (!allFilters.seats.includes(carDets.car.seatCategory)) {
            allFilters.seats.push(carDets.car.seatCategory)
          }
          if (!allFilters.cancellationPolicy.includes(carDets.extras.cancellationPolicy)) {
            allFilters.cancellationPolicy.push(carDets.extras.cancellationPolicy)
          }
          if (!allFilters.supplier.includes(carDets.supplier.name)) {
            allFilters.supplier.sort()
            allFilters.supplier.push(carDets.supplier.name)
          }
          return 0
        })
      }
      setFilters(allFilters)
    }, [data])

    const determineFilterCounts = async () => {
      let tempFilterCounts = {}
      //Initialize filters
      for (const category in filters) {
        tempFilterCounts[category] = []
        filters[category].forEach((subcategory) => {
          tempFilterCounts[category][subcategory] = 0
        })
      }
      data.cars.forEach((car) => {
        //For each car, look into each category. We cannot traverse with for since every category information of the car is in a weird place
        //For car type
        let tempSelectedFilters = { ...carSearchFilters }
        tempSelectedFilters.carType = []
        if (isTheCarSelectedInFilters(car, tempSelectedFilters)) {
          tempFilterCounts.carType[car.car.typeDescription] += 1
        }

        //For fuel
        tempSelectedFilters = { ...carSearchFilters }
        tempSelectedFilters.fuel = []
        if (isTheCarSelectedInFilters(car, tempSelectedFilters)) {
          tempFilterCounts.fuel[car.car.fuelDescription] += 1
        }

        //For seats
        tempSelectedFilters = { ...carSearchFilters }
        tempSelectedFilters.seats = []
        if (isTheCarSelectedInFilters(car, tempSelectedFilters)) {
          tempFilterCounts.seats[car.car.seatCategory] += 1
        }

        //For transmission
        tempSelectedFilters = { ...carSearchFilters }
        tempSelectedFilters.transmission = []
        if (isTheCarSelectedInFilters(car, tempSelectedFilters)) {
          tempFilterCounts.transmission[car.car.transmissionDescription] += 1
        }

        //For cancellation policy
        tempSelectedFilters = { ...carSearchFilters }
        tempSelectedFilters.cancellationPolicy = []
        if (isTheCarSelectedInFilters(car, tempSelectedFilters)) {
          tempFilterCounts.cancellationPolicy[car.extras.cancellationPolicy] += 1
        }

        //For supplier
        tempSelectedFilters = { ...carSearchFilters }
        tempSelectedFilters.supplier = []
        if (isTheCarSelectedInFilters(car, tempSelectedFilters)) {
          tempFilterCounts.supplier[car.supplier.name] += 1
        }
      })
      setFilterCounts(tempFilterCounts)
    }

    const isTheCarSelectedInFilters = (carRentalProduct, carSearchFilters) => {
      const carTypeBool =
        carSearchFilters.carType.length === 0 || carSearchFilters.carType.includes(carRentalProduct.car.typeDescription)
      const fuelBool =
        carSearchFilters.fuel.length === 0 || carSearchFilters.fuel.includes(carRentalProduct.car.fuelDescription)
      const transmissionBool =
        carSearchFilters.transmission.length === 0 ||
        carSearchFilters.transmission.includes(carRentalProduct.car.transmissionDescription)
      const seatsBool =
        carSearchFilters.seats.length === 0 || carSearchFilters.seats.includes(carRentalProduct.car.seatCategory)
      const cancellationPolicyBool =
        carSearchFilters.cancellationPolicy.length === 0 ||
        carSearchFilters.cancellationPolicy.includes(carRentalProduct.extras.cancellationPolicy)
      const supplierBool =
        carSearchFilters.supplier.length === 0 || carSearchFilters.supplier.includes(carRentalProduct.supplier.name)

      return carTypeBool && fuelBool && transmissionBool && seatsBool && cancellationPolicyBool && supplierBool
    }

    useEffect(() => {
      if (data) {
        // renderCars()
        determineFilterCounts()
      }
      initUplift()
      refreshAffirm()
    }, [carSearchFilters, filters])

    const renderCars = async () => {
      // TODO: Need to verify why call affirm and uplift here
      setTimeout(() => {
        refreshAffirm()
        initUplift()
      }, 500)
    }

    useEffect(() => {
      if (!refreshAffirmUiOnce) {
        refreshAffirm()
        initUplift()
        setRefreshAffirmUiOnce(true)
      }
    }, [refreshAffirmUiOnce])

    const refreshAffirm = debounce(
      () => {
        if (window.affirm) {
          window.affirm.ui.ready(function () {
            window.affirm.ui.refresh()
          })
        }
      },
      500,
      { trailing: true, leading: false }
    )

    // TODO: init to many time
    const initUplift = debounce(
      () => {
        if (!window.Uplift) {
          ;(function (u, p, l, i, f, t, o, b, j) {
            u['UpLiftPlatformObject'] = f
            /* eslint-disable-next-line */
            ;(u[f] =
              u[f] ||
              function () {
                ;(u[f].q = u[f].q || []).push(arguments)
                /* eslint-disable-next-line */
              }),
              (u[f].l = 1 * new Date())
            /* eslint-disable-next-line */
            ;(b = p.createElement(l)), (j = p.getElementsByTagName(l)[0])
            b.async = 1
            b.src = i + '?id=' + t
            j.parentNode.insertBefore(b, j)
            o = u.location.host.match(/[\w-]+\.\w{2,3}(:\d+)?$/)
            if (o) o = o[0]
            u[f]('create', t, o)
          })(window, document, 'script', 'https://cdn.uplift-platform.com/a/up.js', 'up', util.getUPCode())
        }
        setTimeout(() => {
          if (window.Uplift) setUpReady()
          if (window.upReady) window.upReady()
        }, 300)
      },
      500,
      { trailing: true, leading: false }
    )

    const setUpReady = () => {
      if (window.upReady) log('upReady already exists.')
      if (!window.upReady) log("upReady doesn't exist.")
      window.upReady = () => {
        log('upReady called')
        initPayMonthly()
        window.Uplift.Payments.load(util.getMockDataUpliftLoadObj())
      }
    }

    function initPayMonthly() {
      try {
        window.Uplift.Payments.init({
          apiKey: '4exdzTns3o9Wv6qpkf013aOLw76iwK2TawtzmCvs',
          locale: 'en-US',
          currency: 'USD',
          checkout: false,
          channel: 'desktop'
        })
      } catch (err) {
        log('initPayMonthly->ERR:', err)
      }
    }

    window.upReady = function () {
      try {
        initPayMonthly()
        window.Uplift.Payments.load(util.getMockDataUpliftLoadObj())
      } catch (err) {
        log('window.upReady->ERR:', err)
      }
    }

    const searchParams = util.getUrlParams(props)
    const { pickupDateStr, dropOffDateStr } = searchParams
    let pickupDate = util.getDateFromAPI(pickupDateStr || searchArgs?.pickupDateStr)
    let dropOffDate = util.getDateFromAPI(dropOffDateStr || searchArgs?.dropOffDateStr)
    const timeRange = util.formatDateTimeRange(pickupDate, dropOffDate, i18next.resolvedLanguage)

    function ScrollTop() {
      window.scrollTo(0, 0)
    }

    const scrollToTop = () => {
      const scrollTarget = document.getElementById('car-search-results-container')
      if (scrollTarget) {
        scrollTarget.scrollIntoView({ behavior: 'smooth' })
      }
    }

    const isShowOnMapActive = props.location?.state && props.location?.state.from === 'availableAreas'

    if (!data) return null

    return (
      <div>
        <div className='car-search-results-container' id='car-search-results-container' style={{ overflow: 'hidden' }}>
          <div className='left-column-container' onScroll={ScrollTop}>
            {' '}
            {/* Left column container appears only in desktop. In mobile, only empty space.*/}
            {showSearchBox && (
              <SearchBoxForm
                onToggleSearch={onToggleSearch}
                searchExpanded={searchExpanded}
                onSearchClicked={onSearchClicked}
              />
            )}
            {isShowOnMapActive && (
              <div className='show-on-desktop'>
                <SeeOnMap history={history} />
              </div>
            )}
            <div className='show-on-desktop'>
              <FilterMenu
                filters={filters}
                selectedFilters={carSearchFilters}
                selectFilter={selectFilter}
                clearAllFilters={clearAllFilters}
                filterCounts={filterCounts}
              />
            </div>
          </div>
          <div className='right-column-container' onScroll={ScrollTop}>
            <div id='head-part'>
              {headerOpen && (
                <div className='mobile-info-card'>
                  <MobileHeaderBar
                    pickupLocationName={pickupLocation?.label}
                    dropOffLocationName={dropOffLocation?.label}
                    timeRange={timeRange}
                  />
                </div>
              )}
              {isShowOnMapActive && headerOpen && <SeeOnMap history={history} className='see-on-map' />}
            </div>
            <OverMobileScreen>
              <CarInfoCard isBundle={false} />
            </OverMobileScreen>
            <div className='tablet-filter-sort-container'>
              {isShowOnMapActive && <SeeOnMap history={history} className='see-on-map' />}
              <MobileFilterMenu
                filters={filters}
                selectedFilters={carSearchFilters}
                selectFilter={selectFilter}
                clearAllFilters={clearAllFilters}
                filterCounts={filterCounts}
                scrollToTop={scrollToTop}
              />
              <CarSortDropdown />
            </div>
            <div className='filter-tags-and-sort-menu'>
              <FilterTags
                filterTags={filterTags}
                setTagFilters={props.setTagFilters}
                filters={carSearchFilters}
                setFilters={props.setCarSearchFilters}
                clearAllFilters={clearAllFilters}
              />
              <div className='desktop-sort-container'>
                <CarSortDropdown />
              </div>
            </div>
            {props.modificationMessage}
            {promoCode && promoCode !== null && promoCode !== '' && (
              <div className='car-search-promo-code-applied'>
                <img loading='eager' src={promoCodeLogo} alt='promo_code_applied_icon' />
                <p style={{ margin: 0 }}>Promo code applied</p>
              </div>
            )}
            <div className='mobile-scroll-container'>
              <CarList
                carProducts={data.cars || []}
                howManyDays={howManyDays}
                refreshAffirmUiOnce={refreshAffirmUiOnce}
                setRefreshAffirmUiOnce={setRefreshAffirmUiOnce}
                onBook={onBook}
              />
              <div className='mobile-sticky-elements-container'>
                <MobileFilterMenu
                  filters={filters}
                  selectedFilters={carSearchFilters}
                  selectFilter={selectFilter}
                  clearAllFilters={clearAllFilters}
                  filterCounts={filterCounts}
                  scrollToTop={scrollToTop}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
)`
  .info-card {
    color: ${themeGet('colors.textGrey')};
  }

  #head-part {
    height: min-content;
  }
`

RawCarSearchResults.propTypes = {
  loading: bool.isRequired,
  error: bool,
  data: CarlinPropTypes.CarSearchResponse,
  showSearchBox: bool,
  searchExpanded: bool,
  onBook: func.isRequired,
  onSearchClicked: func.isRequired,
  onToggleSearch: func.isRequired,
  productHash: string
}

export const CarSearchResults = connect(
  (state) => ({
    carSearchFilters: state.carSearchFilters,
    filterTags: state.filterTags,
    pickupLocation: state.searchArgs.pickupLocation,
    dropOffLocation: state.searchArgs.dropOffLocation,
    sameLocation: state.searchArgs.isSameLocation
  }),
  { setCarSearchFilters, setTagFilters }
)(RawCarSearchResults)
