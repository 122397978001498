import React from 'react'
import { Stepper, Step, makeStyles, StepLabel } from '@material-ui/core'
import IconComplete from './icon-complete.svg'
import IconActive from './icon-active.svg'
import IconDefault from './icon-default.svg'

const useStyles = makeStyles({
  root: {
    padding: 0,
    background: 'transparent',
    '& .MuiStepConnector-line': {
      borderWidth: 4
    },
    '& .MuiStepConnector-alternativeLabel': {
      top: 10,
      left: 'calc(-50% + 12px)',
      right: 'calc(50% + 12px)'
    }
  },
  step: {
    '& .MuiStepConnector-completed .MuiStepConnector-line': {
      borderColor: 'var(--colorSuccess, #3EAA77)'
    },
    '& .MuiStepConnector-active .MuiStepConnector-line': {
      borderColor: 'var(--colorSuccess, #3EAA77)'
    }
  },
  label: {
    '&$completed, &$active, &.Mui-disabled .MuiTypography-root': {
      color: 'var(--textBlack)',
      padding: 8,
      fontWeight: 700,
      margin: 0
    }
  },
  completed: {},
  active: {}
})

function getIcon(index, currStep) {
  if (currStep > index) return IconComplete
  if (currStep === index) return IconActive
  return IconDefault
}

export default function LinearStepper({ steps, activeStep = 0, ...rest }) {
  const classes = useStyles()
  return (
    <Stepper alternativeLabel activeStep={activeStep} classes={{ root: classes.root }} {...rest}>
      {steps.map((label, index) => (
        <Step key={label} classes={{ root: classes.step }}>
          <StepLabel
            classes={{
              alternativeLabel: classes.label,
              completed: classes.completed,
              active: classes.active
            }}
            StepIconComponent={() => <img src={getIcon(index, activeStep)} alt='icon' />}
          >
            {label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}
