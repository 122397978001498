import styled from 'styled-components'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { themeGet } from 'styled-system'
import { Button } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'

export const DialogTitleStyled = styled(DialogTitle)`
  &.MuiDialogTitle-root {
    padding: 24px;
    h2 {
      color: var(--Text-Primary, #525266);
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      font-family: 'Quicksand', 'Quicksand Regular', sans-serif;

      width: 100%;
      margin: 0 auto;

      @media (min-width: ${themeGet('breakpoints.1')}px) {
        width: 70%;
      }
    }
  }
`

export const DialogContentStyled = styled(DialogContent)`
  &.MuiDialogContent-root {
    color: var(--Text-Primary, #525266);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 0 24px 24px 24px;
    border-radius: 12px;
    background: #fff;
    p {
      margin: 0 0 12px;
    }
    section {
      margin-bottom: 30px;
    }
  }
`

export const CloseButton = styled(IconButton)`
  &.MuiIconButton-colorPrimary {
    color: #ff7d0e;
    position: absolute;
    top: 10px;
    right: 6px;
    cursor: pointer;
    background: none;
    border: none;
    font-size: 22px;
  }
  @media (min-width: ${themeGet('breakpoints.1')}px) {
    top: 20px;
    right: 16px;
  }
  @media (min-width: ${themeGet('breakpoints.0')}px) {
    top: 20px;
    right: 16px;
  }
`

export const ButtonGroupsStyled = styled('div')`
  display: flex;
  gap: 12px;
  justify-content: center;
  flex-direction: column;
  @media (min-width: ${themeGet('breakpoints.1')}px) {
    flex-direction: row;
  }
`
export const ButtonStyled = styled(Button)`
  &.MuiButton-containedPrimary {
    border-radius: 8px;
    border: 1px solid #ff7d0e;
    background: #ff7d0e;
    padding: 10px 20px;
    text-transform: none;

    color: var(--White, #fff);
    font-size: 16px;
    font-weight: 700;
    &:hover {
      background: #ff7d0e;
    }
    width: 100%;
    @media (min-width: ${themeGet('breakpoints.1')}px) {
      width: auto;
      &.MuiButton-fullWidth {
        width: 100%;
      }
    }
  }
  &.MuiButton-outlined {
    border-radius: 8px;
    border: 1px solid #ff7d0e;
    background: var(--White, #fff);
    padding: 10px 20px;
    text-transform: none;

    color: #ff7d0e;
    font-size: 16px;
    font-weight: 700;
    width: 100%;
    @media (min-width: ${themeGet('breakpoints.1')}px) {
      width: auto;
      &.MuiButton-fullWidth {
        width: 100%;
      }
    }
  }
`
export const GreenTitleStyled = styled('h6')`
  color: var(--Icon-Success, #3eaa77);
  font-size: 15px;
  font-weight: 600;
  margin: 0 0 8px 0;
  display: block;
  padding-right: 20px;
`
export const EmailFormWrapperStyled = styled('div')`
  color: var(--Text-Primary, #525266);
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  p {
    text-align: center;
    margin: 0 0 12px 0;
  }
`

export const SuccessDialogContentStyled = styled(DialogContent)`
  &.MuiDialogContent-root {
    color: var(--Text-Primary, #525266);
    font-size: 15px;
    font-weight: 500;
    padding: 20px;
    border-radius: 12px;
    background: #fff;
    text-align: center;
    p {
      margin: 0;
    }
    h6 {
      color: var(--Icon-Success, #3eaa77);
      margin: 0;
      margin-bottom: 4px;
      font-size: 18px;
      font-weight: 600;
    }
  }
`
