import React from 'react'
import styled from 'styled-components'
import { Dialog } from '@material-ui/core'
import IconGreenCheck from '../../../util/images/green-check-icon-small.svg'
import IconExtendedProtection from '../../../util/images/icon-extended-protection.svg'
import { InfoTextWithLeftIcon } from '../../styled'
import { useTranslation } from 'react-i18next'
import IconClose from '../../../util/images/icon-close-cancel.svg'

const ModalContainer = styled.div`
  width: min(500px, 90%);
`

const Header = styled.div`
  background-color: var(--colorPrimary);
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 12px 12px 0 0;
  padding: 12px;
  & p {
    margin: 0;
    color: white;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
  & img {
    filter: brightness(10); //turn the image to white
  }
  & button {
    border: none;
    outline: none;
    position: absolute;
    background: url(${IconClose}) no-repeat center;
    height: 24px;
    width: 24px;
    top: 16px;
    right: 12px;
    cursor: pointer;
  }
`

const Body = styled.div`
  background-color: white;
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 0 0 12px 12px;
`

const SubTitle = styled.p`
  margin: 4px 0 0 0;
  color: var(--Actions-Primary, #ff7d0e);
  text-align: center;
  font-family: Quicksand;
  font-size: 14px;
  font-weight: 700;
  line-height: 129%;
`

export default function ProtectionPlanModal(props) {
  const { title, info, show, onClose } = props
  const { t: TRANSLATOR } = useTranslation()
  return (
    <Dialog open={show} onClose={onClose} PaperComponent={ModalContainer}>
      <Header>
        <img src={IconExtendedProtection} alt='Extended Protection' />
        <p>{title}</p>
        <button type='button' onClick={onClose} />
      </Header>
      <Body>
        {info.inclusions.map((text, index) => (
          <InfoTextWithLeftIcon key={index} icon={IconGreenCheck} bg='white' align='start' gap={8} fontSize={14}>
            {text}
          </InfoTextWithLeftIcon>
        ))}
        <SubTitle>{TRANSLATOR('xo1:protectionPlan:textContent')}</SubTitle>
      </Body>
    </Dialog>
  )
}
