import PropTypes from 'prop-types'
import React from 'react'
import CheckIcon from './icon-check.svg'
import { StepperStyled, StepStyled, StepLabel, StepIcon } from './Stepper.styles'

Steppers.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.string).isRequired
}

export default function Steppers({ steps, activeStep = 0 }) {
  return (
    <StepperStyled alternativeLabel activeStep={activeStep}>
      {steps.map((label, index) => (
        <>
          <StepStyled key={label} $active={index === activeStep}>
            <StepLabel>{label}</StepLabel>
            <hr />
            <StepIcon $active={index === activeStep}>
              <img width={20} height={20} src={CheckIcon} alt='check' />
            </StepIcon>
          </StepStyled>
        </>
      ))}
    </StepperStyled>
  )
}
