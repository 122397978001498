import React from 'react'
import { Box, Container } from '@material-ui/core'
import IconArrowBack from './assets/icon-arrow-back.svg'
import IconDropOff from '../../util/images/icon-pickup.svg'
import IconPickUp from '../../util/images/icon-dropoff.svg'
import IconSelectedAncillary from './assets/icon-selected-ancillary.svg'
import BlueInfoIcon from '../../util/images/icon-info-blue.svg'
import { ReactComponent as IconInformation } from './assets/icon-information.svg'
import { ReactComponent as IconClockRunning } from './assets/icon-clock-running.svg'
import util from '../../util'
import { Text, Image, Anchor, Span, StyledGrid, ManageButton } from './styles'
import DefaultCarImage from '../../util/images/placeholder-car.png'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { TooltipUI } from '../../UI/Tooltip'
import { ViewBreakdownButton } from './styles'
import loadable from '@loadable/component'
import VoucherDetail from '../VoucherDetail'
import { useSelector } from 'react-redux'
import { useHistory, Redirect } from 'react-router-dom'
import { ReservedCarCardMobile } from './ReservedCarCardMobile'
import { MobileScreen, OverMobileScreen } from '../common/Screens'

const PriceBreakdownModal = loadable(() => import('./modals/PriceBreakdownModal'), { ssr: false })

const OnRequestBlockStyled = styled('div')`
  display: flex;
  align-items: center;
  gap: 12px;
  > p {
    display: flex;
    color: var(--Actions-Secondary, #0076ff);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    display: flex;
    align-items: center;
    gap: 3px;
  }
`

export function ReservedCarCard() {
  const response = useSelector((state) => state.reservationInfoRetrieval.response)
  const { t: TRANSLATOR, i18n } = useTranslation()
  const [open, setOpen] = React.useState(false)
  const history = useHistory()

  if (!response) {
    return <Redirect to='/' />
  }

  const {
    rentalProduct,
    dtl,
    ancillariesPurchased,
    confirmationNo,
    userInfo,
    reservationId,
    status,
    statusInfo,
    priceBreakDown
  } = response

  return (
    <>
      <MobileScreen customBreakpoint={750}>
        <ReservedCarCardMobile {...response} />
      </MobileScreen>
      <OverMobileScreen customBreakpoint={750}>
        <Container>
          <StyledGrid container alignItems='center' my={2} gap={0.5}>
            <Image src={IconArrowBack} alt='icon-arrow-back' />
            <Anchor onClick={history.goBack}>{TRANSLATOR('reservations:reserved:back')}</Anchor>
          </StyledGrid>
          <Box bgcolor='white' p={4} borderRadius={12} border='1px solid #DFDFE7'>
            <StyledGrid container gap={6}>
              <StyledGrid item>
                <Image src={rentalProduct.supplierUrl} alt='supplier' height={40} display='block' mb={1.5} />
                <Image
                  src={rentalProduct.imageUrl}
                  alt='car'
                  width={300}
                  onError={(e) => (e.target.src = DefaultCarImage)}
                />
              </StyledGrid>
              <StyledGrid item>
                <Text fontSize={24} mb={2.5}>
                  {rentalProduct.name}{' '}
                  <Span>
                    {TRANSLATOR('reservations:reserved:similar')} {rentalProduct.typeDescription}
                  </Span>
                </Text>
                <Box borderBottom='1px solid #DFDFE7' borderTop='1px solid #DFDFE7' py={2.5}>
                  <StyledGrid container justifyContent='space-between'>
                    <StyledGrid item>
                      <StyledGrid container>
                        <Image src={IconPickUp} alt='icon-pickup' height={24} width={24} fit='none' />
                        <StyledGrid item>
                          <Text>{TRANSLATOR('homePage:pickUp')}</Text>
                          <Text fontWeight={400}>{dtl.pickup.address}</Text>
                          <Text fontSize={17} fontWeight={400}>
                            {util.formatDate(dtl.pickup.dateTime, i18n.resolvedLanguage)}
                          </Text>
                        </StyledGrid>
                      </StyledGrid>
                    </StyledGrid>
                    <StyledGrid item>
                      <StyledGrid container>
                        <Image src={IconDropOff} alt='icon-dropoff' height={24} width={24} fit='none' />
                        <StyledGrid item>
                          <Text>{TRANSLATOR('homePage:dropOff')}</Text>
                          <Text fontWeight={400}>{dtl.dropOff.address}</Text>
                          <Text fontSize={17} fontWeight={400}>
                            {util.formatDate(dtl.dropOff.dateTime, i18n.resolvedLanguage)}
                          </Text>
                        </StyledGrid>
                      </StyledGrid>
                    </StyledGrid>
                  </StyledGrid>
                  <StyledGrid container alignItems='center' mt={2.5} gap={1}>
                    <Image src={BlueInfoIcon} alt='blue-info-icon' />
                    <Text color='#0064D6' fontWeight={500}>
                      {TRANSLATOR('reservations:reserved:info')}
                    </Text>
                  </StyledGrid>
                </Box>
                <StyledGrid container mt={2.5} gap={2}>
                  {ancillariesPurchased.map((ancillary, i) => (
                    <StyledGrid item key={i}>
                      <StyledGrid container gap={0.5}>
                        <Image src={IconSelectedAncillary} alt='icon-selected-ancillary' />
                        <Text fontWeight={400}>{ancillary}</Text>
                      </StyledGrid>
                    </StyledGrid>
                  ))}
                </StyledGrid>
              </StyledGrid>
            </StyledGrid>
            <StyledGrid
              container
              alignItems='center'
              borderBottom='1px solid #DFDFE7'
              borderTop='1px solid #DFDFE7'
              py={2.25}
              px={0}
              mt={4.5}
            >
              <StyledGrid item borderRight='1px solid #DFDFE7' pr={1.5}>
                <Text fontWeight={400}>{TRANSLATOR('success:confirmNo')}:</Text>
                {status === 'DONE' ? (
                  <Text fontSize={24}>{confirmationNo}</Text>
                ) : (
                  <OnRequestBlockStyled>
                    <Text fontSize={24}>
                      <IconClockRunning /> {TRANSLATOR('success:onRequest')}
                    </Text>
                    <TooltipUI title={statusInfo}>
                      <div>
                        <IconInformation />
                      </div>
                    </TooltipUI>
                  </OnRequestBlockStyled>
                )}
              </StyledGrid>
              <StyledGrid item pl={1.5} flexGrow={1}>
                <StyledGrid container justifyContent='space-between' alignItems='center'>
                  <StyledGrid item>
                    <Text fontWeight={400}>{TRANSLATOR('xo1:driverInfo:inputEmail')}</Text>
                    <Text fontSize={24}>{userInfo.email}</Text>
                  </StyledGrid>
                  <StyledGrid item>
                    <Text fontSize={24} fontWeight={500}>
                      {util.formatMoneyFloored(
                        priceBreakDown[priceBreakDown.length - 1].price.price,
                        rentalProduct.currency
                      )}{' '}
                      {TRANSLATOR('reservations:reserved:total')}
                    </Text>
                    <ViewBreakdownButton onClick={() => setOpen(true)}>
                      {TRANSLATOR('reservations:reserved:viewBreakdown')}
                    </ViewBreakdownButton>
                  </StyledGrid>
                </StyledGrid>
              </StyledGrid>
            </StyledGrid>
            <Text fontSize={18} fontWeight={700} color='#FF7D0E' mt={2}>
              {TRANSLATOR('reservations:reserved:driverDetails')}
            </Text>
            <Text mt={1.5}>
              {userInfo.firstName} {userInfo.lastName}
            </Text>
            <Text fontWeight={400} mt={1.5}>
              {userInfo.phoneNumber}
            </Text>
            <StyledGrid container alignItems='center' justifyContent='space-between'>
              <StyledGrid item>
                <StyledGrid container gap={8} alignItems='center'>
                  <Text fontSize={18}>
                    <Span fontSize={16} fontWeight={400}>
                      {TRANSLATOR('reservations:reserved:voucher')}:{' '}
                    </Span>
                    {reservationId}
                  </Text>
                  <VoucherDetail />
                </StyledGrid>
              </StyledGrid>
              <StyledGrid item>
                <ManageButton onClick={() => history.push('/my-reservations/modification-options')}>
                  {TRANSLATOR('reservations:modification:manageBooking')}
                </ManageButton>
              </StyledGrid>
            </StyledGrid>
          </Box>
          <PriceBreakdownModal
            open={open}
            handleClose={() => setOpen(false)}
            rentalProduct={rentalProduct}
            priceBreakDown={priceBreakDown}
          />
        </Container>
      </OverMobileScreen>
    </>
  )
}
