import React, { useState } from 'react'
import styled from 'styled-components'
import AddAncillary from '../../util/images/icon-add-ancillary.svg'
import AncillaryChecked from '../../util/images/icon-ancillary-checked.svg'
import money from '../../util/money'
import { PeaceOfMindModal } from './modals/PeaceOfMindModal'
import util from '../../util'
import { useTranslation } from 'react-i18next'
import IconExtendedProtection from '../../util/images/icon-extended-protection.svg'
import ProtectionPlanModal from './modals/ProtectionPlanModal'

const ExtendedPOMModal = styled((props) => {
  const { t } = useTranslation()
  function handleAddProtection() {
    props.updatedSelections({ [props.ancillaryId]: true })
    props.cancel()
  }

  return (
    <div className={props.className}>
      <div class='container'>
        <PeaceOfMindModal cancel={props.cancel} />
        <button type='button' className='add-protection' onClick={handleAddProtection}>
          {t('xo1:popupPeaceOfMind:getThisProtection')}! {util.formatMoney(props.price.price, props.price.currency)}
        </button>
      </div>
    </div>
  )
})`
  @media screen and (min-width: 577px) {
    display: flex;
    align-items: center;
    .container {
      padding: 0;
      max-width: 576px;
      margin: auto;
    }
  }

  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 999;
  inset: 0;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);

  .container {
    background-color: white;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    padding-bottom: 16px;
  }

  .add-protection {
    margin: 0 24px;
    padding: 0;
    min-height: 50px;
    border: none;
    border-radius: 8px;
    background: #ff7d0e;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    cursor: pointer;
  }
`

// TODO: Carlar will remove it
export const Upgrade = styled((props) => {
  const { title, priceText, discountInfoText, subtitle, deselectedSubtitle, id, price, type, info } =
    props.ancillaryProduct
  const { updatedSelections, isInsurance = false, selectedAncillaries } = props
  let check = selectedAncillaries.has(id)
  const [show, setShow] = useState(false)
  const { t } = useTranslation()

  function handleCardClick(e) {
    updatedSelections({ [id]: !check })
    check = !check
  }

  function handleButton(e) {
    e.stopPropagation()
    setShow(true)
  }

  return (
    <div className={props.className}>
      <div className={check ? 'ancillary-card selected' : 'ancillary-card'} onClick={handleCardClick}>
        <img src={check ? AncillaryChecked : AddAncillary} alt='icon_add_ancillary' className='icon' />
        <div className='ancillary'>
          <div className='title-price'>
            <div className='title'>
              <p>{title}</p>
              {isInsurance && <img src={IconExtendedProtection} alt='icon_protection_extended' />}
              {(type === 'PeaceOfMind' || isInsurance) && (
                <button type='button' onClick={handleButton}>
                  {t('xo1:learn')}
                </button>
              )}
            </div>
            <div className='price-discount'>
              <p className='price'>{money.parsePriceText(priceText)}</p>
              {discountInfoText && <p className='discount'>{money.parseDiscount(priceText)}</p>}
            </div>
          </div>
          {!isInsurance && <p className='details'>{check ? subtitle : deselectedSubtitle}</p>}
          {isInsurance && <p className='details'>{t('xo1:extendedProtection')}</p>}
        </div>
        {type === 'RoadsideAssistance' && <span className='best-price'>{t('xo1:bestPrice')}</span>}
      </div>
      {show && !isInsurance && (
        <ExtendedPOMModal
          updatedSelections={updatedSelections}
          ancillaryId={id}
          cancel={() => setShow(false)}
          price={price}
        />
      )}
      {isInsurance && (
        <ProtectionPlanModal show={show && isInsurance} onClose={() => setShow(false)} title={title} info={info} />
      )}
    </div>
  )
})`
  .ancillary-card {
    display: flex;
    gap: 12px;
    align-items: center;
    padding: 12px 12px 16px 12px;
    border-radius: 4px;
    border: 1px solid #dfdfe7;
    background: linear-gradient(180deg, #fff 0%, #fafafa 100%);
    cursor: pointer;
    position: relative;
  }

  .selected {
    border: 1px solid #ff7d0e;
    background: #fff9f5;
    border-radius: 4px;
  }

  & p {
    margin: 0;
  }

  .ancillary {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .title {
    display: flex;
    gap: 12px;
    align-items: center;

    & img {
      margin-left: -8px;
    }

    & p {
      font-weight: 600;
      line-height: 24px;
    }

    & button {
      color: #525266;
      text-align: center;
      font-family: Quicksand;
      font-size: 11px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      padding: 5px 8px;
      border-radius: 9999px;
      border: 1px solid #dfdfe7;
      background: #eff1f8;
      cursor: pointer;
      ${(props) => props.isInsurance && 'margin-left: -4px;'}
    }
  }

  .price-discount {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  .price {
    color: #525266;
    font-family: Quicksand;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
  }

  .discount {
    color: #fe6f00;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
  }

  .details {
    color: #525266;
    font-family: Quicksand;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  .best-price {
    border-radius: 8px 0px 3px 0px;
    background: #ff7d0e;
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 2px 8px;
    color: white;
    font-size: 13px;
    font-weight: 600;
    line-height: 100%;
  }
`
