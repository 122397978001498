import React, { useMemo, useState } from 'react'
import InsuranceCard from './InsuranceCard'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedAncillaries } from '../../redux/slices'
import { InsuranceListWrapper, RadioGroup } from './styles'
import NoInsuranceCard from './NoInsuranceCard'

export function InsuranceList(props) {
  const { insurances, validateInsuranceList, hasError } = props
  const reordered = useMemo(() => [insurances[1], insurances[0], insurances[2]], [insurances])
  const ids = useMemo(() => insurances.map((insurance) => insurance.id), [insurances])
  const [noInsChecked, setNoInsChecked] = useState(false)

  const selectedAncillaries = useSelector((state) => state.ancillary.selectedAncillaries)
  const selectedValue = selectedAncillaries.find((id) => ids.includes(id))

  const dispatch = useDispatch()

  function handleSelect(e) {
    validateInsuranceList()
    const id = e.target.value

    if (!id) {
      const updated = selectedAncillaries.filter((id) => !ids.includes(id))
      setNoInsChecked(true)
      dispatch(setSelectedAncillaries(updated))
      return
    }

    if (!selectedAncillaries.includes(id)) {
      const updated = selectedAncillaries.filter((id) => !ids.includes(id)).concat([id])
      setNoInsChecked(false)
      dispatch(setSelectedAncillaries(updated))
    }
  }

  return (
    <RadioGroup onChange={handleSelect}>
      <InsuranceListWrapper>
        {reordered.map(
          (insurance, index) =>
            insurance && (
              <InsuranceCard
                key={index}
                index={index}
                value={insurance.id}
                checked={selectedValue === insurance.id}
                insurance={insurance}
                error={hasError}
              />
            )
        )}
        {reordered.length > 0 && <NoInsuranceCard checked={noInsChecked && !selectedValue} error={hasError} />}
      </InsuranceListWrapper>
    </RadioGroup>
  )
}
