import React from 'react'
import { Box, Grid, Text } from '../styles'
import { useTranslation } from 'react-i18next'
import util from '../../../util'
import PriceBreakdown from './PriceBreakdown'
import { InfoTextWithLeftIcon } from '../styles'
import IconSeat from '../../../util/images/icon-seat.svg'
import IconInsurance from '../../../util/images/icon-insurance.svg'
import IconLocation from '../assets/icon-location.svg'
import IconManual from '../../../util/images/icon-manual.svg'
import IconAutomatic from '../../../util/images/icon-automatic.svg'

export default function BookingCard({ product, modificationPriceDifference, carSearchArgs }) {
  const { t: TRANSLATOR } = useTranslation()

  const transmissionIcon = util.getTransmission(product.car.classCode) === 'M' ? IconManual : IconAutomatic

  return (
    <>
      <Box>
        <Grid container item wrap='nowrap' style={{ gap: 16 }}>
          <Grid item>
            <img src={product.car.imageUrl} alt='car' width={176} height={'auto'} />
          </Grid>
          <Grid container item xs={7} wrap='nowrap' direction='column' justifyContent='center'>
            <Grid item>
              <Text fontSize={14} fontWeight={600}>
                {product.car.groupDescription}
              </Text>
              <Text fontSize={14} fontWeight={700}>
                {product.car.name}
              </Text>
            </Grid>
            <Grid item>
              <img src={product.supplier.logoUrl} alt='logo' height={24} width='auto' />
            </Grid>
          </Grid>
        </Grid>
        <Grid container style={{ gap: 12, marginTop: 8 }}>
          <Text icon={transmissionIcon} fontSize={14} fontWeight={400}>
            {product.car.transmissionDescription}
          </Text>
          <Text icon={IconSeat} fontSize={14} fontWeight={400}>
            {product.car.seatCount}
          </Text>
          {product.extras.insured && (
            <Text icon={IconInsurance} iconsize={20} fontSize={14} fontWeight={400}>
              {TRANSLATOR('carListing:insurance')}
            </Text>
          )}
        </Grid>
      </Box>
      <Box>
        <Text fontSize={14} fontWeight={700}>
          {TRANSLATOR('homePage:pickUp')}
        </Text>
        <Text icon={IconLocation} fontSize={14} fontWeight={400}>
          {product.stationPair.pickup.address}
        </Text>
        <Text fontSize={14} fontWeight={400} ml={3}>
          {util.formatDate(carSearchArgs?.pickupDateTime)}
        </Text>
        <Text fontSize={14} fontWeight={700} mt={2}>
          {TRANSLATOR('homePage:dropOff')}
        </Text>
        <Text icon={IconLocation} fontSize={14} fontWeight={400}>
          {product.stationPair.dropOff.address}
        </Text>
        <Text fontSize={14} fontWeight={400} ml={3} mb={2}>
          {util.formatDate(carSearchArgs?.dropOffDateTime)}
        </Text>
      </Box>
      <InfoTextWithLeftIcon fullwidth={true}>
        {modificationPriceDifference.totalDifference < 0
          ? TRANSLATOR('reservations:modification:lessPaymentInfo', {
              price: util.formatMoneyFloored(
                Math.abs(modificationPriceDifference.totalDifference),
                product.price.currency
              )
            })
          : TRANSLATOR('reservations:modification:paymentInfo')}
      </InfoTextWithLeftIcon>
      <PriceBreakdown {...modificationPriceDifference} />
    </>
  )
}
