import React from 'react'
import { Dialog } from '@material-ui/core'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const ModalContainer = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: column;
  gap: 10px;
  border-radius: 8px;
  background: var(--White, #fff);
`

const Header = styled.p`
  color: #ff7d0e;
  text-align: center;
  font-size: 15px;
  font-weight: 600;
`

const Title = styled.p`
  color: #525266;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
`

const Action = styled.a`
  padding: 12px;
  text-decoration: none;
  border-radius: 4px;
  background: #ff7d0e;
  color: var(--White, #fff);
  text-align: center;
  font-size: 18px;
  font-weight: 600;
`

export function EmailWarningModal({ href, open, onClose }) {
  const { t: TRANSLATOR } = useTranslation()
  return (
    <Dialog PaperComponent={ModalContainer} open={open} onClose={onClose}>
      <Header>{TRANSLATOR('reservations:modification:modals:email:header')}</Header>
      <Title>{TRANSLATOR('reservations:modification:modals:email:title')}</Title>
      <Action href={href}>{TRANSLATOR('homePage:contactUs')}</Action>
    </Dialog>
  )
}
