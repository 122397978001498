import { useQuery } from 'react-query'
import API from '../../../services/API'

export default function useCompleteModification(modifyArgs) {
  const {
    reservationId: oldReservationId,
    type: modificationType,
    userInfo,
    modificationPriceDifference,
    product,
    paymentOption
  } = modifyArgs

  const details = { product, userInfo }
  const payNowDifference = {
    price: modificationPriceDifference?.totalPayNowDifference,
    currency: modificationPriceDifference?.currency
  }

  return useQuery(
    ['completeModification', oldReservationId],
    () => {
      return API.completeModifyReservation(oldReservationId, details, payNowDifference, modificationType, paymentOption)
    },
    {
      enabled: !!modifyArgs,
      cacheTime: 0,
      staleTime: 0
    }
  )
}
