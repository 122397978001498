import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes, { object } from 'prop-types'
import CarCard from './../../components/CarListing/CarCard'
import { isEmpty } from 'lodash'
import { CarListingWrapper } from './CarList.styles'
import { Virtuoso } from 'react-virtuoso'
import MajorSuppliersBannerCard from '../../components/CarListing/MajorSuppliersBannerCard'
import { checkFirstNCarProductForMajorSupplier } from '../CarListing/Suppliers'

const recommendedSort = (a, b) => {
  if (a.extras.score < b.extras.score) {
    return 1
  } else if (b.extras.score < a.extras.score) {
    return -1
  } else {
    return 0
  }
}
const prcLowToHighSort = (a, b) => {
  if (a.price.total < b.price.total) {
    return -1
  } else if (b.price.total < a.price.total) {
    return 1
  } else {
    return 0
  }
}
const prcHighToLowSort = (a, b) => {
  if (a.price.total < b.price.total) {
    return 1
  } else if (b.price.total < a.price.total) {
    return -1
  } else {
    return 0
  }
}

export const sortFunctionMap = {
  'Price (low to high)': prcLowToHighSort,
  'Price (high to low)': prcHighToLowSort,
  Recommended: recommendedSort
}

const ScrollSeekPlaceholder = ({ height }) => (
  <div
    style={{
      height,
      padding: '8px',
      boxSizing: 'border-box',
      overflow: 'hidden'
    }}
  >
    <div
      style={{
        background: '#eee'
      }}
    ></div>
  </div>
)

function CarList({ carProducts, howManyDays, refreshAffirmUiOnce, setRefreshAffirmUiOnce, onBook }) {
  const sortingOptions = useSelector((state) => state.sortingOption)
  const carSearchFilters = useSelector((state) => state.carSearchFilters)

  const carProductsApplyFilter = useMemo(() => {
    const { carType, fuel, transmission, seats, cancellationPolicy, supplier } = carSearchFilters
    if (
      isEmpty(carType) &&
      isEmpty(fuel) &&
      isEmpty(transmission) &&
      isEmpty(seats) &&
      isEmpty(cancellationPolicy) &&
      isEmpty(supplier)
    ) {
      return carProducts
    }
    return carProducts.filter(
      (carProduct) =>
        (isEmpty(carType) || carType.includes(carProduct.car.typeDescription)) &&
        (isEmpty(fuel) || fuel.includes(carProduct.car.fuelDescription)) &&
        (isEmpty(transmission) || transmission.includes(carProduct.car.transmissionDescription)) &&
        (isEmpty(seats) || seats.includes(carProduct.car.seatCategory)) &&
        (isEmpty(cancellationPolicy) || cancellationPolicy.includes(carProduct.extras.cancellationPolicy)) &&
        (isEmpty(supplier) || supplier.includes(carProduct.supplier.name))
    )
  }, [carSearchFilters, carProducts])

  const carProductsSorted = useMemo(() => {
    return carProductsApplyFilter.sort(sortFunctionMap[sortingOptions[0]])
  }, [carProductsApplyFilter, sortingOptions])

  const haveMajorSupplier = useMemo(() => {
    return checkFirstNCarProductForMajorSupplier(carProductsSorted, carProductsSorted.length)
  }, [carProductsSorted])

  const showBanner = (index) =>
    index === 10 && !checkFirstNCarProductForMajorSupplier(carProductsSorted, 10) && haveMajorSupplier

  if (isEmpty(carProductsSorted)) return null

  return (
    <CarListingWrapper>
      <Virtuoso
        useWindowScroll
        totalCount={carProductsSorted.length}
        components={{ ScrollSeekPlaceholder }}
        itemContent={(index) => (
          <>
            {showBanner(index) && (
              <div style={{ padding: '8px 0' }}>
                <MajorSuppliersBannerCard cars={carProductsSorted} />
              </div>
            )}
            <div style={{ padding: '8px 0' }}>
              <CarCard
                carRentalProduct={carProductsSorted[index]}
                howManyDays={howManyDays}
                refreshAffirmUiOnce={refreshAffirmUiOnce}
                setRefreshAffirmUiOnce={setRefreshAffirmUiOnce}
                onBook={onBook}
              />
            </div>
          </>
        )}
        scrollSeekConfiguration={{
          enter: (velocity) => Math.abs(velocity) > 1500,
          exit: (velocity) => Math.abs(velocity) < 1000
        }}
      />
    </CarListingWrapper>
  )
}

CarList.propTypes = {
  carProducts: PropTypes.arrayOf(object).isRequired,
  howManyDays: PropTypes.number.isRequired,
  onBook: PropTypes.func.isRequired,
  refreshAffirmUiOnce: PropTypes.func,
  setRefreshAffirmUiOnce: PropTypes.func
}

export default CarList
