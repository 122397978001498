import React from 'react'
import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { FaCheckCircle } from 'react-icons/fa'
import SearchForm from '../../components/SearchForm'
import { Container, El, FlexEl } from '../../components/layout'
import { setSearchPickupLocation } from '../../redux/slices'
import { checkDefaultDateRange } from '../../util/searchArgs'
import DynamicPages from '../DynamicPages/DynamicPages'

const FormContainer = styled(FlexEl)`
  min-height: 448px;
  position: relative;
  justify-content: center;
  align-items: center;
  background-color: ${themeGet('colors.newColorPrimary')};
  border-bottom: 1px solid ${themeGet('colors.blueGrey.1')};
  @media (min-width: ${themeGet('breakpoints.1')}px) {
    min-height: 300px;
  }
`

const getImage = (filename) => `https://s3-eu-west-1.amazonaws.com/carla-landing-images/${filename}`

const Advantage = () => <img src={getImage('advantage.png')} alt='Logo of Advantage Advantage Rent A Car.' />
const Alamo = () => <img src={getImage('alamo.png')} alt='Logo of Alamo Rent a Car owned by Enterprise Holdings ' />
const Avis = () => <img src={getImage('avis.png')} alt='Logo of AVIS, the first car rental company in the world.' />
const Budget = () => (
  <img src={getImage('budget.png')} alt='Logo of Budget, a car rental company subsidiary of the Avis Budget Group,' />
)
const Dollar = () => (
  <img
    src={getImage('dollar.png')}
    alt='Logo of Dollar. A major car rental company in USA,  one of many partners of Carla.'
  />
)
const Enterprise = () => <img src={getImage('enterprise.png')} alt='Logo of Enterprise Rent-A-Car. ' />
const Europcar = () => (
  <img
    src={getImage('europcar.png')}
    alt='Logo of Europcar, Keddy. A major car rental company mostly operating in Europe,  one of many partners of Carla.'
  />
)
const Ez = () => <img src={getImage('ez.png')} alt='Logo of EZ Car Rental company, one of many partners of Carla. ' />
const Fox = () => <img src={getImage('fox.png')} alt='Logo of FOX Rent a Car.' />
const Greenmotion = () => <img src={getImage('greenmotion.png')} alt='Logo of Green Motion car rental company. ' />
const Keddy = () => (
  <img
    src={getImage('keddy.png')}
    alt='Logo of Keddy, a division of Europcar operating in Europe and USA,  one of many partners of Carla.'
  />
)
const Hertz = () => (
  <img
    src={getImage('hertz.png')}
    alt='Logo of Hertz, one of the three big rental car holding companies in the United States.'
  />
)
const IceRental = () => (
  <img src={getImage('ice-rental.png')} alt='Logo of Ice Rental Cars, a friendly car rental in Iceland.' />
)
const National = () => <img src={getImage('national.png')} alt='Logo of National Car Rental.' />
const Nu = () => <img src={getImage('nu.png')} alt='Logo of NU Car Rentals operating in USA mostly in Orlando.' />
const Sixt = () => (
  <img src={getImage('sixt.png')} alt='Logo of SIXT, a major car rental company operating all over the world. ' />
)
const Thrifty = () => <img src={getImage('thrifty.png')} alt='Logo of Thrifty, a major car rental company.' />
const Garenta = () => (
  <img src={getImage('garenta.png')} alt='Logo of Garenta. A major car rental company operating in Turkey.' />
)
const Interrent = () => <img src={getImage('interrent.png')} alt='Logo of Interrent, one of many partners of Carla. ' />

const Hero = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 30px auto;
  color: ${themeGet('colors.blueGrey.8')};
`
const HeroImage = styled(({ src, className, alt, ...props }) => (
  <div className={className}>
    <img width={1280} height={960} src={getImage(src)} alt={alt} {...props} />
  </div>
))`
  text-align: center;
  img {
    margin: auto;
    max-width: 100%;
    height: auto;
  }
`

const List = styled(El)`
  padding: 0 15px;
  h2 {
    display: flex;
    font-size: 20px;
    @media (min-width: ${themeGet('breakpoints.1')}px) {
      font-size: 24px;
    }
    svg {
      min-width: 20px;
      margin-top: 5px;
      margin-right: 10px;
      color: ${themeGet('colors.green.5')};
    }
  }
`

const ListItem = ({ children, ...props }) => (
  <h2 {...props}>
    <FaCheckCircle />
    {children}
  </h2>
)

const Brands = styled(({ children, ...props }) => (
  <div {...props}>
    <div>{children}</div>
  </div>
))`
  overflow-x: auto;
  text-align: center;
  margin: 20px 0;
  min-height: 59px;
  padding: 10px 0;
  > div {
    display: inline-flex;
    align-items: center;
    img {
      margin: 0 10px;
      max-height: 35px;
    }
  }
`

const Content = styled(Container)`
  color: ${themeGet('colors.gray.8')};
  padding: 15px;
  p {
    text-align: justify;
  }
  ul {
    padding-left: 25px;
    li {
      margin: 3px 0;
    }
  }
  ul.location-list {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  a {
    display: inline-block;
    color: ${themeGet('colors.green.9')};
    transition: color 100ms ease-in-out;
    &:hover {
      color: ${themeGet('colors.green.7')};
    }
  }
  table.borderedTable {
    border-collapse: collapse;
    width: 100%;
    text-align: left;
    th,
    td {
      padding: 10px;
    }
  }
`

class RawMainContainer extends React.Component {
  locationMap = {
    '/los-angeles-car-rental': { label: 'Los Angeles International Airport', value: '4316' },
    '/orlando-car-rental': { label: 'Orlando International Airport', value: '4844' },
    '/memorial-day-car-rental': { label: 'Orlando International Airport', value: '4844' },
    '/paris-car-rental': { label: 'Charles de Gaulle Airport', value: '1255' },
    '/istanbul-new-airport': { label: 'Istanbul New Airport', value: '19086' },
    '/iceland-car-rental': { label: 'Reykjavik International Airport', value: '6878' },
    '/spring-car-rental': { label: 'London Heathrow Airport', value: '4457' },
    '/italy-car-rental': { label: 'Leonardo da Vinci-Fiumicino Airport', value: '2357' }
  }

  componentDidMount() {
    // prepopulate locations if possible
    const location = this.locationMap[this.props.match.path]
    if (location) {
      this.props.setSearchPickupLocation(location)
    }
    checkDefaultDateRange()
  }

  render() {
    const { children, ...props } = this.props
    return (
      <El {...props}>
        <FormContainer px={[15, 25, 25]} py={[15, 50, 50]}>
          <SearchForm history={props.history} />
        </FormContainer>
        {children}
      </El>
    )
  }
}

const ConnectedMainContainer = connect(null, { setSearchPickupLocation })(RawMainContainer)

const MainContainer = styled(ConnectedMainContainer)`
  ${List}, ${HeroImage} {
    width: 100%;
    @media (min-width: ${themeGet('breakpoints.1')}px) {
      width: 50%;
    }
  }
`
export const LandingPage = {
  MainContainer,
  Helmet,
  Hero,
  HeroImage,
  List,
  ListItem,
  Content,
  Brands,
  Advantage,
  Alamo,
  Avis,
  Budget,
  Dollar,
  Enterprise,
  Europcar,
  Ez,
  Fox,
  Greenmotion,
  Hertz,
  IceRental,
  National,
  Nu,
  Sixt,
  Thrifty,
  Keddy,
  Garenta,
  Interrent
}

const DynamicPageArrayTemplate = [
  {
    id: 'los-angeles',
    link: 'Los Angeles'
  },
  {
    id: 'fort-lauderdale',
    link: 'Fort Lauderdale'
  },
  {
    id: 'tampa',
    link: 'Tampa'
  },
  {
    id: 'miami',
    link: 'Miami'
  },
  {
    id: 'orlando',
    link: 'Orlando'
  },
  {
    id: 'dallas',
    link: 'Dallas'
  },
  {
    id: 'phoenix',
    link: 'Phoenix'
  },
  {
    id: 'tucson',
    link: 'Tucson'
  },
  {
    id: 'las-vegas',
    link: 'Las Vegas'
  },
  {
    id: 'new-york-city',
    link: 'NYC'
  },
  {
    id: 'brooklyn',
    link: 'Brooklyn'
  },
  {
    id: 'atlanta',
    link: 'Atlanta'
  },
  {
    id: 'san-juan',
    link: 'San Juan'
  },
  {
    id: 'chicago',
    link: 'Chicago'
  },
  {
    id: 'san-diego',
    link: 'San Diego'
  },
  {
    id: 'san-francisco',
    link: 'San Francisco'
  },
  {
    id: 'montauk',
    link: 'Montauk'
  },
  {
    id: 'bronx',
    link: 'Bronx'
  },
  {
    id: 'buffalo',
    link: 'Buffalo'
  },
  {
    id: 'niagara-falls',
    link: 'Niagara Falls'
  },
  {
    id: 'queens',
    link: 'Queens'
  },
  {
    id: 'southampton',
    link: 'Southampton'
  },
  {
    id: 'jacksonville',
    link: 'Jacksonville'
  },
  {
    id: 'kissimmee',
    link: 'Kissimmee'
  },
  {
    id: 'fort-myers',
    link: 'Fort Myers'
  },
  {
    id: 'naples',
    link: 'Naples'
  },
  {
    id: 'malibu',
    link: 'Malibu'
  },
  {
    id: 'miami-beach',
    link: 'Miami Beach'
  },
  {
    id: 'st-petersburg',
    link: 'St. Petersburg'
  },
  {
    id: 'panama-city',
    link: 'Panama City'
  },
  {
    id: 'tallahassee',
    link: 'Tallahassee'
  },
  {
    id: 'sacramento',
    link: 'Sacramento'
  },
  {
    id: 'utica',
    link: 'Utica'
  },
  {
    id: 'portland',
    link: 'Portland'
  },
  {
    id: 'tahoe-city',
    link: 'Tahoe City'
  },
  {
    id: 'palm-springs',
    link: 'Palm Springs'
  },
  {
    id: 'spring',
    link: 'Spring'
  },
  {
    id: 'italy',
    link: 'Italy'
  },
  {
    id: 'memorial-day',
    link: 'Memorial Day'
  }
]

const DPArray = DynamicPageArrayTemplate.map((item) => {
  return {
    id: `${item.id}-landing-page`,
    url: `/${item.id}-car-rental`,
    component: DynamicPages,
    link: `${item.link}`,
    dateModified: item.dateModified || '2021-04-16T12:14:30+00:00'
  }
})

export const LandingPageType = {
  Business: 'Business',
  CustomerService: 'Customer Service',
  Discover: 'Discover',
  Hidden: 'Hidden'
}

export default [
  ...DPArray,
  {
    id: 'arab-landing-page',
    url: '/van-rental',
    link: 'Van Rental',
    dateModified: '2021-04-16T12:14:30+00:00'
  },
  {
    id: 'italy-landing-page',
    url: '/italy-car-rental',
    link: 'Italy Car Rental',
    dateModified: '2021-04-16T12:14:30+00:00'
  },
  {
    id: 'spring-car-rental',
    url: '/spring-car-rental',
    link: 'Spring Car Rental',
    dateModified: '2021-04-16T12:14:30+00:00'
  },
  {
    id: 'memorial-day-car-rental',
    url: '/memorial-day-car-rental',
    link: 'Memorial Day Car Rental',
    dateModified: '2021-04-16T12:14:30+00:00'
  },
  {
    id: 'sixt-landing-page',
    url: '/sixt-rent-a-car',
    link: 'Sixt Car Rental',
    dateModified: '2021-04-16T12:14:30+00:00'
  },
  {
    id: 'keddy-landing-page',
    url: '/keddy-car-hire',
    link: 'Keddy Car Hire',
    dateModified: '2021-04-16T12:14:30+00:00'
  },
  {
    id: 'europcar-landing-page',
    url: '/europcar',
    link: 'Europcar Car Rental',
    dateModified: '2021-04-16T12:14:30+00:00'
  },
  {
    id: 'istanbul-landing-page',
    url: '/istanbul-new-airport',
    link: 'Istanbul Car Rental',
    dateModified: '2021-04-16T12:14:30+00:00'
  },
  {
    id: 'memorial-day-weekend',
    url: '/memorial-day-weekend',
    link: 'Memorial Day',
    dateModified: '2021-04-16T12:14:30+00:00'
  },
  {
    id: '4th-of-july',
    url: '/4th-of-july',
    link: '4th of July',
    dateModified: '2021-04-16T12:14:30+00:00'
  },
  {
    id: 'last-minute-car-rental',
    url: '/last-minute-car-rental',
    link: 'Last Minute Car Rental',
    dateModified: '2021-04-16T12:14:30+00:00'
  },
  {
    id: 'paris-landing-page',
    url: '/paris-car-rental',
    link: 'Paris Car Rental',
    dateModified: '2021-04-16T12:14:30+00:00'
  },
  {
    id: 'iceland-landing-page',
    url: '/iceland-car-rental',
    link: 'Iceland Car Rental',
    dateModified: '2021-04-16T12:14:30+00:00'
  },
  {
    id: 'corona-landing-page',
    url: '/coronavirus-car-rental',
    link: 'Coronavirus Notice',
    dateModified: '2021-04-16T12:14:30+00:00'
  },
  {
    id: 'list-your-fleet',
    url: '/listyourfleet',
    link: 'List Your Fleet',
    type: LandingPageType.Business,
    dateModified: '2021-04-16T12:14:30+00:00'
  },
  {
    id: 'terms-conditions',
    url: '/terms-conditions',
    link: 'Terms and Conditions',
    type: LandingPageType.CustomerService,
    dateModified: '2021-04-16T12:14:30+00:00'
  },
  {
    id: 'privacy-policy',
    url: '/privacy-policy',
    link: 'Privacy Policy',
    type: LandingPageType.CustomerService,
    dateModified: '2021-04-16T12:14:30+00:00'
  },
  {
    id: 'advantage-landing-page',
    url: '/advantage-rent-a-car',
    link: 'Advantage Car Rental',
    dateModified: '2021-04-16T12:14:30+00:00'
  },
  {
    id: 'sitemap',
    url: '/sitemap',
    link: 'Site Map',
    type: LandingPageType.Hidden,
    dateModified: '2021-04-16T12:14:30+00:00'
  }
]
