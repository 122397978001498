import { ageItems, defaultAge } from '../util/searchArgs'
// extracts useful information about car search parameters
const getDetailedSearchArgs = (searchArgs) => {
  if (!searchArgs) return null

  const {
    pickupLocation,
    dropOffLocation,
    pickupDateStr,
    dropOffDateStr,
    pickupTime: rawPickupTime,
    dropOffTime: rawDropOffTime,
    age,
    searchId,
    promoCode
  } = searchArgs

  const pickupDate = pickupDateStr ? new Date(pickupDateStr) : null
  const dropOffDate = dropOffDateStr ? new Date(dropOffDateStr) : null

  const pickupTime = rawPickupTime || { hour: 12, min: 0 }
  const dropOffTime = rawDropOffTime || { hour: 12, min: 0 }

  // merge dates and times in a single date object
  let pickupDateTime = null
  if (pickupDate && pickupTime) {
    pickupDateTime = new Date(pickupDate)
    pickupDateTime.setHours(pickupTime.hour)
    pickupDateTime.setMinutes(pickupTime.min)
  }

  let dropOffDateTime = null
  if (dropOffDate && dropOffTime) {
    dropOffDateTime = new Date(dropOffDate)
    dropOffDateTime.setHours(dropOffTime.hour)
    dropOffDateTime.setMinutes(dropOffTime.min)
  }

  let ageOption = ageItems.find((ageItem) => ageItem.value === age)
  if (!ageOption) ageOption = defaultAge

  return {
    pickupLocation,
    dropOffLocation,
    pickupDate,
    dropOffDate,
    pickupTime,
    dropOffTime,
    pickupDateTime,
    dropOffDateTime,
    age,
    ageOption,
    searchId,
    promoCode
  }
}

/**
 * redux selector to give useful data about car search information
 */
export const selectSearchArgs = (state = {}) => {
  const {
    pickupLocation,
    dropOffLocation,
    age,
    ageOption,
    searchId,
    promoCode,
    pickupDate,
    dropOffDate,
    pickupTime,
    dropOffTime,
    pickupDateTime,
    dropOffDateTime,
    isSameLocation
  } = getDetailedSearchArgs(state.searchArgs)

  return {
    pickupLocation,
    dropOffLocation,
    age,
    ageOption,
    searchId,
    promoCode,
    pickupDate,
    dropOffDate,
    pickupTime,
    dropOffTime,
    pickupDateTime,
    dropOffDateTime,
    locationSearch: state.locationSearch,
    optimize: state.optimize,
    nearMePicked: state.nearMePicked.nearMePicked,
    nearMeCoords: state.nearMePicked.nearMeCoords,
    nearMePermission: state.nearMePicked.nearMePermission,
    sameLocation: isSameLocation
  }
}
