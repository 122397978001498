import React from 'react'
import { Box, Container } from '@material-ui/core'
import {
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionDetails,
  StyledGrid,
  Text,
  Span,
  Image,
  ManageButton
} from './styles'
import IconPickup from './assets/icon-pickup.svg'
import IconDropOff from './assets/icon-dropoff.svg'
import IconDirection from './assets/icon-direction.svg'
import IconSelectedAncillary from './assets/icon-selected-ancillary.svg'
import IconExpand from './assets/icon-expand.svg'
import util from '../../util'
import DefaultCarImage from '../../util/images/placeholder-car.png'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { TooltipUI } from '../../UI/Tooltip'
import { ReactComponent as IconInformation } from './assets/icon-information.svg'
import { ReactComponent as IconClockRunning } from './assets/icon-clock-running.svg'
import { ViewBreakdownButton } from './styles'
import loadable from '@loadable/component'
import VoucherDetail from '../VoucherDetail'
import { useHistory } from 'react-router-dom'

const PriceBreakdownModal = loadable(() => import('./modals/PriceBreakdownModal'), { ssr: false })

const OnRequestBlockStyled = styled('div')`
  display: flex;
  align-items: center;
  gap: 12px;
  > p {
    display: flex;
    color: var(--Actions-Secondary, #0076ff);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    display: flex;
    align-items: center;
    gap: 3px;
  }
`

export function ReservedCarCardMobile(props) {
  const {
    rentalProduct,
    dtl,
    ancillariesPurchased,
    confirmationNo,
    userInfo,
    reservationId,
    status,
    statusInfo,
    priceBreakDown,
    isMobile
  } = props

  const { t: TRANSLATOR, i18n } = useTranslation()
  const [open, setOpen] = React.useState(false)
  const history = useHistory()

  return (
    <Container disableGutters style={{ padding: '6px 20px', marginBottom: 24 }}>
      <Box bgcolor={'#FDFDFD'} borderRadius={12} py={2} px={2.5} mb={3}>
        <StyledGrid container direction='column'>
          <Image src={rentalProduct.supplierUrl} alt='supplier' height={40} alignSelf='flex-start' />
          <Image
            src={rentalProduct.imageUrl}
            alt='car'
            width={300}
            alignSelf='center'
            onError={(e) => (e.target.src = DefaultCarImage)}
          />
        </StyledGrid>
        <Text mb={0.5}>{rentalProduct.name}</Text>
        <Text mb={2}>
          {TRANSLATOR('reservations:reserved:similar')} {rentalProduct.typeDescription}
        </Text>
        <StyledGrid container alignItems='center' justifyContent='space-between' mb={2} wrap='nowrap'>
          <StyledGrid item>
            <StyledGrid container gap={1}>
              <Image src={IconPickup} alt='icon-pickup' />
              <StyledGrid item>
                <Text fontSize={18} fontWeight={400}>
                  {util.formatDateWithoutTime(dtl.pickup.dateTime, i18n.resolvedLanguage)}
                </Text>
                <Text fontSize={18} fontWeight={400}>
                  {util.formatTime(dtl.pickup.dateTime)}
                </Text>
              </StyledGrid>
            </StyledGrid>
          </StyledGrid>
          <StyledGrid item>
            <Image src={IconDirection} alt='icon-direction' />
          </StyledGrid>
          <StyledGrid item>
            <StyledGrid container gap={1}>
              <Image src={IconDropOff} alt='icon-pickup' />
              <StyledGrid item>
                <Text fontSize={18} fontWeight={400}>
                  {util.formatDateWithoutTime(dtl.dropOff.dateTime, i18n.resolvedLanguage)}
                </Text>
                <Text fontSize={18} fontWeight={400}>
                  {util.formatTime(dtl.dropOff.dateTime)}
                </Text>
              </StyledGrid>
            </StyledGrid>
          </StyledGrid>
        </StyledGrid>
        <StyledAccordion elevation={0} style={{ borderTop: '1px solid #DFDFE7', borderBottom: '1px solid #DFDFE7' }}>
          <StyledAccordionSummary expandIcon={<Image src={IconExpand} alt='icon-expand' />}>
            {TRANSLATOR('xo2:reservationDetails')}
          </StyledAccordionSummary>
          <StyledAccordionDetails>
            <Text color='#0064D6' fontWeight={400} mb={2}>
              {TRANSLATOR('reservations:reserved:info')}
            </Text>
            <StyledGrid container alignItems='flex-start' mb={2} gap={1}>
              <Image src={IconPickup} alt='icon-pickup' />
              <StyledGrid item>
                <Text mb={1} fontWeight={400}>
                  {TRANSLATOR('homePage:pickUp')}
                </Text>
                <Text fontSize={18}>{dtl.pickup.address}</Text>
                <Text fontSize={18}>{util.formatDate(dtl.pickup.dateTime, i18n.resolvedLanguage)}</Text>
              </StyledGrid>
            </StyledGrid>
            <StyledGrid container alignItems='flex-start' mb={2} gap={1}>
              <Image src={IconPickup} alt='icon-pickup' />
              <StyledGrid item>
                <Text mb={1} fontWeight={400}>
                  {TRANSLATOR('homePage:dropOff')}
                </Text>
                <Text fontSize={18}>{dtl.dropOff.address}</Text>
                <Text fontSize={18}>{util.formatDate(dtl.dropOff.dateTime, i18n.resolvedLanguage)}</Text>
              </StyledGrid>
            </StyledGrid>
          </StyledAccordionDetails>
        </StyledAccordion>
        <Box borderBottom='1px solid #DFDFE7'>
          {ancillariesPurchased?.map((ancillary, i) => (
            <StyledGrid container key={i} alignItems='center' m='12px 0'>
              <Image src={IconSelectedAncillary} alt='icon-selected-ancillary' />
              <Text fontWeight={400}>{ancillary}</Text>
            </StyledGrid>
          ))}
        </Box>
        <Text mt={1.5} fontWeight={400}>
          {TRANSLATOR('success:confirmNo')}:
        </Text>
        {status === 'DONE' ? (
          <Text fontSize={18} mb={0.5}>
            {confirmationNo}
          </Text>
        ) : (
          <OnRequestBlockStyled>
            <Text fontSize={24}>
              <IconClockRunning /> {TRANSLATOR('success:onRequest')}
            </Text>
            <TooltipUI title={statusInfo}>
              <div>
                <IconInformation />
              </div>
            </TooltipUI>
          </OnRequestBlockStyled>
        )}

        <Text fontWeight={400}>Email:</Text>
        <Text fontSize={18} mb={0.5}>
          {userInfo.email}
        </Text>
        <Text fontWeight={400}>{TRANSLATOR('xo1:price')}:</Text>
        <StyledGrid container justifyContent='space-between' mb={1.5}>
          <Text fontSize={18}>
            {util.formatMoneyFloored(priceBreakDown[priceBreakDown.length - 1].price.price, rentalProduct.currency)}
          </Text>
          <ViewBreakdownButton onClick={() => setOpen(true)}>
            {TRANSLATOR('reservations:reserved:viewBreakdown')}
          </ViewBreakdownButton>
        </StyledGrid>
        <StyledGrid container mb={1.5}>
          <VoucherDetail />
        </StyledGrid>
        <StyledAccordion elevation={0} style={{ borderTop: '1px solid #DFDFE7', borderBottom: '1px solid #DFDFE7' }}>
          <StyledAccordionSummary expandIcon={<Image src={IconExpand} alt='icon-expand' />}>
            {TRANSLATOR('reservations:reserved:driverDetails')}
          </StyledAccordionSummary>
          <StyledAccordionDetails>
            <Text fontWeight={400}>
              {userInfo.firstName} {userInfo.lastName}
              <Span fontSize={16} fontWeight={400}>
                &nbsp;{userInfo.phoneNumber}
              </Span>
            </Text>
            <Text mb={1.5} fontWeight={400}>
              {TRANSLATOR('reservations:reserved:voucher')}: <Span fontWeight={600}>&nbsp;{reservationId}</Span>
            </Text>
          </StyledAccordionDetails>
        </StyledAccordion>
      </Box>
      <ManageButton onClick={() => history.push('/my-reservations/modification-options', { isMobile })}>
        {TRANSLATOR('reservations:modification:manageBooking')}
      </ManageButton>
      <PriceBreakdownModal
        open={open}
        handleClose={() => setOpen(false)}
        rentalProduct={rentalProduct}
        priceBreakDown={priceBreakDown}
      />
    </Container>
  )
}
