import React from 'react'
import styled from 'styled-components'
import { bottomDataMap } from '../../util/dynamicLandingPageData'

const title1 = 'Airports serving '
const italyTitle = 'Aeroporti popolari in Italia'
const title2 = 'Popular rental types in '
const title3 = 'Best Areas in '

const BottomLinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  margin: 40px auto;
  width: fit-content;
  padding: 0;
  gap: 55px;
  max-width: 1000px;

  @media only screen and (max-width: 1200px) {
    display: grid;
    gap: 30px;
    padding: 0 20px;
    justify-items: stretch;
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (max-width: 576px) {
    margin-top: -20px;
    gap: 20px;
    grid-template-columns: 1fr;
    justify-items: start;
  }
`

const SingleLink = styled.div`
  cursor: pointer;
  color: #0076ff;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  width: fit-content;
  margin-top: 12px;

  @media only screen and (min-width: 1200px) {
    max-width: max(16vw, 300px);
  }

  @media only screen and (max-width: 576px) {
    font-size: 16px;
  }
`

const SingleTitle = styled.h1`
  font-family: Montserrat;
  font-size: 17px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #525266;
  margin-bottom: 15px;

  @media only screen and (max-width: 576px) {
    font-size: 20px;
  }
`

const RenderColumn = (title, data, name, scrollToTop) => (
  <div>
    <SingleTitle>{title + name}</SingleTitle>
    {data.map((item, index) => {
      return (
        <SingleLink onClick={scrollToTop} key={index}>
          {item}
        </SingleLink>
      )
    })}
  </div>
)

function BottomLinks({ memorialDay, italy, scrollToTop, spring }) {
  const url = window.location.pathname
  const data = bottomDataMap[url]

  if (spring) {
    return url && data && data.name ? (
      <BottomLinksContainer>
        {RenderColumn(title1, data.links1, 'in Popular Spring Locations', scrollToTop)}
        {RenderColumn('', data.links2, 'Popular Rental Types', scrollToTop)}
        {RenderColumn('Best Locations to Visit in Spring', data.links3, '', scrollToTop)}
      </BottomLinksContainer>
    ) : (
      <div />
    )
  }

  if (memorialDay) {
    return url && data && data.name ? (
      <BottomLinksContainer>
        {RenderColumn('', data.links1, 'Airports serving in Popular Memorial Day Locations', scrollToTop)}
        {RenderColumn('', data.links2, 'Popular Rental Types', scrollToTop)}
        {RenderColumn('Best Locations to Visit in Orlando', data.links3, '', scrollToTop)}
      </BottomLinksContainer>
    ) : (
      <div />
    )
  }

  if (italy) {
    return url && data && data.name ? (
      <BottomLinksContainer>
        {RenderColumn(italyTitle, data.links1, '', scrollToTop)}
        {RenderColumn('', data.links2, 'Tipi di noleggio più popolari in Italia', scrollToTop)}
        {RenderColumn('Le migliori località in Italia', data.links3, '', scrollToTop)}
      </BottomLinksContainer>
    ) : (
      <div />
    )
  }

  return url && data && data.name ? (
    <BottomLinksContainer>
      {RenderColumn(title1, data.links1, data.name, scrollToTop)}
      {RenderColumn(title2, data.links2, data.name, scrollToTop)}
      {RenderColumn(title3, data.links3, data.name, scrollToTop)}
    </BottomLinksContainer>
  ) : (
    <div />
  )
}

export default BottomLinks
