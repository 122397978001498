import React from 'react'
import { Box, Grid, makeStyles } from '@material-ui/core'
import { Done } from '@material-ui/icons'
import { Text, RedirectButton, RedirectButtonFilled } from './styles'
import { DividerUI } from '../../UI/Divider'
import { useTranslation } from 'react-i18next'
import { MobileScreen, OverMobileScreen } from '../common/Screens'
import QRCode from 'react-qr-code'

const useStyles = makeStyles({
  root: {
    color: '#3EAA77',
    backgroundColor: '#D5F8E8',
    borderRadius: '50%',
    padding: 2,
    lineHeight: 0
  }
})

export default function Deeplink() {
  const { t: TRANSLATOR } = useTranslation()
  const classes = useStyles()

  return (
    <Box textAlign='center'>
      <Grid container direction='column' style={{ gap: 24 }}>
        <OverMobileScreen customBreakpoint={750}>
          <Grid container alignItems='center' justifyContent='center'>
            <Done classes={{ root: classes.root }} />
            <Text color='var(--colorSuccess)' fontSize={18} ml={1}>
              {TRANSLATOR('reservations:result:success')}
            </Text>
          </Grid>
        </OverMobileScreen>
        <MobileScreen customBreakpoint={750}>
          <Done classes={{ root: classes.root }} style={{ fontSize: 64, margin: 'auto' }} />
          <Text color='var(--colorSuccess)' fontSize={18} ml={1}>
            {TRANSLATOR('reservations:result:success')}
          </Text>
        </MobileScreen>
        <Grid container direction='column' style={{ gap: 16 }}>
          <MobileScreen customBreakpoint={750}>
            <Text fontWeight={500}>{TRANSLATOR('reservations:result:clickCash')}</Text>
            <RedirectButtonFilled href='https://mbna.adj.st/jumpTo/openUserCreditFS?adj_t=1hxg5v3n&adj_campaign=wallet&adj_deep_link=carla%3A%2F%2F'>
              {TRANSLATOR('reservations:result:download')}
            </RedirectButtonFilled>
          </MobileScreen>
          <OverMobileScreen customBreakpoint={750}>
            <Box p={0.5} width='min-content' m='auto' border='1px solid #E9E9E9' borderRadius={8}>
              <QRCode
                value='https://mbna.adj.st/jumpTo/openUserCreditFS?adj_t=1hxg5v3n&adj_campaign=wallet&adj_deep_link=carla%3A%2F%2F'
                size={120}
              />
            </Box>
          </OverMobileScreen>
          <OverMobileScreen customBreakpoint={750}>
            <Text fontWeight={500}>{TRANSLATOR('reservations:result:carlaCash')}</Text>
          </OverMobileScreen>
          <DividerUI>
            <Text fontSize={12} lineHeight='12px' color='var(--colorHint)'>
              {TRANSLATOR('xo1:driverInfo:or')}
            </Text>
          </DividerUI>
          <Text color='#707083' fontWeight={500}>
            {TRANSLATOR('reservations:result:carlaCashInfo')}
          </Text>
        </Grid>
        <RedirectButton href='/'>{TRANSLATOR('homePage:searchCars')}</RedirectButton>
      </Grid>
    </Box>
  )
}
