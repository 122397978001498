import React from 'react'
import useModificationInfo from '../hooks/useModificationInfo'
import { Box, Grid, MobileFixedButton, MobileHeader, AgreeButton } from '../styles'
import { useTranslation } from 'react-i18next'
import { MobileScreen, OverMobileScreen } from '../../common/Screens'
import { useHistory } from 'react-router-dom'
import BookingCard from './BookingCard'

export default function ConfirmModification() {
  const { product, carSearchArgs, modificationPriceDifference } = useModificationInfo()
  const { t: TRANSLATOR } = useTranslation()
  const history = useHistory()

  if (!!!product) return null

  return (
    <>
      <MobileScreen customBreakpoint={600}>
        <MobileHeader>{TRANSLATOR('xo2:reservationDetails')}</MobileHeader>
      </MobileScreen>
      <Box
        bgcolor='white'
        border='1px solid var(--borderGrey)'
        borderRadius={12}
        m={{ xs: '24px 16px', sm: '24px auto' }}
        p={{ xs: '16px 12px', sm: 3 }}
        maxWidth={{ xs: '100%', sm: 600 }}
      >
        <Grid container wrap='nowrap' direction='column' style={{ gap: 32 }}>
          <BookingCard
            modificationPriceDifference={modificationPriceDifference}
            product={product}
            carSearchArgs={carSearchArgs}
          />
          <OverMobileScreen customBreakpoint={600}>
            <AgreeButton onClick={() => history.push('/my-reservations/modify-success')}>
              {TRANSLATOR('reservations:modification:agree')}
            </AgreeButton>
          </OverMobileScreen>
        </Grid>
      </Box>
      <MobileScreen customBreakpoint={600}>
        <MobileFixedButton bg='#0076FF' onClick={() => history.push('/my-reservations/modify-success')}>
          {TRANSLATOR('reservations:modification:agree')}
        </MobileFixedButton>
      </MobileScreen>
    </>
  )
}
