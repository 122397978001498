import PropTypes from 'prop-types'
import React from 'react'
import { LinearStepper } from '../../UI/Stepper'
import { IfFeatureEnabled } from '@growthbook/growthbook-react'
import { Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import * as STEPS from './constants'

ProgressBar.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.string).isRequired
}

export default function ProgressBar({ activeStep }) {
  const { t: TRANSLATOR } = useTranslation()

  return (
    <IfFeatureEnabled feature='progress-bar-for-skyscanner-on-web'>
      <Box mt={2} maxWidth={1000} mx='auto'>
        <LinearStepper steps={Object.values(STEPS).map((step) => TRANSLATOR(step))} activeStep={activeStep} />
      </Box>
    </IfFeatureEnabled>
  )
}
