import React from 'react'
import useModificationInfo from '../hooks/useModificationInfo'
import { CarSearchResults } from '../../CarListing/CarSearchResults'
import { useTranslation } from 'react-i18next'
import { InfoTextWithLeftIcon } from '../styles'
import { setModificationArgs } from '../../../redux/slices/modificationSlice'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

export default function ModifiedCarSearch() {
  const { response, carSearchArgs, type } = useModificationInfo()
  const { t: TRANSLATOR } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  if (!!!response) return null

  // reshape cars data to use present car search results component
  const reshapedData = {
    cars: response.cars.map((item) => {
      return {
        ...item.carRentalProduct,
        modificationPriceDifference: item.modificationPriceDifference
      }
    })
  }

  const getTextKey = () => {
    let baseKey = 'reservations:modification:carSearchMessage'
    switch (type) {
      case 'DRIVER_CHANGE':
        return baseKey.concat(':Driver')
      case 'DATE_TIME_CHANGE':
        return baseKey.concat(':DateTime')
      default:
        return baseKey
    }
  }

  // pass the text here to keep car search results pure
  const modificationMessage = type !== 'CHANGE_VEHICLE' && (
    <InfoTextWithLeftIcon fullwidth={true}>{TRANSLATOR(getTextKey())}</InfoTextWithLeftIcon>
  )

  function onBook(product) {
    const { modificationPriceDifference, ...rest } = product
    dispatch(setModificationArgs({ product: rest, modificationPriceDifference }))
    history.push('/my-reservations/modify-car-confirm')
  }

  const searchArgs = {
    ...carSearchArgs,
    pickupDateStr: carSearchArgs.pickupDateTime,
    dropOffDateStr: carSearchArgs.dropOffDateTime
  }

  return (
    <CarSearchResults
      data={reshapedData}
      searchArgs={searchArgs}
      modificationMessage={modificationMessage}
      onBook={onBook}
    />
  )
}
