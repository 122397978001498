import React, { useRef } from 'react'
import styled from 'styled-components'
import loadable from '@loadable/component'
import { Form } from 'formik'
import { themeGet } from 'styled-system'
import TextView, { CardEl, El, FlexEl, GoogleButton, XO1NextButton } from '../layout'
import googleImg from '../../util/images/google.png'
import GA from '../../services/GA'
// import facebookImg from '../../util/images/facebook.png'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { InputAdornment } from '@material-ui/core'
import { TooltipUI } from '../../UI/Tooltip'
import IconInfo from '../../util/images/icon-info.svg'

// const FacebookLoginLoader = loadable.lib(() => import('react-facebook-login'), {
//   ssr: false
// })

const GoogleLoginLoader = loadable.lib(() => import('react-google-login/dist/google-login'), {
  ssr: false
})

const DriverForm = styled(({ ...props }) => {
  const { t } = useTranslation()
  const {
    values: { firstName, lastName, email, phoneNumber, flightNumber },
    errors,
    touched,
    handleChange,
    setFieldTouched,
    setFieldValue,
    ancillariesAreLoading,
    formTitle = t('xo1:driverInfo:header'),
    isSkyScanner
  } = props

  useEffect(() => {
    const arrayError = Object.keys(errors)
    if (!arrayError) return
    focusToId(arrayError[0])
  }, [errors])

  const fieldRefs = useRef(null)

  const change = (name, e) => {
    e.persist()
    handleChange(e)
    setFieldTouched(name, true, false)
  }

  const login = (response) => {
    const profile = response['profileObj']
    if (profile) {
      setFieldValue('firstName', profile['givenName'] || firstName)
      setFieldValue('lastName', profile['familyName'] || lastName)
      setFieldValue('email', profile['email'] || email)
    }
  }

  // const responseFacebook = (response) => {
  //   GA.facebookLoginSuccess()
  //   setFieldValue('firstName', response['first_name'] || firstName)
  //   setFieldValue('lastName', response['last_name'] || lastName)
  //   setFieldValue('email', response['email'] || email)
  // }

  const onKeyDown = (keyEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault()
    }
  }

  const handleKeyDown = (event, fieldId) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      if (!fieldId) return
      document.querySelector(`#${fieldId}`).focus()
    }
  }

  const scrollInView = (node) => {
    if (!node) return
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    const options = { block: 'start' }
    if (!isSafari) {
      options.behavior = 'smooth'
    }
    node.scrollIntoView({ behavior: 'smooth' })
  }

  const focusToId = (fieldId) => {
    const map = getMap()
    const node = map.get(fieldId)
    //XO1 Driver Form, scrolling screen, now experience is bad we are closing it.
    //scrollInView(node)
  }

  const getMap = () => {
    if (!fieldRefs.current) {
      fieldRefs.current = new Map()
    }
    return fieldRefs.current
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const arrayError = Object.keys(errors)
    if (arrayError.length) {
      window.scrollTo(0, 0)
    }
    if (arrayError) focusToId(arrayError[0])
    props.submitForm()
  }

  const nextButton = (
    <El className='submit-button-container'>
      <El width={[1, 1, 402]} mx='auto' style={{ zIndex: 99999 }}>
        <XO1NextButton id='xo1-next-button' type='submit' disabled={!errors || ancillariesAreLoading}>
          {!isSkyScanner ? 'NEXT' : 'Continue to Secure Payment'}
        </XO1NextButton>
      </El>
    </El>
  )

  return (
    <CardEl id='driver-form' as={Form} onKeyDown={onKeyDown} className={props.className} onSubmit={handleSubmit}>
      {' '}
      {/* formik Form element as card */}
      <El className='form' width={[1, 1, 1]} mx='auto' my={[16, 12, 12]}>
        <h2 className='form-title'>{formTitle}</h2>
        <El className='form-fields' width={[1, 1, 450]} mx='auto'>
          <TextView
            id='firstName'
            name='firstName'
            label={t('xo1:driverInfo:inputFirstName')}
            helperText={touched.firstName ? errors.firstName : ''}
            error={touched.firstName && Boolean(errors.firstName)}
            value={firstName}
            onChange={change.bind(null, 'firstName')}
            innerRef={(node) => {
              const map = getMap()
              if (node) {
                map.set('firstName', node)
              } else {
                map.delete('firstName')
              }
            }}
            onKeyDown={(event) => handleKeyDown(event, 'lastName')}
            onBlur={() => setFieldValue('firstName', firstName?.trim())}
          />
          <TextView
            id='lastName'
            name='lastName'
            label={t('xo1:driverInfo:inputLastName')}
            helperText={touched.lastName ? errors.lastName : ''}
            error={touched.lastName && Boolean(errors.lastName)}
            value={lastName}
            onChange={change.bind(null, 'lastName')}
            innerRef={(node) => {
              const map = getMap()
              if (node) {
                map.set('lastName', node)
              } else {
                map.delete('lastName')
              }
            }}
            onKeyDown={(event) => handleKeyDown(event, 'email')}
            onBlur={() => setFieldValue('lastName', lastName?.trim())}
          />
          <FlexEl flexWrap='wrap' justifyContent='space-between'>
            <El width={[1, 1, 1]}>
              <TextView
                id='email'
                name='email'
                label={t('xo1:driverInfo:inputEmail')}
                helperText={touched.email ? errors.email : ''}
                error={touched.email && Boolean(errors.email)}
                value={email}
                onChange={change.bind(null, 'email')}
                innerRef={(node) => {
                  const map = getMap()
                  if (node) {
                    map.set('email', node)
                  } else {
                    map.delete('email')
                  }
                }}
                onKeyDown={(event) => handleKeyDown(event, 'phoneNumber')}
                onBlur={() => setFieldValue('email', email?.trim())}
              />
            </El>
            <El width={[1, 1, 1]}>
              <TextView
                id='phoneNumber'
                name='phoneNumber'
                label={t('xo1:driverInfo:inputPhoneNumber')}
                type='tel'
                helperText={touched.phoneNumber ? errors.phoneNumber : ''}
                error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                value={phoneNumber}
                onChange={change.bind(null, 'phoneNumber')}
                width={0.48}
                innerRef={(node) => {
                  const map = getMap()
                  if (node) {
                    map.set('phoneNumber', node)
                  } else {
                    map.delete('phoneNumber')
                  }
                }}
                onKeyDown={(event) => handleKeyDown(event, 'flightNumber')}
                onBlur={() => setFieldValue('phoneNumber', phoneNumber?.trim())}
              />
            </El>
          </FlexEl>

          <TextView
            id='flightNumber'
            name='flightNumber'
            label={t('xo1:driverInfo:flightNo')}
            value={flightNumber}
            onChange={change.bind(null, 'flightNumber')}
            innerRef={(node) => {
              const map = getMap()
              if (node) {
                map.set('flightNumber', node)
              } else {
                map.delete('flightNumber')
              }
            }}
            onBlur={() => setFieldValue('flightNumber', flightNumber?.trim())}
            InputProps={{
              endAdornment: (
                <InputAdornment style={{ margin: -6 }}>
                  <TooltipUI title={t('xo1:flightTooltip')}>
                    <img src={IconInfo} alt='icon-info' style={{ cursor: 'pointer' }} />
                  </TooltipUI>
                </InputAdornment>
              )
            }}
          />
          <FlexEl className='socialLoginSeparator'>
            <hr my='auto' />
            <El className='text'>{t('xo1:driverInfo:or')}</El>
            <hr my='auto' />
          </FlexEl>
          {/* <FacebookLoginLoader fallback={null}>
            {({ default: FacebookLogin }) => (
              <FacebookLogin
                appId='1508686502763666'
                autoLoad={false}
                fields='first_name,last_name,email'
                callback={responseFacebook}
                cssClass='btnFacebook'
                isMobile={false}
                icon={<img src={facebookImg} width={20} height={20} alt='' />}
                textButton={t('xo1:driverInfo:facebook')}
                onFailure={() => GA.facebookLoginFailure()}
              />
            )}
          </FacebookLoginLoader> */}
          <GoogleLoginLoader fallback={null}>
            {({ default: GoogleLogin }) => (
              <GoogleLogin
                className='btnGoogle'
                clientId='91683085054-op91uln2jpoup1jqolujl1t0u55l3ku6.apps.googleusercontent.com'
                buttonText={t('xo1:driverInfo:google')}
                onSuccess={(value) => {
                  login(value)
                  GA.googleLoginSuccess()
                }}
                onFailure={(value) => {
                  login(value)
                  GA.googleLoginFailure()
                }}
                cookiePolicy={'single_host_origin'}
                icon={false}
                render={(renderProps) => (
                  <GoogleButton onClick={renderProps.onClick}>
                    <img src={googleImg} alt='' width={20} height={20} />
                    <El my='auto' as='span'>
                      {t('xo1:driverInfo:google')}
                    </El>
                  </GoogleButton>
                )}
                responseType='code,token'
              />
            )}
          </GoogleLoginLoader>
        </El>
        {isSkyScanner && (
          <El className='submit-button-container'>
            <El width={[1, 1, 402]} mx='auto' style={{ zIndex: 99999 }}>
              <XO1NextButton id='xo1-next-button' type='submit' disabled={!errors || ancillariesAreLoading}>
                {t('xo1:driverInfo:withProtection:text')}
              </XO1NextButton>
            </El>
          </El>
        )}
      </El>
    </CardEl>
  )
})`
  width: 100%;
  @media screen and (max-width: ${themeGet('breakpoints.0')}px) {
    & .form-fields {
      padding: 0 12px !important;
    }

    & .form-title {
      padding: 0 12px !important;
    }
  }
  .socialLoginSeparator {
    flex-direction: row;
    margin: 16px 50px 16px 50px;
    .text {
      color: ${themeGet('colors.colorHint')};
      font-family: 'Quicksand', 'Quicksand Regular', sans-serif;
      font-weight: bold;
      font-size: 12px;
      margin-left: 24px;
      margin-right: 24px;
    }
    > hr {
      border-bottom: 1px solid ${themeGet('colors.separatorGrey')};
      border-top: 0;
      border-right: 0;
      border-left: 0;
      flex-grow: 1;
    }
  }

  .btnFacebook {
    border-radius: 22px;
    font-family: 'Quicksand', 'Quicksand Regular', sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: white;
    background-color: #3b5998;
    padding: 13px 24px 13px 24px;
    border: none;
    width: 100%;
    position: relative;
    display: inline-block;

    > img {
      position: absolute;
      left: 24px;
      top: 12px;
      bottom: 12px;
    }
    :hover {
      background: #3b5998;
      opacity: 0.6;
    }

    :active {
    }
    :focus {
    }
  }

  .submit-button-container {
    display: block;
    position: fixed;
    margin-top: 15px;
    width: 100%;
    bottom: 12px;
    left: 16px;
    z-index: 3;

    @media only screen and (max-width: ${themeGet('breakpoints.0')}px) {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      height: auto;
      background-color: white;
      box-shadow: 0px -14px 7px rgba(0, 0, 0, 0.12);
    }

    @media (min-width: ${themeGet('breakpoints.0')}px) {
      padding: 16px 24px 0 24px;
      border-top: 1px solid ${themeGet('colors.separatorGrey')};
      position: static;
      width: 100%;
    }
    #xo1-next-button {
      width: 100%;
      @media (max-width: ${themeGet('breakpoints.0')}px) {
        width: calc(100% - 32px);
        max-width: max(400px, 75%);
        margin: 12px auto;
      }
      font-size: 16px;
      padding: 15px;
      font-weight: bold;
    }
  }
  .form-title {
    margin: 0;
    padding-left: 24px;
    padding-right: 24px;
    font-size: 16px;
    font-weight: 500;
    color: ${themeGet('colors.colorPrimary')};
  }
  .driver-form {
    padding: 12px 0 12px 0;
  }
  .form-fields {
    padding: 0 24px 0 24px;
  }
`

export default DriverForm
