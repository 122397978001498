import React, { useState } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { El, FlexEl, SearchButton } from '../layout'
import { setTagFilters, setCarSearchFilters, setSearchAge, setSortingOption } from '../../redux/slices'
import { selectSearchArgs } from '../../redux/selectors'
import ReactLoading from 'react-loading'
import LocationField from './Fields/LocationField'
import DateField from './Fields/DateField'
import AgeIcon from '../../util/images/driver-s-license.svg'
import { LabelDS } from '../CarlaSelect'

const SearchButtonWithShadow = styled(SearchButton)`
  position: relative;
  text-transform: none !important;
  font-family: 'Quicksand', 'Quicksand Regular', sans-serif;
  font-size: 14px !important;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  height: ${(props) => (props.isPageDynamic ? '52px !important' : '')};

  &::before {
    position: absolute;
    width: 100%;
    height: 50px;
    content: '';
    bottom: 0;
    left: 0;
    border-radius: 0px 12px 12px 0px;
    z-index: -9999;

    // desktop searchbar search button shadow below
    -webkit-box-shadow: -2px 4px 4px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: -2px 4px 4px 0px rgba(0, 0, 0, 0.25);
    box-shadow: -2px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
`

const ButtonWrapper = styled(El)`
  z-index: 999;
  margin-top: 5px;
`

const AgeInputWrapper = styled.div`
  position: relative;
  z-index: 10;
  margin-top: 0px;
  height: ${(props) => (props.isPageDynamic ? '52px' : '50px')};
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  background-color: ${(props) => (props.isAgeFocused ? '#EFF1F8' : 'white')};
  border-bottom: ${(props) => (props.isPageDynamic ? '1px solid #dfdfe7' : 'none')};
  border-top: ${(props) => (props.isPageDynamic ? '1px solid #dfdfe7' : 'none')};

  // desktop age input shadow below
  -webkit-box-shadow: -2px 4px 4px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: -2px 4px 4px 0px rgba(0, 0, 0, 0.25);
  box-shadow: -4px 4px 4px 0px rgba(0, 0, 0, 0.25);
`

const AgeInput = styled.input`
  width: 100%;
  padding-left: 44px !important;
  border: none;
  margin-right: 0;
  background-color: none;
  font-size: 16px;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:focus {
    background-color: #eff1f8;
    outline: none;
    border: none !important;
  }
`

const StyledLabelDS = styled(LabelDS)`
  text-transform: none !important;
  font-size: 18px !important;
  color: white !important;
  color: ${(props) => (props.isPageDynamic ? 'black !important' : 'white !important')};
  font-family: 'Quicksand', 'Quicksand Regular', sans-serif;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-bottom: 5px;
`

const SearchBarDesktop = styled(
  ({
    isPageDynamic,
    nearMePicked,
    pickupLocation,
    dropOffLocation,
    pickupDate,
    dropOffDate,
    pickupTime,
    dropOffTime,
    dateRange,
    ageOption,
    setSearchAge,
    onSubmit,
    age,
    history,
    ...props
  }) => {
    const [isAgeFocused, setIsAgeFocused] = useState(false)
    const isSearchPossible = () => {
      if (
        dropOffDate < pickupDate ||
        (dropOffDate.getTime() === pickupDate.getTime() && dropOffTime.hour < pickupTime.hour) ||
        (dropOffDate.getTime() === pickupDate.getTime() &&
          dropOffTime.hour === pickupTime.hour &&
          dropOffTime.min <= pickupTime.min)
      )
        return false // dropoff date/time should be later than pickup date
      if (
        pickupLocation === undefined ||
        pickupLocation === null ||
        dropOffLocation === undefined ||
        dropOffLocation === null
      )
        return false // locations should be valid

      return true
    }

    //Search button loading animation, hide the first button and show the second button.
    const startLoadingAnimation = () => {
      var nodes = document.querySelectorAll('.car-search-button')
      if (nearMePicked) {
        nodes[0].style.display = 'none' //hide first button
        nodes[1].style.display = 'block' //show second button
      } else {
        // SET SEARCH RELATED FILTERS TO INITIAL STATE
        props.setTagFilters([])
        props.setCarSearchFilters({
          carType: [],
          fuel: [],
          transmission: [],
          seats: [],
          cancellationPolicy: [],
          supplier: []
        })
        props.setSortingOption('RECOMMENDED')

        if (!isSearchPossible() || age < 18) return // check if search is possible before starting spinner on the button

        // 0 and 1 are mobile buttons
        nodes[0].style.display = 'none' //hide first button
        nodes[1].style.display = 'block' //show second button
      }
    }

    return (
      <FlexEl show={[0, 1, 1]} width={[1, 1, 1120]} as='form' onSubmit={onSubmit} {...props}>
        <FlexEl width={[1, 1, 0.385]} mb={[25, 25, 0]} className='form-field-container'>
          <LocationField isPageDynamic={isPageDynamic} pickupDate={pickupDate} dropOffDate={dropOffDate} age={age} />
        </FlexEl>
        <FlexEl width={[1, 1, 0.34]} mb={[25, 25, 0]} className='form-field-container'>
          <DateField isPageDynamic={isPageDynamic} />
        </FlexEl>
        <El width={[1, 1, 0.145]} mb={[25, 25, 0]} className='form-field-container'>
          <StyledLabelDS isPageDynamic={isPageDynamic}>Driver's Age</StyledLabelDS>
          <AgeInputWrapper isPageDynamic={isPageDynamic} isAgeFocused={isAgeFocused}>
            <img
              src={AgeIcon}
              alt={'rent_carla_age_icon'}
              style={{
                position: 'absolute',
                left: '10px',
                top: '16px',
                width: '22px',
                zIndex: 10
              }}
            />
            <AgeInput
              onFocus={() => setIsAgeFocused(true)}
              onBlur={() => setIsAgeFocused(false)}
              required
              type='number'
              min='18'
              max='99'
              placeholder='25'
              value={age}
              onChange={(e) => setSearchAge(e.target.value)}
            ></AgeInput>
          </AgeInputWrapper>
        </El>
        <ButtonWrapper width={[1, 1, 0.13]}>
          <SearchButtonWithShadow
            isPageDynamic={isPageDynamic}
            className='car-search-button'
            type='submit'
            onClick={startLoadingAnimation}
          >
            <El as='span' style={{ fontSize: 19 }}>
              Search Cars
            </El>
          </SearchButtonWithShadow>
          <SearchButtonWithShadow
            isPageDynamic={isPageDynamic}
            className='car-search-button'
            style={{ display: 'none' }}
            type='submit'
          >
            <ReactLoading className='loading-animation' type={'spin'} height={30} width={30} />
          </SearchButtonWithShadow>
        </ButtonWrapper>
      </FlexEl>
    )
  }
)`
  min-height: 198px;
  padding: 24px 28px 18px 30px;
  object-fit: contain;
  flex-wrap: wrap;
  justify-content: space-between;
  border: ${(props) => (props.isPageDynamic ? 'none' : '1px solid #dfdfe7')};
  border-radius: 12px;
  max-height: 198px;
  .form-field-container {
    // upper elements will have priority
    &:nth-child(1) {
      z-index: 10;
    }
    &:nth-child(2) {
      z-index: 9;
    }
    &:nth-child(3) {
      z-index: 8;
    }
  }
  .form-title {
    width: 100%;
    height: 40px;
    margin-bottom: 24px;
    font-size: 32px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #525266;
  }
  .form-field {
    z-index: 1;
    &:hover {
      z-index: 2;
    }
    &:focus,
    &.focus {
      z-index: 3;
    }
    &.left input,
    &.left button {
      border-radius: 9px 0 0 9px;
    }
    &.right input,
    &.right button {
      border-radius: 0 9px 9px 0;
      margin-left: -1px;
    }
  }
  .plane {
    fill: white;
  }

  .car-search-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    margin-top: 20px;
    background-image: linear-gradient(to left, #ffad5e, #ff7d0e);
    font-size: 12px;
    font-family: 'Quicksand', 'Quicksand Regular', sans-serif;
    border-radius: 0px 12px 12px 0px;
  }

  .button-text {
    margin-left: 5px;
  }

  .loading-animation {
    position: relative;
    left: 38%;
    @media screen and (max-width: 1200px) {
      left: 50%;
    }
  }
`

export default connect(selectSearchArgs, { setSearchAge, setCarSearchFilters, setTagFilters, setSortingOption })(
  SearchBarDesktop
)
