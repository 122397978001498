import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import util from '../../util'
import { El, FlexEl } from '../layout'
import { themeGet } from 'styled-system'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { theme } from '../../styles'
import DefaultCar from '../../util/images/placeholder-car.png'
import Automatic from '../../util/images/icon-automatic.svg'
import Manual from '../../util/images/icon-manual.svg'
import Seat from '../../util/images/icon-seat.svg'
import Insurance from '../../util/images/icon-insurance.svg'
import Pickup from '../../util/images/icon-pickup.svg'
import Dropoff from '../../util/images/icon-dropoff.svg'
import { useTranslation } from 'react-i18next'
import Instructions from '../Instructions/Instructions'

export const BookingInfoCardNonSS = styled(
  ({ isBundle = false, carRentalProduct, whichPage, isShowOnMobile, isSkyScanner, ...props }) => {
    const { t: TRANSLATOR, i18n } = useTranslation()
    const { car, supplier, searchArgs, extras, stationPair } = carRentalProduct
    const muiTheme = useTheme()
    const isBreakToMobile = useMediaQuery(muiTheme.breakpoints.down(theme.breakpoints[0] || 576))
    const { checkInDate, checkOutDate } = useSelector((state) => state.hotelBooking.dateRange)
    const { pickupDateStr, dropOffDateStr, isSameLocation } = useSelector((state) => state.searchArgs)
    const { isMatchCriteria } = useSelector((state) => state.bundleBooking)
    const startDate = isBundle ? (isMatchCriteria ? checkInDate : pickupDateStr) : searchArgs.pickupDateTime
    const endDate = isBundle ? (isMatchCriteria ? checkOutDate : dropOffDateStr) : searchArgs.dropOffDateTime
    const { t } = useTranslation()
    const transmissonTranslateMap = ['Manuelle', 'Manual', 'Manuale']
    const language = i18n.resolvedLanguage || 'en'

    if (typeof isShowOnMobile !== undefined && isShowOnMobile !== isBreakToMobile) return null
    const isSixtInsured = supplier.name === 'Sixt' && extras?.insured === true

    function handleError(e) {
      e.target.src = DefaultCar
    }

    const header = (() => {
      if (whichPage === 'XO1' && !isSkyScanner) {
        return TRANSLATOR('xo1:bookingDetails')
      }

      if (whichPage === 'XO1') {
        return TRANSLATOR('xo1:bookingInfo')
      }

      return TRANSLATOR('xo2:reservationDetails')
    })()

    return (
      <FlexEl {...props}>
        <h2>{header}</h2>
        {(whichPage === 'XO2' || !isSkyScanner) && (
          <FlexEl className='body-container'>
            <El>
              <img className='car-img' src={util.toHttps(car.imageUrl)} alt={car.name} onError={handleError} />
            </El>
            <FlexEl className='name-group-container'>
              <El>{car.groupDescription}</El>
              <El>{car.name}</El>
              <El>
                <img className='supplier-img' src={util.toHttps(supplier.logoUrl)} alt={supplier.name} />
              </El>
            </FlexEl>
          </FlexEl>
        )}
        <FlexEl className='info'>
          <div>
            <img
              src={transmissonTranslateMap.includes(car.transmissionDescription) ? Manual : Automatic}
              alt='transmission'
            />
            <span>{car.transmissionDescription}</span>
          </div>
          <div>
            <img src={Seat} alt='seat' />
            <span>{car.seatCount}</span>
          </div>
          {extras.insured && (
            <div>
              <img src={Insurance} alt='insurance' />
              <span>{isSixtInsured ? t('carListing:protection') : t('carListing:insurance')}</span>
            </div>
          )}
        </FlexEl>
        <FlexEl className='station-pair'>
          <div>
            <img src={Pickup} alt={TRANSLATOR('xo2:Pickup')} width={24} height={24} />
            <section>
              <h3>{TRANSLATOR('xo2:Pickup')}</h3>
              <p>{stationPair?.pickup?.name}</p>
              <p>{stationPair?.pickup?.wherePickup}</p>
              <p>{util.formatDateTimeFromDate(startDate, language)}</p>
            </section>
          </div>
          <div>
            <img src={Dropoff} alt={TRANSLATOR('xo2:Dropoff')} width={24} height={24} />
            <section>
              <h3>{TRANSLATOR('xo2:Dropoff')}</h3>
              <p>{isSameLocation ? stationPair?.pickup?.name : stationPair?.dropOff?.name}</p>
              <p>{util.formatDateTimeFromDate(endDate, language)}</p>
            </section>
          </div>
          {whichPage !== 'XO2' && (
            <div style={{ paddingLeft: 24 }}>
              <Instructions />
            </div>
          )}
        </FlexEl>
      </FlexEl>
    )
  }
)`
  @media screen and (min-width: 768px) {
    padding: 24px;
  }

  flex-direction: column;
  padding: 16px 12px 0;
  color: ${themeGet('colors.textBlack')};
  border-bottom: 1px solid ${themeGet('colors.separatorGrey')};

  > h2 {
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    margin: 0 0 16px;
    color: ${themeGet('colors.colorPrimary')};
  }

  .body-container {
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: start;
    margin-bottom: 8px;

    & > div:nth-child(1) {
      flex-basis: 40%;
      flex-grow: 0;
    }
  }

  & .car-img {
    max-width: 100%;
    max-height: 144px;
  }

  .name-group-container {
    flex-flow: column;
    justify-content: center;
    color: #525266;
    font-size: 14px;
    line-height: normal;
    flex-grow: 1;

    & > div:nth-child(1) {
      font-weight: 600;
    }

    & > div:nth-child(2) {
      font-weight: 500;
      text-transform: uppercase;
    }

    & .supplier-img {
      margin-top: 4px;
      max-height: 25px;
      width: auto;
    }
  }

  & .info {
    gap: 12px;
    font-size: 14px;
    line-height: normal;
    font-weight: 400;
    padding-bottom: 16px;
    flex-wrap: wrap;
    margin-left: -12px;
    margin-right: -12px;
    border-bottom: 1px solid ${themeGet('colors.separatorGrey')};

    & > div {
      display: flex;
      align-items: center;
      gap: 2px;
      margin-left: 12px;
    }
  }

  & .station-pair {
    display: flex;
    flex-direction: column;
    padding: 16px 0;
    gap: 16px;
    font-size: 16px;
    line-height: 20px;
    color: #525266;

    & img {
      object-fit: none;
      margin: -2px;
      flex-shrink: 0;
    }

    & div {
      display: flex;
      align-items: start;
      gap: 6px;

      & p,
      h3 {
        margin: 0;
      }

      & h3 {
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 4px;
      }

      & p {
        font-weight: 400;
      }

      & p:last-of-type {
        margin-top: 4px;
        color: #525266;
        font-size: 17px;
      }
    }
  }
`
