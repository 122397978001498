import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import translationAR from './locales/ar/translation.json'
import translationDE from './locales/de/translation.json'
import translationEN from './locales/en/translation.json'
import translationES from './locales/es/translation.json'
import translationFR from './locales/fr/translation.json'
import translationIT from './locales/it/translation.json'
import translationPT from './locales/pt/translation.json'
import translationTR from './locales/tr/translation.json'
import translationZH from './locales/zh/translation.json'
import translationRU from './locales/ru/translation.json'
import translationUK from './locales/uk/translation.json'

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: 'en',
    resources: {
      ar: translationAR,
      de: translationDE,
      en: translationEN,
      es: translationES,
      fr: translationFR,
      it: translationIT,
      pt: translationPT,
      tr: translationTR,
      zh: translationZH,
      ru: translationRU,
      uk: translationUK
    },
    detection: {
      order: ['localStorage', 'sessionStorage', 'navigator'],
      caches: ['localStorage', 'sessionStorage']
    },
    loadPath: './locales/en/translation.json'
  })

export default i18n
