import { FlexEl } from '../layout'
import { Location } from '../svg'
import { themeGet } from 'styled-system'
import React from 'react'
import { string } from 'prop-types'
import styled from 'styled-components'

export const MobileHeaderBar = styled(({ timeRange, pickupLocationName, dropOffLocationName, ...props }) => (
  <FlexEl className={'container'} {...props}>
    <FlexEl className={'wrapper'}>
      <FlexEl>
        <Location />
        {pickupLocationName}
      </FlexEl>
      <FlexEl>
        <Location />
        {dropOffLocationName}
      </FlexEl>
      <FlexEl fontWeight='normal'>
        <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'>
          <g fill='none' fillRule='evenodd'>
            <rect
              width='12'
              height='10.667'
              x='2'
              y='3.333'
              fill='#EFF1F8'
              stroke='#B4B4C2'
              strokeWidth='1.333'
              rx='2'
            />
            <path
              stroke='#B4B4C2'
              strokeLinecap='square'
              strokeWidth='1.333'
              d='M4.667 3L4.667 1.667M11.333 3L11.333 1.667'
            />
            <rect width='2' height='2' x='4' y='7.333' fill='#B4B4C2' rx='1' />
            <rect width='2' height='2' x='10' y='7.333' fill='#B4B4C2' rx='1' />
            <rect width='2' height='2' x='7' y='7.333' fill='#B4B4C2' rx='1' />
            <rect width='2' height='2' x='4' y='10' fill='#B4B4C2' rx='1' />
            <rect width='2' height='2' x='10' y='10' fill='#B4B4C2' rx='1' />
            <rect width='2' height='2' x='7' y='10' fill='#B4B4C2' rx='1' />
            <path fill='#B4B4C2' d='M2.333 3.333H13.666V6H2.333z' />
          </g>
        </svg>
        {timeRange}
      </FlexEl>
    </FlexEl>
  </FlexEl>
))`
  width: 100%;
  justify-content: space-between;

  .wrapper {
    flex-direction: column;
    justify-content: space-between;
    font-size: 14px;
    svg {
      width: 16px;
      height: 16px;
      margin-right: 2px;
    }
  }
  .search-icon {
    width: 32px;
    height: 32px;
    padding: 4px;
    color: white;
    border-radius: 4px;
    background-color: ${themeGet('colors.colorPrimary')};
    margin-top: auto;
    margin-bottom: auto;
  }
`

MobileHeaderBar.propTypes = {
  pickupLocationName: string.isRequired,
  dropOffLocationName: string.isRequired,
  timeRange: string.isRequired
}
