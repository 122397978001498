import React from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

// const phoneRegExpBk = /^\+?(?:\d{1}(?: ?\d+)*)?$/
const phoneRegExp = /^(\+[\d]{1,3})? ?(\([0-9][\d]{2}\)|[0-9][\d]) ?[\d]{3} ?[\d]{2,} ?[\d]{2,}$/

function TranslationMap({ field }) {
  const { t } = useTranslation()
  const textMap = {
    name: t('xo1:driverInfo:inputFirstNameError'),
    lastName: t('xo1:driverInfo:inputLastNameError'),
    email: t('xo1:driverInfo:inputEmailError'),
    phoneNumber: t('xo1:driverInfo:inputPhoneNumberError'),
    validEmail: t('xo1:driverInfo:validEmail'),
    validPhone: t('xo1:driverInfo:validPhone'),
    validName: t('xo1:driverInfo:validName')
  }
  return textMap[field]
}

export const driverValidationSchema = yup.object().shape({
  firstName: yup
    .string()
    .required(<TranslationMap field='name' />)
    .max(50, <TranslationMap field='validName' />),
  lastName: yup
    .string()
    .required(<TranslationMap field='lastName' />)
    .max(50, <TranslationMap field='validName' />),
  email: yup
    .string()
    .required(<TranslationMap field='email' />)
    .email(<TranslationMap field='validEmail' />),
  phoneNumber: yup
    .string()
    .required(<TranslationMap field='phoneNumber' />)
    .matches(phoneRegExp, <TranslationMap field='validPhone' />) // First 2 characters must be digit, other ones can be seperated by one space character.
  //.matches(/^\d+$/, 'Please enter a valid phone number') Old version.
})
