import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, MobileHeader, MobileFixedButton, Container, BackButton, Header, Button } from '../styles'
import DriverInfoCard from './DriverInfoCard'
import useReservationInfo from '../hooks/useReservationInfo'
import useModificationInfo from '../hooks/useModificationInfo'
import useModifyReservation from '../hooks/useModifyReservation'
import { MobileScreen, OverMobileScreen } from '../../common/Screens'
import { useHistory } from 'react-router-dom'
import { setModificationResponse, setModificationArgs } from '../../../redux/slices/modificationSlice'
import { useDispatch } from 'react-redux'
import handleSearchResponse from '../searchResponseHandler'
import FailedModificationModal from '../modals/FailedModificationModal'
import { LoadingScreen } from '../../CarListing/LoadingScreen'

export default function ModifyDriverConfirm() {
  const { response } = useReservationInfo()
  const { reservationId, type, userInfo: modifiedUserInfo, carSearchArgs } = useModificationInfo()
  const modifyReservation = useModifyReservation()
  const { t: TRANSLATOR } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleClick = () => {
    setIsLoading(true)
    modifyReservation.mutate(
      { reservationId, type, carSearchArgs },
      {
        onSuccess: (data) => {
          dispatch(setModificationResponse(data))
          handleSearchResponse(data, type, () => setOpen(true), history, dispatch, setModificationArgs)
          if (!!!data) setIsLoading(false)
        },
        onError: () => {
          setIsLoading(false)
          setOpen(true)
        }
      }
    )
  }

  if (isLoading) return <LoadingScreen />

  return (
    response && (
      <>
        <MobileScreen>
          <MobileHeader>{TRANSLATOR('reservations:modification:modifyDriverDetails')}</MobileHeader>
          <Box px={2}>
            <DriverInfoCard values={modifiedUserInfo} current={false} />
            <DriverInfoCard values={response.userInfo} current={true} />
          </Box>
          <MobileFixedButton onClick={handleClick}>{TRANSLATOR('reservations:modification:confirm')}</MobileFixedButton>
        </MobileScreen>
        <OverMobileScreen>
          <Container>
            <BackButton onClick={() => history.go(-1)}>{TRANSLATOR('reservations:reserved:back')}</BackButton>
            <Box border='1px solid var(--borderGrey)' borderRadius={12} bgcolor='white' p={6}>
              <Header>{TRANSLATOR('reservations:modification:modifyDriverDetails')}</Header>
              <Box maxWidth={700} margin='auto'>
                <DriverInfoCard values={modifiedUserInfo} current={false} />
                <DriverInfoCard values={response.userInfo} current={true} />
                <Button onClick={handleClick}>{TRANSLATOR('reservations:modification:confirm')}</Button>
              </Box>
            </Box>
          </Container>
        </OverMobileScreen>
        <FailedModificationModal open={open} onClick={() => history.replace('/contact')} />
      </>
    )
  )
}
