import React, { useState, useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, FormControlLabel } from '@material-ui/core'
import IconArrowDown from '../../util/images/icon-arrow-down.png'
import IconCheckbox from '../../util/images/icon-checkbox.png'
import IconCheckboxUnchecked from '../../util/images/icon-checkbox-unchecked.svg'
import IconShowHide from '../../util/images/icon-show.svg'
import { useSelector, useDispatch } from 'react-redux'
import { setCarSearchFilters, setTagFilters } from '../../redux/slices'
import {
  Image,
  CategoryHeader,
  SubcategoryHeader,
  Supplier,
  SelectButton,
  CountTag,
  StyledCheckbox
} from './suppliers.styles'

// Supplier name can be like this: "Avis by [broker name]".
const majorSuppliers = ['Avis', 'Budget', 'Hertz', 'Thrifty', 'Dollar', 'Europcar', 'Keddy', 'Sixt']

export function checkMajorSupplier(supplier) {
  for (let i = 0; i < majorSuppliers.length; i++) {
    if (!!supplier.match(majorSuppliers[i])) {
      return true
    }
  }
  return false
}

// This function isn't used in this component. It is imported in the CarList component.
export function checkFirstNCarProductForMajorSupplier(carProduct, n) {
  for (let i = 0; i < n; i++) {
    if (checkMajorSupplier(carProduct[i].supplier.name)) {
      return true
    }
  }
  return false
}

export default function Suppliers({ suppliers, category, counts }) {
  const { t: TRANSLATOR } = useTranslation()
  const filters = useSelector((state) => state.carSearchFilters)
  const tags = useSelector((state) => state.filterTags)
  const dispatch = useDispatch()

  // for performance, otherwise it is noticably slow
  const initialCheckedState = useMemo(() => {
    // initialize checked state false for major suppliers
    return suppliers
      .filter((supplier) => checkMajorSupplier(supplier))
      .reduce((acc, majorSupplier) => ({ ...acc, [majorSupplier]: false }), {})
  }, [suppliers])

  // To control checked state of same suppliers in both categories
  const [checked, setChecked] = useState(initialCheckedState)

  // select deselect all major suppliers
  const [selectAllMajor, setSelectAllMajor] = useState(false)
  const [selectAll, setSelectAll] = useState(false)

  // show/hide all suppliers section. default is hide on initial load if there are any major suppliers
  const [show, setShow] = useState(Object.keys(initialCheckedState).length === 0)

  // show/hide entire Suppliers category. TODO: use the logic in filters.js, right now this is kind of a rogue component
  const [showAll, setShowAll] = useState(true)

  // useEffect to update checked state when tags are updated. TODO: not needed when filters are refactored.
  useEffect(() => {
    setChecked(
      filters[category].reduce((acc, supplier) => {
        acc[supplier] = true
        return acc
      }, {})
    )

    // to toggle select all major button when all major suppliers are selected from banner
    if (
      !selectAllMajor &&
      Object.keys(initialCheckedState).length === tags.filter((tag) => checkMajorSupplier(tag[1])).length
    ) {
      setSelectAllMajor(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags])

  function handleChange(supplier) {
    const copyFilters = { ...filters }
    let copyTags = [...tags]
    return function (e) {
      if (e.target.checked) {
        copyFilters[category] = [...copyFilters[category], supplier]
        copyTags.push([category, supplier])
      } else {
        copyFilters[category] = copyFilters[category].filter((item) => item !== supplier)
        copyTags = copyTags.filter((item) => item[1] !== supplier)
      }
      // toggle button between select all and deselect all. if all is selected, then change button to deselect all and vice versa
      setSelectAllMajor(
        copyFilters[category].filter((supplier) => checkMajorSupplier(supplier)).length ===
          Object.keys(initialCheckedState).length
      )
      dispatch(setCarSearchFilters(copyFilters))
      dispatch(setTagFilters(copyTags))
    }
  }

  function toggleMajorSuppliers() {
    const copyFilters = { ...filters }
    let copyTags = [...tags]

    // The case when every supplier is selected then major suppliers are deselected
    if (selectAllMajor && selectAll) setSelectAll(false)

    if (!selectAllMajor) {
      copyFilters[category] = [...copyFilters[category], ...Object.keys(initialCheckedState)]
      // push major suppliers to tags if they are not already there
      Object.keys(initialCheckedState).forEach((supplier) => {
        if (!copyTags.find((tag) => tag[1] === supplier)) {
          copyTags.push([category, supplier])
        }
      })
    } else {
      copyFilters[category] = copyFilters[category].filter((supplier) => !checkMajorSupplier(supplier))
      // remove major suppliers from tags
      copyTags = copyTags.filter((tag) => !checkMajorSupplier(tag[1]))
    }

    dispatch(setCarSearchFilters(copyFilters))
    dispatch(setTagFilters(copyTags))
    setSelectAllMajor(!selectAllMajor)
  }

  function toggleAllSuppliers() {
    const copyFilters = { ...filters }
    let copyTags = [...tags]

    if (!selectAll) {
      copyFilters[category] = [...suppliers]
      // push all suppliers to tags if they are not already there
      suppliers.forEach((supplier) => {
        if (!copyTags.find((tag) => tag[1] === supplier)) {
          copyTags.push([category, supplier])
        }
      })
    } else {
      copyFilters[category] = []
      // remove all suppliers from tags
      copyTags = copyTags.filter((tag) => tag[0] !== category)
    }

    dispatch(setCarSearchFilters(copyFilters))
    dispatch(setTagFilters(copyTags))
    setSelectAll(!selectAll)
    setSelectAllMajor(!selectAll)
  }

  function handleShowAll(e) {
    e.target.classList.toggle('open')
    setShowAll(!showAll)
  }

  return (
    <>
      <Grid
        container
        justifyContent='space-between'
        style={{ marginTop: 16, paddingTop: 24, borderTop: '1px solid #dfdfe7' }}
      >
        <CategoryHeader>{TRANSLATOR('carListing:supplier')}</CategoryHeader>
        <Image src={IconArrowDown} alt='icon-arrow' height={20} width={20} lineheight='20px' onClick={handleShowAll} />
      </Grid>
      <Grid container direction='column' style={{ gap: 12, display: !showAll && 'none', marginTop: 12 }}>
        {/* show major suppliers only if there are any */}
        {!!Object.keys(initialCheckedState).length && (
          <Grid container justifyContent='space-between'>
            <SubcategoryHeader>{TRANSLATOR('suppliers:major')}</SubcategoryHeader>
            <SelectButton underline='underline' onClick={toggleMajorSuppliers}>
              {selectAllMajor ? TRANSLATOR('suppliers:deselect') : TRANSLATOR('suppliers:select')}
            </SelectButton>
          </Grid>
        )}
        {suppliers.map(
          (supplier, index) =>
            checkMajorSupplier(supplier) && (
              <Grid container justifyContent='space-between' alignItems='center' id='major'>
                <FormControlLabel
                  checked={checked[supplier] || false}
                  style={{ margin: 0, flexGrow: 1 }}
                  key={index}
                  control={
                    <StyledCheckbox
                      disableRipple
                      checkedIcon={<Image src={IconCheckbox} alt='checked' width={24} height={24} lineheight='24px' />}
                      icon={
                        <Image src={IconCheckboxUnchecked} alt='unchecked' width={24} height={24} lineheight='24px' />
                      }
                    />
                  }
                  label={<Supplier>{supplier}</Supplier>}
                  onChange={handleChange(supplier)}
                />
                <CountTag>{counts[supplier] || 0}</CountTag>
              </Grid>
            )
        )}
        <Grid container justifyContent='space-between' style={{ display: !show && 'none' }}>
          <SubcategoryHeader>{TRANSLATOR('suppliers:all')}</SubcategoryHeader>
          <SelectButton underline='underline' onClick={toggleAllSuppliers}>
            {selectAll ? TRANSLATOR('suppliers:deselect') : TRANSLATOR('suppliers:select')}
          </SelectButton>
        </Grid>
        <Grid container direction='column' style={{ gap: 12, display: !show && 'none' }}>
          {suppliers.map((supplier, index) => (
            <Grid container justifyContent='space-between' alignItems='center'>
              <FormControlLabel
                checked={checked[supplier] || false}
                style={{ margin: 0, flexGrow: 1 }}
                key={index}
                control={
                  <StyledCheckbox
                    disableRipple
                    checkedIcon={<Image src={IconCheckbox} alt='checked' width={24} height={24} lineheight='24px' />}
                    icon={
                      <Image src={IconCheckboxUnchecked} alt='unchecked' width={24} height={24} lineheight='24px' />
                    }
                  />
                }
                label={<Supplier>{supplier}</Supplier>}
                onChange={handleChange(supplier)}
              />
              <CountTag>{counts[supplier] || 0}</CountTag>
            </Grid>
          ))}
        </Grid>
        <Grid
          container
          wrap='nowrap'
          style={{ borderBottom: '1px solid #FF7D0E', width: 'min-content', margin: 'auto' }}
        >
          <SelectButton onClick={() => setShow(!show)}>
            {show ? TRANSLATOR('suppliers:less') : TRANSLATOR('suppliers:more')}
          </SelectButton>
          <Image src={IconShowHide} alt='icon-show-hide' style={{ transform: `rotate(${show ? 0 : 180}deg)` }} />
        </Grid>
      </Grid>
    </>
  )
}
