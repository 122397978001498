import React from 'react'
import styled from 'styled-components'

const PlaceHolder = styled.div`
  width: 80vw;
  max-width: 1200px;
  margin: 10px auto;
  border-radius: 12px;
  margin-top: 40px;
  min-height: 150px;

  animation-name: colorChange;
  animation-duration: 1s;
  animation-iteration-count: infinite;

  @keyframes colorChange {
    0% {
      background-color: rgba(239, 241, 248, 1);
    }
    50% {
      background-color: rgba(239, 241, 248, 0.1);
    }
    100% {
      background-color: rgba(239, 241, 248, 1);
    }
  }

  @media only screen and (max-width: 1200px) {
    width: 500px;
    min-height: 210px;
  }

  @media only screen and (max-width: 576px) {
    width: 95vw;
    min-height: 210px;
  }
`

const Container = styled.div`
  width: 80vw;
  max-width: 1200px;
  margin: 10px auto;
  -webkit-box-shadow: 2px 4px 14px 0px rgba(65, 64, 66, 0.25);
  -moz-box-shadow: 2px 4px 14px 0px rgba(65, 64, 66, 0.25);
  box-shadow: 2px 4px 14px 0px rgba(65, 64, 66, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 1200px) {
    width: 500px;
  }

  @media only screen and (max-width: 576px) {
    width: 95vw;
    margin-top: 22px;
  }
`

const TitleDesktop = styled.h2`
  font-family: Montserrat;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #525266;
  margin-bottom: 0px;

  @media only screen and (max-width: 1200px) {
    display: none;
  }
`

const TitleMobile = styled.h2`
  width: 95%;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #525266;
  margin-bottom: 10px;

  @media only screen and (min-width: 1200px) {
    display: none;
  }

  @media only screen and (max-width: 576px) {
    font-size: 16px;
  }
`

const Text = styled.div`
  font-family: Montserrat;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #525266;
  margin-top: 10px;

  @media only screen and (max-width: 1200px) {
    width: 95%;
    text-align: left;
    margin-top: 0px;
  }

  @media only screen and (max-width: 576px) {
    font-size: 16px;
  }
`

const ImageWrapper = styled.div`
  width: 95%;
  margin: 25px;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  @media only screen and (max-width: 1200px) {
    width: 90%;
    flex-wrap: wrap;
  }
`

const Img = styled.img`
  display: block;
  height: 45px;

  @media only screen and (max-width: 1480px) {
    height: 35px;
  }

  @media only screen and (max-width: 1200px) {
    width: 20%;
    height: auto;
  }
`

const baseURL = (e) => `https://caroline-supplier-images.s3-eu-west-1.amazonaws.com/${e}`
//Europcar, Hertz, Green Motion, SIXT, Avis, Budget, Goldcar, Easirent

const Europcar = 'https://carla-web-images.s3.eu-west-1.amazonaws.com/europcar.png'
const Hertz = baseURL('hertz.png')
const GreenMotion = baseURL('green_motion.png')
const SIXT = baseURL('sixt.png')
const Avis = baseURL('avis.png')
const Budget = baseURL('budget.png')
const Goldcar = baseURL('goldcar.png')
const Easirent = baseURL('easirent.png')
const Noleggiare = baseURL('noleggiare.png')
const Centauro = baseURL('centauro.png')
const Sicily = 'https://carla-landing-images.s3.eu-west-1.amazonaws.com/sicily.png'
const Drivalia = baseURL('drivalia.png')
const Thrifty = baseURL('thrifty.png')
const Dollar = baseURL('dollar.png')
const Fox = baseURL('fox.png')
const Payless = baseURL('payless.png')

const springData = [
  { logoUrl: Europcar, name: 'Europcar' },
  { logoUrl: Hertz, name: 'Hertz' },
  { logoUrl: GreenMotion, name: 'Green Motion' },
  { logoUrl: SIXT, name: 'SIXT' },
  { logoUrl: Avis, name: 'Avis' },
  { logoUrl: Budget, name: 'Budget' },
  { logoUrl: Goldcar, name: 'Goldcar' },
  { logoUrl: Easirent, name: 'Easirent' }
]

const italyData = [
  { logoUrl: Europcar, name: 'Europcar' },
  { logoUrl: Noleggiare, name: 'Noleggiare' },
  { logoUrl: GreenMotion, name: 'Green Motion' },
  { logoUrl: Sicily, name: 'Sicily by Car' },
  { logoUrl: Avis, name: 'Avis' },
  { logoUrl: Budget, name: 'Budget' },
  { logoUrl: Drivalia, name: 'Drivalia' },
  { logoUrl: Centauro, name: 'Centauro' }
]

const memorialDayData = [
  { logoUrl: Hertz, name: 'Hertz' },
  { logoUrl: Avis, name: 'Avis' },
  { logoUrl: Budget, name: 'Budget' },
  { logoUrl: Thrifty, name: 'Thrifty' },
  { logoUrl: Dollar, name: 'Dollar' },
  { logoUrl: Payless, name: 'Payless' },
  { logoUrl: Fox, name: 'Fox' },
  { logoUrl: SIXT, name: 'SIXT ' }
]

function PopularCompanies({ memorialDay, italy, loc, data, count, spring }) {
  if (spring) {
    return (
      <Container>
        <TitleDesktop>Popular rental companies for Memorial Day Weekend</TitleDesktop>
        <TitleMobile>Popular rental companies for Memorial Day Weekend</TitleMobile>
        <Text>Compare prices of {count ? count : '100'}+ companies</Text>
        <ImageWrapper>
          {springData.map((item, index) => {
            return <Img height='40px' width='auto' key={index} src={item.logoUrl} alt={item.name} />
          })}
        </ImageWrapper>
      </Container>
    )
  }

  if (italy) {
    return (
      <Container>
        <TitleDesktop>Società di noleggio più popolari in Italia</TitleDesktop>
        <TitleMobile>Società di noleggio più popolari in Italia</TitleMobile>
        <Text>Confronta i prezzi di oltre 90 aziende</Text>
        <ImageWrapper>
          {italyData.map((item, index) => {
            return (
              <Img
                height='40px'
                width='120px'
                style={{ objectFit: 'contain' }}
                key={index}
                src={item.logoUrl}
                alt={item.name}
              />
            )
          })}
        </ImageWrapper>
      </Container>
    )
  }

  if (memorialDay) {
    return (
      <Container>
        <TitleDesktop>Popular rental companies for Memorial Day Weekend</TitleDesktop>
        <TitleMobile>Popular rental companies for Memorial Day Weekend</TitleMobile>
        <Text>Compare prices of {count ? count : '100'}+ companies</Text>
        <ImageWrapper>
          {memorialDayData.map((item, index) => {
            return (
              <Img
                height='40px'
                width='auto'
                style={{ objectFit: 'contain', maxWidth: '120px' }}
                key={index}
                src={item.logoUrl}
                alt={item.name}
              />
            )
          })}
        </ImageWrapper>
      </Container>
    )
  }

  return count ? (
    <Container>
      <TitleDesktop>Popular rental companies in {loc}</TitleDesktop>
      <TitleMobile>{loc} Popular Rental Companies</TitleMobile>
      <Text>Compare prices of {count}+ companies</Text>
      <ImageWrapper>
        {data.map((item, index) => {
          return <Img height='40px' width='auto' key={index} src={item.logoUrl} alt={item.name} />
        })}
      </ImageWrapper>
    </Container>
  ) : (
    <PlaceHolder />
  )
}

export default PopularCompanies
