import React from 'react'
import { useDispatch } from 'react-redux'
import { resetTagFilters, resetCarSearchFilters } from '../../redux/slices'
import MapBackground from '../../util/images/map-background.png'
import MapIcon from '../../util/images/icon-map.svg'
import BackArrow from '../../util/images/icon-go-back-to-map.svg'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const SeeOnMap = styled(({ history, className }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const handleClick = () => {
    history.goBack()
    dispatch(resetCarSearchFilters())
    dispatch(resetTagFilters())
  }

  return (
    <div className={className} onClick={handleClick}>
      <img src={BackArrow} alt='back arrow' className='back-arrow' />
      <div className='see-on-map-container'>
        <img src={MapIcon} alt='map icon' className='map-icon' />
        <p className='see-on-map-text'>{t('carListing:backToMap')}</p>
      </div>
    </div>
  )
})`
  background: lightgray 50% / cover no-repeat;
  position: relative;
  border-radius: 8px;
  background-image: url(${MapBackground});
  cursor: pointer;

  & .back-arrow {
    width: 24px;
    height: 24px;
    object-fit: none;
    position: absolute;
    left: 16px;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  & .map-icon {
    width: 24px;
    height: 24px;
    object-fit: none;
  }

  & .see-on-map-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    padding: 12px 24px;
  }

  & .see-on-map-text {
    margin: 0;
    font-weight: 600;
    line-height: normal;
    color: #525266;
  }

  @media screen and (max-width: 576px) {
    & .see-on-map-container {
      flex-direction: row;
    }

    border-radius: 8px;
    border: 1px solid #dfdfe7;
    box-shadow: 0px 0px 2px 0px rgba(82, 82, 102, 0.04), 0px 2px 2px 0px rgba(82, 82, 102, 0.05);
    max-width: 90vw;
    margin: auto;
    margin-top: 20px;
    margin-bottom: -4px;
  }
`

export default SeeOnMap
