import React from 'react'
import { DriverInfoCardContainer, Box, Grid } from '../styles'
import { useTranslation } from 'react-i18next'

export const DriverInfo = ({ firstName, lastName, email, phoneNumber, TRANSLATOR }) => {
  return (
    <DriverInfoCardContainer>
      <Grid container>
        <p>{TRANSLATOR('xo1:driverInfo:inputFirstName')}:</p>
        <p>{firstName}</p>
      </Grid>
      <Grid container>
        <p>{TRANSLATOR('xo1:driverInfo:inputLastName')}:</p>
        <p>{lastName}</p>
      </Grid>
      <Grid container>
        <p>{TRANSLATOR('xo1:driverInfo:inputEmail')}:</p>
        <p>{email}</p>
      </Grid>
      <Grid container>
        <p>{TRANSLATOR('xo1:driverInfo:inputPhoneNumber')}:</p>
        <p>{phoneNumber}</p>
      </Grid>
    </DriverInfoCardContainer>
  )
}

export default function DriverInfoCard({ values, current }) {
  const { t: TRANSLATOR } = useTranslation()

  return (
    <>
      <h3 style={{ margin: '24px 0 8px', fontSize: 16, fontWeight: 600 }}>
        {TRANSLATOR(`reservations:modification:driver:${current ? 'currentDetails' : 'newDetails'}`)}
      </h3>
      <Box
        bgcolor='white'
        borderRadius={12}
        p='8px 12px'
        border='1px solid'
        borderColor={`var(--${current ? 'borderGrey' : 'colorPrimary'})`}
      >
        <DriverInfo {...values} TRANSLATOR={TRANSLATOR} />
      </Box>
    </>
  )
}
