import React from 'react'
import { useTranslation } from 'react-i18next'
import { setSearchPickupTime, setSearchDropOffTime } from '../../../redux/slices'
import { useSelector, useDispatch } from 'react-redux'
import { DateTimeField, Grid, Box, Text, MobileHeader, MobileFixedButton } from '../styles'
import CurrentBookingCard from './CurrentBookingCard'
import useModificationInfo from '../hooks/useModificationInfo'
import useModifyReservation from '../hooks/useModifyReservation'
import { setModificationArgs, setModificationResponse } from '../../../redux/slices/modificationSlice'
import util from '../../../util'
import { useHistory } from 'react-router-dom'
import handleSearchResponse from '../searchResponseHandler'
import { LoadingScreen } from '../../CarListing/LoadingScreen'
import FailedModificationModal from '../modals/FailedModificationModal'

export default function DateTimeMobile({ rentalProduct, dtl }) {
  const { t: TRANSLATOR } = useTranslation()
  const { reservationId, type, carSearchArgs } = useModificationInfo()
  const modifyReservation = useModifyReservation()
  const { pickupDateStr, pickupTime, dropOffDateStr, dropOffTime } = useSelector((state) => state.searchArgs)
  const dispatch = useDispatch()
  const history = useHistory()
  const [open, setOpen] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)

  // TODO: There is a bug in redux, when you change time from the picker, time is reset to 00:00
  function buildDateTime(date, time) {
    const newDate = new Date(date)

    newDate.setHours(time.hour)
    newDate.setMinutes(time.min)

    return util.formatDateForAPI(newDate)
  }

  // TODO: Pass this from parent
  function handleClick() {
    setIsLoading(true)
    const newPickupDateTime = buildDateTime(pickupDateStr, pickupTime)
    const newDropOffDateTime = buildDateTime(dropOffDateStr, dropOffTime)

    const newCarSearchArgs = {
      ...carSearchArgs,
      pickupDateTime: newPickupDateTime,
      dropOffDateTime: newDropOffDateTime
    }

    dispatch(
      setModificationArgs({
        carSearchArgs: newCarSearchArgs
      })
    )

    modifyReservation.mutate(
      { reservationId, type, carSearchArgs: newCarSearchArgs },
      {
        onSuccess: (data) => {
          dispatch(setModificationResponse(data))
          handleSearchResponse(data, type, () => setOpen(true), history, dispatch, setModificationArgs)
          if (!!!data) setIsLoading(false)
        },
        onError: () => {
          setIsLoading(false)
          setOpen(true)
        }
      }
    )
  }

  if (isLoading) return <LoadingScreen />

  return (
    <>
      <MobileHeader>{TRANSLATOR('reservations:modification:options:dateTime')}</MobileHeader>
      <Box px={2} py={3}>
        <Grid container direction='column' style={{ gap: 24 }}>
          <Box>
            <Text fontWeight={600} mb={1}>
              {TRANSLATOR('reservations:modification:newPickupDateTime')}
            </Text>
            <DateTimeField
              pickupDate={pickupDateStr}
              dropOffDate={dropOffDateStr}
              explanation={TRANSLATOR('homePage:pickUpTime')}
              date={pickupDateStr}
              time={pickupTime}
              onTimeChange={(value) => {
                dispatch(setSearchPickupTime(value))
              }}
            />
          </Box>
          <Box>
            <Text fontWeight={600} mb={1}>
              {TRANSLATOR('reservations:modification:newDropOffDateTime')}
            </Text>
            <DateTimeField
              pickupDate={pickupDateStr}
              dropOffDate={dropOffDateStr}
              explanation={TRANSLATOR('homePage:dropOffTime')}
              date={dropOffDateStr}
              time={dropOffTime}
              onTimeChange={(value) => {
                dispatch(setSearchDropOffTime(value))
              }}
            />
          </Box>
          <Box>
            <Text fontWeight={600} mb={1}>
              {TRANSLATOR('reservations:modification:currentReservation')}
            </Text>
            <Box bgcolor='white' border='1px solid var(--borderGrey)' borderRadius={12} p={1.5}>
              <CurrentBookingCard rentalProduct={rentalProduct} dtl={dtl} />
            </Box>
          </Box>
        </Grid>
      </Box>
      <MobileFixedButton onClick={handleClick}>{TRANSLATOR('reservations:modification:continue')}</MobileFixedButton>
      <FailedModificationModal open={open} onClick={() => history.replace('/contact')} />
    </>
  )
}
