import React from 'react'
import { withRouter } from 'react-router-dom'
import Footer from '../layout/Footer'

const ConnectedFooter = ({ location }) => {
  const hiddenFooterPagePath = ['/bundle-reservation', '/bundle-payment', '/hotel-reservation', '/hotel-payment']
  const hiddenFooterPagePathForMobile = ['/hotel-results/', '/hotel-bundle/list', '/rental-cars/']

  const isHiddenFooter =
    hiddenFooterPagePath.includes(location.pathname) ||
    hiddenFooterPagePathForMobile.some((path) => location?.pathname.includes(path))

  const width = window?.innerWidth || 0
  // eslint-disable-next-line
  if (location.pathname == '/reservation' || location.pathname == '/payment') {
    return width < 576 ? <div style={{ paddingBottom: '4rem' }}></div> : null
  }
  return <>{!isHiddenFooter ? <Footer /> : <div style={{ marginTop: '5.5rem' }}></div>}</>
}

export default withRouter(ConnectedFooter)
