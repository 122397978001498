import React, { Component } from 'react'
import debug from 'debug'
import { connect } from 'react-redux'
import util from '../../util'
import { toast } from 'react-toastify'
import GA from '../../services/GA'
import { setSearchPickupLocation } from '../../redux/slices'
import { selectSearchArgs } from '../../redux/selectors'
import EventSender from '../../services/EventSender'
import { checkDefaultDateRange } from '../../util/searchArgs'
import API from '../../services/API'
import SearchBarDesktop from '../SearchBarHome/SearchBarDesktop'
import styled from 'styled-components'
import SearchBarMobile from '../SearchBarHome/SearchBarMobile'
import NoSSR from '../NoSSR'

const log = debug('carla:search')

const DesktopContainer = styled.div`
  width: 100vw;
  padding-top: 120px;
  padding-bottom: 80px;
  background-image: url(${(props) => props.bg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media only screen and (max-width: 1200px) {
    display: none;
  }
`

const DesktopSearchBarWrapper = styled.div`
  margin: 0 auto;
  width: fit-content;
  background-color: white;
  position: relative;
  border-radius: 0px 12px 12px 12px;

  &::before {
    position: absolute;
    content: '${(props) =>
      props.spring ? 'Cheap Car Hire' : props.italy ? 'Noleggio Auto in Italia' : `${props.locName}`}';
    border-radius: 12px 12px 0px 0px;
    width: fit-content;
    padding: 14px;
    height: 40px;
    background: white;
    left: 0;
    top: -65px;
    font-size: 40px;
    font-family: Montserrat;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #525266;
  }
`

const MobileSearchBarWrapper = styled.div`
  width: 95vw;
  margin: 0 auto;
  -webkit-box-shadow: 2px 4px 14px 0px rgba(65, 64, 66, 0.15);
  -moz-box-shadow: 2px 4px 14px 0px rgba(65, 64, 66, 0.15);
  box-shadow: 2px 4px 14px 0px rgba(65, 64, 66, 0.35);
  padding-bottom: 40px;
  padding-top: 20px;
  border-radius: 12px;
  margin-top: 25px;

  @media only screen and (max-width: 576px) {
    margin-top: 10px;
  }
`

const MobileContainer = styled.div`
  @media only screen and (min-width: 1200px) {
    display: none;
  }
`

const MobileImg = styled.div`
  width: 100vw;
  height: 130px;
  background-image: url(${(props) => props.bg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  &::before {
    position: absolute;
    content: '${(props) => (props.locName ? props.locName : '')} Car Rental';
    border-radius: 0px 12px 0px 0px;
    width: fit-content;
    padding: 14px;
    height: 40px;
    background: white;
    left: 0;
    bottom: 0px;
    font-size: 40px;
    font-family: Montserrat;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #525266;

    @media only screen and (max-width: 576px) {
      font-size: 21px;
      height: 30px;
      bottom: -2px;
    }
  }
`

class SearchBar extends Component {
  getClosestLocation = async () => {
    try {
      log('fetching closest location')
      const closestLocation = await API.getClosestLocation()
      if (closestLocation) {
        const location = {
          label: closestLocation.prettyName,
          value: closestLocation.id,
          type: closestLocation.type
        }
        log('closest location:', closestLocation)
        this.props.setSearchPickupLocation(location)
      } else {
        log('empty response from closestLocation', closestLocation)
      }
    } catch (err) {
      log('closest location not found', err)
    }
  }

  componentDidMount() {
    checkDefaultDateRange()
    if (!this.props.pickupLocation) {
      this.getClosestLocation()
    }
  }

  /**
   * validates search form, returns true if error found, false otherwise
   * @returns {string|boolean}
   */
  validateCarSearchInfo = () => {
    if (!this.props.pickupLocation) {
      return 'Please enter pickup location'
    }

    const { pickupDateTime, dropOffDateTime } = this.props
    if (!pickupDateTime || !dropOffDateTime) {
      return 'Please select pick-up and drop-off date'
    }
    // if pickup is after from
    if (pickupDateTime > dropOffDateTime) {
      return 'Please select drop-off time after pick-up time'
    }
    // if duration less then 3 hours
    if (dropOffDateTime.getTime() - pickupDateTime.getTime() < 1000 * 60 * 30) {
      return 'Please select drop-off time at least 30 minutes after pick-up time'
    }

    return false
  }

  /**
   * submit search
   * @param e {event}
   */
  handleSubmit = async (e) => {
    e.preventDefault()

    // handle error
    const error = this.validateCarSearchInfo()
    if (error) {
      if (!toast.isActive(this.errorToastId)) {
        this.errorToastId = toast.error(error)
      }
      return
    }

    const { pickupLocation, dropOffLocation, pickupDateTime, dropOffDateTime, age } = this.props
    const pickupTimeStr = util.formatDateForAPI(pickupDateTime)
    const dropOffTimeStr = util.formatDateForAPI(dropOffDateTime)

    EventSender.searched(this.props)
    GA.searchForCars()

    //Check if the location is an airport. If it is not Send the user to the map page
    const responsePickup = await API.getPlace(pickupLocation.value)
    const responseDropOff = await API.getPlace(dropOffLocation.value)

    //Right now all clients go to the map if the pickup location and dropOffLocation is the same
    //even though the endpoint accepts different locations. This is going to be fixed in a coordinated manner
    if (pickupLocation.value === dropOffLocation.value && responsePickup.type !== 'airport') {
      //Go to the map page
      this.props.history.push({
        pathname: '/availableAreas',
        state: {
          centerLat: responsePickup.latitude,
          centerLng: responsePickup.longitude,
          pickupLocation: responsePickup,
          dropOffLocation: responseDropOff,
          pickupDateTime,
          dropOffDateTime,
          pickupTimeStr,
          dropOffTimeStr,
          age,
          from: 'home' //This prop is used to render 'back to list view' or not
        }
      })
      return
    }

    // search
    try {
      let url = `/rental-cars/${pickupLocation.value}/${dropOffLocation.value}/${pickupTimeStr}/${dropOffTimeStr}`
      if (age && Number(age) < 25) url += `?age=${age}`
      log('redirecting to search page:', url)
      this.props.history.push(url)
    } catch (err) {
      console.error('error while submitting search form', err)
    }
  }

  render() {
    const isItaly = this.props.italy
    return (
      <NoSSR>
        {/* DESKTOP SEARCH BAR */}
        <DesktopContainer bg={this.props.bg}>
          <DesktopSearchBarWrapper italy={isItaly} locName={this.props.locName} spring={this.props.spring}>
            <SearchBarDesktop age={this.props.age} onSubmit={this.handleSubmit} isPageDynamic={true} />
          </DesktopSearchBarWrapper>
        </DesktopContainer>

        {/* MOBILE AND TABLET SEARCH BAR */}
        <MobileContainer>
          <MobileImg bg={this.props.bg} locName={this.props.locName} />
          <MobileSearchBarWrapper>
            <SearchBarMobile onSubmit={this.handleSubmit} isPageDynamic={true} />
          </MobileSearchBarWrapper>
        </MobileContainer>
      </NoSSR>
    )
  }
}

export default connect(selectSearchArgs, { setSearchPickupLocation })(SearchBar)
