import React from 'react'
import styled from 'styled-components'
import { themeGet } from 'styled-system'
import Icon from '../../util/images/ellipse-5-stroke.svg'

const Container = styled.div`
  width: 100vw;
  padding: 60px 40px;
  background: #eff1f8;

  @media only screen and (max-width: ${themeGet('breakpoints.1')}px) {
    padding: 60px 20px;
  }

  @media only screen and (max-width: ${themeGet('breakpoints.0')}px) {
    padding: 60px 2px;
  }
`

const Wrapper = styled.div`
  margin: 0 auto;
  width: 70%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media only screen and (max-width: ${themeGet('breakpoints.1')}px) {
    width: 85%;
  }

  @media only screen and (max-width: ${themeGet('breakpoints.0')}px) {
    width: 99%;
  }
`

const Title = styled.h1`
  color: #525266;
  font-family: Montserrat;
  font-size: 35px;
  font-weight: 800;
  margin: 0 auto;
  margin-bottom: 30px;
  text-align: center;

  @media only screen and (max-width: ${themeGet('breakpoints.1')}px) {
    font-size: 30px;
    text-align: left;
    margin: 0 20px 30px 20px;
  }

  @media only screen and (max-width: ${themeGet('breakpoints.0')}px) {
    font-size: 22px;
    text-align: left;
    margin: -15px 10px 30px 10px;
  }
`

const SingleLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 13px;
  gap: 15px;

  @media only screen and (max-width: ${themeGet('breakpoints.1')}px) {
    margin: 0px 20px 30px 20px;
  }

  @media only screen and (max-width: ${themeGet('breakpoints.0')}px) {
    margin: -15px 10px 30px 10px;
  }
`

const Text = styled.div`
  font-family: 'Quicksand', 'Quicksand Regular', sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #525266;
`

function GoodToKnow({ italy, data, loc }) {
  const generateContent = () => {
    if (italy) {
      return "È bene saperlo quando si noleggia un'auto."
    } else {
      return `Good to know when you rent a car in ${loc}`
    }
  }

  return data && data.length > 0 ? (
    <Container>
      <Wrapper>
        <Title>{generateContent()}</Title>
        {data &&
          data.map((item, index) => {
            return (
              <SingleLine key={index}>
                <img alt='check icon' src={Icon} />
                <Text>{item}</Text>
              </SingleLine>
            )
          })}
      </Wrapper>
    </Container>
  ) : (
    <div />
  )
}

export default GoodToKnow
