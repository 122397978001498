import styled from 'styled-components'
import { El, FlexEl } from './layout'
import GA from '../services/GA'
import { AppStoreBadge, GooglePlayBadge } from './svg'
import { themeGet } from 'styled-system'
import React from 'react'

const DownloadAppSection = styled((props) => (
  <FlexEl {...props}>
    <El className='title' m={20}>
      Download Carla to get car rental deals
    </El>
    <FlexEl>
      <a
        href='https://itunes.apple.com/app/id1035631234?mt=8'
        target='_blank'
        rel='noopener noreferrer'
        onClick={GA.clickAppStoreBadge}
      >
        <AppStoreBadge />
      </a>
      <a
        href='https://play.google.com/store/apps/details?id=com.mobikasaba.carlaandroid&hl=en&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
        target='_blank'
        rel='noopener noreferrer'
        onClick={GA.clickGooglePlayBadge}
      >
        <GooglePlayBadge />
      </a>
    </FlexEl>
  </FlexEl>
))`
  max-width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  overflow: hidden;
  .title {
    font-size: 18px;
    color: ${themeGet('colors.blueGrey.8')};
    text-align: center;
  }
  a {
    margin: 0 3px;
    svg {
      height: 40px;
      @media (min-width: ${themeGet('breakpoints.0')}px) {
        height: 50px;
      }
    }
  }
`

export default DownloadAppSection
