import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const StepStyled = styled('div')`
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin: 0;
  color: ${(props) => (props.$active ? '#3EAA77' : '#979797')};
  display: flex;
  align-items: center;
  position: relative;
  flex: 1;
  hr {
    min-width: 50px;
    outline: none;
    border: none;
    height: 4px;
    width: 100%;
    background-color: ${(props) => (props.$active ? '#3EAA77' : '#B4B4C2')};
  }
`

export const StepLabel = styled('p')`
  position: absolute;
  bottom: 10px;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  padding: 0 32px 0 8px;
  text-align: left;
  @media (min-width: ${themeGet('breakpoints.0')}px) {
    text-align: center;
  }
`

export const StepIcon = styled('div')`
  display: block;
  padding: 5px;
  border-radius: 50%;
  color: ${(props) => (props.$active ? '#3EAA77' : '#B4B4C2')};
  border: ${(props) => (props.$active ? '1px solid #3EAA77' : '1px solid  #B4B4C2')};
  background-color: ${(props) => (props.$active ? '#3EAA77' : '#B4B4C2')};
  display: flex;
  align-items: center;
`
export const StepperStyled = styled('div')`
  display: flex;
  padding: 24px 0;
  @media (min-width: ${themeGet('breakpoints.0')}px) {
    padding: 16px 0;
  }
`
