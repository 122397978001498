import styled from 'styled-components'

export const CarInfoCardContainer = styled('div')`
  display: flex;
  width: 100%;
  max-width: 850px;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  .info-card {
    display: flex;
    background-color: white;
    border-radius: 12px;
    border: solid 1px #dfdfe7;

    flex-grow: 5;
    margin: 24px 0 16px 0;
    font-size: 14px;
    font-weight: 500;

    height: 80px;
    padding: 8px 24px;
  }

  .info-card-header {
    text-transform: uppercase;
  }

  .info-card-half {
    display: flex;
    width: 45%;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
  }

  .info-card-location {
    display: flex;
    flex-wrap: wrap;
    font-size: 13px;
    flex-direction: column;
    justify-content: center;
  }

  .info-card-datetime {
    display: flex;
  }

  .info-card-separator {
    display: flex;
    margin: -8px 24px 0;
  }
`
export const CarImage = styled('img')`
  position: absolute;
  right: 0;
  bottom: 8px;
`
export const MobileCarInfoCardContainer = styled('div')``

export const CarLocationWrap = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 18px;
  margin: 24px 0;
`
export const CarLocation = styled('div')`
  display: flex;
  gap: 10px;
  p {
    margin: 0;
    font-size: 12px;
    color: #525266;
  }
`

export const LocationTitle = styled('div')`
  font-size: 16px;
  font-weight: 500;
`
export const CompactSession = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 24px;
  color: #000;
  font-size: 16px;
`
export const CheckLabel = styled('span')`
  font-size: 13px;
  color: var(--text-primary, #525266);
`
