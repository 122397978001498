import React, { useState } from 'react'
import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Link } from 'react-router-dom'
import { Container, El, LinkEl, SearchButton } from '../../components/layout'
import util from '../../util'
import { Helmet } from 'react-helmet'
import DownloadAppSection from '../../components/DownloadAppSection'
import { MdSearch } from 'react-icons/md'
import GA from '../../services/GA'
import { format } from 'date-fns'
import SocialShare from './SocialShare'
import { useArticles } from '../../hooks/useArticles'
import useCurrentLang from '../../hooks/useCurrentLang'
import { getAllArticlesFromCache } from '../../sanity/cache'

export const BlogIntro = styled((props) => (
  <El id='blog-intro' {...props}>
    <h1>Car Rental Blog</h1>
    <h3>
      Your best travel companion buddy to get the perfect car for you - fastest to the point. So that you can sit back
      and enjoy creating memories...
    </h3>
  </El>
))`
  text-align: center;
  background-color: white;
  border-bottom: 1px solid ${themeGet('colors.blueGrey.1')};

  h1,
  h3 {
    max-width: 750px;
    margin: auto;
    font-weight: normal;
  }
  h1 {
    padding: 20px 15px 5px 15px;
    font-size: 28px;
    color: ${themeGet('colors.battleshipGrey.1')};
  }
  h3 {
    padding: 5px 15px 25px 15px;
    font-size: 18px;
    color: ${themeGet('colors.blueGrey.8')};
  }
`

export const BlogSearchButton = styled((props) => (
  <El {...props}>
    <SearchButton as={Link} to='/' onClick={GA.clickBottomBlogSearchButton}>
      <span>SEARCH FOR CARS</span>
      <MdSearch className='icon' />
    </SearchButton>
  </El>
))`
  padding: 25px 15px;
  text-align: center;
  ${SearchButton} {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 300px;
    margin: auto;
    padding: 15px 5px;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1px;
    .icon {
      font-size: 26px;
      margin-left: 5px;
    }
  }
`

export const BlogList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  list-style-type: none;
  padding: 0;
  // no height adjustment on mobile
  @media (min-width: ${themeGet('breakpoints.0')}px) {
    align-items: stretch;
  }
`
export const BlogCard = styled(LinkEl)`
  display: flex;
  flex-direction: column;
  background-color: white;
  color: ${themeGet('colors.textBlack')};
  text-decoration: none;
  border-radius: 10px;
  overflow: hidden;
  margin: 15px 15px;
  /* google shadow */
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  transition: all 100ms;

  .blog-post-img-container {
    background-image: url('${({ article }) => article.imageUrl}');
    background-size: cover;
    background-position: center;
    height: 200px;
    overflow: hidden;
    img {
      max-width: 100%;
    }
  }
  .blog-post-title {
    display: flex;
    align-items: center;
    min-height: 70px;
    padding: 10px 15px 5px 15px;
    line-height: 1.5;
    font-size: 18px;
  }
  .blog-post-date {
    padding: 4px 15px;
    color: ${themeGet('colors.blueGrey.4')};
    text-transform: uppercase;
    font-size: 14px;
    transition: color 100ms;
  }
  .blog-post-description {
    color: ${themeGet('colors.blueGrey.7')};
    padding: 10px 15px 20px 15px;
    text-overflow: ellipsis;
    text-align: justify;
    line-height: 1.3;
    font-size: 14px;
    overflow: hidden;
    transition: color 100ms;
  }

  &:hover {
    box-shadow: 0 2px 2px 0 ${themeGet('colors.deepOrange.2')}, 0 3px 1px -2px ${themeGet('colors.deepOrange.2')},
      0 1px 5px 0 ${themeGet('colors.deepOrange.2')};
    .blog-post-date {
      color: ${themeGet('colors.blueGrey.6')};
    }
    .blog-post-description {
      color: ${themeGet('colors.textBlack')};
    }
  }
`

const ElTime = styled.time`
  &:focus {
    outline: none;
  }
  display: block;
`

function Blogs({ staticContext, ...rest }) {
  // eslint-disable-next-line no-unused-vars
  const [articles, _] = useState(Object.values(util.getArticleMap()))
  const lang = useCurrentLang()
  const serverData = staticContext?.data
  const { data: clientData = [], isLoading } = useArticles(lang)
  const sanityArticles = serverData ? serverData : clientData

  const canonical = 'https://rentcarla.com/blog'
  const title = 'Carla - Car Rental Blog'
  const description =
    'Find the best travel and car rental tips and tricks to have the best experience. Check out these blogs.'
  const keywords =
    'carla blog, car rental blog, car rental help, car rental tips, travel assistant, journey, rent a car, move free'
  const imageUrl = 'https://s3-eu-west-1.amazonaws.com/carla-blog-images/rental-tips-porsche.jpg'
  const mergedArticles = [...sanityArticles, ...articles]
  if (!mergedArticles && !isLoading) return null

  return (
    <El bg='newColorPrimary'>
      <BlogIntro />
      <Container {...rest}>
        <Helmet>
          <script type='application/ld+json'>{util.getArticleListStructure(articles)}</script>
          <title>{title}</title>
          <link rel='canonical' href='https://rentcarla.com/blog' />
          <link rel='amphtml' href='https://rentcarla.com/amp/blog' />
          <meta name='description' content={description} />
          <meta name='keywords' content={keywords} />
          <meta property='og:url' content={canonical} />
          <meta property='og:type' content='article' />
          <meta property='og:title' content={title} />
          <meta property='og:description' content={description} />
          <meta property='og:image' content={imageUrl} />
          <meta property='og:image:width' content={1999} />
          <meta property='og:image:height' content={1271} />
          <meta name='twitter:title' content={title} />
          <meta name='twitter:description' content={description} />
          <meta name='twitter:image' content={imageUrl} />
        </Helmet>
        <BlogList>
          {mergedArticles.map((article, index) =>
            !!article ? (
              <BlogCard
                as={Link}
                key={`${article.slug}-${index}`}
                width={[1, 0.42, 0.3]}
                article={article}
                to={article.canonicalLink}
              >
                <El className='blog-post-img-container' />
                <El className='blog-post-title'>{article.title}</El>
                <ElTime
                  dateTime={format(
                    !!article.datePublishedFormatted ? new Date(`${article.datePublishedFormatted}`) : Date.now(),
                    'yyyy-MM-dd'
                  )}
                  className='blog-post-date'
                >
                  {article.datePublishedFormatted}
                </ElTime>
                <El className='blog-post-description'>{article.description}</El>
              </BlogCard>
            ) : null
          )}
        </BlogList>
        <BlogSearchButton />
        <SocialShare url={canonical} />
        <DownloadAppSection />
      </Container>
    </El>
  )
}

Blogs.propTypes = {}

Blogs.load = async () => {
  try {
    return await getAllArticlesFromCache('en')
  } catch (error) {
    console.error(error)
  }
}

export default Blogs
