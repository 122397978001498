// full screen container
import styled from 'styled-components'
import { ButtonEl, El, FlexEl, OrangeButton } from './layout'
import { themeGet } from 'styled-system'
import { colors } from '../styles'
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import util from '../util'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import Loading from './Loading'

const PomModalContainer = styled(FlexEl)`
  position: fixed;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2000;
  width: 100%;
  background-color: rgb(0, 0, 0, 0.5);

  &.fade {
    background-color: rgb(0, 0, 0, 0);
    transition: all 200ms ease;
    // pomModal content
    > * {
      opacity: 0;
      transform: scale(0.5);
      transition: all 200ms ease;
    }
  }
  &.fade-enter {
    background-color: rgb(0, 0, 0, 0);
    > * {
      opacity: 0;
      transform: scale(0.5);
    }
  }
  &.fade-enter-active,
  &.fade-enter-done,
  &.fade-exit {
    @media (min-width: ${themeGet('breakpoints.1')}px) {
      background-color: rgb(0, 0, 0, 0.5);
    }
    > * {
      opacity: 1;
      transform: scale(1);
    }
  }
  &.fade-exit-active {
    background-color: rgb(0, 0, 0, 0);
    > * {
      opacity: 0;
      transform: scale(1.5);
    }
  }
`

// content (full screen)
const PomModalContent = styled(FlexEl)`
  position: absolute;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2000;
  width: 100%;
  min-height: 100vh;
  min-height: 100dvh;
  overflow: auto;
  overflow-x: hidden;
  border-radius: 12px;
  background-color: white;
  > * {
    max-width: 100%;
  }
  // desktop
  @media (min-width: ${themeGet('breakpoints.1')}px) {
    width: ${(props) => props.width || 500}px;
    max-width: ${(props) => props.width || 500}px;
    max-height: 85%;
    min-height: auto;
    margin-left: -${(props) => (props.width ? props.width / 2 : 250)}px;
    left: 50%;
    top: unset;
    bottom: unset;
    box-shadow: 0 0 30px rgb(0, 0, 0, 0.5);
  }
`

// close icon
export const CloseButton = styled(ButtonEl)`
  background-color: transparent;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2001;
  width: 50px;
  height: 60px;
`

const OKButton = styled(OrangeButton)`
  width: 100%;
  margin-left: auto;
  font-size: 16px;
  font-family: 'Quicksand', 'Quicksand Regular', sans-serif;
  font-weight: 600;
  padding: 15px;
  @media (min-width: ${themeGet('breakpoints.0')}px) {
    width: auto;
    padding: 15px 40px;
  }
`

const PomModalHeader = styled(FlexEl)`
  width: 100%;
  height: 40px;
  align-items: center;
  padding: 15px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  border-bottom: 1px solid ${colors.silver};
`

// standard pomModal
class PomModal extends React.Component {
  static propTypes = {
    title: PropTypes.string, // shows header component if true
    closable: PropTypes.bool, // shows cross icon if true
    ok: PropTypes.bool, // shows OK button if true
    isOpen: PropTypes.bool,
    price: PropTypes.string,
    onClose: PropTypes.func, // parent handler
    width: PropTypes.number // optional desktop width in pixel
  }

  static defaultProps = {
    isOpen: true,
    width: 500
  }

  state = {}

  closeClicked = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.props.onClose(e)
    document.body.classList.remove('overflow-hidden')
  }

  onSelect = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.props.onSelect()
    this.closeClicked(e)
  }

  outsideClicked = (e) => {
    if (!this.contentNode.contains(e.target) && this.props.onClose) {
      e.preventDefault()
      e.stopPropagation()
      this.props.onClose(e)
      document.body.classList.remove('overflow-hidden')
    }
  }

  componentDidMount() {
    if (this.props.isOpen && util.isBrowser()) {
      document.body.classList.add('overflow-hidden')
    }
  }

  componentDidUpdate() {
    if (this.props.isOpen && util.isBrowser()) {
      document.body.classList.add('overflow-hidden')
    }
  }

  componentWillUnmount() {
    if (util.isBrowser()) {
      document.body.classList.remove('overflow-hidden')
    }
  }

  render() {
    const { children, title, closable, isOpen, width, ok, t, ...props } = this.props
    if (!isOpen) {
      return <Fragment />
    }

    return (
      <PomModalContainer {...props} onClick={this.outsideClicked}>
        <PomModalContent ref={(ref) => (this.contentNode = ref)} width={width}>
          {closable && <CloseButton onClick={this.closeClicked} />}
          {title && <PomModalHeader>{title}</PomModalHeader>}
          {children}
          {ok && (
            <El width={1} p={15}>
              <OKButton onClick={this.onSelect}>
                {t('xo1:popupPeaceOfMind:getThisProtection')}: {this.props.price}{' '}
              </OKButton>
            </El>
          )}
        </PomModalContent>
      </PomModalContainer>
    )
  }
}

// pomModal with transition
export class FadePomModal extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    price: PropTypes.string
  }

  render() {
    const { isOpen, ...props } = this.props
    return (
      <TransitionGroup>
        {isOpen && (
          <CSSTransition timeout={200} classNames='fade'>
            <PomModal isOpen={true} price={this.price} className='fade' {...props} />
          </CSSTransition>
        )}
      </TransitionGroup>
    )
  }
}

// eslint-disable-next-line no-unused-vars
styled(({ text, ...props }) => (
  <FlexEl {...props}>
    <El mt={40} fontSize={28}>
      {text}
    </El>
    <Loading width={100} my={100} />
  </FlexEl>
))`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 25px;
  text-align: center;
`
const defaultTitle = 'Something went wrong!'
const defaultDescription = 'We are trying to fix it.'
styled(({ title = defaultTitle, description = defaultDescription, ...props }) => (
  <FlexEl {...props}>
    <El as='h1' className='title'>
      {title}
    </El>
    {props.children}
    <El className='description'>{description}</El>
  </FlexEl>
))`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 25px;
  text-align: center;
  .title {
    color: ${themeGet('colors.colorPrimary')};
    font-size: 30px;
  }
  .description {
    margin-top: 25px;
    margin-bottom: 30px;
    font-size: 24px;
  }
  img {
    max-width: 100%;
  }
`
// this pops up when an exception is not handled by
export default PomModal
