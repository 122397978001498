import React from 'react'
import { FormControlLabel, Checkbox, makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  root: {
    margin: 0,
    padding: 0,
    '&:hover': {
      background: 'none'
    }
  },
  label: {
    color: 'var(--textBlack)',
    marginLeft: 8,
    fontSize: (props) => props.fontSize || 14
  }
})

export function CheckboxUI({ onChange, checked, label, iconChecked, iconUnchecked, ...rest }) {
  const classes = useStyles()

  return (
    <FormControlLabel
      classes={{ root: classes.root, label: classes.label }}
      control={
        <Checkbox
          classes={{ root: classes.root }}
          disableRipple
          checkedIcon={<img src={iconChecked} alt='checked' width={24} height={24} lineheight='24px' />}
          icon={<img src={iconUnchecked} alt='unchecked' width={24} height={24} lineheight='24px' />}
        />
      }
      checked={checked}
      label={label}
      onChange={onChange}
      {...rest}
    />
  )
}
