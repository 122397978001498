import styled from 'styled-components'

export const ModalHeader = styled('div')`
  border-bottom: 0.5px solid #a7a9ad;
  background: #fff;
  padding: 12px 16px;
  display: flex;
  justify-content: space-around;
  justify-content: space-between;
  align-items: center;
  h1 {
    color: var(--Actions-Primary, #ff7d0e);
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    margin: 0;
  }
`

export const StyledCloseIcon = styled('div')`
  cursor: pointer;
`
