import React from 'react'
import { Divider, Box, Grid } from '@material-ui/core'

export function DividerUI({ children, m = '5%', ...rest }) {
  return (
    <Grid container alignItems='center' wrap='nowrap'>
      <Grid item xs={6}>
        <Divider {...rest} />
      </Grid>
      <Box mx={m}>{children}</Box>
      <Grid item xs={6}>
        <Divider {...rest} />
      </Grid>
    </Grid>
  )
}
