import React from 'react'
import { PortableText } from '@portabletext/react'

const components = {
  block: {
    'block-s3-image': ({ children }) => <img alt='value' src={children.text} />,
    'block-html': ({ children }) => {
      return <div style={{ maxWidth: '100%' }} dangerouslySetInnerHTML={{ __html: children[0] }} />
    }
  },
  types: {
    image: ({ value }) => <img alt='value' src={value.imageUrl} />,
    callToAction: ({ value, isInline }) =>
      isInline ? <a href={value.url}>{value.text}</a> : <div className='callToAction'>{value.text}</div>
  },
  marks: {
    link: ({ children, value }) => {
      return <a href={value.href}>{children}</a>
    }
  },
  list: ({ children }) => <ul className='blog-list'>{children}</ul>,
  listItem: {
    bullet: ({ children, value }) => {
      return <li className='blog-list-item'>{children}</li>
    }
  }
}

export default function BlogPortableText({ content, img, imageWidth, imageHeight, title }) {
  return (
    <>
      <div className='main-container main-container-articles'>
        <div className='image-full'>
          <img width={imageWidth} height={imageHeight} src={img} alt={title} />
        </div>
        <h1>{title}</h1>
        <PortableText value={content} components={components} />
      </div>
    </>
  )
}
