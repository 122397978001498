import styled from 'styled-components'
import { InputDS } from '../../CarlaSelect'
import { themeGet } from 'styled-system'

export const LocationIconStyled = styled('img')`
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-12px);
  width: 24px;
  z-index: 10;
`
export const PickUpLocationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: flex-end;
  border-radius: 12px;
  margin-top: 0px;
  background-color: ${(props) => (props.shadowEffect ? '#EFF1F8 !important' : 'white')};
  border-right: none;
  overflow: hidden;
  border-top-right-radius: ${(props) => `${props.$rightRadius}px` || 0};
  border-bottom-right-radius: ${(props) => `${props.$rightRadius}px` || 0};
  border-top-left-radius: ${(props) => `${props.$leftRadius}px` || 0};
  border-bottom-left-radius: ${(props) => `${props.$leftRadius}px` || 0};

  &::before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: '';
    bottom: 0;
    left: 0;
    border-radius: 12px;
    z-index: -5;
  }
`

export const NoLocationFoundImage = styled.img`
  display: block;
  height: 70px;
  width: auto;
  margin: 10px auto;
  margin-bottom: 30px;
`

export const NoLocationFoundText = styled.div`
  width: 80%;
  font-size: 20px;
  text-align: center;
  margin: 0px auto;
`

export const InputArea = styled(InputDS)`
  width: 100%;
  padding-left: 42px;
  border-radius: 12px 0px 0px 12px;
  font-size: 16px;
  border: none;
  background: #fff;
  font-weight: 500;
  height: auto;
  padding-top: 16px;
  padding-bottom: 16px;
  border: 2px solid transparent;
`

export const GroupTitle = styled('label')`
  margin-bottom: 5px;
  display: block;
  font-size: 14px;
  font-weight: 600;
`

export const PopularLocationsTitle = styled.div`
  font-family: 'Quicksand', 'Quicksand Regular', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: ${themeGet('colors.colorPrimary')};
  margin: 20px;
  margin-bottom: 10px;
`
