import React, { Component } from 'react'
import { func, string } from 'prop-types'
import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { El, FlexEl } from '../../layout'
import util from '../../../util'
import { CarlinPropTypes } from '../../../entities'
import isEqual from 'lodash/isEqual'
import RCSlider from './../../common/RCSlider'

const StyledSlider = styled(RCSlider)`
  & .rc-slider-handle {
    width: 24px;
    height: 24px;
    margin-top: -10px;
    margin-left: -12px;
    background-color: ${themeGet('colors.colorPrimary')};
    border-color: ${themeGet('colors.colorPrimary')};
    transition: border-color 100ms ease-in-out, box-shadow 100ms ease-in-out;
    &:hover {
      border-color: #e86f09;
    }
    &:focus {
      border-color: #e86f09;
    }
  }
  & .rc-slider-rail {
    height: 5px;
    background-color: #ffaf6a;
  }
  & .rc-slider-track {
    background-color: #e86f09;
  }
`

const Container = styled(FlexEl)`
  padding: 25px 25px 10px 25px;
  justify-content: space-between;
  flex-wrap: wrap;
  .label {
    font-size: 14px;
    justify-content: space-between;
  }
`

export default class TimeSlider extends Component {
  static propTypes = {
    value: CarlinPropTypes.TimeObject,
    onChange: func,
    label: string
  }

  static defaultProps = {
    value: { hour: 12, min: 0 },
    onChange: console.log,
    label: 'Time'
  }

  state = {
    sliderValue: null,
    value: { hour: 12, min: 0 }
  }

  mapPropsToState = () => {
    const { hour, min } = this.props.value
    this.setState({
      sliderValue: hour * 60 + min,
      value: { hour, min }
    })
  }

  componentDidMount() {
    this.mapPropsToState()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!isEqual(this.props.value, prevProps.value)) {
      this.mapPropsToState()
    }
  }

  handleSliderChange = (sliderValue) => {
    const hour = Math.floor(sliderValue / 60)
    const min = sliderValue % 60
    this.setState({ sliderValue, value: { hour, min } })
  }

  handleSliderChangeDone = () => {
    const { hour, min } = this.state.value
    this.props.onChange({ hour, min })
  }

  render() {
    const { label, ...props } = this.props
    const { value, sliderValue } = this.state
    
    return (
      <Container flexWrap='wrap' {...props}>
        <FlexEl width={0.4} className='label'>
          <El>{label}:</El>
          <El as='b' pr={20}>
            {util.formatTimeObjUS(value)}
          </El>
        </FlexEl>
        <El width={0.6}>
          <StyledSlider
            min={0} // start 00:00
            max={24 * 60 - 30} // end 23:30
            step={30}
            value={sliderValue}
            onChange={this.handleSliderChange}
            onAfterChange={this.handleSliderChangeDone}
          />
        </El>
      </Container>
    )
  }
}
