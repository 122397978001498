import React, { Component } from 'react'
import util from '../util'
import { El, GreenButton } from '../components/layout'
import styled from 'styled-components'
import { themeGet } from 'styled-system'

const ReviewHero = styled(El)`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 250px;
  padding: 15px;
  background: url('https://gallery.mailchimp.com/e94734ffc131a848c0e2e0b6b/images/977f1eb1-df24-4187-ab83-e0e6480d0521.jpg')
    no-repeat center;
  background-size: cover;
  @media (min-width: ${themeGet('breakpoints.0')}px) {
    height: 320px;
  }
  h1 {
    width: 100%;
    max-width: 600px;
  }
`

const ReviewInstructions = styled(El)`
  padding: 25px 15px;
  box-shadow: 0px -2px 6px rgba(0,0,0,.1);
  color: ${themeGet('colors.blueGrey.8')};
  ul {
    margin: 0;
    list-style-type: none;
    li {
      text-align: center;s
    }
  }
  p {
    font-size: 18px;
    text-align: center;
  }
  #review-button {
    width: 100%;
    margin: 20px auto;
    text-align: center;
    padding: 15px 0;
    font-size: 20px;
    @media(min-width: ${themeGet('breakpoints.0')}px) {
      max-width: 250px;
    }
  }
`
class ReviewUs extends Component {
  state = {
    os: null,
    target: null
  }

  componentDidMount() {
    const os = util.getOS()

    let target = 'https://itunes.apple.com/us/app/carla-rent-a-car-comparing-car-rental-companies/id1035631234?mt=8'
    if (os === 'ios') target = 'https://bit.ly/2ljrmjo_ios'
    if (os === 'osx') target = 'https://bit.ly/2mpakFF_mac'
    if (os === 'android') target = 'https://bit.ly/2moUn20_android'

    const noDevice = ['ios', 'android', 'osx'].indexOf(os) === -1
    if (window && noDevice) {
      window.location.href = 'https://bit.ly/2l7GZ28_noDevice'
      return
    }

    this.setState({
      os,
      target
    })
  }

  render() {
    const { os, target } = this.state

    return (
      <El {...this.props}>
        <ReviewHero>
          <h1>
            Would you
            <br />
            review us?
          </h1>
        </ReviewHero>
        <ReviewInstructions>
          <p>We are so happy to hear that you were pleased by your experience with Carla!</p>
          <p>We would be super thrilled if you helped us spread the word.</p>
          {os === 'osx' && (
            <ul>
              <li>Go to our App Store page by selecting "Open iTunes"</li>
              <li>
                Select <em>Ratings and Reviews</em>
              </li>
              <li>
                Hit <em>Write a Review</em>
              </li>
              <li>
                Keep in mind - <strong>Sharing is Caring!</strong>
              </li>
            </ul>
          )}
          {os === 'ios' && (
            <ul>
              <li>Go to our App Store page</li>
              <li>
                Hit <em>Write a Review</em>
              </li>
              <li>
                Keep in mind - <strong>Sharing is Caring!</strong>
              </li>
            </ul>
          )}
          {os === 'android' && (
            <ul>
              <li>Go to our Play Store page</li>
              <li>Scroll down and tap the star rating</li>
              <li>
                Keep in mind - <strong>Sharing is Caring!</strong>
              </li>
            </ul>
          )}
          <GreenButton as='a' id='review-button' href={target} target='_blank'>
            Review Now
          </GreenButton>
        </ReviewInstructions>
      </El>
    )
  }
}

export default ReviewUs
