import styled from 'styled-components'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { themeGet } from 'styled-system'

export const InstructionsButton = styled('button')`
  color: #0076ff;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.28px;
  text-decoration-line: underline;
  cursor: pointer;
  border: none;
  background: transparent;
  padding: 4px 2px;
  margin: 8px 0;
  text-align: left;
`

export const DialogTitleStyled = styled(DialogTitle)`
  &.MuiDialogTitle-root {
    padding: 20px 24px 16px;
    position: relative;
  }
  .MuiTypography-h6 {
    color: #ff7d0e;
    font-size: 16px;
  }
`

export const DialogContentStyled = styled(DialogContent)`
  &.MuiDialogContent-root {
    color: var(--Text-Primary, #525266);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 8px 24px;
    border-radius: 12px;
    background: #fff;
    h4 {
      color: #696984;
      font-size: 15px;
      font-weight: 600;
      margin: 0 0 12px 0;
    }
    p {
      margin: 0 0 12px;
      @media (min-width: ${themeGet('breakpoints.1')}px) {
        margin: 12px 0;
      }
    }
    section {
      margin-bottom: 30px;
    }
  }
`

export const CloseButton = styled('button')`
  color: #b4b4c2;
  position: absolute;
  top: 10px;
  right: 6px;
  cursor: pointer;
  background: none;
  border: none;
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: ${themeGet('breakpoints.1')}px) {
    top: 20px;
    right: 16px;
  }
  @media (min-width: ${themeGet('breakpoints.0')}px) {
    top: 20px;
    right: 16px;
  }
`
