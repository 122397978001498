import React, { Component } from 'react'
import styled from 'styled-components'
import debug from 'debug'
import { Redirect, Link as UILink } from 'react-router-dom'
import { connect } from 'react-redux'
import { Formik } from 'formik'
import { toast } from 'react-toastify'
import { Container, El, FlexEl, PageContainer, SecondaryButton } from '../../components/layout'
import API from '../../services/API'
import GA, { facebookLoginSuccess } from '../../services/GA'
import DriverForm from '../../components/XO1/DriverForm'
import DriverFormNonSS from '../../components/XO1/DriverFormNonSS'
import { DefaultErrorModal, LoadingModal } from '../../components/Modal'
import {
  setExtrasSelected,
  updateCarRentalProduct,
  setXO1Details,
  setUserDetails,
  setThreeDs,
  setAncillaryProducts,
  setSelectedAncillaries,
  setVerified,
  setCredit
} from '../../redux/slices'
import EventSender from '../../services/EventSender'
import { BookingInfoCard } from '../../components/XO1/BookingInfoCard'
import { BookingInfoCardNonSS } from '../../components/XO1/BookingInfoCardNonSS'
import { CarDetailsCard } from '../../components/XO1/CarDetailsCard'
import { CarDetailsCardNonSS } from '../../components/XO1/CarDetailsCardNonSS'
import util from '../../util'
import isEqual from 'lodash/isEqual'
import pick from 'lodash/pick'
import cloneDeep from 'lodash/cloneDeep'
import { BookingInformation } from '../../components/XO1/BookingInformation'
import { driverValidationSchema } from './../../util/formValidation'
import Cookies from 'js-cookie'
import { IoIosArrowBack as BackIcon } from 'react-icons/io'
import IconWorld from '../../util/images/icon-world.svg'
import { withTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'
import QR from '../../util/images/image-xo1-qr.png'
// import ContinueButtonGroup from './ContinueButtonGroup'
import useCheckCountry from '../../hooks/useCheckCountry'
// import ProtectionPlan from '../../components/ProtectionPlan/ProtectionPlan'
// import { DesktopScreen } from '../../components/common/Screens'
import { growthbook } from '../../growthbook'
import { UTM_SOURCE_SKYSCANNER, GROWTHBOOK_SKYSCANNER_KEY_SUFFIX } from '../../components/XO1/constants'
import ProgressBar from '../../experiments/ProgressBar'
import { reset as resetModification } from '../../redux/slices/modificationSlice'

const log = debug('carla:order')

export const ModalType = {
  TC: 'TERMS_AND_CONDITIONS',
  IMPORTANT_INFO: 'IMPORTANT_INFORMATION',
  INSURANCE_INFO: 'INSURANCE_INFORMATION',
  NONE: '',
  PEACE_OF_MIND: 'PEACE_OF_MIND'
}

const INSURANCE_TYPE = 'Insurance'
const DEFAULT_SUGGEST_INSURANCE = 'Extended Protection'

export const shouldShowTripleInsurance = (ancillaryProducts) =>
  ancillaryProducts && ancillaryProducts.filter((ap) => ap.type === INSURANCE_TYPE).length === 3

const BookingContainer = styled.div`
  background-color: white;
  border-radius: 8px;

  & > form {
    border: none;
    padding: 0 0 16px;
  }

  & > div:nth-child(2) {
    padding: 16px 12px;

    & > h3 {
      margin: 0;
      color: #ff7d0e;
      font-size: 16px;
      line-height: 20px;
      font-weight: 600;
    }

    & > p {
      margin: 0;
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
      color: #525266;
    }
  }

  & .inclusions-container {
    padding: 16px 12px;

    & .loader {
      padding: 0;
    }
  }
`

const XO1Container = styled.main`
  @media screen and (min-width: 768px) {
    max-width: 1440px;
    flex-direction: row;
    display: flex;
    align-items: start;
    justify-content: center;
    gap: 24px;
    margin: auto;

    & .inclusions-container {
      padding: 16px 24px;
    }

    & > form {
      position: sticky;
      max-width: max(35%, 275px);
      left: 55%;
      top: 0px;
      order: 2;

      @media screen and (min-width: 768px) {
        padding: 24px !important;
      }
    }

    & > div {
      order: 1;
      flex-grow: 1;
      max-width: 45%;
    }
  }

  & .MuiOutlinedInput-root {
    background-color: #fbfbfd;
  }

  padding: 20px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: max(425px, 80%);
  margin: auto;

  & > div {
    border: 1px solid #dfdfe7;
    border-radius: 8px;
  }

  & > form {
    border-radius: 8px;
  }

  & > form:nth-child(1) {
    padding: 16px 12px;

    & div:last-child > button {
      margin-top: 12px;
    }

    & label {
      background-color: white;
    }

    & .info-button {
      width: 24px;
      height: 24px;
      background-color: transparent;
      border: none;
      outline: none;
      position: absolute;
      left: calc(100% - 24px - 8px);
      top: calc(50% - 32px);
      cursor: pointer;
    }

    & .tooltip-image {
      width: 275px;
      height: auto;
      position: absolute;
      z-index: 1;
      left: calc(100% - 275px);
      top: calc(-50% - 36px);
    }

    & label[data-shrink='false'] {
      background-color: #fbfbfd;
      font-size: 16px;
      line-height: normal;
      font-weight: 500;
      transform: translate(12px, 14px) scale(1);
    }

    & legend {
      & > span {
        display: none;
      }
    }

    & p {
      position: absolute;
      font-size: 12px;
      font-weight: 500;
      line-height: normal;
      color: #ff7d0e;
      bottom: -16px;
      margin-top: 0;
      margin-bottom: 0;
    }

    & input {
      border-radius: 8px;
      height: 20px;
      padding: 12px !important;
    }

    & > div:nth-child(1) {
      margin: 0;

      & * {
        padding: 0;
        margin-top: 0;
      }

      & > h2 {
        font-weight: 600;
        margin-bottom: 20px;
      }

      & > .form-fields {
        max-width: 100%;

        & > div:nth-child(-n + 2) {
          margin: 0 0 32px;
        }

        & div:nth-child(3) {
          & div {
            margin: 0;
          }

          & > div:nth-child(n) {
            margin-bottom: 32px;
          }
        }

        & > div:last-of-type {
          margin: 20px 0;
        }

        & > button {
          margin: 12px 0;
        }

        & button {
          height: 44px;
        }
      }
    }
  }

  & .terms-and-conditions {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #525266;
  }
`

const SectionContainer = styled.div`
  @media screen and (min-width: 768px) {
    padding: 16px 24px !important;
  }
  border-bottom: 1px solid ${(props) => props.theme.colors.separatorGrey};
`

const XO1AppRedirect = styled((props) => {
  const checkCountry = useCheckCountry()
  const { onMouseEnter, onMouseLeave, children, className } = props
  return !checkCountry ? null : (
    <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} className={className}>
      {children}
    </div>
  )
})`
  @media screen and (min-width: 576px) and (max-width: 768px) {
    margin-top: 12px;
  }
  background: white;
  border-radius: 12px;
  border: 1px solid #dfdfe7;
  display: flex;
  align-items: center;
  padding: 17px 24px;
  gap: 16px;
  margin-bottom: 15px;
  position: relative;

  & > p {
    margin: 0;
    color: #000;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;

    & > a,
    span {
      color: #0076ff;
      font-size: 16px;
      font-weight: 700;
      line-height: normal;
      text-decoration-line: underline;
      display: inline;
    }
  }
`

const QRCodeContainer = styled.div`
  height: 96px;
  width: 96px;
  border-radius: 12px;
  border: 12px solid white;
  outline: 12px solid #ff7d0e;
  position: absolute;
  background: url(${(props) => props.qrCode});
  background-size: contain;
  top: 110%;
  left: 20%;
  display: ${(props) => (props.isMobile || !props.showQR ? 'none' : 'block')};

  & ::after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    top: -41px;
    left: calc(50% - 12px);
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 17px solid #ff7d0e;
  }
`

class RawXO1 extends Component {
  state = {
    isModificationsLoading: false, // if making pre-checkout calls for priceline
    isReservationInitiated: false, // If user clicked Next Button and the reservation request is initiated
    error: false, // An error raised while initiating reservation
    selectedAncillaries: new Set(),
    modal: ModalType.NONE,
    form: React.createRef(),
    modalProtection: false,
    modalProtectionSkippable: false,
    showQR: false,
    validateInsuranceList: false,
    hasError: false
  }

  // For Growthbook implementation
  getPreselectedAncillaries = (ancillaryProducts) => {
    try {
      const isSkyScanner = growthbook.getAttributes()['utmSource'] === UTM_SOURCE_SKYSCANNER
      const suffix = isSkyScanner ? GROWTHBOOK_SKYSCANNER_KEY_SUFFIX : ''
      const filterFn = (item, index) =>
        growthbook.isOn(item.type.concat(suffix)) && (index === 0 || item.type !== 'Insurance')
      return ancillaryProducts.filter(filterFn)
    } catch {
      return ancillaryProducts.filter((item) => item.preSelected)
    }
  }

  requestAncillaryProducts = async () => {
    if (!this.props.carRentalProduct) return
    const { carRentalProduct } = this.props

    try {
      const ancillaryProductResponse = await API.getAncillaryProducts(
        carRentalProduct,
        this.props.i18n.resolvedLanguage
      )
      if (ancillaryProductResponse && ancillaryProductResponse.data) {
        this.props.setAncillaryProducts({
          loading: false,
          error: false,
          data: ancillaryProductResponse.data
        })
        // initial preselected fill
        const preSelectedAncillaries = this.getPreselectedAncillaries(ancillaryProductResponse.data)
        this.props.setSelectedAncillaries(preSelectedAncillaries.map((item) => item.id))
      } else {
        this.props.setAncillaryProducts({
          data: null,
          loading: false,
          error: 'not found'
        })
      }
    } catch (err) {
      console.error('error fetching ancillaryProducts: ', err)
      this.props.setAncillaryProducts({
        data: null,
        loading: false,
        error: err
      })
    }
  }

  // Make modification if which needsModification
  makeModifications = async () => {
    if (!this.props.carRentalProduct) return

    const { carRentalProduct } = this.props
    if (!carRentalProduct.extras.needsModification) return

    this.setState({
      isModificationsLoading: true
    })

    try {
      const newCarRentalProduct = await API.makeModifications(carRentalProduct)
      // this overrides requestId, importantInformationList vs fields of car result while holding attached dateRange field
      newCarRentalProduct.searchArgs = carRentalProduct.searchArgs //@todo fix
      this.props.updateCarRentalProduct(newCarRentalProduct)
      this.setState({
        isModificationsLoading: false
      })
    } catch (err) {
      console.error('error making pre-checkout modifications', err)
      this.showToastError('The car you selected is not available at this time')
      this.props.history.go(-1)
    }
  }

  showToastError = (err) => {
    if (!err) return
    if (toast.isActive(this.errorToastId)) {
      toast.update(this.errorToastId, { render: err })
    } else {
      this.errorToastId = toast.error(err)
    }
  }

  // if making any XHR request at the moment
  isLoading = () => {
    const { insuranceQuoteLoading, isModificationsLoading } = this.state
    return insuranceQuoteLoading || isModificationsLoading
  }

  openModal = (modalType) => this.setState({ modal: modalType })
  closeModal = () => this.setState({ modal: ModalType.NONE })

  createXO1Details = async (userDetails, ancillaries) => {
    const { carRentalProduct, setCredit, setVerified } = this.props
    // NOTE: keep old implement
    const cloneCarRentalProduct = cloneDeep(carRentalProduct)

    // because backend acts differently when it sees these in addedDetails endpoint
    cloneCarRentalProduct.extras.insuranceSelection = null
    cloneCarRentalProduct.extras.tripProtectionQuote = null
    cloneCarRentalProduct.extras.peaceOfMindQuote = null

    const userInfo = pick(userDetails, ['firstName', 'lastName', 'email', 'phoneNumber'])
    const xo1Details = {
      product: cloneCarRentalProduct,
      userInfo: userInfo,
      flightNo: userDetails?.flightNumber,
      ...ancillaries
    }
    log('xo1Details', xo1Details)

    API.addDetails(xo1Details).then((res) => {
      setCredit(res.userCredit)
      setVerified(res.userVerified)
    })

    return xo1Details
  }

  isInsuranceSelected = () => {
    const { ancillaryProducts } = this.props
    if (!ancillaryProducts) return facebookLoginSuccess
    let insuranceProductIds = ancillaryProducts.filter((ap) => ap.type === 'Insurance').map((ap) => ap.id)
    if (insuranceProductIds.length === 0) return false
    for (const insuranceId of insuranceProductIds) {
      if (this.props.selectedAncillaries.has(insuranceId)) return true
    }
    return false
  }

  isErrorFree = (errObj) => {
    let result = true
    for (const item in errObj) {
      if (errObj[item] !== '') result = false
    }
    return result
  }

  // driver form submit
  handleSubmit = async (values) => {
    log('starting reservation', values)

    if (Cookies.get('utm_source') === 'skyscanner' && !this.state.validateInsuranceList) {
      this.setState({ hasError: true })
      return
    }
    // This is to show the protection modal for nonskycanner branch
    if (
      !this.isInsuranceSelected() &&
      !this.state.modalProtectionSkippable &&
      this.props.ancillaryProducts?.filter((ap) => ap.type === INSURANCE_TYPE).length > 0 &&
      Cookies.get('utm_source') !== 'skyscanner'
    ) {
      this.setState({ modalProtection: true, modalProtectionSkippable: true })
      return
    }

    if (this.isInsuranceSelected()) {
      GA.makeInsurance()
    }

    GA.startReservation()

    this.props.setUserDetails(values)
    this.props.setExtrasSelected(Array.from(this.props.selectedAncillaries))

    try {
      this.setState({
        isReservationInitiated: true
      })

      const xo1Details = await this.createXO1Details(values, {
        ancillaryProducts: this.props.ancillaryProducts,
        selectedAncillaries: Array.from(this.props.selectedAncillaries)
      })
      this.props.setXO1Details(xo1Details)
      this.setState({
        isReservationInitiated: false
      })
      EventSender.reservationStarted(xo1Details)
      this.props.history.push('/payment')
    } catch (err) {
      console.error('error while starting reservation:', err)
      this.setState({
        isReservationInitiated: false,
        err
      })
    }
  }

  componentDidMount() {
    ;(function (u, p, l, i, f, t, o, b, j) {
      u['UpLiftPlatformObject'] = f
      /* eslint-disable-next-line */
      ;(u[f] =
        u[f] ||
        function () {
          ;(u[f].q = u[f].q || []).push(arguments)
          /* eslint-disable-next-line */
        }),
        (u[f].l = 1 * new Date())
      /* eslint-disable-next-line */
      ;(b = p.createElement(l)), (j = p.getElementsByTagName(l)[0])
      b.async = 1
      b.src = i + '?id=' + t
      j.parentNode.insertBefore(b, j)
      o = u.location.host.match(/[\w-]+\.\w{2,3}(:\d+)?$/)
      if (o) o = o[0]
      u[f]('create', t, o)
    })(window, document, 'script', 'https://cdn.uplift-platform.com/a/up.js', 'up', util.getUPCode())
    log('carRentalProduct: ', this.props.carRentalProduct)
    log('userDetails: ', this.props.userDetails)
    this.props.resetModification() // to prevent collision
    if (!!!this.props.ancillaryProducts) this.insuranceAndModifications()
    if (this.isInsuranceSelected()) this.setState({ validateInsuranceList: true })
    // 3DS-related code blocks are left commented out on purpose - in case the feature needs to be turned on again down the road.
  }

  insuranceAndModifications = () => {
    this.requestAncillaryProducts().then(log('ancillaryProducts: ', this.props.ancillaryProducts))
    if (this.props.carRentalProduct?.extras.needsModification) {
      this.makeModifications().then(log('modifiedCar: ', this.props.carRentalProduct))
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (isEqual(prevProps.carRentalProduct, this.props.carRentalProduct)) {
      return
    }
  }

  updatedSelections = (formValues) => {
    let selections = this.props.selectedAncillaries
    let hasChange = false
    for (const key in formValues) {
      const selected = formValues[key]
      if (selections.has(key) && selected) continue
      if (!selections.has(key) && !selected) continue

      if (selected) {
        selections.add(key)
      } else {
        selections.delete(key)
      }
      hasChange = true
    }
    if (!hasChange) return
    this.props.setSelectedAncillaries(Array.from(selections))
  }

  selectedInsurance = (id) => {
    const { ancillaryProducts, selectedAncillaries } = this.props
    for (const ap of ancillaryProducts) {
      if (ap.type === INSURANCE_TYPE) {
        selectedAncillaries.delete(ap.id)
      }
    }
    selectedAncillaries.add(id)
    this.props.setSelectedAncillaries(Array.from(selectedAncillaries))
  }

  deselectedInsurance = (id) => {
    this.props.selectedAncillaries.delete(id)
    this.props.setSelectedAncillaries(Array.from(this.props.selectedAncillaries))
  }

  handleSubmitWithinPaymentProtected = (submitFunc, id) => {
    if (this.props.ancillaryProductsLoading) return
    if (!id) {
      const { ancillaryProducts } = this.props
      if (ancillaryProducts && ancillaryProducts.length >= 2) {
        const defaultSuggestAncillary = ancillaryProducts.find((value) => value?.title === DEFAULT_SUGGEST_INSURANCE)
        this.selectedInsurance(defaultSuggestAncillary ? defaultSuggestAncillary.id : ancillaryProducts[1]?.id)
      }
    } else {
      // TODO: need to refactor
      this.selectedInsurance(id)
    }
    submitFunc()
  }

  handleSubmitWithoutPaymentProtected = (submitFunc) => {
    if (this.props.ancillaryProductsLoading) return
    const { ancillaryProducts } = this.props
    for (const ap of ancillaryProducts) {
      if (ap.type === INSURANCE_TYPE) {
        this.props.setSelectedAncillaries(Array.from(this.props.selectedAncillaries))
        this.props.selectedAncillaries.delete(ap.id)
      }
    }
    submitFunc()
  }

  render() {
    const { modal, isReservationInitiated, error } = this.state
    const { carRentalProduct, userDetails, redirectURL, ancillaryProducts, selectedAncillaries } = this.props
    const { pickupDateStr, dropOffDateStr } = carRentalProduct.searchArgs
    const selectedAncillariesItem =
      ancillaryProducts?.filter((item) => selectedAncillaries.has(item.id) && item.type === INSURANCE_TYPE)[0] || null
    let utmSource = Cookies.get('utm_source')
    const isSkyScanner = utmSource === 'skyscanner'
    const { t } = this.props
    const browserLng = typeof navigator !== undefined ? navigator.language : 'en' // TODO: Not needed after all languages are translated

    if (!carRentalProduct) {
      return <Redirect to='/' />
    }

    let initialFormValues = {}
    const tripleInsurance = shouldShowTripleInsurance(ancillaryProducts)
    if (ancillaryProducts) {
      for (const ancillary of ancillaryProducts) {
        if (tripleInsurance && ancillary.type === INSURANCE_TYPE) continue // ???
        initialFormValues[ancillary.id] = false
        if (selectedAncillaries && selectedAncillaries.size) {
          Array.from(selectedAncillaries).forEach((item) => {
            initialFormValues[item] = true
          })
        }
      }
    }

    return !isSkyScanner ? (
      <PageContainer bg='newColorPrimary' style={{ overflowX: 'visible' }} ref={this.state.form}>
        <LoadingModal isOpen={isReservationInitiated} onClose={() => {}} text='Initiating reservation' />
        <DefaultErrorModal
          isOpen={Boolean(error)}
          closable={true}
          ok={true}
          onClose={() => this.setState({ error: null })}
        />
        <XO1Container className='XO1-container'>
          <Formik
            render={(props) => (
              <>
                <DriverFormNonSS {...props} ancillariesAreLoading={ancillaryProducts === null} />
              </>
            )}
            initialValues={{ ...userDetails }}
            enableReinitialize={true}
            validationSchema={driverValidationSchema}
            validateOnBlur={true}
            validateOnChange={true}
            onSubmit={this.handleSubmit}
          />
          <BookingContainer>
            <BookingInfoCardNonSS
              carRentalProduct={carRentalProduct}
              whichPage={'XO1'}
              isShowOnMobile={true}
              isSkyScanner={false}
            />
            <BookingInfoCardNonSS
              carRentalProduct={carRentalProduct}
              width={[1, 1, 1]}
              whichPage={'XO1'}
              isShowOnMobile={false}
              isSkyScanner={false}
            />
            <SectionContainer>
              <h3>{t('xo1:price')}</h3>
              <p>
                {util.formatMoneyFloored(
                  carRentalProduct.price.actualTotal / util.howManyDays(pickupDateStr, dropOffDateStr),
                  carRentalProduct.price.currency
                )}
                /{t('carListing:day')}
              </p>
            </SectionContainer>
            <Formik
              render={(props) => (
                <CarDetailsCardNonSS
                  {...props}
                  fromXO1WithoutSkyScanner={true}
                  width={1}
                  carRentalProduct={carRentalProduct}
                  ancillaryProducts={ancillaryProducts}
                  modal={modal}
                  openModal={this.openModal}
                  closeModal={this.closeModal}
                  isLoading={this.isLoading()}
                  selectedAncillaries={selectedAncillaries}
                  updatedSelections={this.updatedSelections}
                  selectedInsurance={this.selectedInsurance}
                  deselectedInsurance={this.deselectedInsurance}
                  mb={15}
                  modalProtection={this.state.modalProtection}
                />
              )}
              initialValues={initialFormValues}
              enableReinitialize={true}
              validationSchema={driverValidationSchema}
              onSubmit={this.handleSubmit}
            />
          </BookingContainer>
        </XO1Container>
      </PageContainer>
    ) : (
      <PageContainer bg='newColorPrimary'>
        <LoadingModal isOpen={isReservationInitiated} onClose={() => {}} text='Initiating reservation' />
        <DefaultErrorModal
          isOpen={Boolean(error)}
          closable={true}
          ok={true}
          onClose={() => this.setState({ error: null })}
        />
        <ProgressBar activeStep={1} />
        <Container as={FlexEl} flexWrap='wrap'>
          <El width={[1, 0.5, 0.6]} p={15}>
            {isSkyScanner ? (
              redirectURL ? (
                <SecondaryButton as={UILink} to={redirectURL}>
                  <BackIcon /> {t('xo1:deals')}
                </SecondaryButton>
              ) : (
                <SecondaryButton onClick={() => this.props.history.go(-1)}>
                  <BackIcon /> {t('xo1:deals')}
                </SecondaryButton>
              )
            ) : null}
            <XO1AppRedirect
              onMouseEnter={() => this.setState({ showQR: true })}
              onMouseLeave={() => this.setState({ showQR: false })}
            >
              <QRCodeContainer qrCode={QR} isMobile={isMobile} showQR={this.state.showQR} />
              <img src={IconWorld} alt='icon-world' />
              <p>
                {isMobile ? (
                  <a href='https://app.adjust.com/1d8qh5rq' target='_blank' rel='noopener noreferrer'>
                    {t('xo1Redirect:link', { lng: browserLng || 'en' })}
                  </a>
                ) : (
                  <span>{t('xo1Redirect:link', { lng: browserLng || 'en' })}</span>
                )}
                {t('xo1Redirect:text', { lng: browserLng })}
              </p>
            </XO1AppRedirect>
            <Formik
              render={(props) => (
                <BookingInformation
                  carRentalProduct={carRentalProduct}
                  ancillaryProducts={ancillaryProducts}
                  isBundle={false}
                  {...props}
                />
              )}
            />
            <Formik
              render={(props) => (
                <>
                  <DriverForm
                    {...props}
                    ancillariesAreLoading={ancillaryProducts === null}
                    isSkyScanner={isSkyScanner}
                  />
                  {/* <DesktopScreen>
                    <ProtectionPlan
                      isLoading={this.props.ancillaryProductsLoading}
                      planInfo={ancillaryProducts?.filter((ap) => ap.type === INSURANCE_TYPE)?.[0]}
                    />
                  </DesktopScreen> */}
                  {/* {isSkyScanner && (
                    <ContinueButtonGroup
                      selectedAncillariesItem={ancillaryProducts?.filter((ap) => ap.type === INSURANCE_TYPE)?.[0]}
                      submitForm={props.submitForm}
                      handleSubmitWithoutPaymentProtected={this.handleSubmitWithoutPaymentProtected}
                      handleSubmitWithinPaymentProtected={this.handleSubmitWithinPaymentProtected}
                    />
                  )} */}
                </>
              )}
              initialValues={{ ...userDetails }}
              enableReinitialize={true}
              validationSchema={driverValidationSchema}
              validateOnBlur={true}
              validateOnChange={true}
              onSubmit={this.handleSubmit}
            />
          </El>
          <El width={[1, 0.5, 0.4]} p={15} mt={[-15, 67]}>
            <Formik
              render={(props) => (
                <CarDetailsCard
                  {...props}
                  width={1}
                  carRentalProduct={carRentalProduct}
                  ancillaryProducts={ancillaryProducts}
                  modal={modal}
                  openModal={this.openModal}
                  closeModal={this.closeModal}
                  isLoading={this.isLoading()}
                  selectedAncillaries={selectedAncillaries}
                  updatedSelections={this.updatedSelections}
                  selectedInsurance={this.selectedInsurance}
                  deselectedInsurance={this.deselectedInsurance}
                  mb={15}
                  validateInsuranceList={() => this.setState({ validateInsuranceList: true, hasError: false })}
                  hasError={this.state.hasError}
                />
              )}
              initialValues={initialFormValues}
              enableReinitialize={true}
              validationSchema={driverValidationSchema}
              onSubmit={this.handleSubmit}
            />
            <BookingInfoCard width={1} carRentalProduct={carRentalProduct} whichPage={'XO1'} />
          </El>
        </Container>
      </PageContainer>
    )
  }
}

const XO1 = connect(
  (state) => ({
    carRentalProduct: state.carRentalProduct,
    userDetails: state.userDetails,
    selectedAncillaries: new Set(state.ancillary.selectedAncillaries),
    redirectURL: state.skyscanner.redirectURL,
    ancillaryProducts: state.ancillary.ancillaryProducts.data,
    ancillaryProductsLoading: state.ancillary.ancillaryProducts.loading,
    user: state.user
  }),
  {
    updateCarRentalProduct,
    setUserDetails,
    setXO1Details,
    setExtrasSelected,
    setThreeDs,
    setAncillaryProducts,
    setSelectedAncillaries,
    resetModification,
    setCredit,
    setVerified
  }
)(RawXO1)

export default withTranslation()(XO1)
