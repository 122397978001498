import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import Downshift from 'downshift'
import { InputDS, LabelDS, MenuDS, SelectContainerDS } from '../../CarlaSelect'
import { El, FlexEl, SearchButton } from '../../layout'
import util from '../../../util'
import TimeSlider from '../../SearchForm/DesktopForm/TimeSlider'
import DateRangePicker from '../../DatePicker/DateRangePicker'
import { setSearchDropOffTime, setSearchPickupTime } from '../../../redux/slices'
import { selectSearchArgs } from '../../../redux/selectors'
import CalendarIcon from '../../../util/images/icon-calendar.svg'

const DateInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  background-color: white;
  background-color: ${(props) => (props.isDateInputSelected ? '#EFF1F8' : 'white')};
  position: relative;
  border: ${(props) => (props.isPageDynamic ? '1px solid #dfdfe7' : 'none')};
  cursor: pointer;

  &::before {
    position: absolute;
    width: 100%;
    height: 50px;
    content: '';
    bottom: 0;
    left: 0;
    border-radius: 0;
    z-index: -5;

    // shadow under date box desktop
    -webkit-box-shadow: ${(props) => !props.isCarSearchPage && '-2px 4px 4px 0px rgba(0,0,0,0.25)'};
    -moz-box-shadow: ${(props) => !props.isCarSearchPage && '-2px 4px 4px 0px rgba(0,0,0,0.25)'};
    box-shadow: ${(props) => !props.isCarSearchPage && '-4px 5px 4px 0px rgba(0,0,0,0.25)'};
  }
`

const InputArea = styled(InputDS)`
  cursor: pointer;
  border-radius: 0 !important;
  border: none !important;
  width: 100% !important;
  padding-left: 44px;
  background-color: ${(props) => (props.isDateInputSelected ? '#EFF1F8' : '')};
  font-size: ${(props) => (!props.isCarSearchPage ? 16 : 13)}px;
  //date input desktop inside shadow (from right to left),
  -webkit-box-shadow: ${(props) =>
    !props.isDateInputSelected && !props.isCarSearchPage && !props.isPageDynamic
      ? 'inset -4px 0px 4px 0px rgba(0,0,0,0.25)'
      : 'none'};
  -moz-box-shadow: ${(props) =>
    !props.isDateInputSelected && !props.isCarSearchPage && !props.isPageDynamic
      ? 'inset -4px 0px 4px 0px rgba(0,0,0,0.25)'
      : 'none'};
  box-shadow: ${(props) =>
    !props.isDateInputSelected && !props.isCarSearchPage && !props.isPageDynamic
      ? 'inset -4px 0px 4px 0px rgba(0,0,0,0.25)'
      : 'none'};
`

const StyledLabelDS = styled(LabelDS)`
  text-transform: none !important;
  font-size: 18px !important;
  font-family: 'Quicksand', 'Quicksand Regular', sans-serif;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-bottom: 5px;
  color: ${(props) => (props.isPageDynamic ? '#525266 !important' : '#fff !important')};
`

const BottomButtonWrapper = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 25px;
  display: flex;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  justify-content: flex-end;
  align-items: center;
  background-color: #eff1f8;
  box-shadow: rgba(0, 0, 0, 0.12) 0px -4px 8px;
`

const SubmitButton = styled.button`
  background-color: #ff7d0e;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 120px;
  padding: 12px;
  font-size: 16px;
  color: white;
  border-radius: 8px;
`

// do not close date range picker when user switched

const DateField = styled(
  class extends Component {
    render() {
      const {
        pickupTime,
        mobile,
        showOnMobile,
        setShowOnMobile,
        dropOffTime,
        pickupDateTime,
        dropOffDateTime,
        isCarSearchPage,
        locationChange,
        updateSearchDateRange,
        setSearchPickupTime,
        setSearchDropOffTime,
        ...props
      } = this.props

      const downshiftStateReducer = (state, changes) => {
        if (changes.type === Downshift.stateChangeTypes.clickButton) {
          isCarSearchPage && setShowOnMobile(true)
          return {
            ...changes,
            isOpen: true
          }
        }
        isCarSearchPage && locationChange(true)
        return changes
      }

      let inputValue = 'Select time'
      if (pickupDateTime && dropOffDateTime) {
        inputValue = util.formatDateTimeRange(pickupDateTime, dropOffDateTime)
      }

      return (
        <Downshift onChange={updateSearchDateRange} stateReducer={downshiftStateReducer} {...props}>
          {({ isOpen, getRootProps, getLabelProps, getInputProps, getMenuProps, getToggleButtonProps }) => {
            return (
              <SelectContainerDS {...getRootProps()} {...props}>
                {/* DATE RANGE SELECT */}
                <El width={1} className='form-field'>
                  {!isCarSearchPage && (
                    <StyledLabelDS {...getLabelProps()} isPageDynamic={this.props.isPageDynamic}>
                      Pick up and Drop off time
                    </StyledLabelDS>
                  )}
                  <DateInputWrapper
                    isPageDynamic={this.props.isPageDynamic}
                    isDateInputSelected={isOpen}
                    isCarSearchPage={isCarSearchPage}
                  >
                    <img
                      src={CalendarIcon}
                      alt={'rent_carla_calendar_icon'}
                      style={{
                        position: 'absolute',
                        left: '10px',
                        top: '14px',
                        width: '22px',
                        zIndex: 10
                      }}
                    />
                    <InputArea
                      isPageDynamic={this.props.isPageDynamic}
                      type='button'
                      isDateInputSelected={isOpen}
                      isCarSearchPage={isCarSearchPage ? true : false}
                      {...getInputProps({
                        value: inputValue,
                        placeholder: 'When'
                      })}
                      {...getToggleButtonProps()}
                    />
                  </DateInputWrapper>
                </El>
                {/* DATE RANGE CALENDAR POPUP */}
                <FlexEl width={[1, 1, 550]}>
                  <MenuDS
                    style={{
                      paddingBottom: mobile ? '110px' : '16px',
                      maxHeight: mobile && '560px',
                      overflow: mobile && 'scroll',
                      zIndex: mobile && 1000,
                      boxShadow: mobile && '0px 4px 4px 0px rgba(0, 0, 0, 0.12)'
                    }}
                    isOpen={mobile ? showOnMobile : isOpen}
                    {...getMenuProps()}
                    id='daterange-selectbox'
                  >
                    <DateRangePicker />
                    <TimeSlider label='Pickup time' value={pickupTime} onChange={setSearchPickupTime} />
                    <TimeSlider label='Drop off time' value={dropOffTime} onChange={setSearchDropOffTime} />
                    {mobile && (
                      <BottomButtonWrapper>
                        <SubmitButton onClick={() => setShowOnMobile(false)}>Apply</SubmitButton>
                      </BottomButtonWrapper>
                    )}
                  </MenuDS>
                </FlexEl>
              </SelectContainerDS>
            )
          }}
        </Downshift>
      )
    }
  }
)`
  #daterange-selectbox {
    max-height: unset;
    ${SearchButton} {
      font-size: 16px;
      padding: 10px 40px;
    }
  }
  ${MenuDS} {
    width: 100%;
    text-align: center;
  }
`

export default connect(selectSearchArgs, { setSearchPickupTime, setSearchDropOffTime })(DateField)
