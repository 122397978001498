import * as PropTypes from 'prop-types'
import { CarlinPropTypes } from '../../../entities'
import Downshift from 'downshift'
import React from 'react'
import { AsyncDownshift } from '../../CarlaSelect/AsyncDownshift'
import AgeIcon from '../../../util/images/driver-s-license.svg'
import styled from 'styled-components'
import { themeGet } from 'styled-system'

import { AgeMobileDS, SelectContainerDS, SelectDS } from '../../CarlaSelect/components'

export { AsyncDownshift }
export * from '../../CarlaSelect/components'

export const selectPropTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  value: CarlinPropTypes.SelectItem,
  onChange: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string
}

export const asyncSelectPropTypes = {
  value: CarlinPropTypes.SelectItem,
  onChange: PropTypes.func,
  loadItems: PropTypes.func,
  label: PropTypes.string
}

const AgeInputWrapper = styled.div`
  margin-top: 0px;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background: url(${AgeIcon});
  background-repeat: no-repeat;
  background-color: white;
  background-position: 8% 50%;
  width: 25%;
  padding-left: 35px;
  margin-left: auto;
  margin-right: 5%;
  border-radius: 12px;
  overflow: hidden;
  border: ${(props) => (props.isPageDynamic ? '1px solid #dfdfe7' : 'none')};

  // age input desktop shadow below
  -webkit-box-shadow: ${(props) =>
    props.isPageDynamic ? '2px 4px 14px 0px rgba(65,64,66,0.15)' : '0px 4px 4px 0px rgba(0,0,0,0.25)'};
  -moz-box-shadow: ${(props) =>
    props.isPageDynamic ? '2px 4px 14px 0px rgba(65,64,66,0.15)' : '0px 4px 4px 0px rgba(0,0,0,0.25)'};
  box-shadow: ${(props) =>
    props.isPageDynamic ? '2px 4px 14px 0px rgba(65,64,66,0.15)' : '0px 4px 4px 0px rgba(0,0,0,0.25)'};

  @media only screen and (min-width: 350px) {
    width: 39%;
  }

  @media only screen and (min-width: 450px) {
    width: 34%;
  }

  @media only screen and (min-width: ${themeGet('breakpoints.1')}px) {
    width: 29%;
  }

  @media only screen and (min-width: 700px) {
    width: 24%;
  }
`

const StyledSelectDS = styled(SelectDS)`
  width: 100% !important;
  border: none !important;
`

export const AgeSelectMobile = ({ isPageDynamic, items, value, onChange, label, placeholder, ...props }) => {
  return (
    <Downshift
      selectedItem={value.value === '25' ? { value: '21', label: '21' } : value}
      onChange={onChange}
      items={items}
      itemToString={(item) => (item ? item.label : '')}
      {...props}
    >
      {({
        getLabelProps,
        getRootProps,
        getInputProps,
        getToggleButtonProps,
        getMenuProps,
        getItemProps,
        isOpen,
        closeMenu,
        selectedItem,
        ...props
      }) => {
        return (
          <SelectContainerDS {...getRootProps()} {...props}>
            <AgeInputWrapper isPageDynamic={isPageDynamic}>
              <StyledSelectDS id='AgeInput' type='button' {...getToggleButtonProps()}>
                {selectedItem ? selectedItem.label : placeholder}
              </StyledSelectDS>
            </AgeInputWrapper>
            <AgeMobileDS
              {...{ items, label, placeholder }}
              {...{ isOpen, selectedItem, getMenuProps, getItemProps, closeMenu }}
            />
          </SelectContainerDS>
        )
      }}
    </Downshift>
  )
}
AgeSelectMobile.propTypes = selectPropTypes
