import React from 'react'
import { Grid, Box, ModificationOption, MobileHeader, Container, Header, BackButton, SubHeader } from '../styles'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { MobileScreen, OverMobileScreen } from '../../common/Screens'
import { CompactReservedCar } from '../../Reservations/HelperComponents'
import useReservationInfo from '../hooks/useReservationInfo'

export default function () {
  const { response } = useReservationInfo()
  const { t: TRANSLATOR } = useTranslation()
  const history = useHistory()

  const options = (
    <Grid container direction='column' style={{ gap: 16 }}>
      <ModificationOption onClick={() => history.push('/my-reservations/modify-date-time')}>
        {TRANSLATOR('reservations:modification:options:dateTime')}
      </ModificationOption>
      <ModificationOption onClick={() => history.push('/my-reservations/modify-driver')}>
        {TRANSLATOR('reservations:modification:options:driver')}
      </ModificationOption>
      <ModificationOption onClick={() => history.push('/my-reservations/modify-location')}>
        {TRANSLATOR('reservations:modification:options:location')}
      </ModificationOption>
      <ModificationOption onClick={() => history.push('/my-reservations/change-vehicle')}>
        {TRANSLATOR('reservations:modification:options:vehicle')}
      </ModificationOption>
      <ModificationOption onClick={() => history.push('/my-reservations/cancellation')}>
        {TRANSLATOR('reservations:modification:options:cancel')}
      </ModificationOption>
    </Grid>
  )
  return (
    <>
      <MobileScreen customBreakpoint={750}>
        <MobileHeader>{TRANSLATOR('reservations:modification:modifyReservation')}</MobileHeader>
        <Box m={3}>{options}</Box>
      </MobileScreen>
      <OverMobileScreen customBreakpoint={750}>
        <Container>
          <BackButton onClick={() => history.go(-1)}>{TRANSLATOR('reservations:reserved:back')}</BackButton>
          <Box border='1px solid var(--borderGrey)' borderRadius={12} bgcolor='white' p={6}>
            <Header>{TRANSLATOR('reservations:modification:modifyReservation')}</Header>
            <Grid container wrap='nowrap' justifyContent='space-around' spacing={4} style={{ marginTop: 16 }}>
              <Grid item sm={5}>
                <SubHeader>{TRANSLATOR('reservations:modification:currentReservation')}</SubHeader>
                <CompactReservedCar {...response} />
              </Grid>
              <Grid item sm={6} md={5}>
                <Box p={3} border='1px solid var(--borderGrey)' borderRadius={12}>
                  {options}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </OverMobileScreen>
    </>
  )
}
