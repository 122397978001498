import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Radio from '@material-ui/core/Radio'

const StyledRadio = withStyles({
  root: {
    color: '#525266',
    '&$checked': {
      color: '#FF7D0E'
    },
    '&$hover': {
      color: '#FF7D0E'
    }
  },
  checked: {}
})((props) => <Radio disableRipple color='default' {...props} />)

export function RadioUI(props) {
  return <StyledRadio {...props} />
}
