import React from 'react'
import Info from '../../util/images/icon-info.svg'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const PhoneInfoTooltip = styled((props) => {
  const { t } = useTranslation()
  return (
    <div className={props.className} type='button'>
      <img src={Info} alt='Info' />
      <p id='tooltip-text'>{t('xo1:tooltip')}</p>
    </div>
  )
})`
  position: absolute;
  right: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: none;
  background: none;

  & :hover {
    #tooltip-text {
      display: block;
    }
  }

  #tooltip-text {
    display: none;
    background: rgba(0, 0, 0, 0.8);
    z-index: 1;
    position: absolute;
    right: calc(100% - 32px);
    bottom: calc(100% + 12px);
    opacity: 0.8229;
    padding: 12px 8px;
    border-radius: 12px;
    white-space: normal;
    width: 230px;
    color: white;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }
`

export default PhoneInfoTooltip
