import React, { Component } from 'react'
import Downshift from 'downshift'
import debounce from 'lodash/debounce'
import trim from 'lodash/trim'

export class AsyncDownshift extends Component {
  state = {
    isLoading: false,
    error: false,
    items: []
  }

  // call item loading functions with 300ms delay
  requestAPI = debounce((input) => {
    this.props.loadItems(input).then(this.handleItems).catch(this.handleError)
  }, 300)

  handleInputChange = (inputValue, { selectedItem, closeMenu }) => {
    const input = trim(inputValue)
    const inputIsEmpty = !(input && input.length > 0)
    const inputAlreadySelected = selectedItem && selectedItem.label === inputValue

    if (inputIsEmpty || inputAlreadySelected) {
      closeMenu()
      return
    }

    this.setState({
      isLoading: true,
      error: false,
      items: []
    })
    this.requestAPI(input)
  }

  // when async item loading call is successful
  handleItems = (items) => {
    if (items && items.length > 0) {
      this.setState({
        isLoading: false,
        error: false,
        items
      })
    } else {
      this.setState({
        isLoading: false,
        error: 404,
        items: []
      })
    }
  }

  // when async item loading call failed
  handleError = (error) => {
    this.setState({
      isLoading: false,
      error: error,
      items: []
    })
  }

  render() {
    const { children, value, onChange, ...props } = this.props
    const { isLoading, error, items } = this.state
    return (
      <Downshift
        onChange={onChange}
        items={items}
        itemToString={(item) => (item ? item.label : '')}
        selectedItem={value}
        onInputValueChange={this.handleInputChange}
        {...props}
      >
        {(propsDS) => {
          return children({ ...propsDS, isLoading, error, items })
        }}
      </Downshift>
    )
  }
}
