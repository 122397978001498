import React from 'react'
import { Dialog } from '@material-ui/core'
import { useTranslation, Trans } from 'react-i18next'
import { useBreakpoint } from '../../common/Screens'
import { Header, TextContent, ButtonAddProtection } from '../styles'
import { useDispatch } from 'react-redux'
import { setSelectedAncillaries } from '../../../redux/slices'

export default function ProtectionInfoModal({
  insuranceId,
  open,
  onClose,
  selectedAncillaries,
  validateInsuranceList,
  price
}) {
  const { t: TRANSLATOR } = useTranslation()
  const isMobile = useBreakpoint(768)
  const dispatch = useDispatch()

  if (!insuranceId) return null

  function addProtection() {
    if (!selectedAncillaries.includes(insuranceId)) {
      dispatch(setSelectedAncillaries([...selectedAncillaries, insuranceId]))
      validateInsuranceList()
    }
    onClose()
  }

  return (
    <Dialog PaperComponent='div' open={open} onClose={onClose} maxWidth='md'>
      <Header $mobile={isMobile}>
        {TRANSLATOR('xo1:popupUpgrade:header')}
        <button type='button' onClick={onClose} />
      </Header>
      <TextContent $mobile={isMobile}>
        <h3>{TRANSLATOR('xo1:protectionPlan:textContent')}</h3>
        <ul>
          <li>
            <Trans i18nKey={'xo1:protectionPlan:coverage'} components={{ b: <b></b> }} />
          </li>
          <li>
            <Trans i18nKey={'xo1:protectionPlan:drivers'} components={{ b: <b></b> }} />
          </li>
          <li>
            <Trans i18nKey={'xo1:protectionPlan:protection'} components={{ b: <b></b> }} />
          </li>
        </ul>
        <p>{TRANSLATOR('xo1:protectionPlan:warning')}</p>
        <ButtonAddProtection $mobile={isMobile} type='button' onClick={addProtection}>
          {TRANSLATOR('xo1:protectionPlan:addProtection', { price, interpolation: { escapeValue: false } })}
        </ButtonAddProtection>
      </TextContent>
    </Dialog>
  )
}
