import { useEffect, useState } from 'react'

export default function useDeepLinkConverter() {
  const [result, setResult] = useState(false)

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    if (params.get('promoCode') === 'true') {
      setResult(true)
    }
  }, [])

  return result
}
