import React from 'react'
import useReservationInfo from '../hooks/useReservationInfo'
import useModificationInfo from '../hooks/useModificationInfo'
import CarInfoCard from './CarInfoCard'
import { MobileScreen, OverMobileScreen } from '../../common/Screens'
import { Box, MobileHeader, Text, MobileFixedButton, Container, BackButton, Header, Button, Grid } from '../styles'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { setXO1Details } from '../../../redux/slices'
import { useDispatch } from 'react-redux'

export default function ModifyCarConfirm() {
  const { response } = useReservationInfo()
  const modification = useModificationInfo()
  const { t: TRANSLATOR } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  if (!!!response) return null

  const { userInfo, rentalProduct, dtl } = response
  const {
    type,
    userInfo: modifiedUserInfo,
    product,
    carSearchArgs,
    modificationPriceDifference,
    response: { oldCarPrice }
  } = modification

  const { shouldCharge, totalDifference, totalPayNowDifference } = modificationPriceDifference

  const diff = shouldCharge ? totalDifference : totalDifference - totalPayNowDifference
  const total = shouldCharge
    ? totalDifference + oldCarPrice.total
    : totalDifference + oldCarPrice.total - totalPayNowDifference

  const newCar = (
    <CarInfoCard
      userInfo={modifiedUserInfo}
      type={type}
      supplierUrl={product.supplier.logoUrl}
      imageUrl={product.car.imageUrl}
      total={total}
      currency={product.price.currency}
      groupDescription={product.car.groupDescription}
      name={product.car.name}
      classCode={product.car.classCode}
      transmissionDescription={product.car.transmissionDescription}
      seatCount={product.car.seatCount}
      insured={product.extras.insured}
      stationPair={product.stationPair}
      pickupDateTime={carSearchArgs?.pickupDateTime}
      dropOffDateTime={carSearchArgs?.dropOffDateTime}
      newCar={true}
      totalDifference={diff}
    />
  )

  const currentCar = (
    <CarInfoCard
      userInfo={userInfo}
      type={type}
      supplierUrl={rentalProduct.supplierUrl}
      imageUrl={rentalProduct.imageUrl}
      total={oldCarPrice.total}
      currency={oldCarPrice.currency}
      groupDescription={rentalProduct.typeDescription}
      name={rentalProduct.name}
      classCode={rentalProduct.classCode}
      transmissionDescription={rentalProduct.transmissionDescription}
      seatCount={rentalProduct.seatCount}
      stationPair={dtl}
      pickupDateTime={dtl.pickup.dateTime}
      dropOffDateTime={dtl.dropOff.dateTime}
    />
  )

  function handleClick() {
    if (!shouldCharge) {
      history.push('/my-reservations/modify-booking-confirm')
      return
    }

    // TODO: Setting xo1details for xo2 to work. Rewrite xo2 to decouple xo1 from xo2 and related payment forms
    dispatch(setXO1Details({ product, userInfo: modifiedUserInfo }))
    history.push('/my-reservations/payment')
  }

  function getTypeHeaderTranslationKey() {
    switch (type) {
      case 'DRIVER_CHANGE':
        return 'driver'
      case 'DATE_TIME_CHANGE':
        return 'dateTime'
      case 'GENERAL_CHANGE':
        return 'location'
      case 'CHANGE_VEHICLE':
        return 'vehicle'
      default:
        return 'modifyReservation'
    }
  }

  return (
    <>
      <MobileScreen>
        <MobileHeader>{TRANSLATOR(`reservations:modification:options:${getTypeHeaderTranslationKey()}`)}</MobileHeader>
        <Box px={2} pt={3} pb={10}>
          <Text fontWeight={600} mb={1}>
            {TRANSLATOR('reservations:modification:newReservation')}
          </Text>
          {newCar}
          <Text fontWeight={600} mb={1} mt={3}>
            {TRANSLATOR('reservations:modification:currentReservation')}
          </Text>
          {currentCar}
        </Box>
        <MobileFixedButton onClick={handleClick}>{TRANSLATOR('reservations:modification:confirm')}</MobileFixedButton>
      </MobileScreen>
      <OverMobileScreen>
        <Container style={{ marginBottom: 24 }}>
          <BackButton onClick={() => history.go(-1)}>{TRANSLATOR('reservations:reserved:back')}</BackButton>
          <Box border='1px solid var(--borderGrey)' borderRadius={12} bgcolor='white' p={6}>
            <Header>{TRANSLATOR(`reservations:modification:options:${getTypeHeaderTranslationKey()}`)}</Header>
            <Box pt={3} px={{ xs: 0, sm: 3, md: 6, lg: 10 }}>
              <Grid container wrap='nowrap' justifyContent='center' style={{ gap: 48 }}>
                <Grid item xs={6}>
                  <Text fontWeight={600} mb={1}>
                    {TRANSLATOR('reservations:modification:currentReservation')}
                  </Text>
                  {currentCar}
                </Grid>
                <Grid item xs={6}>
                  <Text fontWeight={600} mb={1}>
                    {TRANSLATOR('reservations:modification:newReservation')}
                  </Text>
                  {newCar}
                </Grid>
              </Grid>
              <Button onClick={handleClick}>{TRANSLATOR('reservations:modification:confirm')}</Button>
            </Box>
          </Box>
        </Container>
      </OverMobileScreen>
    </>
  )
}
