import React, { Component } from 'react'
import util from '../../util'
import debug from 'debug'
import { DayPickerContainer } from './index'
import { connect } from 'react-redux'
import { updateSearchDateRange, clearSearchDateRange } from '../../redux/slices'
import { selectSearchArgs } from '../../redux/selectors'
import GA from '../../services/GA'

const log = debug('carla:search')

class DateRangePicker extends Component {
  state = {
    today: util.normalizeDate(new Date()),
    hovered: null, // current date with mouse over (Date)
    firstMonth: null // first month calendar is showing (Date)
  }

  constructor(props) {
    super(props)
    this.state.firstMonth = props.pickupDate
  }

  handleDayClick = (day) => {
    util.normalizeDate(day)
    const { pickupDate, dropOffDate } = this.props
    const { today } = this.state
    if (day < today) {
      return
    }

    let newPickupDate, newDropOffDate // new cells on calendar

    if (!pickupDate && !dropOffDate) {
      // start selection
      newPickupDate = day
      newDropOffDate = day
      log(`starting new range selection from: ${newPickupDate}`)
      GA.startedDateSelectionOnDesktop()
    } else if (pickupDate && dropOffDate) {
      // restart selection
      if (pickupDate.getTime() === dropOffDate.getTime()) {
        // if single date is selected
        if (day.getTime() === pickupDate.getTime()) {
          // if pickup date clicked again
          newPickupDate = null
          newDropOffDate = null
          log(`pickup date clicked again, clearing`)
        } else if (day > dropOffDate) {
          newPickupDate = pickupDate
          newDropOffDate = day
          log(`selection complete, drop off date ${newPickupDate}`)
        } else if (day < pickupDate) {
          newPickupDate = day
          newDropOffDate = day
          log(`restarting selection starting from ${newPickupDate}`)
        }
      } else {
        // if a range is selected
        if (day.getTime() === pickupDate.getTime()) {
          // if pickup date clicked again
          newPickupDate = null
          newDropOffDate = null
          log(`pickup date clicked again, clearing`)
        } else {
          newPickupDate = day
          newDropOffDate = day
          log(`restarting selection from: ${newPickupDate}`)
        }
      }
    } else {
      console.error('wtf')
    }

    if (newPickupDate && newDropOffDate) {
      this.props.updateSearchDateRange({
        startDate: newPickupDate,
        endDate: newDropOffDate
      })
    } else {
      this.props.clearSearchDateRange()
    }
  }

  handleDayMouseEnter = (day) => {
    if (this.props.fromDate && this.props.toDate) {
      this.setState({ hovered: null })
    } else {
      this.setState({ hovered: day })
    }
  }

  handleDayMouseLeave = () => this.setState({ hovered: null })

  render() {
    // date pickers
    const { pickupDate, dropOffDate } = this.props
    const { hovered, today, firstMonth } = this.state
    const disabledDays = { before: today }

    const selectedDays = {
      from: pickupDate,
      to: dropOffDate
    }
    const modifiers = {
      start: pickupDate,
      end: dropOffDate
    }

    if (
      pickupDate &&
      dropOffDate &&
      pickupDate.getTime() === dropOffDate.getTime() && // only single day selected currently
      hovered > pickupDate // hovered day is after pickup date
    ) {
      modifiers.selecting = {
        from: pickupDate,
        to: hovered
      }
      modifiers.end = hovered
    }

    return (
      <DayPickerContainer
        {...this.props}
        numberOfMonths={2}
        fromMonth={today}
        month={firstMonth}
        disabledDays={disabledDays}
        selectedDays={selectedDays}
        modifiers={modifiers}
        onDayMouseEnter={this.handleDayMouseEnter}
        onDayMouseLeave={this.handleDayMouseLeave}
        onDayClick={this.handleDayClick}
      />
    )
  }
}

export default connect(selectSearchArgs, { updateSearchDateRange, clearSearchDateRange })(DateRangePicker)
