import React, { useRef } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { ReactComponent as RightArrowIcon } from './assets/icon_arrownext.svg'
import { useTranslation } from 'react-i18next'
import { MobileScreen, OverMobileScreen } from '../common/Screens'
import * as Styles from './SupplierTermsAndConditions.styles'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`term-and-condition-tabpanel-${index}`}
      aria-labelledby={`term-and-condition-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#ffffff',
    '.MuiTabs-root': {}
  },
  colorPrimary: {
    boxShadow: 'none',
    backgroundColor: '#3f51b5',
    '& .MuiTabs-flexContainer': {
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      flexDirection: 'column',
      background: '#ffffff',
      gap: '10px',
      '& .MuiTab-root': {
        flex: 1,
        color: '#0076FF',
        fontSize: '15px',
        fontWeight: 600,
        lineHeight: '20px',
        opacity: 1,
        textTransform: 'capitalize',
        borderRadius: '8px',
        background: 'rgba(0, 118, 255, 0.03)',
        width: '100%',
        maxWidth: '100%',
        padding: '4px 4px 4px 12px'
      },
      '& .MuiTab-wrapper': {
        alignItems: 'center',
        flexDirection: 'row-reverse',
        justifyContent: 'space-between'
      },
      '& .MuiTab-labelIcon': {
        minHeight: 'auto',
        '&.Mui-selected': {
          background: 'rgba(0, 118, 255, 0.1)',
          '& svg': {
            transform: 'rotate(90deg)'
          }
        }
      },
      '& .MuiTab-labelIcon .MuiTab-wrapper > *:first-child': {
        marginBottom: 0
      }
    },
    '& .MuiTabs-indicator': {
      display: 'none'
    }
  }
}))

const PDFRender = ({ caption, description: pdf }) => {
  if (!pdf) return null
  return (
    <>
      <MobileScreen>
        <p style={{ padding: '24px' }}>
          <a target='_blank' href={pdf} rel='noreferrer'>
            {caption}
          </a>
        </p>
      </MobileScreen>
      <OverMobileScreen>
        <object data={pdf} type='application/pdf' width='100%' height='100%'>
          <p style={{ padding: '24px' }}>
            <a target='_blank' href={pdf} rel='noreferrer'>
              {caption}
            </a>
          </p>
        </object>
      </OverMobileScreen>
    </>
  )
}

const TextRender = ({ descriptionList }) => {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)
  const { t: TRANSLATOR } = useTranslation()
  const titleRef = useRef(null)

  const scrollInView = (node) => {
    if (!node) return
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    const options = { block: 'start' }
    if (!isSafari) {
      options.behavior = 'smooth'
    }
    node.scrollIntoView({ behavior: 'smooth' })
  }

  const handleChange = (event, newValue) => {
    if (titleRef && titleRef.current) {
      scrollInView(titleRef.current)
    }
    setValue(newValue)
  }

  return (
    <Styles.TextRenderContainer>
      <Styles.AppBarTitle>{TRANSLATOR('xo1:tableOfContents')}</Styles.AppBarTitle>
      <AppBar position='static' className={classes.colorPrimary}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label={TRANSLATOR('xo1:supplierTermsAndConditions')}
          variant='scrollable'
        >
          {descriptionList?.map((item, index) => (
            <Tab key={index} disableRipple label={item.caption} icon={<RightArrowIcon />} />
          ))}
        </Tabs>
      </AppBar>
      <Styles.TabContentStyled ref={titleRef}>
        <Styles.AppBarTitle>{TRANSLATOR('xo1:supplierTermsAndConditions')}</Styles.AppBarTitle>
        {descriptionList?.map((item, index) => (
          <>
            <TabPanel key={index} value={value} index={index}>
              <Styles.TabPanelTitle>{item.caption}</Styles.TabPanelTitle>
              <p>{item.description}</p>
            </TabPanel>
          </>
        ))}
      </Styles.TabContentStyled>
    </Styles.TextRenderContainer>
  )
}

export default function SupplierTermsAndConditions() {
  const { type, descriptionList } = useSelector((state) => state.carRentalProduct.extras.supplierRentalPolicy)

  if (!type || !descriptionList) return null
  return type === 'pdf' ? <PDFRender {...descriptionList[0]} /> : <TextRender descriptionList={descriptionList} />
}
