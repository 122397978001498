import { useLocation } from 'react-router-dom'

export default function useModificationType() {
  const location = useLocation()
  const parts = location.pathname.split('/')
  const modifyPart = parts[parts.length - 1]
  const keys = modifyPart.split('-')

  switch (keys[1]) {
    case 'location':
      return 'GENERAL_CHANGE'
    case 'date':
      return 'DATE_TIME_CHANGE'
    case 'driver':
      return 'DRIVER_CHANGE'
    case 'vehicle':
      return 'CHANGE_VEHICLE'
    default:
      return 'GENERAL_CHANGE'
  }
}
