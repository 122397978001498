import React from 'react'
import loadable from '@loadable/component'
import { Route, Switch } from 'react-router-dom'
import routes from './routes'

const Under25Help = loadable(() => import('./components/DuplicateBooking'))
const TermsConditions = loadable(() => import('./routes/TermsConditions'))
const PrivacyPolicy = loadable(() => import('./routes/PrivacyPolicy'))
const Reservations = loadable(() => import('./routes/Reservations'))
const Contact = loadable(() => import('./routes/ContactUs'))
const Sitemap = loadable(() => import('./routes/Sitemap'))
// Dynamic landing page
const CountryArab = loadable(() => import(`./routes/LandingPage/pages/CountryArab`))
//const CountryItaly = loadable(() => import(`./routes/LandingPage/pages/CountryItaly`))
const BrandSixt = loadable(() => import(`./routes/LandingPage/pages/BrandSixt`))
const BrandKeddy = loadable(() => import(`./routes/LandingPage/pages/BrandKeddy`))
const BrandEuropcar = loadable(() => import(`./routes/LandingPage/pages/BrandEuropcar`))
const CityIstanbul = loadable(() => import(`./routes/LandingPage/pages/CityIstanbul`))
const MemorialDay = loadable(() => import(`./routes/LandingPage/pages/MemorialDay`))
const FourthOfJuly = loadable(() => import(`./routes/LandingPage/pages/FourthOfJuly`))
const LastMinute = loadable(() => import(`./routes/LandingPage/pages/LastMinute`))
const CityParis = loadable(() => import(`./routes/LandingPage/pages/CityParis`))
const CountryIceland = loadable(() => import(`./routes/LandingPage/pages/CountryIceland`))
const CoronaRental = loadable(() => import(`./routes/LandingPage/pages/CoronaRental`))
const ListYourFleet = loadable(() => import(`./routes/ListYourFleet`))
const BrandAdvantage = loadable(() => import(`./routes/LandingPage/pages/BrandAdvantage`))
// End Dynamic landing page
const PageNotFound = loadable(() => import(`./routes/PageNotFound`))
const HomePage = loadable(() => import(`./routes/Home`))
// const HotelPage = loadable(() => import(`./routes/HotelPage`))
// const BundlePage = loadable(() => import(`./routes/BundlePage`))

const XO1 = loadable(() => import('./routes/XO1'), {
  ssr: false
})

//Redirected pages. It was originally made for skyscanner
const Redirect = loadable(() => import('./routes/Redirect'), {
  ssr: false
})

// const HotelReservationPage = loadable(() => import('./routes/ReservationPage'), {
//   ssr: false
// })
// const BundleReservationPage = loadable(() => import('./routes/ReservationPage/Bundle'), {
//   ssr: false
// })
const XO2 = loadable(() => import('./routes/XO2'), {
  ssr: false
})
// const HotelXO2 = loadable(() => import('./routes/XO2/hotel'), {
//   ssr: false
// })
// const BundleXO2 = loadable(() => import('./routes/XO2/bundle'), {
//   ssr: false
// })
const MockXo2 = loadable(() => import('./routes/XO2/mockXO2'), {
  ssr: false,
  resolveComponent: (components) => components.default
})
const Success = loadable(() => import('./routes/Success'), {
  ssr: false
})
const CarSearchResults = loadable(() => import('./routes/CarListing'), {
  ssr: false
})
const HotelListing = loadable(() => import('./routes/Hotels'), {
  ssr: false
})
const BundleListing = loadable(() => import('./routes/BundleList'), {
  ssr: false
})

function Router() {
  return (
    <Switch>
      <Route exact key={'home'} path={'/'} component={HomePage} />
      {/* <Route exact key={'hotel-home'} path={'/hotels'} component={HotelPage} />
      <Route exact key={'bundle-home'} path={'/hotel-bundle'} component={BundlePage} /> */}
      <Route exact key={'under25help'} path={'/under25help'} component={Under25Help} />
      <Route exact key={'terms-conditions'} path={'/terms-conditions'} component={TermsConditions} />
      <Route exact key={'privacy-policy'} path={'/privacy-policy'} component={PrivacyPolicy} />
      <Route exact key={'xo1'} path={'/reservation'} component={XO1} />
      <Route
        exact
        key={'redirect'}
        path={[
          '/redirect/:departdatetime/:returndatetime/:pickup/:dropoff/:driverAge/:carId',
          '/redirect',
          '/redirect/:departdatetime/:returndatetime/:pickup/:dropoff/:driverAge/:carId/:carType'
        ]}
        component={Redirect}
      />
      <Route key={'xo2'} path={['/payment', '/my-reservations/payment']} component={XO2} />
      {/* <Route exact key={'hotel-xo2'} path={'/hotel-payment'} component={HotelXO2} /> */}
      {/* <Route exact key={'bundle-xo2'} path={'/bundle-payment'} component={BundleXO2} /> */}
      <Route exact key={'mockXO2'} path={'/mock-payment'} component={MockXo2} />
      <Route exact key={'success'} path={'/success'} component={Success} />
      <Route exact key={'my-reservations'} path={'/my-reservations'} component={Reservations} />
      <Route exact key={'contact'} path={'/contact'} component={Contact} />
      <Route exact key={'sitemap'} path={'/sitemap'} component={Sitemap} />
      {/* Landing pages */}
      <Route exact key={'arab-landing-page'} path={'/van-rental'} component={CountryArab} />
      {/*<Route exact key={'italy-landing-page'} path={"/italy-car-rental"} component={CountryItaly}/>*/}
      <Route exact key={'sixt-landing-page'} path={'/sixt-rent-a-car'} component={BrandSixt} />
      <Route exact key={'keddy-landing-page'} path={'/keddy-car-hire'} component={BrandKeddy} />
      <Route exact key={'europcar-landing-page'} path={'/europcar'} component={BrandEuropcar} />
      <Route exact key={'istanbul-landing-page'} path={'/istanbul-new-airport'} component={CityIstanbul} />
      <Route exact key={'memorial-day-weekend'} path={'/memorial-day-weekend'} component={MemorialDay} />
      <Route exact key={'4th-of-july'} path={'/4th-of-july'} component={FourthOfJuly} />
      <Route exact key={'last-minute-car-rental'} path={'/last-minute-car-rental'} component={LastMinute} />
      <Route exact key={'paris-car-rental'} path={'/paris-car-rental'} component={CityParis} />
      <Route exact key={'iceland-car-rental'} path={'/iceland-car-rental'} component={CountryIceland} />
      <Route exact key={'coronavirus-car-rental'} path={'/coronavirus-car-rental'} component={CoronaRental} />
      <Route exact key={'list-your-fleet'} path={'/listyourfleet'} component={ListYourFleet} />
      <Route exact key={'advantage-landing-page'} path={'/advantage-rent-a-car'} component={BrandAdvantage} />
      {/* End landing pages */}
      <Route
        exact
        key={'rental-cars'}
        path={'/rental-cars/:pickupLocationId/:dropOffLocationId/:pickupDateStr/:dropOffDateStr'}
        component={CarSearchResults}
      />
      <Route
        exact
        key={'hotel-results'}
        path={[
          '/hotel-results/:latitude/:longitude/:pickupDateStr/:dropOffDateStr',
          '/hotel-results/:latitude/:longitude/:pickupDateStr/:dropOffDateStr/view'
        ]}
        component={HotelListing}
      />
      <Route
        exact
        key={'bundle-list'}
        path={['/hotel-bundle/list', '/hotel-bundle/list/view']}
        component={BundleListing}
      />
      {/* <Route exact key={'hotel-reservation'} path={'/hotel-reservation'} component={HotelReservationPage} />
      <Route exact key={'bundle-reservation'} path={'/bundle-reservation'} component={BundleReservationPage} /> */}
      {routes
        .filter((r) => r.component)
        .map((route) => {
          if (route.id === 'blog-post' || route.id === 'blog') {
            return (
              <Route
                key={route.id}
                {...route}
                render={({ context: staticContext }) => {
                  if (staticContext) {
                    staticContext.statusCode = 200
                  }

                  return route.component
                }}
              />
            )
          }
          return <Route key={route.id} {...route} />
        })}
      <Route exact key={'fallback'} path={'*'} component={PageNotFound} />
    </Switch>
  )
}

export default Router
