import React, { useState, useEffect } from 'react'
import IconArrowDown from '../../util/images/icon-arrow-down.png'
import IconCheckbox from '../../util/images/icon-checkbox.png'
import IconCancelSmall from '../../util/images/icon-cancel-small.png'
import MobileFilterIcon from '../../util/images/icon-mobile-filter.png'
//Styling
import './Filters.css'
import GA from '../../services/GA'
import { useTranslation } from 'react-i18next'
import Suppliers from './Suppliers'

export const CategoryMapping = {
  carType: 'Car Type',
  fuel: 'Fuel',
  transmission: 'Transmission',
  seats: 'Seats',
  cancellationPolicy: 'Cancellation Policy',
  supplier: 'Supplier'
}

// Needed for translation but refactor would be nice
const languageMapping = {
  'Car Type': 'carListing:carType',
  Fuel: 'carListing:fuel',
  Transmission: 'carListing:transmission',
  Seats: 'carListing:seats',
  'Cancellation Policy': 'carListing:cancellationPolicy',
  Supplier: 'carListing:supplier'
}

const carTypeMapping = {
  'Economy - Compact': {
    en: 'Economy - Compact',
    fr: 'Économique - Compacte',
    de: 'Economy - Compact',
    it: 'Economica - Piccola',
    es: 'Económico - Compacto',
    tr: 'Ekonomi',
    pt: 'Econômico'
  },
  'Intermediate - Fullsize': {
    en: 'Intermediate - Fullsize',
    fr: 'Intermédiaire - Grande Routière',
    de: 'Mittelklasse - Oberklasse',
    it: 'Media - Grandi',
    es: 'Mediano - Grande',
    tr: 'Orta - Büyük',
    pt: 'Sedan'
  },
  'Luxury - Convertible': {
    en: 'Luxury - Convertible',
    fr: 'Luxe - Cabriolet',
    de: 'Luxus - Cabrio',
    it: 'Lusso - Convertibile',
    es: 'Lujo - Convertible',
    tr: 'Lüks - Cabrio',
    pt: 'Luxo - Conversível'
  },
  'Luxury - Premium': {
    en: 'Luxury - Premium',
    fr: 'Luxe - Berline Premium',
    de: 'Luxus - Premiumklasse',
    it: 'Lusso - Premium',
    es: 'Lujo - Premium',
    tr: 'Lüks - Prestij',
    pt: 'Luxo - Premium'
  },
  Convertible: {
    en: 'Convertible',
    fr: 'Cabriolet',
    de: 'Cabrio',
    it: 'Convertibile',
    es: 'Convertible',
    tr: 'Cabrio',
    pt: 'Conversível'
  },
  SUV: {
    en: 'SUV',
    fr: 'SUV',
    de: 'SUV',
    it: 'SUV',
    es: 'SUV',
    tr: 'SUV',
    pt: 'SUV'
  },
  Van: {
    en: 'Van',
    fr: 'Minibus',
    de: 'Van',
    it: 'Furgoni',
    es: 'Furgoneta',
    tr: 'Minibüs',
    pt: 'Minivan'
  }
}

export const FilterMenu = ({
  filters,
  selectedFilters,
  selectFilter,
  clearAllFilters,
  setTabletMenuOpen = () => {},
  filterCounts
}) => {
  //Set the initial state of open categories to true
  const initialOpenCategories = {}
  Object.keys(filters).map((filter) => (initialOpenCategories[filter] = true))
  const [openCategories, setOpenCategories] = useState(initialOpenCategories)
  const { t, i18n } = useTranslation()

  const clearAllFilter = () => {
    clearAllFilters()
    setTabletMenuOpen(false)
  }

  const onArrowClick = (filterCategory) => {
    let temp = { ...openCategories }
    temp[filterCategory] = !temp[filterCategory]
    setOpenCategories(temp)
  }

  const renderCategories = () => {
    if (filters.supplier) {
      filters.supplier.sort((a, b) => a.localeCompare(b, 'en', { sensitivity: 'base' }))
    }
    const filterCategories = Object.keys(filters)
    let firstOnePlaced = false
    return filterCategories.map((filterCategory) => {
      if (filters[filterCategory].length > 1) {
        if (CategoryMapping[filterCategory] === 'Supplier')
          return (
            <Suppliers // TODO: refactor all categories to be like this
              suppliers={filters[filterCategory]}
              counts={filterCounts[filterCategory]}
              key={filterCategory}
              category={filterCategory}
            />
          )
        return (
          <div className='filter-category-container' key={filterCategory}>
            {firstOnePlaced ? <hr className='category-divider' /> : (firstOnePlaced = true)}
            <div
              className={
                CategoryMapping[filterCategory] === 'Supplier'
                  ? 'filter-category-header supplier'
                  : 'filter-category-header'
              }
            >
              <span>{t(languageMapping[CategoryMapping[filterCategory]])}</span>
              <img
                onClick={() => onArrowClick(filterCategory)}
                className='filter-header-arrow-icon'
                alt='arrow-icon'
                src={IconArrowDown}
                style={openCategories[filterCategory] ? {} : { transform: 'rotate(180deg)' }}
              />
            </div>
            <div
              className={
                openCategories[filterCategory]
                  ? 'filter-subcategories-container open'
                  : 'filter-subcategories-container closed'
              }
            >
              {filters[filterCategory].map((subcategory) => {
                return (
                  <div
                    className='filter-subcategory-container'
                    onClick={() => selectFilter(filterCategory, subcategory)}
                    key={subcategory}
                    data-testid={subcategory}
                  >
                    <span className='checkbox-container'>
                      {selectedFilters[filterCategory]?.includes(subcategory) && (
                        <img className='checkbox' alt='filter-checkbox' src={IconCheckbox} />
                      )}
                    </span>
                    {filterCategory === 'carType' ? (
                      <span className='subcategory-text'>
                        {carTypeMapping[subcategory][i18n.resolvedLanguage] ?? subcategory}
                      </span>
                    ) : (
                      <span className='subcategory-text'>{subcategory}</span>
                    )}
                    <span className='filter-count-tag'>
                      {filterCounts[filterCategory]
                        ? filterCounts[filterCategory][subcategory]
                          ? filterCounts[filterCategory][subcategory]
                          : 0
                        : null}
                    </span>
                  </div>
                )
              })}
            </div>
          </div>
        )
      }
      return null
    })
  }

  return (
    <div className='filter-menu-container'>
      <div className='filter-header'>
        <span data-testid='filter-component'>{t('carListing:filters')}</span>
        <button className='desktop-clear-all-button' onClick={clearAllFilter}>
          {t('carListing:clearAll')}
        </button>
        <img
          className='tablet-icon-cancel-small'
          onClick={() => setTabletMenuOpen(false)}
          src={IconCancelSmall}
          alt='cancel-icon'
        />
      </div>
      {renderCategories()}
      <button className='desktop-clear-all-button-bottom' onClick={clearAllFilter}>
        {t('carListing:clearAllFilters')}
      </button>
    </div>
  )
}

export const MobileFilterMenu = ({
  filters,
  selectedFilters,
  selectFilter,
  clearAllFilters,
  filterCounts,
  scrollToTop,
  setHeaderOpen = () => {}
  /*
    ApplyButtonText = 'Apply',
    ClearButtonText = 'Clear',
    updateShowCarsCount
    */
}) => {
  //Controlling whether the menu is open or not
  const [filterMenuOpen, setFilterMenuOpen] = useState(false)
  const { t, i18n } = useTranslation()

  //Set the initial state of open categories to true
  const initialOpenCategories = {}
  Object.keys(filters).map((filter) => (initialOpenCategories[filter] = true))
  const [openCategories, setOpenCategories] = useState(initialOpenCategories)

  useEffect(() => {
    //When the filter menu opens on tablet and mobile, lock the back screen for scrolling
    if (filterMenuOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'initial'
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterMenuOpen])

  const onArrowClick = (filterCategory) => {
    let temp = { ...openCategories }
    temp[filterCategory] = !temp[filterCategory]
    setOpenCategories(temp)
  }

  const applyFilters = () => {
    setFilterMenuOpen(false)
    setHeaderOpen && setHeaderOpen(true)
    scrollToTop()
  }

  const clearFilters = () => {
    clearAllFilters()
    setFilterMenuOpen(false)
    setHeaderOpen(true)
  }

  const closeHeaderAndToggleFilter = () => {
    setFilterMenuOpen(!filterMenuOpen)
    setHeaderOpen(true)
    GA.tappedFilter()
  }
  const setFilterMenuOpenAndSendGAEvent = () => {
    setFilterMenuOpen(!filterMenuOpen)
    GA.tappedFilter()
  }

  const renderCategories = () => {
    const filterCategories = Object.keys(filters)
    let firstOnePlaced = false
    return filterCategories.map((filterCategory) => {
      if (filters[filterCategory].length > 1) {
        if (CategoryMapping[filterCategory] === 'Supplier')
          return (
            <Suppliers // TODO: refactor all categories to be like this
              suppliers={filters[filterCategory]}
              counts={filterCounts[filterCategory]}
              key={filterCategory}
              category={filterCategory}
            />
          )
        return (
          <div className='filter-category-container' key={filterCategory}>
            {firstOnePlaced ? <hr className='category-divider' /> : (firstOnePlaced = true)}
            <div className='filter-category-header'>
              <span>{t(languageMapping[CategoryMapping[filterCategory]])}</span>
              <img
                onClick={() => onArrowClick(filterCategory)}
                className='filter-header-arrow-icon'
                alt='arrow-icon'
                src={IconArrowDown}
                style={openCategories[filterCategory] ? {} : { transform: 'rotate(180deg)' }}
              />
            </div>
            <div
              className={
                openCategories[filterCategory]
                  ? 'filter-subcategories-container open'
                  : 'filter-subcategories-container closed'
              }
            >
              {filters[filterCategory].map((subcategory) => {
                return (
                  <div
                    className='filter-subcategory-container'
                    onClick={() => selectFilter(filterCategory, subcategory)}
                    key={subcategory}
                    data-testid={subcategory}
                  >
                    <span className='checkbox-container'>
                      {selectedFilters[filterCategory]?.includes(subcategory) && (
                        <img className='checkbox' alt='filter-checkbox' src={IconCheckbox} />
                      )}
                    </span>
                    {filterCategory === 'carType' ? (
                      <span className='subcategory-text'>
                        {carTypeMapping[subcategory][i18n.resolvedLanguage] ?? subcategory}
                      </span>
                    ) : (
                      <span className='subcategory-text'>{subcategory}</span>
                    )}
                    <span className='filter-count-tag'>
                      {filterCounts[filterCategory]
                        ? filterCounts[filterCategory][subcategory]
                          ? filterCounts[filterCategory][subcategory]
                          : 0
                        : null}
                    </span>
                  </div>
                )
              })}
            </div>
          </div>
        )
      }
      return null
    })
  }

  return (
    <div>
      <div className='tablet-filter-container'>
        <div className='tablet-filter-controller' onClick={() => setFilterMenuOpenAndSendGAEvent()}>
          <span className='tablet-filter-controller-text'>
            <span className='tablet-filter-controller-header'>{t('carListing:filter')}</span>
          </span>
          <div className='tablet-filter-controller-right'>
            <img className='filter-container-arrow-icon' src={IconArrowDown} alt='arrow-icon' />
          </div>
        </div>
        <div className='tablet-filter-menu-container' style={filterMenuOpen ? {} : { transform: 'translateX(-100%)' }}>
          <div className='filter-header'>
            <span>{t('carListing:filters')}</span>
            <img
              className='tablet-icon-cancel-small'
              onClick={() => setFilterMenuOpen(false)}
              src={IconCancelSmall}
              alt='cancel-icon'
            />
          </div>
          {renderCategories()}
          <div className='filter-menu-bottom-controllers'>
            <button className='tablet-filter-menu-clear-button' onClick={clearFilters}>
              {t('carListing:clear')}
            </button>
            <button className='tablet-filter-menu-apply-button' onClick={applyFilters}>
              {t('carListing:apply')}
            </button>
          </div>
        </div>
        <div className='tablet-filter-menu-backdrop' style={filterMenuOpen ? {} : { display: 'none' }} />
      </div>

      <div className='mobile-filter-container'>
        <div className='tablet-filter-controller' onClick={closeHeaderAndToggleFilter}>
          <div className='tablet-filter-controller-right'>
            <img className='mobile-filter-container-icon' src={MobileFilterIcon} alt='arrow-icon' />
          </div>
          <span className='tablet-filter-controller-text'>{t('carListing:filter')}</span>
        </div>
        <div className='tablet-filter-menu-container' style={filterMenuOpen ? {} : { transform: 'translateX(-100%)' }}>
          <div className='filter-header'>
            <span>{t('carListing:filters')}</span>
            <img
              className='tablet-icon-cancel-small'
              onClick={() => setFilterMenuOpen(false)}
              src={IconCancelSmall}
              alt='cancel-icon'
            />
          </div>
          {renderCategories()}
          <div className='filter-menu-bottom-controllers'>
            <button className='tablet-filter-menu-clear-button' onClick={clearFilters}>
              {t('carListing:clear')}
            </button>
            <button className='tablet-filter-menu-apply-button' onClick={applyFilters}>
              {t('carListing:apply')}
            </button>
          </div>
        </div>
        <div className='tablet-filter-menu-backdrop' style={filterMenuOpen ? {} : { display: 'none' }} />
      </div>
    </div>
  )
}
