import React from 'react'
import styled from 'styled-components'
import SkipTheLineImg from '../../util/images/inapp-skip-the-line-1@2x.webp'

const SkipTheLineContainer = styled.div`
  margin: 100px auto;
  width: 90%;
  max-width: 1200px;
  padding: 30px auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 30px;
  position: relative;

  &::before {
    position: absolute;
    content: '';
    width: 90%;
    height: 2px;
    border-bottom: 2px solid #dfdfe7;
    bottom: -60px;

    @media only screen and (max-width: 1200px) {
      display: none;
    }
  }

  @media only screen and (max-width: 1200px) {
    flex-direction: column-reverse;
    margin-top: 310px;
    margin-bottom: 35px;
    gap: 5px;
  }

  @media only screen and (min-width: 576px) and (max-width: 770px) {
    margin-top: 370px;
  }

  @media only screen and (max-width: 576px) {
    margin-top: -50px;
    margin-bottom: 20px;
    gap: 0px;
  }
`

const TextWrapper = styled.div`
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media only screen and (max-width: 1200px) {
    align-items: center;
    max-width: 600px;
  }

  @media only screen and (max-width: 576px) {
    max-width: 95vw;
  }
`

const Title = styled.h2`
  width: 280px;
  color: #525266;
  font-family: Montserrat;
  font-size: 34px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  @media only screen and (max-width: 576px) {
    margin: 10px auto;
    font-size: 20px;
    text-align: center;
    width: fit-content;
  }

  @media only screen and (min-width: 576px) and (max-width: 1200px) {
    margin: 0 auto;
    font-size: 30px;
    text-align: left;
    margin-bottom: 20px;
    width: fit-content;
  }
`

const Text = styled.div`
  color: #525266;
  font-family: 'Quicksand', 'Quicksand Regular', sans-serif;
  font-size: 19px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 25px;
  letter-spacing: normal;
  margin: 40px auto;
  margin-top: 10px;

  @media only screen and (max-width: 1200px) {
    text-align: center;
    width: 60%;
    min-width: 400px;
  }

  @media only screen and (max-width: 576px) {
    width: 90vw;
    min-width: 90vw;
  }
`

export const DefaultSeeCarRentalsButton = styled.button`
  width: 310px;
  height: 50px;
  border-radius: 12px;
  background-image: linear-gradient(to left, #ff9d4a, #ff7d0e);
  border: none;
  color: white;
  font-size: 18px;
  -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  font-family: 'Quicksand', 'Quicksand Regular', sans-serif !important;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
`

const DesktopSeeCarRentalsButton = styled(DefaultSeeCarRentalsButton)`
  @media only screen and (max-width: 1200px) {
    display: none;
  }
`

const Img = styled.img`
  display: block;
  height: 400px;

  @media only screen and (max-width: 576px) {
    height: 200px;
  }

  @media only screen and (min-width: 576px) and (max-width: 1200px) {
    height: 250px;
  }
`

function SkipTheLine(props) {
  const { italy, loc, scrollToTop, spring } = props
  const italyButton = 'Cerca in Italia'
  const italyContent = 'Salta la coda e mettiti in viaggio'
  const italySubContent =
    'Il vostro tempo è prezioso per noi. Quindi perché aspettare in coda? Acquistate il nostro pacchetto Skip The Line a un prezzo esclusivo e mettetevi in viaggio.'
  return (
    <SkipTheLineContainer>
      <TextWrapper>
        <Title>{italy ? italyContent : 'Skip The Line, Get Travelling'}</Title>
        <Text>
          {spring
            ? 'Your time is valuable to us. So why wait in the line? Get our Skip the Line package for an exclusive price and get traveling.'
            : italy
            ? italySubContent
            : `Your time is valuable to us. So why wait in the line? Get our Skip The Line Package for an exclusive price in ${loc} and get travelling.`}
        </Text>
        <DesktopSeeCarRentalsButton onClick={scrollToTop}>
          {spring ? 'See Rental Cars' : italy ? italyButton : `See Rental Cars In ${loc}`}
        </DesktopSeeCarRentalsButton>
      </TextWrapper>
      <Img src={SkipTheLineImg} height='400px' width='auto' alt='passangers skip the line' />
    </SkipTheLineContainer>
  )
}

export default SkipTheLine
