import React from 'react'
import styled from 'styled-components'
import { themeGet } from 'styled-system'
import loadable from '@loadable/component'
import { El } from '../../components/layout'

const ReactShareLoader = loadable.lib(() => import('react-share'), {
  ssr: false
})

const SocialShare = styled(({ url, size = 50, ...props }) => (
  <El {...props}>
    <ReactShareLoader fallback={null}>
      {({
        LinkedinShareButton,
        LinkedinIcon,
        PinterestShareButton,
        PinterestIcon,
        TumblrShareButton,
        TumblrIcon,
        WhatsappShareButton,
        WhatsappIcon
      }) => (
        <>
          <LinkedinShareButton url={url}>
            <LinkedinIcon size={size} round={true} className='icon' />
          </LinkedinShareButton>
          <PinterestShareButton url={url} media={url}>
            <PinterestIcon size={size} round={true} className='icon' />
          </PinterestShareButton>
          <TumblrShareButton url={url}>
            <TumblrIcon size={size} round={true} className='icon' />
          </TumblrShareButton>
          <WhatsappShareButton url={url}>
            <WhatsappIcon size={size} round={true} className='icon' />
          </WhatsappShareButton>
        </>
      )}
    </ReactShareLoader>
  </El>
))`
  display: flex;
  justify-content: center;
  padding: 30px 0;
  .icon {
    padding: 3px;
    @media (min-width: ${themeGet('breakpoints.0')}px) {
      padding: 6px;
    }
    opacity: 0.8;
    transition: opacity 100ms ease-in-out;
    &:hover,
    &:active {
      opacity: 1;
      cursor: pointer;
    }
  }
`

export default SocialShare
