import React from 'react'
import {
  Box,
  Grid,
  Container,
  Text,
  BackButton,
  Header,
  Button,
  LocationSelectDesktop,
  DateFieldDesktop
} from '../styles'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import util from '../../../util'
import IconLocation from '../assets/icon-location.svg'
import { useSelector, useDispatch } from 'react-redux'
import useModifyReservation from '../hooks/useModifyReservation'
import useModificationInfo from '../hooks/useModificationInfo'
import { CheckboxUI } from '../../../UI/Checkbox'
import {
  toggleSameLocation,
  updateSearchDateRange,
  setSearchPickupTime,
  setSearchDropOffTime
} from '../../../redux/slices'
import IconUnchecked from '../assets/icon-unchecked.svg'
import IconChecked from '../assets/icon-checked.svg'
import { setModificationArgs, setModificationResponse } from '../../../redux/slices/modificationSlice'
import handleSearchResponse from '../searchResponseHandler'
import { LoadingScreen } from '../../CarListing/LoadingScreen'
import FailedModificationModal from '../modals/FailedModificationModal'

export default function LocationDesktop({ rentalProduct, dtl }) {
  const { t: TRANSLATOR, i18n } = useTranslation()
  const history = useHistory()
  const { pickupDateStr, pickupTime, dropOffDateStr, dropOffTime, isSameLocation, pickupLocation, dropOffLocation } =
    useSelector((state) => state.searchArgs)
  const { reservationId, type, carSearchArgs } = useModificationInfo()
  const modifyReservation = useModifyReservation()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = React.useState(false)
  const [open, setOpen] = React.useState(false)

  function buildDateTime(date, time) {
    const newDate = new Date(date)

    newDate.setHours(time.hour)
    newDate.setMinutes(time.min)

    return util.formatDateForAPI(newDate)
  }

  // TODO: pass this from parent
  function handleClick() {
    setIsLoading(true)
    const newPickupDateTime = buildDateTime(pickupDateStr, pickupTime)
    const newDropOffDateTime = buildDateTime(dropOffDateStr, dropOffTime)

    const newCarSearchArgs = {
      ...carSearchArgs,
      pickupDateTime: newPickupDateTime,
      dropOffDateTime: newDropOffDateTime,
      pickupLocation: pickupLocation.value,
      dropOffLocation: isSameLocation ? pickupLocation.value : dropOffLocation.value
    }

    dispatch(
      setModificationArgs({
        carSearchArgs: newCarSearchArgs
      })
    )

    modifyReservation.mutate(
      { reservationId, type, carSearchArgs: newCarSearchArgs },
      {
        onSuccess: (data) => {
          dispatch(setModificationResponse(data))
          handleSearchResponse(data, type, () => setOpen(true), history, dispatch, setModificationArgs)
          if (!!!data) setIsLoading(false)
        },
        onError: () => {
          setIsLoading(false)
          setOpen(true)
        }
      }
    )
  }

  if (isLoading) return <LoadingScreen />

  const headerTranslationKey = type === 'GENERAL_CHANGE' ? 'location' : 'vehicle'

  return (
    <>
      <Container>
        <BackButton onClick={() => history.go(-1)}>{TRANSLATOR('reservations:reserved:back')}</BackButton>
        <Box bgcolor='white' p={6} borderRadius={12} border='1px solid #DFDFE7'>
          <Header>{TRANSLATOR(`reservations:modification:options:${headerTranslationKey}`)}</Header>
          <Text mb={1.5} pl={3}>
            {TRANSLATOR('reservations:modification:currentReservation')}
          </Text>
          <Box px={3}>
            <Grid container wrap='nowrap' style={{ gap: 48 }}>
              <Grid item xs={6} md={4}>
                <Box>
                  <img src={rentalProduct.supplierUrl} alt='supplier' width='33%' style={{ maxHeight: 40 }} />
                </Box>
                <Box textAlign='center'>
                  <img src={rentalProduct.imageUrl} alt='car' width='100%' style={{ maxWidth: 300 }} />
                </Box>
              </Grid>
              <Grid item xs={6} md={8}>
                <Text fontSize={24} mb={2.5}>
                  {rentalProduct.name}
                </Text>
                <Box borderBottom='1px solid #DFDFE7' borderTop='1px solid #DFDFE7' py={2.5}>
                  <Grid container justifyContent='space-between'>
                    <Grid item>
                      <Text icon={IconLocation}>{TRANSLATOR('homePage:pickUp')}</Text>
                      <Text fontWeight={400} ml={3.5}>
                        {dtl.pickup.address}
                      </Text>
                      <Text fontSize={17} fontWeight={400} ml={3.5}>
                        {util.formatDate(dtl.pickup.dateTime, i18n.resolvedLanguage)}
                      </Text>
                    </Grid>
                    <Grid item>
                      <Text icon={IconLocation}>{TRANSLATOR('homePage:dropOff')}</Text>
                      <Text fontWeight={400} ml={3.5}>
                        {dtl.dropOff.address}
                      </Text>
                      <Text fontSize={17} fontWeight={400} ml={3.5}>
                        {util.formatDate(dtl.dropOff.dateTime, i18n.resolvedLanguage)}
                      </Text>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Text fontWeight={600} mt={4} mb={2}>
            {TRANSLATOR('reservations:modification:newPickupDateTime')}
          </Text>
          <Box border='1px solid var(--borderGrey)' borderRadius={12} p={3}>
            <Grid container wrap='nowrap' id='overflow-auto' style={{ gap: 12 }}>
              <Grid container direction='column' item xs={6} wrap='nowrap' style={{ gap: 12 }}>
                <LocationSelectDesktop
                  type='pickUp'
                  label={TRANSLATOR(`homePage:${isSameLocation ? 'pickUpDropOffLocation' : 'pickUpLocation'}`)}
                />
                {!isSameLocation && (
                  <LocationSelectDesktop type='dropOff' label={TRANSLATOR('homePage:dropOffLocation')} />
                )}
                <CheckboxUI
                  iconChecked={IconChecked}
                  iconUnchecked={IconUnchecked}
                  label={TRANSLATOR('homePage:dropOffAtTheSameLocation')}
                  checked={isSameLocation}
                  onChange={() => dispatch(toggleSameLocation())}
                />
              </Grid>
              <Grid item xs={6}>
                <DateFieldDesktop
                  label={TRANSLATOR('homePage:pickUpDropOffTime')}
                  startDate={new Date(pickupDateStr)}
                  endDate={new Date(dropOffDateStr)}
                  onChangeDateRange={(value) => dispatch(updateSearchDateRange(value))}
                  hasTime
                  setSearchPickupTime={(time) => dispatch(setSearchPickupTime(time))}
                  setSearchDropOffTime={(time) => dispatch(setSearchDropOffTime(time))}
                  pickupTime={pickupTime}
                  dropOffTime={dropOffTime}
                />
                <Button onClick={handleClick}>{TRANSLATOR('homePage:searchCars')}</Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
      <FailedModificationModal open={open} onClick={() => history.replace('/contact')} />
    </>
  )
}
