import React from 'react'
import { makeStyles, Tooltip } from '@material-ui/core'

const useStyles = makeStyles({
  tooltipArrow: {
    fontSize: 15,
    fontWeight: 500,
    backgroundColor: '#404040'
  },
  arrow: {
    color: '#404040'
  }
})

export function TooltipUI(props) {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)

  if (!props.title) return props.children

  return (
    <Tooltip
      open={open}
      placement='top'
      arrow
      disableTouchListener
      disableFocusListener
      disableHoverListener
      classes={{ tooltipArrow: classes.tooltipArrow, arrow: classes.arrow }}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      {...props}
    />
  )
}
