import { useQuery } from 'react-query'
import { fetchArticles } from './../sanity'

export function useArticles(lang = 'en') {
  const { data = {}, isLoading, error } = useQuery(['sanity-articles'], () => fetchArticles())

  return {
    data: Object.keys(data).map((slug) => data[slug][lang]),
    isLoading,
    error
  }
}
