import React from 'react'
import { Dialog } from '@material-ui/core'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { CircularProgress } from '@material-ui/core'
import { Text } from '../styles'

const ModalContainer = styled.div`
  display: flex;
  padding: 48px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 18px;
  border-radius: 12px;
  border: 1px solid #dfdfe7;
  background: var(--White, #fff);
`

export default function CompletingModification({ open }) {
  const { t: TRANSLATOR } = useTranslation()
  return (
    <Dialog PaperComponent={ModalContainer} open={open}>
      <Text mb={4} fontSize={28}>
        {TRANSLATOR('xo2:reservationLoadingMessage')}
      </Text>
      <CircularProgress style={{ margin: 'auto', color: '#FF7D0E' }} size={80} />
    </Dialog>
  )
}
