import React from 'react'
import { Box, Grid, Text } from '../styles'
import { useTranslation } from 'react-i18next'
import util from '../../../util'
import IconSeat from '../../../util/images/icon-seat.svg'
import IconInsurance from '../../../util/images/icon-insurance.svg'
import IconLocation from '../assets/icon-location.svg'
import IconManual from '../../../util/images/icon-manual.svg'
import IconAutomatic from '../../../util/images/icon-automatic.svg'

export default function CurrentBookingCard({ rentalProduct, dtl }) {
  const { t: TRANSLATOR } = useTranslation()

  const transmissionIcon = util.getTransmission(rentalProduct.classCode) === 'M' ? IconManual : IconAutomatic
  const trasmissionTranslationKey = util.getTransmission(rentalProduct.classCode) === 'M' ? 'manual' : 'automatic'

  return (
    <>
      <Box>
        <Grid container item wrap='nowrap' style={{ gap: 16 }}>
          <Grid item>
            <img src={rentalProduct.imageUrl} alt='car' width={176} height={'auto'} />
          </Grid>
          <Grid container item xs={7} wrap='nowrap' direction='column' justifyContent='center'>
            <Grid item>
              <Text fontSize={14} fontWeight={600}>
                {rentalProduct.typeDescription}
              </Text>
              <Text fontSize={14} fontWeight={700}>
                {rentalProduct.name}
              </Text>
            </Grid>
            <Grid item>
              <img src={rentalProduct.supplierUrl} alt='logo' height={24} width='auto' />
            </Grid>
          </Grid>
        </Grid>
        <Grid container style={{ gap: 12, marginTop: 8 }}>
          <Text icon={transmissionIcon} fontSize={14} fontWeight={400}>
            {rentalProduct.transmissionDescription ||
              TRANSLATOR(`reservations:modification:${trasmissionTranslationKey}`)}
          </Text>
          <Text icon={IconSeat} fontSize={14} fontWeight={400}>
            {rentalProduct.seatCount}
          </Text>
          {rentalProduct.insured && (
            <Text icon={IconInsurance} iconsize={20} fontSize={14} fontWeight={400}>
              {TRANSLATOR('carListing:insurance')}
            </Text>
          )}
        </Grid>
      </Box>
      <Box mt={1}>
        <Text fontSize={14} fontWeight={700}>
          {TRANSLATOR('homePage:pickUp')}
        </Text>
        <Text icon={IconLocation} fontSize={14} fontWeight={400}>
          {dtl.pickup.address}
        </Text>
        <Text fontSize={14} fontWeight={400} ml={3}>
          {util.formatDate(dtl.pickup.dateTime)}
        </Text>
        <Text fontSize={14} fontWeight={700} mt={2}>
          {TRANSLATOR('homePage:dropOff')}
        </Text>
        <Text icon={IconLocation} fontSize={14} fontWeight={400}>
          {dtl.dropOff.address}
        </Text>
        <Text fontSize={14} fontWeight={400} ml={3} mb={2}>
          {util.formatDate(dtl.dropOff.dateTime)}
        </Text>
      </Box>
    </>
  )
}
