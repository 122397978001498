import React from 'react'
import ReactDOM from 'react-dom'
import { loadableReady } from '@loadable/component'
import { BrowserRouter } from 'react-router-dom'
import { AppContainer } from 'react-hot-loader'
import styled, { ThemeProvider } from 'styled-components'
import { themeGet } from 'styled-system'
import { GlobalStyle, theme } from './styles'
import App from './App'
import util from './util'
import * as serviceWorker from './serviceWorker'
import { El, FlexEl } from './components/layout'
import Modal from './components/Modal'
import './i18n'

let Root = (
  <AppContainer>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </AppContainer>
)

const GoogleTranslateContainer = styled(FlexEl)`
  justify-content: center;
  margin-top: 20vh;
  padding: 25px;
  text-align: center;
  font-size: 36px;
  @media (min-width: ${themeGet('breakpoints.1')}px) {
    margin-top: 0;
  }
`

if (util.isGoogleTranslate()) {
  Root = (
    <El>
      <ThemeProvider theme={theme}>
        <El>
          <GlobalStyle />
          <Modal isOpen={true}>
            <GoogleTranslateContainer>
              Google Translate is not supported by Carla for now. Localization is coming soon.
            </GoogleTranslateContainer>
          </Modal>
        </El>
      </ThemeProvider>
    </El>
  )
}

loadableReady(() => {
  ReactDOM.hydrate(Root, document.getElementById('root'))
})

// hot module replacement
if (module.hot) {
  module.hot.accept('./App.js', () => {
    const NextApp = require('./App.js').default
    ReactDOM.hydrate(
      <AppContainer>
        <BrowserRouter>
          <NextApp />
        </BrowserRouter>
      </AppContainer>,
      document.getElementById('root')
    )
  })
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
