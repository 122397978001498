import React from 'react'
import { useSelector } from 'react-redux'
import { Text, Status, StatusWrapper } from './styles'
import IconShield from './assets/icon-shield.svg'
import IconShieldFilled from './assets/icon-shield-filled.svg'
import { useTranslation } from 'react-i18next'
import ProtectionInfoModal from './modals/ProtectionInfoModal'

function getProtectionTier(ancillaries, selectedAncillaries) {
  const tierMap = {
    FullProtection: 2,
    ExtendedProtection: 3,
    PremiumProtection: 4,
    RoadsideAssistance: 1
  }

  const selectedItems = ancillaries.filter((item) => selectedAncillaries.includes(item.id))
  const insurance = selectedItems.find((item) => item.type === 'Insurance')
  const roadsideAssistance = selectedItems.find((item) => item.type === 'RoadsideAssistance')

  const ancillaryTier = !!roadsideAssistance ? tierMap[roadsideAssistance.type] : 0
  const insuranceTier = !!insurance ? tierMap[insurance.info.title] : 0
  const tier = ancillaryTier + insuranceTier

  switch (tier) {
    case 0:
      return [0, 'critical']
    case 1:
      return [1, 'notProtected']
    case 2:
      return [2, 'protected']
    case 3:
      return [3, 'protected']
    case 4:
      return [4, 'wellProtected']
    default:
      return [5, 'entirelyProtected']
  }
}

export default function ProtectionStatusCard({ validateInsuranceList }) {
  const { ancillaryProducts, selectedAncillaries } = useSelector((state) => state.ancillary)
  const [open, setOpen] = React.useState(false)
  const { t: TRANSLATOR } = useTranslation()

  if (!ancillaryProducts.data) return null

  const { priceText } = ancillaryProducts.data[0]

  const [tier, tierText] = getProtectionTier(ancillaryProducts.data, selectedAncillaries)
  const shieldFilled = new Array(tier).fill(IconShieldFilled)
  const shield = new Array(5 - tier).fill(IconShield)

  return (
    <StatusWrapper>
      <Text>{TRANSLATOR('xo1:protectionStatus')}:</Text>
      <Status $status={tier}>
        <p>{TRANSLATOR(`xo1:${tierText}`)}</p>
        {tier < 2 && <button type='button' onClick={() => setOpen(true)} />}
        <div>
          {shieldFilled.map((shield, index) => (
            <img src={shield} alt='shield' key={index} />
          ))}
          {shield.map((shield, index) => (
            <img src={shield} alt='shield' key={index} />
          ))}
        </div>
      </Status>
      <ProtectionInfoModal
        open={open}
        onClose={() => setOpen(false)}
        selectedAncillaries={selectedAncillaries}
        insuranceId={ancillaryProducts.data[0]?.id}
        validateInsuranceList={validateInsuranceList}
        price={priceText?.split(' ')[1]?.toLowerCase()}
      />
    </StatusWrapper>
  )
}
