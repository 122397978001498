import { arrayOf, number, shape, string } from 'prop-types'

const InsuranceInclusion = shape({
  title: string.isRequired,
  content: string
})

const InsurancePolicy = shape({
  name: string,
  code: string,
  pdsUrl: string,
  inclusionList: arrayOf(InsuranceInclusion)
})

const InsuranceQuote = shape({
  broker: string,
  reference: string,
  totalAmount: number,
  insuranceCoverAmount: number,
  dailyAmountFormatted: string,
  disclaimer: string,
  poweredByLogo: string,
  policy: InsurancePolicy
})

export const Insurance = {
  InsuranceInclusion,
  InsuranceQuote,
  InsurancePolicy
}
