import * as React from 'react'
import './nearme.css'
import iconNearme from '../../util/images/icons/iconNearme.svg'

function FindCarsNearMe({ onClick, ...props }) {
  return (
    <div className='find-cars-near-me' onClick={onClick}>
      <img src={iconNearme} alt='rent_carla_near_me_logo' />
      <p>Find cars near me</p>
    </div>
  )
}

export default FindCarsNearMe
