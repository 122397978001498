import React from 'react'
import { MobileScreen, OverMobileScreen } from '../../common/Screens'
import DateTimeMobile from './DateTimeMobile'
import DateTimeDesktop from './DateTimeDesktop'
import useReservationInfo from '../hooks/useReservationInfo'
import useModificationType from '../hooks/useModificationType'
import { useDispatch } from 'react-redux'
import {
  updateSearchDateRange,
  setSearchPickupTime,
  setSearchDropOffTime,
  setSearchPickupLocation,
  setSearchDropOffLocation
} from '../../../redux/slices'
import { setModificationArgs } from '../../../redux/slices/modificationSlice'
import util from '../../../util'

export default function ModifyDateTime() {
  const { response } = useReservationInfo()
  const dispatch = useDispatch()
  const type = useModificationType()

  if (!!!response) return null

  // TODO: Decouple search args and date picker
  const { rentalProduct, dtl, placePair, reservationId, userInfo } = response
  const startDate = util.getDateFromAPI(dtl.pickup.dateTime)
  const endDate = util.getDateFromAPI(dtl.dropOff.dateTime)
  dispatch(updateSearchDateRange({ startDate, endDate }))
  dispatch(setSearchPickupTime({ hour: startDate.getHours(), min: startDate.getMinutes() }))
  dispatch(setSearchDropOffTime({ hour: endDate.getHours(), min: endDate.getMinutes() }))
  dispatch(
    setSearchPickupLocation({
      value: placePair.pickup.id,
      label: placePair.pickup.prettyName,
      type: placePair.pickup.type
    })
  )
  dispatch(
    setSearchDropOffLocation({
      value: placePair.dropOff.id,
      label: placePair.dropOff.prettyName,
      type: placePair.dropOff.type
    })
  )
  dispatch(
    setModificationArgs({
      reservationId,
      userInfo,
      type,
      carSearchArgs: {
        pickupDateTime: dtl.pickup.dateTime,
        dropOffDateTime: dtl.dropOff.dateTime,
        pickupLocation: placePair.pickup.id,
        dropOffLocation: placePair.dropOff.id
      }
    })
  )

  return (
    <>
      <MobileScreen>
        <DateTimeMobile rentalProduct={rentalProduct} dtl={dtl} />
      </MobileScreen>
      <OverMobileScreen>
        <DateTimeDesktop />
      </OverMobileScreen>
    </>
  )
}
