import styled from 'styled-components'

export const SortDropdownContainer = styled('div')`
  position: relative;
  width: 100%;
  cursor: pointer;

  .sort-container-controller {
    display: flex;
    justify-content: space-between;

    height: 100%;
    background-color: white;
    border-radius: 8px;
    border: 1px solid var(--borderGrey);
    box-sizing: border-box;
    padding: 9px 8px;
  }

  .sort-container-left {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .sort-container-header {
    font-size: 14px;
    color: var(--textGrey);
  }

  .sort-container-main {
    font-size: 16px;
    font-weight: 500;
    color: var(--textBlack);
  }

  .sort-container-right {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .sort-container-arrow-icon {
    transition: transform 0.2s;
    height: 20px;
    width: 20px;
  }

  @media screen and (max-width: 1200px) {
    width: 48.8%;
    .sort-container-controller {
      padding-right: 24px;
      padding-left: 16px;
      border-radius: 12px;
    }
  }
  @media screen and (max-width: 576px) {
    width: 50%;
    .sort-container-controller {
      border-radius: 0;
      min-height: 44px;
      height: 100%;
      padding: 4px 8px;
    }

    .sort-container-main {
      font-size: 14px;
    }
  }

  @media screen and (max-width: 360px) {
    .sort-container-main {
      font-size: 3.64vw;
    }
  }
`
export const SortDropdownOption = styled('div')`
  box-sizing: border-box;
  padding: 12px;
  &:hover {
    background-color: #eff1f8;
  }
`
export const SortDropdownPopup = styled('div')`
  position: absolute;
  margin-top: 8px;
  z-index: 100;
  width: 100%;
  background-color: white;
  border-radius: 12px;
  border: solid 1px var(--borderGrey);
  box-shadow: 0 0 6px 2px rgba(111, 112, 115, 0.2);

  max-height: 200px;
  overflow: hidden;
  opacity: 1;
  transition: 0.2s;
`
