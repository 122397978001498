import styled from 'styled-components'
import { El, FlexEl, BlueGreyLink } from '../layout'
import { ModalType } from '../../routes/XO1'
import React from 'react'
import { themeGet } from 'styled-system'
import { arrayOf } from 'prop-types'
import { CarlinPropTypes } from '../../entities'
import { FadeModal } from '../Modal'
import { InsuranceInfoModal } from './modals/InsuranceInfoModal'
import Check from '../../util/images/icon-check.svg'
import ProtectionPremium from '../../util/images/icon-premium-protection.svg'
import ProtectionExtended from '../../util/images/icon-extended-protection.svg'
import ProtectionFull from '../../util/images/icon-full-protection.svg'
import IconInfoProtection from '../../util/images/icon-info-protection.svg'
import money from '../../util/money'
import { useTranslation } from 'react-i18next'

export const InsuranceSelectorInner = styled(
  ({ ancillaryProducts, selectedInsurance, deselectedInsurance, currentSelections, ...props }) => {
    const ins1 = ancillaryProducts[1]
    const ins2 = ancillaryProducts[2]
    const bigInsurance = ancillaryProducts[0]

    function selectImageSource(ancillaryProduct) {
      switch (ancillaryProduct?.info?.title) {
        case 'PremiumProtection':
          return ProtectionPremium
        case 'ExtendedProtection':
          return ProtectionExtended
        case 'FullProtection':
          return ProtectionFull
        default:
          return ProtectionFull
      }
    }

    return (
      <FlexEl {...props}>
        <InsuranceSelectorItem
          ancillaryProduct={ins1}
          isMostPopular={false}
          currentSelections={currentSelections}
          selectedInsurance={selectedInsurance}
          deselectedInsurance={deselectedInsurance}
          imgSource={selectImageSource(ins1)}
        ></InsuranceSelectorItem>
        <InsuranceSelectorItem
          ancillaryProduct={bigInsurance}
          isMostPopular={true}
          currentSelections={currentSelections}
          selectedInsurance={selectedInsurance}
          deselectedInsurance={deselectedInsurance}
          imgSource={selectImageSource(bigInsurance)}
        ></InsuranceSelectorItem>
        <InsuranceSelectorItem
          ancillaryProduct={ins2}
          isMostPopular={false}
          currentSelections={currentSelections}
          selectedInsurance={selectedInsurance}
          deselectedInsurance={deselectedInsurance}
          imgSource={selectImageSource(ins2)}
        ></InsuranceSelectorItem>
      </FlexEl>
    )
  }
)`
  @media screen and (max-width: 568px) {
  }
  & > div {
    margin: 0;
    padding: 16px 8px 8px 8px;
  }
  flex-direction: row;
  gap: 4px;
  justify-content: center;
  align-items: end;
  margin-top: 20px;
`

const MostPopularBanner = styled(({ ...props }) => {
  const { t } = useTranslation()
  const style = {
    background: 'linear-gradient(143deg, #FFAB64 -1.15%, #FF7D0E 100%)',
    borderRadius: '8px 8px 0 0',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontSize: '12px',
    lineHeight: '12px',
    fontWeight: '500',
    marginTop: '-36px',
    position: 'absolute',
    left: '0',
    width: 'calc(100% + 2px)',
    padding: '4px 0',
    marginLeft: '-1px'
  }
  return (
    <El style={style} color='white'>
      {t('xo1:mostPopular')}
    </El>
  )
})``

const InsuranceSelectorItem = styled(
  ({ ancillaryProduct, isMostPopular, selectedInsurance, deselectedInsurance, currentSelections, ...props }) => {
    const { t } = useTranslation()
    let selected = currentSelections.has(ancillaryProduct.id)
    const borderColor = selected ? '#FF7D0E' : '#DFDFE7'
    const bgColor = selected ? '#FFF9F5' : 'linear-gradient(180deg, #FFF 0%, #FAFAFA 100%)'
    const style = {
      background: bgColor,
      flexDirection: 'column',
      borderRadius: `4px`,
      textAlign: 'center',
      border: `solid 1px ${borderColor}`
    }

    return (
      <FlexEl
        style={style}
        onClick={(e) => (selected ? deselectedInsurance(ancillaryProduct.id) : selectedInsurance(ancillaryProduct.id))}
        {...props}
      >
        {isMostPopular && <MostPopularBanner className='banner'></MostPopularBanner>}
        <El className='txt'>
          <img src={props.imgSource} alt='protection' />
          <El className='title'>{ancillaryProduct.title}</El>
          <El className='price'>{money.parsePriceText(ancillaryProduct.priceText)}</El>
          {ancillaryProduct.discountInfoText && (
            <El className='discount'>{money.parseDiscount(ancillaryProduct.priceText)}</El>
          )}
          <El className={selected ? 'selected' : 'choose'}>
            {selected ? (
              <>
                {t('xo1:selected')} <img src={Check} alt='check' />
              </>
            ) : (
              <>{t('xo1:choose')}</>
            )}
          </El>
        </El>
      </FlexEl>
    )
  }
)`
  position: relative;
  flex: 1 1 0;
  margin-top: 16px;

  .choose {
    padding: 6px 8px;
    width: 100%;
    border-radius: 999px;
    border: 1px solid #ff7d0e;
    color: #ff7d0e;
    font-size: 13px;
    line-height: 100%;
    font-weight: 500;
    margin-top: 12px;
  }

  .selected {
    width: 100%;
    border-radius: 999px;
    background: #ff7d0e;
    border: 1px solid transparent;
    color: white;
    font-size: 13px;
    line-height: 100%;
    font-weight: 500;
    padding: 6px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    margin-top: 12px;

    & img {
      object-fit: none;
    }
  }

  &:hover {
    cursor: pointer;
  }

  .title {
    color: #656679;
    font-family: Quicksand;
    font-size: 14px;
    font-weight: 700;
    line-height: normal;
    margin-top: 8px;
    width: 95px;
    margin-left: auto;
    margin-right: auto;
  }

  .price {
    color: #525266;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    margin-top: 4px;
  }

  .discount {
    color: #fe6f00;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    margin-top: 2px;
  }
`

const selectedMainInsuranceProduct = (ancillaryProducts, selectedAncillaryIds) => {
  for (const ap of ancillaryProducts) {
    if (selectedAncillaryIds.has(ap.id)) return ap
  }
  return null
}

const defaultMainInsuranceProduct = (ancillaryProducts) => {
  for (const ap of ancillaryProducts) {
    if (ap.preSelected) return ap
  }
  return null
}

export const InsuranceSelectorNonSS = styled(
  ({
    insuranceProducts,
    openModal,
    modal,
    closeModal,
    selectedInsurance,
    deselectedInsurance,
    currentSelections,
    ...props
  }) => {
    const { t } = useTranslation()
    const selectedMainInsurance = selectedMainInsuranceProduct(insuranceProducts, currentSelections)
    const deselectedMainInsurance = defaultMainInsuranceProduct(insuranceProducts, currentSelections)
    const mainInsuranceProduct = selectedMainInsurance || deselectedMainInsurance
    const header =
      (selectedMainInsurance && selectedMainInsurance.subtitle) ||
      (deselectedMainInsurance && deselectedMainInsurance.deselectedSubtitle
        ? deselectedMainInsurance.deselectedSubtitle
        : '')

    return (
      <FlexEl {...props}>
        <FlexEl>
          <FlexEl className='insurance-quote-detail' flexDirection='column' flexGrow={1}>
            <FlexEl className='insurance-quote-title' flexDirection='row'>
              <El className='protection'>{t('xo1:protection')}</El>
              <BlueGreyLink onClick={() => openModal(ModalType.INSURANCE_INFO)}>
                <img src={IconInfoProtection} alt='icon_info_protection' className='icon' />
              </BlueGreyLink>
            </FlexEl>
          </FlexEl>
        </FlexEl>
        <InsuranceSelectorInner
          ancillaryProducts={insuranceProducts}
          selectedInsurance={selectedInsurance}
          deselectedInsurance={deselectedInsurance}
          currentSelections={currentSelections}
        ></InsuranceSelectorInner>
        {header && <El className='header'>{header}</El>}
        <FadeModal
          isOpen={modal === ModalType.INSURANCE_INFO}
          closable={true}
          ok={true}
          onClose={closeModal}
          width={750}
        >
          <InsuranceInfoModal ancillaryProducts={insuranceProducts} selectedProduct={mainInsuranceProduct} />
        </FadeModal>
      </FlexEl>
    )
  }
)`
  @media screen and (max-width: 600px) {
    padding: 16px 12px;
  }
  flex-direction: column;
  gap: 16px;
  font-size: 16px;
  color: ${themeGet('colors.textGrey')};
  padding: 16px 24px;
  border-bottom: solid 1px ${themeGet('colors.separatorGrey')};

  .insurance-quote-detail {
    flex-direction: column;
    flex-grow: 1;
    font-weight: normal;

    > .insurance-quote-title {
      font-weight: 500;
    }
  }

  .insurance-quote-title {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #ff7d0e;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }

  .icon {
    object-fit: none;
    display: flex;
    align-items: center;
  }

  .header {
    color: #525266;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    margin-bottom: -16px;
  }

  .checkbox-container {
    margin: auto;
  }

  .insurance-quote-disclaimer {
    font-size: 12px;
    @media (min-width: ${themeGet('breakpoints.0')}px) {
      text-align: left;
    }

    a {
      display: inline-block;
      color: ${themeGet('colors.blueGrey.7')};
      &:hover,
      &:focus {
        color: ${themeGet('colors.textBlack')};
      }
    }
  }
`
InsuranceSelectorNonSS.propTypes = {
  insuranceProducts: arrayOf(CarlinPropTypes.AncillaryProduct).isRequired
}
