import { useQuery } from 'react-query'
import API from '../../services/API'
import { useSelector } from 'react-redux'

export default function useReview() {
  const language = useSelector((state) => state.user.language)

  const { data, isLoading } = useQuery(['loading-review'], () => API.getReviews(language), {
    keepPreviousData: true,
    staleTime: 1000 * 300
  })

  return {
    data,
    isLoading
  }
}
