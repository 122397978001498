import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty, isEqual } from 'lodash'
import IconCancel from '../../util/images/icon-cancel.png'
import IconClick from '../../util/images/icon-click-grey.png'
import { useTranslation } from 'react-i18next'

import { FilterTagsContainer, SelectedFilterTag } from './FilterTags.styles'

function FilterTags({ filterTags = [], setTagFilters, filters, setFilters, clearAllFilters }) {
  const { t } = useTranslation()
  const removeTag = (filterTag) => {
    setTagFilters(filterTags.filter((item) => !isEqual(item, filterTag)))

    setFilters({
      ...filters,
      [filterTag[0]]: filters[filterTag[0]]?.filter((item) => !isEqual(item, filterTag[1]))
    })
  }

  return (
    <FilterTagsContainer>
      {filterTags.map((tag) => (
        <SelectedFilterTag key={tag[1]} data-testid='filter-tag'>
          <span className='filter-tag-text'>{tag[1]}</span>
          <span className='filter-tag-icons'>
            <img className='filter-tag-icon-click' src={IconClick} onClick={() => removeTag(tag)} alt='click' />
            <img
              data-testid='remove-filter-tag'
              className='filter-tag-icon-cancel'
              src={IconCancel}
              onClick={() => removeTag(tag)}
              alt='cancel'
            />
          </span>
        </SelectedFilterTag>
      ))}
      {!isEmpty(filterTags) && (
        <SelectedFilterTag className='clear-all-tag' onClick={clearAllFilters} data-testid='clear-all-filter'>
          <span className='filter-tag-text'>{t('carListing:clearAllFilters')}</span>
          <span className='filter-tag-icons'>
            <img className='filter-tag-icon-cancel-all' src={IconCancel} alt='clear' />
          </span>
        </SelectedFilterTag>
      )}
    </FilterTagsContainer>
  )
}

FilterTags.propTypes = {
  filterTags: PropTypes.arrayOf(PropTypes.array).isRequired,
  setTagFilters: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  clearAllFilters: PropTypes.func.isRequired
}

export default FilterTags
