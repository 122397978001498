import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import { useSelector } from 'react-redux'
import { IoIosCloseCircle } from 'react-icons/io'
import { useTranslation } from 'react-i18next'
import { DialogContentStyled, DialogTitleStyled, CloseButton } from './Instructions.styles'
import { isEmpty } from 'lodash'

function InstructionsDialog({ open, onClose }) {
  const { t: TRANSLATOR } = useTranslation()
  const { pickup, dropOff } = useSelector((state) => state.carRentalProduct.stationPair)

  return (
    <Dialog maxWidth='sm' fullWidth onClose={onClose} aria-labelledby='simple-dialog-title' open={open}>
      <DialogTitleStyled id='simple-dialog-title'>{TRANSLATOR('xo1:instructionsTitle')}</DialogTitleStyled>
      <DialogContentStyled>
        {isEmpty(pickup?.instructions) && isEmpty(dropOff?.instructions) ? (
          <section>
            <p>{TRANSLATOR('xo1:noInstructionMessage')}</p>
          </section>
        ) : (
          <>
            {pickup && (
              <section>
                <h4>{TRANSLATOR('xo1:pickUpLocations')}</h4>
                <p>{pickup?.instructions}</p>
              </section>
            )}
            {dropOff && (
              <section>
                <h4>{TRANSLATOR('xo1:dropOffLocations')}</h4>
                <p>{dropOff?.instructions}</p>
              </section>
            )}
          </>
        )}
      </DialogContentStyled>
      <CloseButton onClick={onClose}>
        <IoIosCloseCircle />
      </CloseButton>
    </Dialog>
  )
}

InstructionsDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
}

export default InstructionsDialog
