import React, { useState } from 'react'
import { Container, RadioGroup, Box } from '@material-ui/core'
import {
  StyledRadio,
  StyledFormControlLabel,
  StyledGrid,
  Text,
  Image,
  useStyles,
  ConfirmButton,
  StyledButtonFilled
} from './styles'
import IconCheck from './assets/icon-check.svg'
import { DesktopContainer } from './HelperComponents'
import { useFormik } from 'formik'
import API from '../../services/API'
import ReactLoading from 'react-loading'
import { useTranslation } from 'react-i18next'
import GA from '../../services/GA'
import { FreeCancelLabel } from '../XO1/CarDetailsCard'
import { MdCheck as CheckIcon } from 'react-icons/md'
import { useSelector, useDispatch } from 'react-redux'
import { setCancelOption } from '../../redux/slices'
import { useHistory } from 'react-router-dom'
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min'
import { useBreakpoint, MobileScreen, OverMobileScreen } from '../common/Screens'

function Terms({ terms }) {
  const { t: TRANSLATOR } = useTranslation()

  if (terms.includes('less than'))
    return (
      <Text mt={3} whiteSpace='pre-line'>
        {terms}
      </Text>
    )
  // Following 3 lines are like this because of the response.
  const lines = terms?.split('\n\n')
  const refundDetails = lines?.slice(0, -2)
  const info = lines?.slice(-2)

  return (
    <StyledGrid container gap={1.5} direction='column'>
      <Text mt={3}>{TRANSLATOR('reservations:options:refund')}:</Text>
      {refundDetails?.map((item, index) => (
        <StyledGrid container key={index} wrap='nowrap' alignItems='flex-start' gap={1.5}>
          <Image src={IconCheck} alt='icon-check' />
          <Text fontWeight={500}>{item}</Text>
        </StyledGrid>
      ))}
      {info.map((item, index) => (
        <Text fontWeight={500} key={index}>
          {item}
        </Text>
      ))}
    </StyledGrid>
  )
}

function CarlaCash({ creditTerms }) {
  return (
    <StyledGrid container direction='column'>
      <Text mt={3}>{creditTerms.header}</Text>
      <ul style={{ listStylePosition: 'outside', paddingLeft: 18 }}>
        {creditTerms.bulletPoints.map((item, index) => (
          <li key={index}>
            <Text fontWeight={500}>{item}</Text>
          </li>
        ))}
      </ul>
      <Text>{creditTerms.footer}</Text>
    </StyledGrid>
  )
}

function Options(props) {
  const history = useHistory()
  const breakpoint = useBreakpoint(750)
  const dispatch = useDispatch()

  const {
    cancellationTerms: { terms, creditTerms, message }
  } = props

  const { label } = useStyles()
  const [loading, setLoading] = useState(false)
  const { t: TRANSLATOR, i18n } = useTranslation()
  const formik = useFormik({
    initialValues: {
      selectedOption: creditTerms ? 'carla' : 'no-carla'
    },
    onSubmit: (values) => {
      GA.submitCompleteCancellation()
      const useCredit = values.selectedOption === 'carla'

      const {
        reservationId,
        userInfo: { email }
      } = props.response

      setLoading(true)
      API.confirmCancellation(reservationId, email, useCredit, i18n.resolvedLanguage)
        .then(() => {
          dispatch(setCancelOption(values.selectedOption))
          history.replace('/my-reservations/cancellation/result')
        })
        .catch((err) => console.error(err))
      return null
    }
  })

  if (message) return <Redirect to={{ pathname: '/my-reservations' }} />

  function handleChange(event) {
    formik.setFieldValue('selectedOption', event.target.value)
  }

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <RadioGroup
          name='selectedOption'
          value={formik.values.selectedOption}
          onChange={handleChange}
          style={{ marginBottom: breakpoint && 60 }}
        >
          <StyledGrid
            container
            gap={breakpoint ? 1.5 : 6}
            wrap={breakpoint ? 'wrap' : 'nowrap'}
            justifyContent='center'
          >
            {creditTerms && (
              <StyledGrid
                item
                bgcolor='white'
                p={2.5}
                borderRadius={12}
                border='1px solid #DFDFE7'
                flex={breakpoint ? '1 1 50%' : '0 1 50%'}
              >
                <StyledGrid container justifyContent='space-between' gap={1.5}>
                  <StyledFormControlLabel
                    value='carla'
                    control={<StyledRadio />}
                    label={TRANSLATOR('reservations:options:carlaCash')}
                    className={label}
                  />
                  <FreeCancelLabel $color='#035830' $bgColor='#D5F8E8' style={{ flex: '0 1 194px' }}>
                    <CheckIcon style={{ color: '#035830', fontSize: '16px' }} />
                    {TRANSLATOR('xo1:freeCancel')}
                  </FreeCancelLabel>
                </StyledGrid>
                <CarlaCash creditTerms={creditTerms} />
              </StyledGrid>
            )}
            {terms && (
              <StyledGrid
                item
                bgcolor='white'
                p={2.5}
                borderRadius={12}
                border='1px solid #DFDFE7'
                flex={breakpoint ? '1 1 50%' : '0 1 50%'}
              >
                <StyledFormControlLabel
                  value='no-carla'
                  control={<StyledRadio />}
                  label={TRANSLATOR('reservations:form:cancel')}
                  className={label}
                />
                <Terms terms={terms} />
              </StyledGrid>
            )}
          </StyledGrid>
          {!breakpoint && (
            <ConfirmButton type='submit' alignSelf='flex-end'>
              {loading ? (
                <ReactLoading type={'spin'} height={24} width={24} color='white' />
              ) : (
                TRANSLATOR('reservations:options:confirm')
              )}
            </ConfirmButton>
          )}
          {breakpoint && (
            <Box
              position='fixed'
              bottom={0}
              width='100%'
              bgcolor='white'
              py={1.25}
              px={2}
              display='flex'
              marginLeft={-2.5}
            >
              <StyledButtonFilled type='submit'>
                {loading ? (
                  <ReactLoading type={'spin'} height={24} width={24} color='white' />
                ) : (
                  TRANSLATOR('reservations:options:confirm')
                )}
              </StyledButtonFilled>
            </Box>
          )}
        </RadioGroup>
      </form>
    </>
  )
}

export function CancellationOptions() {
  const { t: TRANSLATOR } = useTranslation()
  const { cancellationTerms, response } = useSelector((state) => state.reservationInfoRetrieval)

  if (!response) {
    return <Redirect to='/' />
  }

  return (
    <>
      <MobileScreen customBreakpoint={750}>
        <Container disableGutters style={{ padding: 20 }}>
          <Options response={response} cancellationTerms={cancellationTerms} />
        </Container>
      </MobileScreen>
      <OverMobileScreen customBreakpoint={750}>
        <DesktopContainer
          children={[<Options response={response} cancellationTerms={cancellationTerms} />]}
          value=''
          header={TRANSLATOR('reservations:options:cancelOptions')}
        />
      </OverMobileScreen>
    </>
  )
}
