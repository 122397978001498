import React from 'react'
import useModificationInfo from '../hooks/useModificationInfo'
import useCompleteModification from '../hooks/useCompleteModification'
import usePopStateRedirect from '../hooks/usePopStateRedirect'
import { useTranslation } from 'react-i18next'
import {
  SuccessInfoRow,
  SuccessInfo,
  SuccessHeader,
  SuccessRedirect,
  SuccessCarInfo,
  Box,
  Grid,
  Container
} from '../styles'
import IconDriver from '../assets/icon-driver.svg'
import IconConfirm from '../assets/icon-confirm.svg'
import IconPickup from '../assets/icon-pickup.svg'
import IconDropOff from '../assets/icon-dropoff.svg'
import IconSuccess from '../assets/icon-success.svg'
import IconManual from '../../../util/images/icon-manual.svg'
import IconAutomatic from '../../../util/images/icon-automatic.svg'
import { isMobile, isAndroid } from 'react-device-detect' // user agent sniffing
import util from '../../../util'
import PriceBreakdown from './PriceBreakdown'
import CompletingModificationModal from '../modals/CompletingModificationModal'
import FailedModificationModal from '../modals/FailedModificationModal'
import { useHistory } from 'react-router-dom'

export default function ModifySuccess() {
  const modifyArgs = useModificationInfo()
  const { data, isLoading, isError } = useCompleteModification(modifyArgs)
  usePopStateRedirect()
  const history = useHistory()
  const { t: TRANSLATOR, i18n } = useTranslation()

  if (isLoading) return <CompletingModificationModal open={true} />
  if (isError || !!data.message)
    return <FailedModificationModal open={true} onClick={() => history.replace('/contact')} />

  if (!!!modifyArgs.response || !!!data) return null

  const {
    confirmationNo,
    reservation: {
      userInfo,
      dtl,
      carlaPlusDetails,
      rentalProduct: { name, imageUrl, supplierUrl, groupDescription, classCode, seatCount }
    }
  } = data

  const { modificationPriceDifference } = modifyArgs

  const iconTransmission = util.getTransmission(classCode) === 'M' ? IconManual : IconAutomatic

  return (
    <Container style={{ marginBottom: 24 }}>
      <Grid container>
        <Grid item xs={12} md={7}>
          <Box
            bgcolor='white'
            p={{ xs: 2, md: 3 }}
            border='1px solid var(--borderGrey)'
            borderRadius={12}
            my={{ xs: 2, md: 3 }}
            mr={{ md: 3 }}
          >
            <SuccessHeader icon={IconSuccess}>
              <h1>{TRANSLATOR('success:enjoy')}</h1>
              <h2>{TRANSLATOR('reservations:modification:success')}</h2>
            </SuccessHeader>
            <SuccessInfo>
              <SuccessInfoRow icon={IconDriver}>
                <p>{TRANSLATOR('success:driver')}</p>
                <p>
                  {userInfo.firstName} {userInfo.lastName}
                </p>
              </SuccessInfoRow>
              <SuccessInfoRow icon={IconConfirm}>
                <p>{TRANSLATOR('success:confirmNo')}</p>
                <p>{confirmationNo}</p>
              </SuccessInfoRow>
              <SuccessInfoRow icon={IconPickup}>
                <p>{TRANSLATOR('xo2:Pickup')}</p>
                <p>{dtl.pickup.address}</p>
                <p>{util.formatDate(dtl.pickup.dateTime, i18n.resolvedLanguage || 'en')}</p>
              </SuccessInfoRow>
              <SuccessInfoRow icon={IconDropOff}>
                <p>{TRANSLATOR('xo2:Dropoff')}</p>
                <p>{dtl.dropOff.address}</p>
                <p>{util.formatDate(dtl.dropOff.dateTime, i18n.resolvedLanguage || 'en')}</p>
              </SuccessInfoRow>
            </SuccessInfo>
          </Box>
          <Box mr={{ md: 3 }}>
            <SuccessRedirect isMobile={isMobile} isAndroid={isAndroid} lang={i18n.resolvedLanguage} t={TRANSLATOR} />
          </Box>
        </Grid>
        <Grid item xs={12} md={5}>
          <Box
            bgcolor='white'
            p={{ xs: 2, md: 3 }}
            border='1px solid var(--borderGrey)'
            borderRadius={12}
            my={{ xs: 2, md: 3 }}
          >
            <SuccessCarInfo
              name={name}
              imageUrl={imageUrl}
              supplierUrl={supplierUrl}
              groupDescription={groupDescription}
              seatCount={seatCount}
              carlaPlusDetails={carlaPlusDetails}
              iconTransmission={iconTransmission}
            />
          </Box>
          <Box bgcolor='white' p={{ xs: 2, md: 3 }} border='1px solid var(--borderGrey)' borderRadius={12}>
            <PriceBreakdown {...modificationPriceDifference} />
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}
