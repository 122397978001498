import styled from 'styled-components'
import { themeGet } from 'styled-system'
import React, { Component } from 'react'
import util from '../../util'
import { El, FlexEl } from '../layout'
import { ModalHeaderDS, ModalSelectDS } from '../CarlaSelect'
import leftArrow from './left-arrow.svg'
import rightArrow from './right-arrow.svg'
import DayPicker from './../common/DayPicker'

export const DayPickerContainer = styled(DayPicker)`
  background-color: white;
  & *:focus {
    outline: none;
  }
  & .DayPicker-Months {
    @media screen and (min-width: 576px) {
      flex-wrap: nowrap;
    }
  }
  & .DayPicker-Day {
    width: 40px;
    height: 40px;
    font-size: 15px;
    color: ${themeGet('colors.grey')};
    border-radius: 0;
    @media (min-width: ${themeGet('breakpoints.0')}px) {
      width: 32px;
      height: 32px;
      font-size: 13px;
    }
  }

  &.DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    // on empty day hover
    &.DayPicker-Day:not(.DayPicker-Day--selecting):not(.DayPicker-Day--selected):hover {
      border-radius: 50%;
    }
    &.DayPicker-Day:not(.DayPicker-Day--selected):hover {
      color: ${themeGet('colors.colorPrimary')};
      background-color: ${themeGet('colors.newColorPrimary')};
    }

    @media (hover: hover) and (pointer: fine) {
      &.DayPicker-Day--selected:hover {
        color: ${themeGet('colors.colorPrimary')};
        background-color: ${themeGet('colors.blueGrey.1')};
      }
    }

    &.DayPicker-Day--selected {
      color: white;
      background-color: ${themeGet('colors.colorPrimary')};
      font-weight: bold;
    }
    // set from datetime picker
    &.DayPicker-Day--start {
      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;
    }
    &.DayPicker-Day--end {
      border-top-right-radius: 50%;
      border-bottom-right-radius: 50%;
    }
    &.DayPicker-Day--selecting {
      color: ${themeGet('colors.colorPrimary')};
      background-color: ${themeGet('colors.newColorPrimary')};
    }
    // set from date picker
    &.DayPicker-Day--single.DayPicker-Day--selected {
      border-radius: 50%;
    }
  }

  & .DayPicker-Caption {
    text-align: center;
    font-size: 14px;
    font-weight: bold;
  }
  & .DayPicker-NavButton {
    background-size: cover;
    width: 30px;
    height: 30px;
    opacity: 0.6;
    &:hover {
      opacity: 0.8;
    }
    &:focus {
      opacity: 1;
    }
    &.DayPicker-NavButton--next {
      background-image: url(${rightArrow});
      right: 15px;
      top: 10px;
    }
    &.DayPicker-NavButton--prev {
      background-image: url(${leftArrow});
      left: 15px;
      top: 10px;
    }
  }
  & .DayPicker-Day--disabled {
    color: #ddd !important;
    background-color: ${themeGet('colors.none')} !important;
  }
`

const Container = styled(FlexEl)`
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

export default class DatePicker extends Component {
  handleDayClick = (day) => {
    // set fields beyond hour to zero
    util.normalizeDate(day)
    const afterDate = this.props.after || util.normalizeDate(new Date())
    if (day < afterDate) {
      return
    }
    this.props.onChange(day)
  }

  render() {
    // date pickers
    const afterDate = this.props.after || util.normalizeDate(new Date())
    const { label, value, closeMenu } = this.props
    const disabledDays = { before: afterDate }

    return (
      <Container>
        <ModalHeaderDS label={label} closeMenu={closeMenu}>
          <ModalSelectDS type='button' onClick={closeMenu} width={1}>
            {util.formatDateShort(value)}
          </ModalSelectDS>
        </ModalHeaderDS>
        <El
          css={`
            overflow-y: scroll;
          `}
          mt={80}
        >
          <DayPickerContainer
            {...this.props}
            numberOfMonths={6}
            fromMonth={afterDate}
            month={value}
            disabledDays={disabledDays}
            selectedDays={value}
            modifiers={{ single: value }}
            onDayClick={this.handleDayClick}
          />
        </El>
      </Container>
    )
  }
}
