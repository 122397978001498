import styled from 'styled-components'

export const FilterTagsContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  width: 75%;

  @media screen and (max-width: 1200px) {
    width: 100%;
  }
  @media screen and (max-width: 576px) {
    display: none;
  }
`
export const SelectedFilterTag = styled('span')`
  display: inline-block;
  height: fit-content;

  background-color: white;
  border: solid 1px var(--borderGrey);
  border-radius: 22px;
  box-sizing: border-box;
  padding: 7px 6px;
  margin-right: 8px;
  margin-bottom: 8px;

  &.clear-all-tag {
    background-color: var(--borderGrey);
    cursor: pointer;
  }

  .filter-tag-text {
    position: relative;
    bottom: 3px;
    margin: 0 6px;
  }

  .filter-tag-icons {
    display: inline-block;
    position: relative;
    top: 2px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  .filter-tag-icon-click {
    position: absolute;
    width: 20px;
    height: 20px;
    &:hover {
      opacity: 0;
    }
  }

  .filter-tag-icon-cancel {
    position: absolute;
    width: 20px;
    height: 20px;
    opacity: 0;
  }

  .filter-tag-icon-cancel:hover {
    opacity: 1;
  }

  .filter-tag-icon-cancel-all {
    position: absolute;
    width: 20px;
    height: 20px;
  }
`
