import React from 'react'
import { Box, Grid, Text } from '../styles'
import { useTranslation } from 'react-i18next'
import util from '../../../util'

export default function PriceBreakdown({ shouldCharge, totalPayNowDifference, remainderDifference, currency }) {
  const { t: TRANSLATOR } = useTranslation()

  const payNow = shouldCharge ? totalPayNowDifference : 0.0
  const total = payNow + remainderDifference

  return (
    <Box>
      <Text color='var(--colorPrimary)' fontWeight={600} mb={2}>
        {TRANSLATOR('xo2:priceBreakdown')}
      </Text>
      <Grid container justifyContent='space-between' alignItems='center'>
        <Text color='var(--colorSuccess)' fontWeight={600} mb={1}>
          {TRANSLATOR('reservations:modification:payNow')}
        </Text>
        <Text color='var(--colorSuccess)' fontWeight={600} mb={1}>
          {util.formatMoneySignPrefix(payNow, currency, 2)}
        </Text>
      </Grid>
      <Grid container justifyContent='space-between' alignItems='center'>
        <Text fontWeight={400} mb={1.5}>
          {TRANSLATOR('reservations:modification:payAtCounter')}
        </Text>
        <Text fontWeight={400} mb={1.5}>
          {util.formatMoneySignPrefix(remainderDifference, currency, 2)}
        </Text>
      </Grid>
      <Grid container justifyContent='space-between' alignItems='center'>
        <Text color='#273C75'>{TRANSLATOR('reservations:modification:total')}</Text>
        <Text color='#273C75'>{util.formatMoneySignPrefix(total, currency, 2)}</Text>
      </Grid>
    </Box>
  )
}
