import React, { useRef } from 'react'
import * as styles from './styles'
import IconUnchecked from './assets/icon-unchecked.svg'
import IconChecked from './assets/icon-checked.svg'
import { useTranslation } from 'react-i18next'

const { useStyles, CardWrapper, RowWrapper, InsuranceWrapper, Title, Text, RadioUI, Subtitle } = styles

export default function (props) {
  const { t: TRANSLATOR } = useTranslation()
  const classes = useStyles()
  const radioRef = useRef()
  const { value, checked, error } = props

  return (
    <>
      <CardWrapper onClick={() => radioRef.current.click()} $checked={checked} $isError={error}>
        <RowWrapper>
          <InsuranceWrapper>
            <Title>
              <Text>{TRANSLATOR('xo1:noProtection')}</Text>
            </Title>
          </InsuranceWrapper>
          <RadioUI
            value={value}
            classes={classes}
            icon={<img src={IconUnchecked} alt='icon' />}
            checkedIcon={<img src={IconChecked} alt='icon-checked' />}
            inputRef={radioRef}
            checked={checked}
          />
        </RowWrapper>
        <Subtitle>{TRANSLATOR('xo1:acceptRisk')}</Subtitle>
      </CardWrapper>
    </>
  )
}
