import React, { Component } from 'react'
import debug from 'debug'
import { connect } from 'react-redux'
import NoSSR from '../NoSSR'
import util from '../../util'
import { toast } from 'react-toastify'
import GA from '../../services/GA'
import MobileForm from './MobileForm/index'
import DesktopForm from './DesktopForm'
import { setSearchPickupLocation } from '../../redux/slices'
import { selectSearchArgs } from '../../redux/selectors'
import EventSender from '../../services/EventSender'
import { checkDefaultDateRange } from '../../util/searchArgs'
import API from '../../services/API'

const log = debug('carla:search')

class SearchForm extends Component {
  getClosestLocation = async () => {
    try {
      log('fetching closest location')
      const closestLocation = await API.getClosestLocation()
      if (closestLocation) {
        const location = {
          label: closestLocation.prettyName,
          value: closestLocation.id,
          type: closestLocation.type
        }
        log('closest location:', closestLocation)
        this.props.setSearchPickupLocation(location)
      } else {
        log('empty response from closestLocation', closestLocation)
      }
    } catch (err) {
      log('closest location not found', err)
    }
  }

  componentDidMount() {
    checkDefaultDateRange()
    if (!this.props.pickupLocation) {
      this.getClosestLocation()
    }
  }

  /**
   * validates search form, returns true if error found, false otherwise
   * @returns {string|boolean}
   */
  validateCarSearchInfo = () => {
    if (!this.props.pickupLocation) {
      return 'Please enter pickup location'
    }

    const { pickupDateTime, dropOffDateTime } = this.props
    if (!pickupDateTime || !dropOffDateTime) {
      return 'Please select pick-up and drop-off date'
    }
    // if pickup is after from
    if (pickupDateTime > dropOffDateTime) {
      return 'Please select drop-off time after pick-up time'
    }
    // if duration less then 3 hours
    if (dropOffDateTime.getTime() - pickupDateTime.getTime() < 1000 * 60 * 30) {
      return 'Please select drop-off time at least 30 minutes after pick-up time'
    }

    return false
  }

  /**
   * submit search
   * @param e {event}
   */
  handleSubmit = async (e) => {
    e.preventDefault()

    // handle error
    const error = this.validateCarSearchInfo()
    if (error) {
      if (!toast.isActive(this.errorToastId)) {
        this.errorToastId = toast.error(error)
      }
      return
    }

    const { pickupLocation, dropOffLocation, pickupDateTime, dropOffDateTime, age } = this.props
    const pickupTimeStr = util.formatDateForAPI(pickupDateTime)
    const dropOffTimeStr = util.formatDateForAPI(dropOffDateTime)

    EventSender.searched(this.props)
    GA.searchForCars()

    //Check if the location is an airport. If it is not Send the user to the map page
    const responsePickup = await API.getPlace(pickupLocation.value)
    const responseDropOff = await API.getPlace(dropOffLocation.value)

    //Right now all clients go to the map if the pickup location and dropOffLocation is the same
    //even though the endpoint accepts different locations. This is going to be fixed in a coordinated manner
    if (
      pickupLocation.value === dropOffLocation.value &&
      responsePickup.type !== 'airport' &&
      !pickupLocation.value.includes('_') &&
      Number.isInteger(responsePickup.locationId)
    ) {
      //Go to the map page
      this.props.history.push({
        pathname: '/availableAreas',
        state: {
          centerLat: responsePickup.latitude,
          centerLng: responsePickup.longitude,
          pickupLocation: responsePickup,
          dropOffLocation: responseDropOff,
          pickupDateTime,
          dropOffDateTime,
          pickupTimeStr,
          dropOffTimeStr,
          age,
          from: 'home' //This prop is used to render 'back to list view' or not
        }
      })
      return
    }
    // search
    try {
      let url = `/rental-cars/${pickupLocation.value}/${dropOffLocation.value}/${pickupTimeStr}/${dropOffTimeStr}`
      if (age && Number(age) < 25) url += `?age=${age}`
      log('redirecting to search page:', url)
      this.props.history.push(url)
    } catch (err) {
      console.error('error while submitting search form', err)
    }
  }

  render() {
    return (
      <NoSSR>
        <MobileForm show={[1, 0, 0]} onSubmit={this.handleSubmit} />
        <DesktopForm show={[0, 1, 1]} onSubmit={this.handleSubmit} />
      </NoSSR>
    )
  }
}

export default connect(selectSearchArgs, { setSearchPickupLocation })(SearchForm)
