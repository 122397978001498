// TODO: This component looks like retired. Make sure and delete
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import util from '../../util'
import { El, FlexEl } from '../layout'
import { themeGet } from 'styled-system'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { theme } from '../../styles'
import { ReactComponent as PickupIcon } from '../../util/images/icons/pickupIcon.svg'
import { ReactComponent as DropoffIcon } from '../../util/images/icons/dropoffIcon.svg'
import DefaultCar from '../../util/images/placeholder-car.png'
import { useTranslation } from 'react-i18next'

export const BookingInfoCard = styled(({ isBundle = false, carRentalProduct, whichPage, isShowOnMobile, ...props }) => {
  const { t: TRANSLATOR, i18n } = useTranslation()
  const { car, supplier, searchArgs, stationPair } = carRentalProduct
  const muiTheme = useTheme()
  const isBreakToMobile = useMediaQuery(muiTheme.breakpoints.down(theme.breakpoints[0] || 576))
  const { checkInDate, checkOutDate } = useSelector((state) => state.hotelBooking.dateRange)
  const { pickupDateStr, dropOffDateStr, isSameLocation } = useSelector((state) => state.searchArgs)
  const { isMatchCriteria } = useSelector((state) => state.bundleBooking)
  const startDate = isBundle ? (isMatchCriteria ? checkInDate : pickupDateStr) : searchArgs.pickupDateTime
  const endDate = isBundle ? (isMatchCriteria ? checkOutDate : dropOffDateStr) : searchArgs.dropOffDateTime
  const language = i18n.resolvedLanguage || 'en'

  if (typeof isShowOnMobile !== undefined && isShowOnMobile !== isBreakToMobile) return null

  function handleError(e) {
    e.target.src = DefaultCar
  }

  return (
    <FlexEl {...props}>
      <h2>{whichPage === 'XO1' ? 'Booking Information' : 'Reservation Details'}</h2>
      {whichPage === 'XO2' && (
        <FlexEl className='body-container'>
          <FlexEl className='car-img-container'>
            <img className='car-img' src={util.toHttps(car.imageUrl)} alt={car.name} onError={handleError} />
          </FlexEl>
          <FlexEl className='name-group-container'>
            <El color='colorPrimary'>{car.groupDescription}</El>
            <El mt='4px'>{car.name}</El>
          </FlexEl>
        </FlexEl>
      )}
      <FlexEl className='block-wrapper'>
        <FlexEl className='location-block'>
          <div className='pair'>
            <PickupIcon />
            <El className='location-type'>{TRANSLATOR('xo2:Pick Up')}</El>
          </div>
          <FlexEl flex-direction='row' mt='4px'>
            <El ml='4px'>{stationPair?.pickup?.name}</El>
          </FlexEl>
          {stationPair?.pickup?.wherePickup}
          <FlexEl flex-direction='row' mt='4px'>
            <El ml='4px' mt='1px'>
              {util.formatDateTimeFromDate(startDate, language)}
            </El>
          </FlexEl>
        </FlexEl>
        <FlexEl className='location-block'>
          <div className='pair'>
            <DropoffIcon />
            <El className='location-type'>{TRANSLATOR('homePage:dropOff')}</El>
          </div>
          <FlexEl flex-direction='row'>
            <El ml='4px'>{isSameLocation ? stationPair?.pickup?.name : stationPair?.dropOff?.name}</El>
          </FlexEl>
          <FlexEl flex-direction='row' mb='12px'>
            <El ml='4px' mt='1px'>
              {util.formatDateTimeFromDate(endDate, language)}
            </El>
          </FlexEl>
        </FlexEl>
      </FlexEl>
      <FlexEl className='supplier-container' flex-direction='row'>
        <El className='supply-by-text' flex-grow='1'>
          <h3>{TRANSLATOR('carListing:supplier')}</h3>
        </El>
        <img className='supplier-img' src={util.toHttps(supplier.logoUrl)} alt={supplier.name} />
      </FlexEl>
    </FlexEl>
  )
})`
  flex-direction: column;
  padding: 8px 0px 12px 0px;
  background-color: white;
  color: ${themeGet('colors.textBlack')};
  > h2 {
    margin: 0px 24px 0px 8px;
    font-size: 16px;
    font-weight: bold;
    color: ${themeGet('colors.colorPrimary')};
  }

  .body-container {
    margin: 0px 24px 0px 8px;
    padding-top: 12px;
    padding-bottom: 12px;
    border-bottom: solid 1px ${themeGet('colors.separatorGrey')};
  }

  .car-img {
    max-width: 100%;
    max-height: 120px;
    object-fit: contain;
  }

  .car-img-container {
    margin-right: 12px;
  }

  .name-group-container {
    flex-flow: column;
    font-size: 16px;
    font-weight: 500;
    flex-grow: 1;
    margin: auto;
  }

  .supplier-container {
    padding: 12px 24px 12px 16px;
    margin-left: 8px;
    border-top: solid 1px ${themeGet('colors.separatorGrey')};
    border-bottom: solid 1px ${themeGet('colors.separatorGrey')};

    justify-content: space-between;
    > img {
      width: 109px;
      height: 54px;
      object-fit: contain;
      float: right;
    }
    > .supply-by-text {
      > h3 {
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  .block-wrapper {
    flex-direction: column;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .location-block {
    padding: 12px 24px 0px 24px;
    flex-direction: column;
    font-size: 16px;
    > .location-type {
      font-size: 14px;
      margin-bottom: 4px;
      color: ${themeGet('colors.textGrey')};
    }
  }

  .pair {
    display: flex;
    align-items: center;
    flex-flow: row;
    padding-right: 80px;
    > svg {
      margin-right: 12px;
    }
  }
`
