import debug from 'debug'
import { newExpiredTime } from './constants'
import { fetchArticles } from './queries'
const log = debug('carla:sanity')

export let cacheArticles = {
  data: {},
  expired: undefined
}

export const saveToCache = (results) => {
  log('saveToCache')
  cacheArticles = {
    data: results,
    expired: newExpiredTime()
  }
}

export const getFromCache = async (slug) => {
  log('Cache Hit - expired time: ', cacheArticles.expired)
  if (!cacheArticles || !cacheArticles.data || !cacheArticles.data[slug]) {
    await fetchArticles()
  }
  if (!cacheArticles.expired || cacheArticles.expired < new Date().getTime()) {
    log('Cache was expired...')
    fetchArticles()
  }
  return {
    en: cacheArticles.data[slug]?.['en'] || {},
    fr: cacheArticles.data[slug]?.['fr'] || {},
    de: cacheArticles.data[slug]?.['de'] || {},
    it: cacheArticles.data[slug]?.['it'] || {},
    es: cacheArticles.data[slug]?.['es'] || {}
  }
}

export const getAllArticlesFromCache = async (lang = 'en') => {
  log('Cache Hit - expired time: ', cacheArticles.expired)
  let results = {}
  if (!cacheArticles || !cacheArticles.data) {
    results = await fetchArticles()
  } else {
    results = cacheArticles.data
  }
  if (!cacheArticles.expired || cacheArticles.expired < new Date().getTime()) {
    log('Cache was expired...')
    fetchArticles()
  }
  return Object.keys(results).map((slug) => results[slug]?.[lang])
}
