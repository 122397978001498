import React, { useMemo } from 'react'
import { Box, Grid } from '@material-ui/core'
import ImageMajorSupplierBanner from '../../util/images/image-major-supplier-banner.png'
import ImageMajorSuppliersLogo from '../../util/images/image-major-suppliers-logo.png'
import { useDispatch, useSelector } from 'react-redux'
import { setCarSearchFilters, setTagFilters } from '../../redux/slices'
import { checkMajorSupplier } from './Suppliers'
import { useTranslation, Trans } from 'react-i18next'
import { TextBanner, ImageBanner, Button } from './suppliers.styles'

export default function MajorSuppliersBannerCard({ cars }) {
  const { t: TRANSLATOR } = useTranslation()
  const majorSuppliers = useMemo(
    () => new Set(cars.filter((car) => checkMajorSupplier(car.supplier.name)).map((car) => car.supplier.name)),
    [cars]
  )
  const dispatch = useDispatch()
  const filters = useSelector((state) => state.carSearchFilters)
  const tags = useSelector((state) => state.filterTags)

  function handleClick() {
    const copyFilters = { ...filters, supplier: [...majorSuppliers] }
    const copyTags = tags.filter((tag) => tag[0] !== 'supplier')
    majorSuppliers.forEach((supplier) => copyTags.push(['supplier', supplier]))
    dispatch(setCarSearchFilters(copyFilters))
    dispatch(setTagFilters(copyTags))
  }

  return (
    <Box border='1px solid #FF7D0E' borderRadius='8px' bgcolor='#FFF9F5' p={2}>
      <Grid container direction='column' justifyContent='center' alignItems='center' style={{ gap: 16 }}>
        <ImageBanner src={ImageMajorSupplierBanner} alt='Major Suppliers Banner' height={24} m='0 0 -4px 0' />
        <TextBanner weight={600} m='0 0 -16px 0'>
          {TRANSLATOR('suppliers:cta')}
        </TextBanner>
        <TextBanner size={14}>
          <Trans i18nKey={'suppliers:ctaText'} components={{ b: <b></b> }} />
        </TextBanner>
        <Button onClick={handleClick}>{TRANSLATOR('suppliers:button')}</Button>
        <ImageBanner src={ImageMajorSuppliersLogo} alt='Major Suppliers Logo' height={20} />
      </Grid>
    </Box>
  )
}
