import React, { useState, useContext } from 'react'
import { OtherContext } from './CancellationForm'
import { Box, Container, Dialog } from '@material-ui/core'
import { StyledButtonFilled, StyledButtonOutlined, StyledTextField, StyledGrid, Image, Text, Anchor } from './styles'
import { useFormik } from 'formik'
import * as yup from 'yup'
import API from '../../services/API'
import ReactLoading from 'react-loading'
import IconCreditCard from './assets/icon-creditcard.svg'
import ImageToggleCard from './assets/image-toggle-card.png'
import ImageToggleCardFR from './assets/image-toggle-card-fr.png'
import ImageToggleCardES from './assets/image-toggle-card-es.png'
import ImageToggleCardDE from './assets/image-toggle-card-de.png'
import ImageToggleCardIT from './assets/image-toggle-card-it.png'
import IconNoNumber from './assets/icon-nonumber.svg'
import IconNoDocs from './assets/icon-nodocs.svg'
import IconDeposit from './assets/icon-deposit.svg'
import IconOther from './assets/icon-other.svg'
import IconPickup from './assets/icon-pickup.svg'
import IconDropOff from './assets/icon-dropoff.svg'
import IconDirection from './assets/icon-direction.svg'
import IconSelectedAncillary from './assets/icon-selected-ancillary.svg'
import IconArrowBack from './assets/icon-arrow-back.svg'
import util from '../../util'
import DefaultCarImage from '../../util/images/placeholder-car.png'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { MobileScreen, useBreakpoint } from '../common/Screens'
import { useDispatch } from 'react-redux'
import { setCancellationTerms } from '../../redux/slices'

const LocalizedToggleImages = {
  en: ImageToggleCard,
  fr: ImageToggleCardFR,
  es: ImageToggleCardES,
  de: ImageToggleCardDE,
  it: ImageToggleCardIT
}

const validationSchema = yup.object({
  other: yup.string().required('Please type something here.')
})

export function CompactReservedCar(props) {
  const { rentalProduct, dtl, ancillariesPurchased, header } = props
  const { t: TRANSLATOR, i18n } = useTranslation()
  const breakpoint = useBreakpoint(750)

  return (
    <Box m={breakpoint ? 0 : -2.5}>
      <Text fontSize={18} mb={1.5}>
        {header}
      </Text>
      <StyledGrid container direction='column'>
        <Image
          src={rentalProduct.supplierUrl}
          alt='supplier'
          height={40}
          alignSelf='flex-start'
          style={{ marginBottom: 10 }}
        />
        <Image
          src={rentalProduct.imageUrl}
          alt='car'
          width={300}
          alignSelf='center'
          mb={1.5}
          onError={(e) => (e.target.src = DefaultCarImage)}
        />
      </StyledGrid>
      <Text fontSize={18} fontWeight={400} mb={0.5}>
        {rentalProduct.name}
      </Text>
      <Text fontWeight={400} mb={2}>
        {TRANSLATOR('reservations:reserved:similar')} {rentalProduct.typeDescription}
      </Text>
      <StyledGrid container alignItems='center' justifyContent='space-between' mb={2} wrap='nowrap'>
        <StyledGrid item>
          <StyledGrid container gap={1}>
            <Image src={IconPickup} alt='icon-pickup' />
            <StyledGrid item gap={1}>
              <Text fontWeight={400} fontSize={18}>
                {util.formatDateWithoutTime(dtl.pickup.dateTime, i18n.resolvedLanguage)}
              </Text>
              <Text fontWeight={400} fontSize={18}>
                {util.formatTime(dtl.pickup.dateTime)}
              </Text>
            </StyledGrid>
          </StyledGrid>
        </StyledGrid>
        <StyledGrid item>
          <Image src={IconDirection} alt='icon-direction' />
        </StyledGrid>
        <StyledGrid item>
          <StyledGrid container gap={1}>
            <Image src={IconDropOff} alt='icon-pickup' />
            <StyledGrid>
              <Text fontWeight={400} fontSize={18}>
                {util.formatDateWithoutTime(dtl.dropOff.dateTime, i18n.resolvedLanguage)}
              </Text>
              <Text fontWeight={400} fontSize={18}>
                {util.formatTime(dtl.dropOff.dateTime)}
              </Text>
            </StyledGrid>
          </StyledGrid>
        </StyledGrid>
      </StyledGrid>
      <Box borderTop='1px solid #DFDFE7' pt={1.5}>
        <StyledGrid container gap={1.5} pt={1.5}>
          {ancillariesPurchased?.map((ancillary, i) => (
            <StyledGrid item key={i}>
              <StyledGrid container alignItems='center' gap={0.5}>
                <Image src={IconSelectedAncillary} alt='icon-selected-ancillary' />
                <Text fontWeight={400}>{ancillary}</Text>
              </StyledGrid>
            </StyledGrid>
          ))}
        </StyledGrid>
      </Box>
    </Box>
  )
}

export function NoCreditCard() {
  const { t: TRANSLATOR, i18n } = useTranslation()
  return (
    <Box>
      <Image src={IconCreditCard} alt='icon-creditcard' display='block' m='auto' />
      <Text fontSize={18} mt={3}>
        {TRANSLATOR('reservations:helpers:debit:header')}
      </Text>
      <Text fontWeight={500} mb={3}>
        {TRANSLATOR('reservations:helpers:debit:text')}
      </Text>
      <Image
        src={LocalizedToggleImages[i18n.resolvedLanguage || 'en']}
        alt='toggle-card'
        width={320}
        display='block'
        m='auto'
      />
    </Box>
  )
}

export function NoNumber({ confirmationNo, supplierUrl }) {
  const { t: TRANSLATOR } = useTranslation()
  return (
    <Box>
      <Image src={IconNoNumber} alt='icon-nonumber' display='block' m='auto' />
      <Text fontSize={18} mt={3}>
        {TRANSLATOR('reservations:helpers:number:header')}
      </Text>
      <Text>{TRANSLATOR('success:confirmNo')}:</Text>
      <Text color='#0076FF' fontSize={18} fontWeight={500} my={1.5}>
        {confirmationNo}
      </Text>
      <Text fontWeight={500} mb={1}>
        {TRANSLATOR('reservations:helpers:number:text')}
      </Text>
      <Text>{TRANSLATOR('reservations:helpers:number:supplierDetails')}</Text>
      <Image src={supplierUrl} height={30} width={'auto'} alt='supplier' />
    </Box>
  )
}

export function NoDocuments() {
  const { t: TRANSLATOR } = useTranslation()
  return (
    <Box>
      <Image src={IconNoDocs} alt='icon-nodocs' display='block' m='auto' />
      <Text fontWeight={500} my={3}>
        {TRANSLATOR('reservations:helpers:docs:header')}
      </Text>
      {TRANSLATOR('reservations:helpers:docs:text')}
    </Box>
  )
}

export function HighDeposit() {
  const { t: TRANSLATOR } = useTranslation()
  return (
    <Box>
      <Image src={IconDeposit} alt='icon-deposit' display='block' m='auto' />
      <Text my={3} fontWeight={500}>
        {TRANSLATOR('reservations:helpers:deposit:header')}
      </Text>
      <Text fontWeight={500}> {TRANSLATOR('reservations:helpers:docs:text')}</Text>
    </Box>
  )
}

export function Other(props) {
  const context = useContext(OtherContext)
  const [loading, setLoading] = useState(false)
  const { t: TRANSLATOR, i18n } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const formik = useFormik({
    initialValues: {
      other: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true)
      API.getCancellationTerms(props.reservationId, props.email, i18n.resolvedLanguage)
        .then((res) => {
          dispatch(setCancellationTerms(res))
          history.replace('/my-reservations/cancellation/options')
          return null
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => setLoading(false))
    }
  })

  function handleChange(event) {
    context.setOther(event.target.value)
    formik.setFieldValue('other', event.target.value)
  }

  return (
    <Box>
      <Image src={IconOther} alt='icon-other' display='block' m='auto' />
      <Text my={3} fontWeight={500}>
        {TRANSLATOR('reservations:helpers:other:text')}
      </Text>
      <form onSubmit={formik.handleSubmit}>
        <StyledTextField
          id='outlined-multiline-flexible'
          label={TRANSLATOR('reservations:helpers:other:placeholder')}
          error={formik.touched.other && Boolean(formik.errors.other)}
          multiline
          minRows={4}
          variant='outlined'
          fullWidth
          onChange={handleChange}
          customcolor={formik.touched.other && formik.errors.other && '#EB001B'}
        />
        <MobileScreen customBreakpoint={750}>
          <StyledGrid container gap={2.25} style={{ marginTop: 25 }}>
            <StyledButtonFilled type='button' onClick={() => (window.location.href = '/contact')}>
              {TRANSLATOR('homePage:contactUs')}
            </StyledButtonFilled>
            <StyledButtonOutlined type='submit'>
              {loading ? (
                <ReactLoading type={'spin'} height={24} width={24} color='#FF7D0E' />
              ) : (
                TRANSLATOR('reservations:form:cancel')
              )}
            </StyledButtonOutlined>
          </StyledGrid>
        </MobileScreen>
      </form>
    </Box>
  )
}

export function NotEligibleForCancellation({ message }) {
  const [open, setOpen] = useState(true)
  return (
    <Dialog open={open}>
      <StyledGrid container direction='column' gap={2.25} style={{ textAlign: 'center' }} alignItems='center' p={2.5}>
        <Text fontSize={18} fontWeight={500}>
          {message}
        </Text>
        <StyledButtonFilled onClick={() => setOpen(false)}>OK</StyledButtonFilled>
      </StyledGrid>
    </Dialog>
  )
}

export function DesktopContainer(props) {
  const { t: TRANSLATOR } = useTranslation()
  const history = useHistory()
  const border = props.value !== '' ? '1px solid #DFDFE7' : 'none'
  const lastIndex = (index) => index === 1 // This is here for the last element to have border always.
  return (
    <Container>
      <StyledGrid container alignItems='center' my={2} gap={0.5}>
        <Image src={IconArrowBack} alt='icon-arrow-back' />
        <Anchor onClick={history.goBack}>{TRANSLATOR('reservations:reserved:back')}</Anchor>
      </StyledGrid>
      <Box bgcolor='white' p={4} borderRadius={12} border='1px solid #DFDFE7'>
        <Box borderBottom='1px solid #DFDFE7' pb={1.25} pl={1.5} mb={2}>
          <Text fontSize={24}>{props.header}</Text>
        </Box>
        <StyledGrid container alignItems='flex-start' gap={6} px={4} wrap='nowrap'>
          {props.children.map((child, index) => (
            <StyledGrid
              key={index}
              item
              flex={1}
              border={lastIndex(index) ? '1px solid #DFDFE7' : border}
              p={2.5}
              borderRadius={12}
            >
              {child}
            </StyledGrid>
          ))}
        </StyledGrid>
      </Box>
    </Container>
  )
}
