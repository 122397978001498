import React, { useState, useRef, createContext, useContext, useEffect } from 'react'
import ReactLoading from 'react-loading'
import API from '../../services/API'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { Box, Container } from '@material-ui/core'
import {
  NoCreditCard,
  NoNumber,
  NoDocuments,
  HighDeposit,
  Other,
  CompactReservedCar,
  DesktopContainer
} from './HelperComponents'
import {
  StyledButtonFilled,
  StyledButtonOutlined,
  StyledFormControlLabel,
  StyledRadio,
  StyledRadioGroup,
  StyledGrid,
  Text
} from './styles'
import IconExclamation from './assets/icon-exclamation.svg'
import { IconText } from './Verification.styles'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import GA from '../../services/GA'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { setCancellationTerms } from '../../redux/slices'
import { MobileScreen, OverMobileScreen, useBreakpoint } from '../common/Screens'

export const OtherContext = createContext()

const validationSchema = yup.object({
  cancellation: yup.string().required(i18next.t('reservations:form:select'))
})

function CustomForm({ value, changeOption, reservationId, email }) {
  const [loading, setLoading] = useState(false)
  const [otherEmpty, setOtherEmpty] = useState(false)
  const context = useContext(OtherContext)
  const { t: TRANSLATOR, i18n } = useTranslation()
  const history = useHistory()
  const breakpoint = useBreakpoint(750)
  const dispatch = useDispatch()

  useEffect(() => {
    if (context.other !== '') {
      setOtherEmpty(false)
    }
    return () => {
      setOtherEmpty(false)
    }
  }, [context.other])

  // This is weird but buttons change places in the design.
  const check = value !== 'other'
  const formik = useFormik({
    initialValues: {
      cancellation: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      GA.submitCancellationTerms()
      if (values.cancellation === 'other' && context.other === '') {
        setOtherEmpty(true)
        return
      }
      setLoading(true)
      API.getCancellationTerms(reservationId, email, i18n.resolvedLanguage)
        .then((res) => {
          dispatch(setCancellationTerms(res))
          history.replace('/my-reservations/cancellation/options')
          return null
        })
        .catch((err) => {
          console.error(err)
        })
    }
  })

  function handleChange(event) {
    context.setOther('')
    setOtherEmpty(false)
    formik.setFieldValue('cancellation', event.target.value)
    changeOption(event)
  }

  return (
    <>
      <Text fontSize={18} mb={0.75}>
        {TRANSLATOR('reservations:form:header')}
      </Text>
      <Text fontWeight={500} mb={2.25}>
        {TRANSLATOR('reservations:form:text')}
      </Text>
      <form onSubmit={formik.handleSubmit}>
        <StyledRadioGroup name='cancellation' value={formik.values.cancellation} onChange={handleChange}>
          <StyledFormControlLabel
            value='no-card'
            control={<StyledRadio />}
            label={TRANSLATOR('reservations:form:noCard')}
            onClick={GA.selectCancelReasonCreditCard}
          />
          <StyledFormControlLabel
            value='no-number'
            control={<StyledRadio />}
            label={TRANSLATOR('reservations:form:noNumber')}
            onClick={GA.selectCancelReasonConfirmationNumber}
          />
          <StyledFormControlLabel
            value='no-documents'
            control={<StyledRadio />}
            label={TRANSLATOR('reservations:form:noDocs')}
            onClick={GA.selectCancelReasonMissingDocs}
          />
          <StyledFormControlLabel
            value='deposit'
            control={<StyledRadio />}
            label={TRANSLATOR('reservations:form:deposit')}
            onClick={GA.selectCancelReasonDeposit}
          />
          <StyledFormControlLabel
            value='other'
            control={<StyledRadio />}
            label={TRANSLATOR('reservations:form:other')}
            onClick={GA.selectCancelReasonOther}
          />
        </StyledRadioGroup>
        {!formik.isValid && formik.touched.cancellation && (
          <IconText bg='#FCDDEC' style={{ marginTop: 12 }}>
            <img src={IconExclamation} alt='exclamation' />
            <p
              style={{
                margin: 0,
                color: '#525266',
                fontSize: 15,
                fontWeight: 500,
                lineHeight: 'normal'
              }}
            >
              {formik.errors.cancellation}
            </p>
          </IconText>
        )}
        {(check || !breakpoint) && (
          <StyledGrid container mt={2.25} gap={2.25} wrap='nowrap'>
            <StyledButtonFilled type='button' onClick={() => (window.location.href = '/contact')}>
              {TRANSLATOR('homePage:contactUs')}
            </StyledButtonFilled>
            <StyledButtonOutlined type='submit'>
              {loading ? (
                <ReactLoading type={'spin'} height={24} width={24} color='#FF7D0E' />
              ) : (
                TRANSLATOR('reservations:form:cancel')
              )}
            </StyledButtonOutlined>
          </StyledGrid>
        )}
      </form>
      {otherEmpty && (
        <p style={{ color: '#EB001B', margin: '12px 0 -12px 0' }}>{TRANSLATOR('reservations:form:reason')}</p>
      )}
    </>
  )
}

function CancellationMobile({ value, option, handleChange, reservationId, email }) {
  return (
    <Container disableGutters style={{ padding: 20 }}>
      <Box bgcolor='white' p={2.5} borderRadius={12} border='1px solid #DFDFE7'>
        <CustomForm value={value} changeOption={handleChange} reservationId={reservationId} email={email} />
      </Box>
      <Box bgcolor='white' p={2.5} borderRadius={12} border='1px solid #DFDFE7' mt={3}>
        {option.current}
      </Box>
    </Container>
  )
}

function CancellationDesktop({ value, option, handleChange, reservationId, email }) {
  const { t: TRANSLATOR } = useTranslation()
  return (
    <DesktopContainer
      header={TRANSLATOR('reservations:form:cancellation')}
      children={[
        option.current,
        <CustomForm value={value} changeOption={handleChange} reservationId={reservationId} email={email} />
      ]}
      value={value}
    />
  )
}

export function CancellationForm() {
  const history = useHistory()
  const [value, setValue] = useState('')
  const option = useRef()
  const [other, setOther] = useState('')
  const { t: TRANSLATOR } = useTranslation()
  const response = useSelector((state) => state.reservationInfoRetrieval.response)

  if (!response) {
    history.replace('/')
    return null
  }

  const {
    rentalProduct: { supplierUrl },
    confirmationNo,
    reservationId,
    userInfo: { email }
  } = response

  switch (value) {
    case 'no-card':
      option.current = <NoCreditCard />
      break
    case 'no-number':
      option.current = <NoNumber {...{ confirmationNo, supplierUrl }} />
      break
    case 'no-documents':
      option.current = <NoDocuments />
      break
    case 'deposit':
      option.current = <HighDeposit />
      break
    case 'other':
      option.current = <Other reservationId={reservationId} email={email} />
      break
    default:
      option.current = <CompactReservedCar {...response} header={TRANSLATOR('reservations:form:details')} />
      break
  }

  const handleChange = (event) => {
    setValue(event.target.value)
  }

  return (
    <OtherContext.Provider value={{ other, setOther, value }}>
      <MobileScreen customBreakpoint={750}>
        <CancellationMobile
          value={value}
          option={option}
          handleChange={handleChange}
          reservationId={reservationId}
          email={email}
        />
      </MobileScreen>
      <OverMobileScreen customBreakpoint={750}>
        <CancellationDesktop
          value={value}
          option={option}
          handleChange={handleChange}
          reservationId={reservationId}
          email={email}
        />
      </OverMobileScreen>
    </OtherContext.Provider>
  )
}
