import debug from 'debug'
import API from './API'
import util from '../util'
import { store as reduxStore } from '../redux/store'
import { setAuthKey, setCarlinDeviceId, setDeviceId, setSharedSecret, setUserCountry } from '../redux/slices'
import EventSender from './EventSender'
import hmacSHA512 from 'crypto-js/hmac-sha512'
import encHex from 'crypto-js/enc-hex'
import axios from 'axios'
import isEqual from 'lodash/isEqual'
import i18next from 'i18next'

const log = debug('carla:user')

const SessionManager = {
  getDeviceId() {
    return reduxStore.getState().user.deviceId
  },

  getCarlinDeviceId() {
    return reduxStore.getState().user.carlinDeviceId
  },

  getAuthKey() {
    return reduxStore.getState().user.authKey
  },

  getSharedSecret() {
    return reduxStore.getState().user.sharedSecret
  },

  /**
   * checks property and cookies for user id, generates new one  if not found
   * @returns {Promise<string>}
   */

  getRegion: function () {
    return reduxStore.getState().user.country
  },

  getUserCountry() {
    return reduxStore.getState().user.country
  },

  async fetchAuthKey() {
    let deviceId = this.getCarlinDeviceId()
    let authKey = this.getAuthKey()
    let sharedSecret = this.getSharedSecret()
    await this.fetchUserCountry()
    if (authKey && deviceId && sharedSecret) {
      log(`deviceId: ${deviceId}, authKey: ${authKey}, sharedSecret: ${sharedSecret}`)
      return authKey
    }
    log('registering to backend')
    while (!this.getAuthKey()) {
      try {
        const language = i18next.resolvedLanguage
        let registerDeviceResponse = await API.registerToBackend(language)
        deviceId = registerDeviceResponse.deviceId
        sharedSecret = registerDeviceResponse.sharedSecret
        log(`deviceId: ${deviceId}, sharedSecret: ${sharedSecret}, ${registerDeviceResponse}`)
        authKey = hmacSHA512(deviceId.toString(), sharedSecret).toString(encHex)
        this.setAuthKey(authKey)
        this.setCarlinDeviceId(deviceId)
        this.setSharedSecret(sharedSecret)
      } catch (e) {
        log('cannot fetch device id, retrying', e)
        await util.sleep(1000)
      }
    }
    log(`new authKey generated: "${authKey}"`)
    return authKey
  },

  async generateDeviceIdForDeepLink(onError) {
    let authKey = this.getAuthKey()
    if (authKey) {
      log(`device id found in state: ${authKey}`)
      return
    }
    log('generating new auth key')
    if (!this.getAuthKey()) {
      try {
        await this.fetchAuthKey()
      } catch (e) {
        onError(e)
      }
    }
    log(`new device id generated: "${this.getAuthKey()}"`)
  },

  async fetchUserCountry() {
    const userCountryCode = this.getUserCountry()
    const isValid = this.getWithExpiry('countryCodeExpiry')
    if (userCountryCode && isValid) {
      return userCountryCode
    } else {
      axios
        .get('https://ipapi.co/json/')
        .then((response) => {
          const countryCode = response.data.country_code
          this.setUserCountry(countryCode)
          return countryCode
        })
        .catch((error) => {
          // TODO: sent to sentry
          this.setUserCountry('US')
          return 'US'
        })
    }
  },

  setDeviceId(deviceId) {
    reduxStore.dispatch(setDeviceId(deviceId))
  },

  setUserCountry(country) {
    reduxStore.dispatch(setUserCountry(country))
  },

  setSharedSecret(sharedSecret) {
    reduxStore.dispatch(setSharedSecret(sharedSecret))
  },

  setAuthKey(authKey) {
    reduxStore.dispatch(setAuthKey(authKey))
  },

  setCarlinDeviceId(carlinDeviceId) {
    reduxStore.dispatch(setCarlinDeviceId(carlinDeviceId))
  },

  getSessionId() {
    return util.getFromLocalStorage('session-id') || 0
  },

  /**
   * @param searchArgs {{pickupLocationId, dropOffLocationId, pickupDateStr, dropOffDateStr, age}}
   * @returns {null|object}
   */
  getLastCarRentalProducts(searchArgs) {
    const lastCarSearchInfo = util.getFromLocalStorage('carla-last-car-search-info')
    const lastCarSearchTS = util.getFromLocalStorage('carla-last-car-search-ts')
    const nowTS = new Date().getTime()

    if (lastCarSearchInfo) {
      lastCarSearchInfo.age = String(lastCarSearchInfo.age)
      searchArgs.age = String(searchArgs.age)
    }

    if (!isEqual(searchArgs, lastCarSearchInfo)) return null // if search info is changed
    if (nowTS - lastCarSearchTS > 1000 * 60 * 5) return null // cache limit is 5 mins
    return {
      carCached: util.getFromLocalStorage('carla-last-car-search-results'),
      adsCached: util.getFromLocalStorage('carla-last-ads-search-results')
    }
  },
  saveLastCarRentalProducts(searchArgs, carResult) {
    const ts = new Date().getTime()
    util.saveToLocalStorage('carla-last-car-search-results', carResult)
    util.saveToLocalStorage('carla-last-car-search-info', searchArgs)
    util.saveToLocalStorage('carla-last-car-search-ts', ts)
  },

  saveLastAdsRentalProducts(adsResult) {
    util.saveToLocalStorage('carla-last-ads-search-results', adsResult)
  },

  // get last saved state from local storage
  getSavedState() {
    const state = util.getFromLocalStorage('state')
    if (state === null) {
      return undefined
    }
    return state
  },

  saveState(state) {
    util.saveToLocalStorage('state', state)
  },

  saveWithExpiry(key, value, ttl) {
    util.saveToLocalStorageWithExpiry(key, value, ttl)
  },

  getWithExpiry(key) {
    return util.getFromLocalStorageWithExpiry(key)
  },

  async startSession() {
    // to check if session is already count
    if (!util.getFromSessionStorage('session-started')) {
      util.saveToSessionStorage('session-started', true)
      let sessionId = util.getFromLocalStorage('session-id')
      if (sessionId === null) sessionId = -1

      const newSessionId = Number(sessionId) + 1
      log('starting new session, current: ', newSessionId)
      util.saveToLocalStorage('session-id', newSessionId)
      EventSender.sessionStarted()
    }

    return await Promise.all([this.fetchAuthKey()])
  }
}

export default SessionManager
