/* eslint-disable jsx-a11y/alt-text */
import 'url-search-params-polyfill'
import React, { Component, useContext, useState } from 'react'
import loadable from '@loadable/component'
import { Provider } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { Helmet } from 'react-helmet'
import debug from 'debug'
import util from './util'
import NavigationBar from './layout/NavigationBar'
import { GlobalStyle, theme } from './styles'
import SessionManager from './services/SessionManager'
import ErrorHandler from './services/ErrorHandler'
import { init as initNotification } from './services/Notification'
import { PageMainContainer } from './components/layout'
import GA, { GATracker } from './services/GA'
import EventSender from './services/EventSender'
import clone from 'lodash/clone'
import { PromoCode } from './components/PromoCodeModal'
import ConnectedFooter from './util/ConnectedFooter'
import Router from './Router'
import loadBodyScript from './3nd-party'
import { MobileScreen } from './components/common/Screens'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { store as reduxStore } from './redux/store'
import { isEmpty } from 'lodash'
import loadAffirmScript from './3nd-party/affirm'
import { withSSR } from 'react-i18next'
import ScrollToTop from './components/ScrollTop'
import { GrowthBookRootProvider } from './growthbook'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60
    }
  }
})

const ReactPortal = loadable(() => import('./components/ReactPortal'), {
  ssr: false,
  resolveComponent: (components) => components.default
})
const DownloadPopup = loadable(() => import('./components/DownloadPopup'), {
  ssr: false
})
const ReactToastify = loadable(() => import('./components/common/ReactToastify'), {
  ssr: false
})
const DefaultErrorModal = loadable(() => import('./components/Modal'), {
  ssr: false,
  resolveComponent: (components) => components.DefaultErrorModal
})

const log = debug('carla:app')
export const CarlaContext = React.createContext()

const DefaultHeaders = () => (
  <Helmet>
    <title>Carla - Rent a car from Avis, Hertz, Enterprise, Dollar, Keddy. Car rental app</title>
    <meta
      name='description'
      content="Compare car rental prices from 900 companies in 160 countries. Get car rental offers from major and local suppliers in seconds. Select the best rental car fits your needs. Complete your car reservation under 30 seconds. Whether you're planning a road trip, a family vacation, or a business trip, finding the right rental car is easy with our comprehensive search engine. With a wide selection of cars and rental options to choose from, you'll find the perfect vehicle for your needs. Plus, our streamlined booking process makes it quick and easy to reserve your rental car in just a few clicks.
    "
    />
    <meta name='msvalidate.01' content='D638AAF25CECF1FAD26EB51419027073' />
    <meta name='yandex-verification' content={util.getYandexContent()} />
    <meta property='fb:app_id' content='1508686502763666' />
    <meta name='twitter:card' content='summary_large_image' />
    <meta name='twitter:site' content='@RentCarla' />
    <meta name='twitter:creator' content='@RentCarla' />
    <meta name='apple-itunes-app' content='app-clip-bundle-id=com.kasaba.Carla.Clip, app-id=1035631234' />
    <meta name='google' value='notranslate'></meta>
    <script type='application/ld+json'>{util.getOrganizationStructure()}</script>
    <script type='application/ld+json'>{util.getWebsiteStructure()}</script>
    <link rel='preconnect' href='https://www.google-analytics.com' />
  </Helmet>
)

const NavBarContext = React.createContext()
const UpdateNavBarContext = React.createContext()

export const useBarKey = () => {
  return useContext(NavBarContext)
}

export const useSetBarKey = () => {
  return useContext(UpdateNavBarContext)
}

const DynamicNavBarContext = ({ children }) => {
  const [navBarKey, setNavBarKey] = useState('REGULAR')

  function setKey(arg) {
    let key = arg !== null ? arg : 'REGULAR'
    setNavBarKey(key)
  }

  return (
    <NavBarContext.Provider value={navBarKey}>
      <UpdateNavBarContext.Provider value={setKey}>{children}</UpdateNavBarContext.Provider>
    </NavBarContext.Provider>
  )
}

class App extends Component {
  state = {
    error: null,
    context: {
      history: null,
      location: null
    }
  }

  initClarity = () => {
    ;(function (c, l, a, r, i, t, y) {
      c[a] =
        c[a] ||
        function () {
          ;(c[a].q = c[a].q || []).push(arguments)
        }
      t = l.createElement(r)
      t.async = 1
      t.src = 'https://www.clarity.ms/tag/' + i
      y = l.getElementsByTagName(r)[0]
      y.parentNode.insertBefore(t, y)
    })(window, document, 'clarity', 'script', 'mjhglqefmo')
  }

  constructor(props) {
    super(props)
    // debug module browser setting
    if (util.isDevelopment() && util.isBrowser() && util.isCookiesEnabled()) {
      localStorage.debug = 'carla:*'
      // tool to do various operations
      window.dev = util.dev
    }

    log(
      `starting carla app, environment: "${process.env.CARLA_ENV}", version: "${
        process.env.CARLA_VERSION
      }", session id: ${SessionManager.getSessionId()}`
    )

    ErrorHandler.init()
    GA.init()
    // used by optimize
    if (util.isBrowser()) {
      window.store = reduxStore
    }

    // set global context provider values
    this.state.context = {
      history: props.history,
      location: props.location
    }
  }

  // redirect to home page when user closes error modal
  handleAfterError = () => {
    this.setState({ error: null })
    this.props.history.push('/')
  }

  componentDidMount() {
    // set device id and user country
    SessionManager.startSession()
    // send session end event when closing tab
    window.addEventListener('beforeunload', () => {
      EventSender.sessionEnded()
    })
    this.initClarity()

    // Load onesignal script
    loadBodyScript({
      key: 'oneSignalScript',
      src: 'https://cdn.onesignal.com/sdks/OneSignalSDK.js',
      isAsync: true,
      callback: initNotification
    })

    loadBodyScript({
      key: 'googlePayScript',
      src: 'https://pay.google.com/gp/p/js/pay.js',
      isAsync: true
    })

    loadBodyScript({
      key: 'stripeScript',
      src: 'https://js.stripe.com/v2/',
      isAsync: true
    })

    loadAffirmScript({
      key: 'affirmScript',
      isAsync: true
    })
  }

  // updaet propogating context (route locaion)
  componentDidUpdate(prevProps, prevState) {
    const isLocationChanged = prevProps.location !== this.props.location

    const newContext = clone(this.state.context)

    if (isLocationChanged) {
      newContext.location = this.props.location
      this.setState({ context: newContext })
    }
  }

  /**
   * show "something is wrong" and send error to sentry
   */
  componentDidCatch(error, errorInfo) {
    ErrorHandler.handleReactError(error, errorInfo)
    this.setState({ error })
  }

  render() {
    return (
      <Provider store={reduxStore}>
        <QueryClientProvider client={queryClient}>
          <GrowthBookRootProvider>
            <CarlaContext.Provider value={this.state.context}>
              <ThemeProvider theme={theme}>
                <GATracker>
                  <ScrollToTop>
                    <DefaultHeaders />
                    <GlobalStyle />
                    <DynamicNavBarContext>
                      <NavigationBar currentPage={this.state.context.location} />
                      <PageMainContainer>
                        <DefaultHeaders />
                        <PromoCode />
                        {!isEmpty(this.state.error) && (
                          <DefaultErrorModal isOpen={true} onClose={this.handleAfterError} closable={true} ok={true} />
                        )}
                        {!this.state.error && <Router />}
                      </PageMainContainer>
                    </DynamicNavBarContext>
                    <ConnectedFooter />
                    <ReactPortal>
                      <MobileScreen>
                        <DownloadPopup />
                      </MobileScreen>
                    </ReactPortal>
                    <ReactToastify />
                  </ScrollToTop>
                </GATracker>
              </ThemeProvider>
            </CarlaContext.Provider>
          </GrowthBookRootProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </Provider>
    )
  }
}

export default withSSR()(withRouter(App))
