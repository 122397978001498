import styled from 'styled-components'

export const WrapperStyled = styled('div')``

export const OpenModalButtonStyled = styled('a')`
  display: flex;
  align-items: center;
  gap: 4px;
  color: #ff7d0e;
  font-size: 18px;
  font-weight: 600;
  text-decoration-line: underline;
  cursor: pointer;
`
