import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

export default function useModificationInfo() {
  const history = useHistory()
  const data = useSelector((state) => state.modification)
  // return to homepage for empty state
  if (Object.values(data).every((value) => value === null)) history.replace('/')
  return data
}
