import React from 'react'
import styled from 'styled-components'
import { InsuranceSelectorInner } from '../InsuranceSelectorNonSS'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { setSelectedAncillaries } from '../../../redux/slices'

export const ProtectionModal = styled((props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const selectedAncillaries = useSelector((state) => state.ancillary.selectedAncillaries)

  // this gets the selected protection out of all ancillary products
  function getSelectedProtection() {
    const protectionIds = props.ancillaryProducts.map((product) => product.id)
    const s = new Set(protectionIds)
    const intersection = [...props.currentSelections].filter((x) => s.has(x))
    return new Set(intersection)
  }

  // clicks xo1nextbutton on xo1
  function handleSubmit() {
    const xo1Next = document.querySelector('#xo1-next-button')
    try {
      xo1Next.click()
    } catch (err) {
      console.error(err)
    }
  }

  // skip clears protection and clicks next on xo1
  function handleSkip() {
    const protection = getSelectedProtection()
    if (!!protection.size) {
      dispatch(setSelectedAncillaries([...selectedAncillaries].filter((x) => !protection.has(x))))
    }
    handleSubmit()
  }

  return (
    props.isOpen && (
      <div className={props.className}>
        <div className='container'>
          <button type='button' className='skip' onClick={handleSkip}>
            {t('xo1:skip')}
          </button>
          <div className='cta'>
            <h1>{t('xo1:popupUpgrade:header')}</h1>
            <p>{t('xo1:popupUpgrade:text')}</p>
            <InsuranceSelectorInner
              className='insurance-selector'
              ancillaryProducts={props.ancillaryProducts}
              selectedInsurance={props.selectedInsurance}
              deselectedInsurance={props.deselectedInsurance}
              currentSelections={props.currentSelections}
            />
          </div>
          <button type='button' className='next' disabled={!getSelectedProtection().size} onClick={handleSubmit}>
            {t('xo1:next')}
          </button>
        </div>
      </div>
    )
  )
})`
  background: rgba(0, 0, 0, 0.5);
  height: 100vh;
  width: 100vw;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  .insurance-selector {
    margin: 0;
  }

  h1 {
    color: #525266;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin: 0 auto;
    max-width: 343px;
  }

  p {
    color: #696984;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    margin 8px auto 36px;
    max-width: 343px;
  }

  .container {
    @media screen and (min-width: 600px) {
      max-width: 500px;
      height: min-content;
      margin: auto; 
      top: 0;
      padding-left: 24px;
      padding-right: 24px;
      border-radius: 12px;
    }
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 20px;
    background: white;
    z-index: 1001;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px 16px 20px;
    border-radius: 16px 16px 0px 0px;
  }

  .skip {
    align-self: flex-end;
    padding: 0;
    background: transparent;
    border: none;
    color: #ff7d0e;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration-line: underline;
    text-transform: uppercase;
    cursor: pointer;
  }

  .next {
    padding: 0;
    height: 50px;
    border: none;
    border-radius: 8px;
    background: #ff7d0e;
    color: #FFF;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    cursor: pointer;

    &:hover {
      background: #e86f09;
    }

    &:disabled {
      background: #B4B4C2;
    }
  }
`
