import React from 'react'
import { InstructionsButton } from './Instructions.styles'
import InstructionsDialog from './InstructionsDialog'
import { useTranslation } from 'react-i18next'

function Instructions() {
  const { t: TRANSLATOR } = useTranslation()
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  return (
    <div data-testid='instructions-component'>
      <InstructionsButton type='button' onClick={handleClickOpen}>
        {TRANSLATOR('xo1:viewInstructions')}
      </InstructionsButton>
      <InstructionsDialog open={open} onClose={() => setOpen(false)} />
    </div>
  )
}

Instructions.propTypes = {}

export default Instructions
