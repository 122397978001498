import React, { useEffect } from 'react'
import { Box } from '@material-ui/core'
import { DesktopContainer, CompactReservedCar } from './HelperComponents'
import { Text, StyledGrid, Image, RedirectButton } from './styles'
import IconCancelSuccess from './assets/icon-cancel-success.svg'
import { useTranslation } from 'react-i18next'
import { Redirect, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { MobileScreen, OverMobileScreen } from '../common/Screens'
import Deeplink from './Deeplink'

function CancellationMessage() {
  const { t: TRANSLATOR } = useTranslation()
  const history = useHistory()

  // To force out of flow when cancellation is completed
  useEffect(() => {
    window.addEventListener(
      'popstate',
      () => {
        history.replace('/')
      },
      { once: true }
    )
  })

  const contents = (
    <StyledGrid container direction='column' gap={2.25} style={{ textAlign: 'center' }} alignItems='center' p={2.5}>
      <Image src={IconCancelSuccess} alt='icon-cancel-success' />
      <Text fontSize={18} color='#3EAA77'>
        {TRANSLATOR('reservations:result:success')}
      </Text>
      <Text>{TRANSLATOR('reservations:result:cancelMessage')}</Text>
      <RedirectButton href='/'>{TRANSLATOR('reservations:result:goHome')}</RedirectButton>
    </StyledGrid>
  )

  return (
    <>
      <MobileScreen customBreakpoint={750}>
        <Box bgcolor='white' border='1px solid var(--borderGrey)' borderRadius={12} p={2.5} m={2.5}>
          {contents}
        </Box>
      </MobileScreen>
      <OverMobileScreen customBreakpoint={750}>{contents}</OverMobileScreen>
    </>
  )
}

export function CancellationResult() {
  const { t: TRANSLATOR } = useTranslation()
  const { cancelOption, response } = useSelector((state) => state.reservationInfoRetrieval)

  if (!response) return <Redirect to='/' />

  return (
    <>
      <MobileScreen customBreakpoint={750}>
        {cancelOption === 'carla' ? (
          <Box bgcolor='white' border='1px solid var(--borderGrey)' borderRadius={12} p={2.5} m={2.5}>
            <Deeplink />
          </Box>
        ) : (
          <CancellationMessage />
        )}
      </MobileScreen>
      <OverMobileScreen customBreakpoint={750}>
        <DesktopContainer
          header={TRANSLATOR('reservations:form:cancellation')}
          value=''
          children={[
            <CompactReservedCar {...response} header={TRANSLATOR('reservations:result:details')} />,
            cancelOption === 'carla' ? <Deeplink /> : <CancellationMessage />
          ]}
        />
      </OverMobileScreen>
    </>
  )
}
