import React from 'react'
import { useTranslation } from 'react-i18next'
import { DriverFormTitle, Grid, TextFieldUI, InfoAdornment } from '../styles'
import { useFormik } from 'formik'
import { driverValidationSchema as validationSchema } from '../../../util/formValidation'
import { useDispatch } from 'react-redux'
import { setModificationArgs } from '../../../redux/slices/modificationSlice'
import useModificationType from '../hooks/useModificationType'
import { setSearchPickupLocation, setSearchDropOffLocation } from '../../../redux/slices'

export default function DriverForm({ id, onSubmit, ...rest }) {
  const { t: TRANSLATOR } = useTranslation()
  const { userInfo, reservationId, placePair, dtl } = rest
  const { firstName, lastName, email, phoneNumber } = userInfo
  const dispatch = useDispatch()
  const type = useModificationType()

  const formik = useFormik({
    initialValues: {
      firstName,
      lastName,
      email,
      phoneNumber
    },
    validationSchema,
    onSubmit: (values) => {
      const carSearchArgs = {
        pickupDateTime: dtl.pickup.dateTime,
        dropOffDateTime: dtl.dropOff.dateTime,
        pickupLocation: placePair.pickup.id,
        dropOffLocation: placePair.dropOff.id
      }
      dispatch(
        setSearchPickupLocation({
          value: placePair.pickup.id,
          label: placePair.pickup.prettyName,
          type: placePair.pickup.type
        })
      )
      dispatch(
        setSearchDropOffLocation({
          value: placePair.dropOff.id,
          label: placePair.dropOff.prettyName,
          type: placePair.dropOff.type
        })
      )
      dispatch(
        setModificationArgs({
          userInfo: values,
          type,
          reservationId,
          carSearchArgs
        })
      )
      onSubmit()
    }
  })

  return (
    <form onSubmit={formik.handleSubmit} id={id}>
      <DriverFormTitle style={{ marginBottom: 16 }}>{TRANSLATOR('xo1:driverInfo:header')}</DriverFormTitle>
      <Grid container direction='column' style={{ gap: 12 }}>
        <TextFieldUI
          label={TRANSLATOR('xo1:driverInfo:inputFirstName')}
          name='firstName'
          value={formik.values.firstName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.firstName && Boolean(formik.errors.firstName)}
          helperText={(formik.touched.firstName && formik.errors.firstName) || ' '}
        />
        <TextFieldUI
          label={TRANSLATOR('xo1:driverInfo:inputLastName')}
          name='lastName'
          value={formik.values.lastName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.lastName && Boolean(formik.errors.lastName)}
          helperText={(formik.touched.lastName && formik.errors.lastName) || ' '}
        />
        <TextFieldUI
          label={TRANSLATOR('xo1:driverInfo:inputEmail')}
          disabled={true}
          endadornment={<InfoAdornment onClick={() => rest.setOpen(true)} />}
          name='email'
          value={formik.values.email}
          helperText=' '
        />
        <TextFieldUI
          label={TRANSLATOR('xo1:driverInfo:inputPhoneNumber')}
          name='phoneNumber'
          value={formik.values.phoneNumber}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
          helperText={(formik.touched.phoneNumber && formik.errors.phoneNumber) || ' '}
        />
      </Grid>
    </form>
  )
}
