import styled from 'styled-components'
import { El } from '../../components/layout'
import { themeGet } from 'styled-system'

export const ContentContainer = styled(El)`
  max-width: 1000px;
  margin: 15px auto;
  h1 {
    font-size: 32px;
    text-align: center;
  }
  h2 {
    font-weight: normal;
    font-size: 26px;
    margin-top: 20px;
    margin-left: 5px;
  }
  a {
    display: inline;
  }

  .blog-list {
    padding-left: 40px;
    color: ${themeGet('colors.black.7')};
    li.blog-list-item {
      padding: 8px 0;
    }
  }

  h3 {
    margin-top: 0;
  }
  p {
    text-align: justify;
  }
  .store-badges {
    flex-flow: row;
    flex-grow: 1;
    margin: auto;
    max-width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px 0;
    overflow: hidden;
    a {
      margin: 0 3px;
      svg {
        height: 40px;
        @media (min-width: ${themeGet('breakpoints.0')}px) {
          height: 50px;
        }
      }
    }
  }
  .image {
    max-width: 100%;
    object-fit: contain;
    margin: 6px;
    object-position: 25px 10%;
    align-items: center;
  }
  .center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 100%;
  }
  .image-paragraph {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    .image {
      margin: 0 -15px;
      img {
        max-width: 100%;
      }
    }
    .blog-list {
      padding-left: 40px;
      color: ${themeGet('colors.blueGrey.7')};
      li.blog-list-item {
        padding: 8px 0;
      }
    }
    @media (min-width: ${themeGet('breakpoints.0')}px) {
      flex-wrap: nowrap;
      .text {
        width: 55%;
      }
      .image {
        width: 42%;
        margin: 0;
      }
    }
  }
`
