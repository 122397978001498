import styled from 'styled-components'
import { El, FlexEl } from '../layout'
import { Info } from '../CarListing/CarCard'
import { themeGet } from 'styled-system'
import React from 'react'
import { CarlinPropTypes } from '../../entities'
import { arrayOf, bool, string } from 'prop-types'
import { Insurance } from '../../entities/insurance'
import { useTranslation } from 'react-i18next'

const isAncillarySelected = (ancillary, selectedAncillaryIds) => {
  return selectedAncillaryIds.has(ancillary.id)
}

const selectedInsuranceTitle = (ancillaryProducts, selectedAncillaryIds) => {
  if (!ancillaryProducts) return null
  for (const ap of ancillaryProducts) {
    if (ap.type === 'Insurance' && selectedAncillaryIds.has(ap.id)) return ap.title
  }
  return null
}

const deselectedInsuranceTitle = (ancillaryProducts) => {
  if (!ancillaryProducts) return null
  for (const ap of ancillaryProducts) {
    if (ap.type === 'Insurance' && ap.preSelected) return ap.title
  }
  return null
}

const IconInclusionsTick = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'>
    <g fill='none' fillRule='evenodd'>
      <circle cx='10' cy='10' r='10' fill='#08D776' />
      <path
        stroke='#FFF'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.667'
        d='M3 7.347L6.354 11.333 13 4.667'
        transform='translate(2 2)'
      />
    </g>
  </svg>
)

const IconInclusionsNotSelectedTick = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='10' cy='10' r='9' fill='#A6A6A6' />
    <path
      d='M5.50098 9.41167L8.5198 12.9998L14.501 6.99976'
      stroke='white'
      stroke-width='1.8'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
  </svg>
)

export const InclusionsListNonSS = styled(
  ({ inclusions, ancillaryProducts, selectedAncillaries, fromXO1WithoutSkyScanner, ...props }) => {
    const selectedInsTitle = selectedInsuranceTitle(ancillaryProducts, selectedAncillaries)
    const deselectedInsTitle = deselectedInsuranceTitle(ancillaryProducts)
    const availableInsuranceTitle = selectedInsTitle || deselectedInsTitle
    const { t } = useTranslation()
    return (
      <El {...props}>
        <El className='card-title'>{fromXO1WithoutSkyScanner ? t('xo1:includedInYourTrip') : t('xo1:included')}</El>
        <El as='ul'>
          {availableInsuranceTitle && (
            <FlexEl as='li'>
              <Info
                style={{ textDecoration: selectedInsTitle ? 'none' : 'line-through' }}
                icon={selectedInsTitle ? <IconInclusionsTick /> : <IconInclusionsNotSelectedTick />}
                description={availableInsuranceTitle}
              />
            </FlexEl>
          )}
          {ancillaryProducts &&
            ancillaryProducts
              .filter((ap) => ap.type !== 'Insurance')
              .map((ap, i) => {
                return (
                  <FlexEl key={i} as='li'>
                    <Info
                      style={{ textDecoration: isAncillarySelected(ap, selectedAncillaries) ? 'none' : 'line-through' }}
                      icon={
                        isAncillarySelected(ap, selectedAncillaries) ? (
                          <IconInclusionsTick />
                        ) : (
                          <IconInclusionsNotSelectedTick />
                        )
                      }
                      description={ap.title}
                    />
                  </FlexEl>
                )
              })}
          {
            /* Show inclusion list items */
            inclusions.map((inclusion, i) => (
              <FlexEl as='li' key={i}>
                <Info icon={<IconInclusionsTick />} description={inclusion} />
              </FlexEl>
            ))
          }
        </El>
      </El>
    )
  }
)`
  flex-flow: column;
  margin: 0;
  padding: 0;
  .card-title {
    color: ${themeGet('colors.colorPrimary')};
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: 600;
  }
  ul {
    margin: 0;
    padding: 0;
  }
`
InclusionsListNonSS.propTypes = {
  inclusions: arrayOf(string).isRequired,
  insuranceQuote: Insurance.InsuranceQuote,
  tripProtectionQuote: CarlinPropTypes.TripProtectionQuote,
  peaceOfMindQuote: CarlinPropTypes.PeaceOfMindQuote,
  insuranceSelected: bool,
  tripProtectionSelected: bool,
  peaceOfMindSelected: bool
}
