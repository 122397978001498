import styled from 'styled-components'

export const AppBarTitle = styled('div')`
  color: var(--Text-Primary, #525266);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 16px;
`

export const TabContentStyled = styled('div')`
  padding: 12px;
  color: var(--Text-Primary, #525266);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 16px;
  border-top: 1px solid #dfdfe7;
`

export const TabPanelTitle = styled('div')`
  color: var(--Actions-Secondary, #0076ff);
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
`

export const TextRenderContainer = styled('div')`
  padding: 16px;
  box-shadow: none;
`
