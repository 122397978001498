import React from 'react'

export default () => (
  <>
    <h3>Terms and Conditions of Carla Car Rental Platform</h3>
    <h4>1. General Provisions</h4>
    <p>
      1.1. <strong>Protection of Rights</strong>: Carla Car Rental (&quot;Carla&quot;) will make every effort in good
      faith to protect your rights. By using our services, you agree to the terms and conditions set forth herein.
    </p>
    <p>
      1.2. <strong>Reservation Forwarding</strong>: Carla is an intermediary service connecting travelers with car
      rental companies. It provides a convenient and streamlined way for customers to search for, compare, and book
      rental vehicles from various providers in multiple locations.{' '}
    </p>
    <p>
      Carla will forward your car reservation to the relevant rental car provider. The terms and conditions of the car
      rental company will apply in addition to Carla&#39;s terms and conditions. Please review the rental car company’s
      terms and conditions, which are available online or upon collection of the vehicle.{' '}
    </p>
    <p>
      1.3. <strong>Acceptance of Terms</strong>: By making a booking with Carla, you confirm that you have read,
      understood, and accepted our Terms and Conditions.
    </p>
    <h4>2. Requirements for Car Rental</h4>
    <p>
      2.1. <strong>Driver's License</strong>: A valid driving license is required. The driver’s license must be
      written&nbsp;
      <strong>in Latin characters.</strong> If it is not the case, you need to have an international driving license.
    </p>
    <p>
      2.1.2 <strong>Specificities for renting a car in Italy: </strong>If you rent a car in Italy, please note that the
      International Driving License is mandatory for Non-EU citizens.
    </p>
    <p>
      2.2. <strong>Credit/Debit Card</strong>: A credit card or debit card in the driver’s name is required for the
      deposit.
    </p>
    <p>
      2.3. <strong>Voucher &amp; Confirmation</strong>: Upon completing your reservation, you will receive a voucher via
      email with the reservation details, which can also be accessed through the Carla app.
    </p>
    <p>
      Rental cars listed on Carla are sourced directly from car rental companies&#39; systems in real-time. Most
      vehicles are instantly confirmed upon booking. However, in situations of low availability or for special car
      classes, certain vehicles are designated as On-Request Cars. These cars are not immediately available for booking
      and require confirmation from the car rental company, subject to availability, and may need additional processing
      time. In rare instances, car rental companies may deny the booking. If your reservation is not confirmed, you will
      receive a full refund.
    </p>
    <p>
      In very rare cases, car rental companies cancel a confirmed reservation. This happens in the case of unexpected
      shortage of fleet such as extensions of vehicles, accidents, breakdowns. In such cases, we’ll notify you about the
      cancellation. If your reservation is canceled by the car rental company, you will receive a full refund. Carla
      cannot be held responsible for any extra charges or consequential costs in the event of cancellations.{' '}
    </p>
    <h4>3. Disclaimer:</h4>
    <p>
      Carla strives to ensure that the information provided is accurate and complete. However, to the extent permitted
      by law, we shall not be liable for any loss or damage you may incur due to inaccuracies or incomplete information.
      We make no representations, warranties, or conditions regarding the information provided. All such
      representations, warranties, and conditions are excluded, except where their exclusion is prohibited by law.
    </p>
    <h4>4. Included Services Explanation where applicable</h4>
    <p>
      We display real-time data from car rental companies&#39; systems. In rare instances, due to typos or technical
      glitches, discrepancies may occur between our displayed information and the car rental companies&#39; systems.
      Carla is not liable for any additional charges incurred as a result of these discrepancies. Once an agreement is
      signed with the car rental company, Carla cannot be held responsible for any extra charges or consequential costs.
    </p>
    <p>
      4.1. <strong>Free Services</strong>: Check the &quot;Included Free&quot; section to see what is included in your
      rental price. Additional services, such as out-of-hours fees, underage fees, fuel, and one-way rental fees, may
      not be included and must be paid locally to the car rental company.
    </p>
    <p>
      4.2. <strong>Unlimited Mileage/Kilometers</strong>: If it is mentioned as Unlimited Mileage/Kilometers, you can
      drive the rental car any number of miles/kilometers without extra charges. Please note that some car rental
      companies categorize 1000 km or 3000 km as Unlimited Mileage/Kilometers, please check with the car rental company
      to make sure.
    </p>
    <h4>5. Protection Plan</h4>
    <p>
      5.1. <strong>Collision Damage Waiver (CDW)</strong>: CDW reduces the renter&#39;s liability from the total cost of
      damage to an excess amount, except when the renter is under the influence of alcohol or drugs. CDW does not cover
      windscreens, tires, underbody, replacement locks, replacement keys, or towing charges. CDW inclusions vary by car
      rental company; please verify upon vehicle pick-up.
    </p>
    <p>
      5.2. <strong>Theft Protection (TP)</strong>: TP reduces the renter&#39;s liability in the event of theft or damage
      due to theft or attempted theft. Terms vary by car rental company; verify upon pick-up.
    </p>
    <p>
      5.3. <strong>Supplemental Liability Insurance (SLI)</strong>: SLI provides coverage against third-party claims for
      bodily injury or property damage resulting from an accident. Terms vary by car rental company; verify upon
      pick-up.
    </p>
    <p>
      5.4. <strong>Protection Plan</strong>: Full protection plans can be purchased to cover accident-related damages up
      to $500, secondary to any existing insurance or CDW. Claims must be made within 10 days of the incident.
      Protection plan does not cover for scratches. Protection plan does not cover for damages happening when the renter
      is not in the car or the car is in park position. All damages should be claimed right after the incident by email
      by providing an explanation of the accident, photos showing the damages clearly, car rental company invoices,
      police report, rental agreement, a photo of the driving license, card statement showing the charges. Failure to
      provide all documentation will result in not getting reimbursed. Protection plan is only valid for the renter, car
      class and dates booked. The protection plan is not valid for accidents occurring on off-road terrains or rural
      roads. If you extend your rental, upgrade the car or the car class is changed by the rental company, you should
      inform us at pick-up date to modify your protection plan. Protection plan will not be valid if you fail to do so.
    </p>
    <h4>6. Amendments and Modifications</h4>
    <p>
      6.1. <strong>Free Amendments</strong>: Clients may modify existing reservations subject to a $25 modification fee
      once confirmed. If you want to modify a reservation, please contact customer support and email your pick up - drop
      off location exact dates and times. Carla will modify your reservation based on availability. There may be
      additional costs based on rates.{' '}
    </p>
    <p>
      6.2. <strong>Contact Information</strong>: Accurate contact information must be provided. Carla is not responsible
      for failed communications due to incorrect information.{' '}
    </p>
    <h4>7. Optional Services</h4>
    <p>
      For optional services, we display real-time data from car rental companies&#39; systems. In rare instances, due to
      typos or technical glitches, discrepancies may occur between our displayed information and the car rental
      companies&#39; systems. We are not liable for any additional charges incurred as a result of these discrepancies.
      If you are unsure about what is included, please contact our customer support team for clarification. Once an
      agreement is signed with the car rental company, Carla cannot be held responsible for any extra charges or
      consequential costs.&quot;
    </p>
    <p>
      7.1. <strong>Additional Services</strong>: Optional services such as toll passes and fuel fees are determined and
      charged by the car rental company. Carla is not liable for charges incurred due to documentation signed at the car
      rental counter.
    </p>
    <p>
      7.2. <strong>Out of Hours Fee</strong>: Some car rental companies may charge a fee for pick-ups after closing
      hours. This fee is payable at the counter.{' '}
    </p>
    <p>
      7.3. <strong>One-Way Fees</strong>: Dropping off the car at a different location may incur a one-way fee.{' '}
      <p>
        7.3.1 One-Way Fee Definition: A one-way fee is a charge applied by car rental companies when the rental vehicle
        is picked up at one location and dropped off at a different location. Car rental companies incur additional
        operational costs when a vehicle is returned to a different location from where it was picked up. This includes
        the logistical expenses of transporting the vehicle back to its original location or to another location where
        it is needed. The one-way fee helps cover these costs. One way fee is higher if you drop off the car in a
        different country.
      </p>
      <p>7.3.2 Fee Applicability</p>
      <p>
        7.3.2.1 One-Way Fee Inclusion: The one-way fee may be included in the rental price or charged separately at the
        rental counter. Please verify the details in the &quot;Included Free&quot; section of your rental agreement.
      </p>
      <p>
        7.3.2.2 Variable Charges: One-way fees can vary depending on the car rental company, car class, and locations
        involved. Specific charges will be determined and provided by the car rental company.
      </p>
    </p>
    <p>
      7.4. <strong>Underage Fees</strong>: Additional charges may apply for drivers under 25. Verify if this fee is
      included in your price.
    </p>
    <h4>8. Deposit &amp; Payment</h4>
    <p>
      8.1. <strong>Security Deposit</strong>: A security deposit is required by the car rental company, held temporarily
      on your credit card and refunded after the car is returned. Deposit amounts vary; verify with the rental company.
      All car rental companies require a deposit.{' '}
    </p>
    <p>
      8.2. On Carla, you can make reservations with either partial or full prepayment. Payments can be made using
      various methods, including credit or debit cards. Regardless of the prepayment option chosen, all car rental
      companies will require a credit or debit card to be presented at the counter. The payment systems of the car
      rental companies are separate from Carla&#39;s system, and charges will be processed independently by them. It is
      essential to present a physical credit or debit card in the renter&#39;s name at the counter, even if you have
      paid the full amount in advance.
    </p>
    <p>
      8.3. Payment currency. Carla will charge you in the currency displayed at checkout, if applicable. In rare
      instances, Carla may charge you in USD based on the bank&#39;s exchange rate. Carla is not responsible for any
      additional fees or currency conversion differences imposed by your bank or credit card company.
    </p>
    <p>
      8.4. Carla will refund you in the charged currency, if you are eligible for a refund. Carla is not responsible for
      any additional fees or currency conversion differences imposed by your bank or credit card company.
    </p>
    <h4>9. Car Segments</h4>
    <p>
      9.1. <strong>Car Class Reservation</strong>: Reservations are for car classes, not specific models. Exact makes
      and models are not guaranteed except for high-end vehicles or guaranteed models where it is explicitly stated.{' '}
    </p>
    <p>
      9.2. Car Specifications: Carla will display car specifications such as car seats, transmission, fuel type coming
      from car rental companies’ systems. In rare instances, due to typos or technical glitches, discrepancies may occur
      between our displayed information and the car rental companies&#39; systems. Carla is not liable for any
      additional charges incurred as a result of these discrepancies. Once an agreement is signed with the car rental
      company, Carla cannot be held responsible for any extra charges or consequential costs.{' '}
    </p>
    <h4>10. Car Pick-Up Locations</h4>
    <p>
      10.1. <strong>Airport Pick-Up</strong>: Go to the car rental company counter in the terminal.
    </p>
    <p>
      10.2. <strong>Free Shuttle</strong>: Some companies offer a free shuttle to their office from the airport.
    </p>
    <p>
      10.3. <strong>Meet &amp; Greet</strong>: An agent may meet you at the airport. They will contact you prior or hold
      a sign with their company logo.
    </p>
    <h4>11. Non-Refundable Rates</h4>
    <p>
      11.1. <strong>Non-Refundable Policy</strong>: Non-refundable reservations often come with lower rates compared to
      flexible bookings. This provides travelers with the opportunity to save money in exchange for committing to their
      reservation without the option of a refund. By offering non-refundable reservations, car rental companies ensure a
      certain level of revenue. This financial security allows them to manage their fleet and operations more
      effectively, knowing that they have guaranteed income from these bookings. Non-refundable reservations discourage
      last-minute cancellations. This allows car rental companies to better plan and allocate their resources, ensuring
      that vehicles are available for committed bookings and reducing the uncertainty of cancellations. Non-refundable
      reservations are a common practice in the travel and hospitality industry. If you book a rental car with
      non-refundable rate, your prepayment will not be refunded even if you cancel your reservation or do not pick up
      the car.
    </p>
    <h4>12. Cancellation Policy</h4>
    <p>
      Flexible Cancellation Policy
      <br />
      1.0. 100% of your prepayment is eligible for Carla Cash credits if you cancel before pick up time.
      <br /> 1.1. 50% of the prepayment amount will be refunded when cancelling at least 48 hours before pickup.
      <br />
      1.2. Prepayment amount is not refundable when cancelling less than 48 hours before pickup.
      <br /> 1.3. If you have purchased Trip Cancellation Coverage (where applicable), you will be fully refunded minus
      the Trip Cancellation Coverage fee when cancelling 6 hours before pick up. <br />
      1.4. You cannot cancel your booking after pickup.
    </p>
    <p>Standard Cancellation Policy</p>
    <p>1.0 This is a promo rate. Your prepayment will not be eligible for refund if you cancel.</p>
    <p>
      You can cancel your reservation on Carla app. Please tap the top left drawer icon on the home screen. Tap “Cancel
      Reservation” under the info tab. Enter your confirmation number and email to cancel. As we are showing rental cars
      from different car rental companies, cancellation policy differs based on the car and company. You can find it
      under Terms &amp; Conditions or Included section. Cancellations and modifications should be done through Carla.
      Any cancellations done with the car rental company may result in ineligibility of getting a refund. CARLA PLUS If
      the client wants to cancel Carla Plus membership after it’s purchased, they won’t be refunded for the existing
      charge however they won’t be charged for further. If the client wants to modify the existing reservation, they
      will be responsible for a $25 modification fee once it’s confirmed.
    </p>
    <p>
      13.1. <strong>Refund Policy</strong>:{' '}
    </p>
    <p>Membership charges for Carla Plus are non-refundable.</p>
    <h4>14. Refund Policy</h4>
    <p>
      Carla does not make a refund of car rental charge in any of the following circumstances: If the client did not
      pick up the car. If the client did not show up to a car rental counter on the pick up day, or showed up late, or
      dropped the car off ahead of schedule. If the client is unable to pick up the car for the reason that some of his
      documents (driving license, passport, credit card, debit card or other ID) do not meet car rental company terms
      and conditions. Please contact the car rental company beforehand for specific information. Some of the car rental
      companies require an international driver’s license. If the client is under the minimum age. The minimum age to
      rent a vehicle at most car rental locations in the United States and Canada is 21 years of age. Please contact the
      car rental company beforehand for specific information on driver ages at the location where you intend to rent and
      the applicable charge for drivers 21 to 24 years of age. If the client is unable to provide a credit card or debit
      card in the driver&#39;s name, or there are not enough funds on it to block a security deposit. Car rental
      companies accept debit cards with supporting documents such as flight ticket, utility bills. Car rental companies
      will not accept prepaid, recharge or virtual credit cards — or any card on which the relevant characters are not
      embossed. If the client’s credit score is not enough to rent a car. If the client was previously blacklisted by
      the car rental company.
    </p>
    <h4>15. Restrictions</h4>
    <p>
      U.S. Law restricts Carla Car Rental from conducting business with individuals or entities located or traveling in
      restricted areas. Businesses or individuals in the following regions and countries are not permitted to make a
      booking or use our platform to show their rental cars:
    </p>
    <ul class='blog-list'>
      <li class='blog-list-item'>Iran</li>
      <li class='blog-list-item'>North Korea</li>
      <li class='blog-list-item'>Syria</li>
      <li class='blog-list-item'>Crimea, region of Ukraine</li>
      <li class='blog-list-item'>Cuba</li>
      <li class='blog-list-item'>Individual nationals of Cuba</li>
      <li class='blog-list-item'>Any other country or region restricted by US law</li>
    </ul>
    <p>
      In compliance with U.S. Law, Carla Car Rental does not conduct business with individuals and entities on a list
      called the Specially Designated Nationals list. We verify identity with car rental companies with face to face
      meetings, email or video conference. We verify the identity of our users with government id verification if
      necessary. If you fail to verify your identity, you may not be able to use our services and platform.
    </p>
    <p>
      Contact: <a href='mailto:carla@rentcarla.com'>carla@rentcarla.com</a>
    </p>
    <p>Enjoy your trip!</p>
    <p>Carla Team</p>
  </>
)
