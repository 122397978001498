import React from 'react'
import loadable from '@loadable/component'
import { El } from '../layout'
import styled from 'styled-components'
import NewLoadingAnimation from './NewLoadingAnimation.json'
import './LoadingScreen.css'
import Profile from '../../util/images/icon-profile.svg'
import useReview from '../../hooks/car/useReview'
import { useTranslation } from 'react-i18next'

const LottieLoader = loadable.lib(() => import('react-lottie-player/dist/LottiePlayerLight'), {
  ssr: false
})

// we are searching over 900 bla bla
const LoadingText = styled(El)`
  font-size: 21px;
  text-align: center;
  color: #525266;
  font-weight: 600;
  line-height: 24px;
  margin-top: 48px;
`

function Review() {
  const { data, isLoading } = useReview()
  if (!data || !data.length || isLoading) return null

  const randomReviewIndex = Math.floor(Math.random() * data.length)
  const { name, review } = data[randomReviewIndex] || data[0]
  const isHiddenReview = !name && !review

  return (
    <>
      {!isHiddenReview && (
        <div className='review'>
          <img src={Profile} alt='profile' width={36} height={36} />
          <div>
            <p className='review-text'>{review}</p>
            <p className='review-name'>{name}</p>
          </div>
        </div>
      )}
    </>
  )
}

export function LoadingScreen() {
  const { t } = useTranslation()
  return (
    <>
      <div style={{ minHeight: '105px', margin: '33px 15px 0px' }}>
        <Review />
      </div>
      <LottieLoader fallback={null}>
        {({ default: Lottie }) => (
          <Lottie
            className='lottie'
            loop
            play
            animationData={NewLoadingAnimation}
            rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
          />
        )}
      </LottieLoader>
      <LoadingText>{t('carListing:bestDeals')}</LoadingText>
    </>
  )
}
