import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import styled from 'styled-components'
import { LogoContainer, Logo } from '../ExclusiveDeals'
import ImageEuropcarLogo from '../../../util/images/image-europcar-logo.png'
import ImageThriftyLogo from '../../../util/images/image-thrifty-logo.png'
import ImageDollarLogo from '../../../util/images/image-dollar-logo.png'
import ImageGoldcarLogo from '../../../util/images/image-goldcar-logo.png'
import ImageLocalSuppliersLogo from '../../../util/images/image-local-suppliers.png'
import ImageCancel from '../../../util/images/image-cancel.png'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'

const mobileStyle = { position: 'fixed', bottom: 0, margin: 0, width: '100%' }

const Container = styled.div`
  background-color: #fff;
  border-radius: ${isMobile ? '12px 12px 0 0' : '12px'};
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: ${isMobile ? '16px' : '32px'};
`

const CancelImage = styled.img`
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 16px;
`

const Header = styled.h1`
  margin: 0;
  color: #ff7d0e;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
`

const Text = styled.p`
  margin: 0;
  color: #6e6c67;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`

const FooterText = styled.p`
  margin: 0;
  color: #525266;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
`

const ListItem = styled.li`
  color: #6e6c67;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`

const List = styled.ul`
  margin: 0;
  list-style-position: inside;
`

export default function ExclusiveDealsModal(props) {
  const { t: TRANSLATOR } = useTranslation()

  return (
    <Dialog
      open={props.isOpen}
      PaperComponent={Container}
      onClose={props.handleClick}
      maxWidth={isMobile ? 'sm' : false}
      PaperProps={isMobile ? { style: mobileStyle } : {}}
    >
      <CancelImage src={ImageCancel} alt='cancel' onClick={props.handleClick} />
      <Header>{TRANSLATOR('xo1:exclusive:header')}</Header>
      <Text>{TRANSLATOR('xo1:exclusive:modal:text')}</Text>
      <LogoContainer>
        <Logo src={ImageEuropcarLogo} alt='europcar logo' height={32} />
        <Logo src={ImageThriftyLogo} alt='thrifty logo' height={23} />
        <Logo src={ImageDollarLogo} alt='dollar logo' height={32} />
        <Logo src={ImageGoldcarLogo} alt='goldcar logo' height={32} />
        <Logo src={ImageLocalSuppliersLogo} alt='local suppliers logo' height={36} />
      </LogoContainer>
      <List>
        <ListItem>{TRANSLATOR('xo1:exclusive:modal:listItem1')}</ListItem>
        <ListItem>{TRANSLATOR('xo1:exclusive:modal:listItem2')}</ListItem>
        <ListItem>{TRANSLATOR('xo1:exclusive:modal:listItem3')}</ListItem>
        <ListItem>{TRANSLATOR('xo1:exclusive:modal:listItem4')}</ListItem>
        <ListItem>{TRANSLATOR('xo1:exclusive:modal:listItem5')}</ListItem>
      </List>
      {isMobile && <FooterText>{TRANSLATOR('xo1:exclusive:footer')}</FooterText>}
    </Dialog>
  )
}
