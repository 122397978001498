import styled from 'styled-components'
import { Checkbox } from '@material-ui/core'

const Text = styled.p`
  margin: 0;
  color: #525266;
`

export const Image = styled.img`
  object-fit: contain;
  line-height: ${(props) => props.lineheight || 'normal'};
  cursor: pointer;
  transition: transform 0.2s;
  &.open {
    transform: rotate(180deg);
    transition: transform 0.2s;
  }
`

export const CategoryHeader = styled(Text)`
  font-weight: 600;
  line-height: normal;
`

export const SubcategoryHeader = styled(Text)`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`

export const Supplier = styled(Text)`
  font-weight: 400;
  line-height: normal;
  margin-left: 8px;
`

export const SelectButton = styled.button`
  white-space: nowrap;
  padding: 0;
  border: none;
  background: none;
  color: #ff7d0e;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-decoration-line: ${(props) => (props.underline ? 'underline' : 'none')};
`

export const CountTag = styled.span`
  font-size: 14px;
  color: #b4b4c2;
`

export const StyledCheckbox = styled(Checkbox)`
  padding: 0 !important;
  & :hover {
    background: none !important;
  }
`

export const TextBanner = styled.p`
  margin: ${(props) => props.m || 0};
  color: #525266;
  font-size: ${(props) => props.size || 16}px;
  font-weight: ${(props) => props.weight || 400};
`

export const Span = styled.span`
  font-size: 14px;
  font-weight: 700;
`

export const ImageBanner = styled.img`
  object-fit: contain;
  margin: ${(props) => props.m || 0};
`

export const Button = styled.button`
  border-radius: 8px;
  border: 0.75px solid #ed8508;
  background: linear-gradient(180deg, #ff963d 0%, #ff7d0e 100%);
  box-shadow: 0px 0px 2px 0px rgba(82, 82, 102, 0.04), 0px 2px 2px 0px rgba(82, 82, 102, 0.05);
  padding: 8px 12px;
  color: #fff;
  white-space: nowrap;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  width: min-content;
`
