import React from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import TermsAndConditions from '../TermsAndConditions'
import SupplierTermsAndConditions from './../../components/SupplierTermsAndConditions'
import { ModalHeader, StyledCloseIcon } from './TermsAndConditionsModalStyles'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import { ReactComponent as CloseIcon } from './assets/icon-close-primary.svg'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`term-and-condition-tabpanel-${index}`}
      aria-labelledby={`term-and-condition-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  )
}

function a11yProps(index) {
  return {
    id: `term-and-condition-tab-${index}`,
    'aria-controls': `term-and-condition-tabpanel-${index}`
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#ffffff',
    '.MuiTabs-root': {
      justifyContent: 'space-around'
    }
  },
  colorPrimary: {
    '& .MuiTabs-flexContainer': {
      justifyContent: 'space-around',
      background: '#ffffff',
      '& .MuiTab-root': {
        flex: 1,
        maxWidth: '50%',
        background: '#ffffff',
        color: '#525266',
        textAlign: 'center',
        fontSize: '15px',
        fontWeight: 600,
        lineHeight: '20px',
        opacity: 1,
        textTransform: 'capitalize',
        '& .MuiTab-wrapper': {
          wordBreak: 'break-word'
        }
      }
    },
    '& .MuiTabs-indicator': {
      background: '#FF7D0E'
    }
  },
  tabPanel: {
    height: '3000px' //hmm
  }
}))

export default function TermsAndConditionsModal({ onClose }) {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)
  const { t: TRANSLATOR } = useTranslation()
  const { descriptionList } = useSelector((state) => state.carRentalProduct.extras.supplierRentalPolicy)

  const isShowSupplierTerms = !isEmpty(descriptionList) && !isEmpty(descriptionList?.[0]?.description)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <div className={classes.root}>
      <ModalHeader>
        <h1>{TRANSLATOR('xo1:termsAndConditions')}</h1>
        <StyledCloseIcon onClick={onClose}>
          <CloseIcon />
        </StyledCloseIcon>
      </ModalHeader>
      <AppBar position='static' className={classes.colorPrimary}>
        <Tabs value={value} onChange={handleChange}>
          <Tab disableRipple label={TRANSLATOR('xo1:carlaTermsAndConditions')} {...a11yProps(0)} />
          {isShowSupplierTerms && (
            <Tab disableRipple label={TRANSLATOR('xo1:supplierTermsAndConditions')} {...a11yProps(1)} />
          )}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <TermsAndConditions />
      </TabPanel>
      {isShowSupplierTerms && (
        <TabPanel value={value} index={1} className={classes.tabPanel}>
          <SupplierTermsAndConditions />
        </TabPanel>
      )}
    </div>
  )
}
