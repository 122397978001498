import styled from 'styled-components'
import { CardFlexEl, El, FlexEl, OrangeLink } from '../layout'
import util from '../../util'
import { Insurance, Seat } from '../svg'
import React, { Fragment } from 'react'
import { Info } from '../CarListing/CarCard'
import { themeGet } from 'styled-system'
import GA from '../../services/GA'
import { Form } from 'formik'
import { FadeModal } from '../Modal'
import { ImportantInfoModal } from './modals/ImportantInfoModal'
import { InclusionsList } from './InclusionsList'
import Loading from '../Loading'
import { ModalType, shouldShowTripleInsurance } from '../../routes/XO1'
import { AncillarySelectionCard } from './AncillarySelectionCard'
import ReactLoading from 'react-loading'
import { theme } from '../../styles'
import { MdCheck as CheckIcon } from 'react-icons/md'
import { ReactComponent as IconLimited } from './../../util/images/icon_limited.svg'
import Cookies from 'js-cookie'
import Default from '../../util/images/placeholder-car.png'
import { ReactComponent as Automatic } from '../../util/images/icon-automatic.svg'
import { ReactComponent as Manuel } from '../../util/images/icon-manual.svg'
import { useTranslation } from 'react-i18next'
import ExclusiveDeals from './ExclusiveDeals'
import { isMobile } from 'react-device-detect'
import ExclusiveDealsModal from './modals/ExclusiveDealsModal'
import { InfoIcon } from './ExclusiveDeals'
import IconInfoOrange from '../../util/images/icon-info-orange.svg'
import InfoIconGreen from '../../util/images/icon-info-green.svg'
import { TooltipUI } from '../../UI/Tooltip'
import TermsAndConditionsModal from './../../components/TermsAndConditionsModal'
import { Dialog } from '@material-ui/core'
import { InsuranceList, ProtectionStatusCard } from '../Insurance'
import ImageEuropcarLogo from '../../util/images/image-europcar-logo.png'
import { AncillaryInfo } from '../Insurance/styles'

export const FreeCancelLabel = styled.div`
  border-radius: 8px;
  background: ${(props) => props.$bgColor || '#d5f8e8'};
  padding: 6px 8px;
  line-height: normal;
  font-size: 13px;
  font-weight: 600;
  color: ${(props) => props.$color || '#035830'};
  flex: 1;
  width: min-content;
  white-space: nowrap;
  text-align: center;
  align-items: center;
  align-content: center;
  display: flex;
  gap: 4px;
  justify-content: center;
  cursor: ${(props) => (props.$icon ? 'pointer' : 'default')};

  & :after {
    content: ${(props) => (props.$icon && props.$show ? `url(${InfoIconGreen})` : '')};
    height: 16px;
    width: 16px;
    margin-left: 4px;
  }

  @media screen and (min-width: 576px) and (max-width: 980px), screen and (max-width: 479px) {
    width: 100%;
  }
`

export const CarDetailsCard = styled(
  ({
    carRentalProduct,
    ancillaryProducts,
    selectedAncillaries,
    updatedSelections,
    selectedInsurance,
    deselectedInsurance,
    modal,
    openModal,
    closeModal,
    isLoading,
    values,
    validateInsuranceList,
    hasError,
    ...props
  }) => {
    const tripleInsurance = ancillaryProducts && shouldShowTripleInsurance(ancillaryProducts)
    const [isOpen, setIsOpen] = React.useState(false) // for ExclusiveDealsModal
    const [isTermsOpen, setIsTermsOpen] = React.useState(false) // for TermsAndConditions
    const { t } = useTranslation()

    // This is needed for correct icon for transmisssion because it is based on english string matching
    const transmissonTranslateMap = ['Manuelle', 'Manual', 'Manuale', 'Manuel']
    const filteredAncillaries = ancillaryProducts?.filter((ap) => ap.type !== 'Insurance')
    const insurances = ancillaryProducts?.filter((ap) => ap.type === 'Insurance')

    const {
      car,
      supplier,
      extras,
      dependencies: { brokerName }
    } = carRentalProduct
    const isSixtInsured = supplier.name === 'Sixt' && extras?.insured === true
    let utmSource = Cookies.get('utm_source')
    const flexibleCancellationTranslationMap = ['flexible', 'flessibile', 'flexível', 'flexibel', 'esnek']
    const isFreeCancel = flexibleCancellationTranslationMap.some((val) =>
      extras?.cancellationPolicy?.toLowerCase().includes(val)
    )

    function handleClick() {
      if (supplier.name === 'Exclusive' && brokerName !== 'Europcar') setIsOpen(!isOpen)
    }

    const supplierUrl =
      supplier.name === 'Exclusive' && brokerName === 'Europcar' ? ImageEuropcarLogo : util.toHttps(supplier.logoUrl)

    return (
      <CardFlexEl as={Form} className={props.className}>
        <FlexEl className='top-container' style={{ justifyContent: 'space-between' }}>
          <FlexEl className='name-group-container' style={{ flexGrow: 0 }}>
            <El className='card-title'>{t('xo1:carDetails')}</El>
            <El mt='12px'>{car.groupDescription}</El>
            <El mt='4px'>{car.name}</El>
          </FlexEl>
          <FlexEl className='total-price-container' style={{ alignItems: 'start', gap: 4 }}>
            <img src={supplierUrl} alt={'rent_carla_calendar_icon'} className='supplier-logo' onClick={handleClick} />
            {supplier.name === 'Exclusive' && isMobile && brokerName !== 'Europcar' && (
              <InfoIcon src={IconInfoOrange} alt='info icon' onClick={handleClick} style={{ marginTop: 11 }} />
            )}
          </FlexEl>
        </FlexEl>
        <FlexEl className='body-container'>
          <FlexEl className='car-img-container'>
            <img
              className='car-img'
              src={util.toHttps(car.imageUrl)}
              alt={car.name}
              onError={(e) => (e.target.src = Default)}
            />
          </FlexEl>
          <FlexEl className='info-container'>
            <Info
              icon={transmissonTranslateMap.includes(car.transmissionDescription) ? <Manuel /> : <Automatic />}
              description={car.transmissionDescription}
            />
            <Info icon={<Seat />} description={car.seatCount} />
            {carRentalProduct.extras.insured && (
              <Info
                icon={<Insurance />}
                description={isSixtInsured ? t('carListing:protection') : t('carListing:insurance')}
              />
            )}
          </FlexEl>
        </FlexEl>
        <El>
          {!ancillaryProducts ? (
            <El style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '12px 24px' }}>
              <ReactLoading
                className='loading-animation'
                type={'spin'}
                height={30}
                width={30}
                color={`${theme.colors.colorPrimary}`}
              />
            </El>
          ) : (
            <>
              {supplier.name === 'Exclusive' && brokerName !== 'Europcar' && !isMobile && <ExclusiveDeals />}
              <ExclusiveDealsModal isOpen={isOpen} handleClick={handleClick} />
              <FlexEl className='inclusions-container'>
                {/* // TODO: refactor */}
                {utmSource === 'skyscanner' && (
                  <FlexEl
                    className='badge-container'
                    justifyContent='space-between'
                    style={{ gap: '12px', marginBottom: '24px' }}
                  >
                    {isFreeCancel && (
                      <TooltipUI title={carRentalProduct.freeCancellationInfo?.freeCancellationInfoText}>
                        <FreeCancelLabel
                          $color='#035830'
                          $bgColor='#D5F8E8'
                          $icon={true}
                          $show={!!carRentalProduct.freeCancellationInfo}
                        >
                          <CheckIcon style={{ color: '#035830', fontSize: '16px' }} />
                          {t('xo1:freeCancel')}
                        </FreeCancelLabel>
                      </TooltipUI>
                    )}
                    <FreeCancelLabel $color='#FF7D0E' $bgColor='#FDEFE9'>
                      <IconLimited />
                      {t('xo1:confirmation')}
                    </FreeCancelLabel>
                  </FlexEl>
                )}
                {!carRentalProduct.needsModification && (
                  <InclusionsList
                    inclusions={carRentalProduct.inclusions}
                    selectedAncillaries={selectedAncillaries}
                    ancillaryProducts={ancillaryProducts}
                  />
                )}
                {/* Term and condition popup */}
                <OrangeLink
                  style={{ margin: '12px 0px 0px 0px', padding: 0 }}
                  onClick={() => {
                    setIsTermsOpen(true)
                    GA.openTermsConditions()
                  }}
                >
                  <u>{t('xo1:termsAndConditions')}</u>
                </OrangeLink>
                <Dialog open={isTermsOpen} onClose={() => setIsTermsOpen(false)} fullWidth maxWidth='md'>
                  <TermsAndConditionsModal onClose={() => setIsTermsOpen(false)} />
                </Dialog>
                {carRentalProduct.extras.importantInfo && (
                  <Fragment>
                    <OrangeLink onClick={() => openModal(ModalType.IMPORTANT_INFO)}>
                      <u>{t('xo1:importantInfo')}</u>
                    </OrangeLink>
                    <FadeModal
                      isOpen={modal === ModalType.IMPORTANT_INFO}
                      closable={true}
                      ok={true}
                      onClose={closeModal}
                      width={750}
                    >
                      <ImportantInfoModal importantInfo={carRentalProduct.extras.importantInfo} />
                    </FadeModal>
                  </Fragment>
                )}
              </FlexEl>
              {carRentalProduct.dependencies.brokerName !== 'Carla-HDSERV' && (
                <FlexEl className='additional-products-container'>
                  <FlexEl className='insurance-card'>
                    {isLoading && (
                      <El my={25} mx='auto' width={50}>
                        <Loading />
                      </El>
                    )}
                  </FlexEl>
                  <ProtectionStatusCard validateInsuranceList={validateInsuranceList} />
                  {ancillaryProducts && tripleInsurance && (
                    <El px={['12px', '24px']} py='12px' style={{ borderBottom: '1px solid var(--separatorGrey)' }}>
                      <El as='p' m='0 0 12px' fontSize={16} fontWeight={500} color={theme.colors.colorPrimary}>
                        {t('xo1:getProtection')}
                      </El>
                      {hasError && (
                        <El as='p' color='#C1272D' my='12px' fontSize={14} fontWeight={600}>
                          {t('xo1:chooseOption')}
                        </El>
                      )}
                      <InsuranceList
                        insurances={insurances}
                        validateInsuranceList={validateInsuranceList}
                        hasError={hasError}
                      />
                    </El>
                  )}
                  {ancillaryProducts && (
                    <>
                      <El as='p' m='12px 0 0 24px' fontSize={16} fontWeight={500} color={theme.colors.colorPrimary}>
                        {t('xo1:extras')}
                      </El>
                      <AncillaryInfo>{t('xo1:ancillaryInfo')}</AncillaryInfo>
                      {filteredAncillaries?.map((ap, i) => (
                        <FlexEl
                          key={i}
                          className={i + 1 === filteredAncillaries.length ? 'final-insurance-card' : 'insurance-card'}
                        >
                          <AncillarySelectionCard
                            ancillaryProduct={ap}
                            currency={carRentalProduct.price.currency}
                            updatedSelections={updatedSelections}
                            modal={modal}
                            openModal={openModal}
                            closeModal={closeModal}
                            selectedAncillaries={selectedAncillaries}
                          />
                        </FlexEl>
                      ))}
                    </>
                  )}
                </FlexEl>
              )}
            </>
          )}
        </El>
      </CardFlexEl>
    )
  }
)`
  flex-direction: column;
  width: 100%;
  border-radius: 12px;
  padding: 16px 0px 16px 0px;
  color: ${themeGet('colors.textBlack')};

  @media screen and (max-width: ${themeGet('breakpoints.0')}px) {
    .top-container {
      padding: 0px 12px !important;
    }

    .inclusions-container {
      padding: 12px 12px 0px 12px !important;
    }

    .insurance-card {
      padding: 12px !important;
    }

    .final-insurance-card {
      padding: 12px 12px 0px 12px !important;
    }
  }

  .top-container {
    padding: 0px 24px;
  }

  .card-title {
    color: ${themeGet('colors.colorPrimary')};
    font-size: 16px;
  }

  .body-container {
    padding: 10px 24px 12px 24px;
    border-bottom: solid 1px ${themeGet('colors.separatorGrey')};
  }

  .car-img {
    max-width: 100%;
    max-height: 144px;
    object-fit: contain;
  }

  .info-container {
    flex-flow: column;
    color: ${themeGet('colors.textBlack')};
    font-size: 15px;
    margin-left: 24px;
  }

  .name-group-container {
    flex-flow: column;
    font-size: 15px;
    font-weight: 500;
    flex-grow: 1;
  }

  .total-price-container {
    object-fit: contain;
  }

  .inclusions-container {
    flex-flow: column;
    padding: 12px 24px 0px 24px;
  }
  .additional-products-container {
    flex-flow: column;
  }

  .insurance-card {
    flex-flow: column;
    padding: 12px 24px 12px 24px;
    z-index: 1.5;
    border-bottom: solid 1px ${themeGet('colors.separatorGrey')};
  }

  .final-insurance-card {
    flex-flow: column;
    padding: 12px 24px 0px 24px;
  }

  .trip-protection-card {
    flex-flow: column;
    padding: 12px 24px 12px 24px;
    border-bottom: solid 1px ${themeGet('colors.separatorGrey')};
  }
  .pom-card {
    flex-flow: column;
    padding: 12px 24px 0px 24px;
  }

  .supplier-logo {
    max-width: 150px;
    max-height: 40px;
    cursor: ${(props) => props.carRentalProduct.supplier.name === 'Exclusive' && 'pointer'};
  }

  .badge-container {
    @media screen and (min-width: 576px) and (max-width: 980px), screen and (max-width: 479px) {
      flex-direction: column;
    }
  }
`
