import React from 'react'
import loadable from '@loadable/component'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const SlickLoader = loadable.lib(() => import('react-slick'))

function SlickSlider(props) {
  return <SlickLoader fallback={null}>{({ default: Slick }) => <Slick {...props} />}</SlickLoader>
}

export default SlickSlider
