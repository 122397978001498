import React, { Component } from 'react'
import Downshift from 'downshift'
import { connect } from 'react-redux'
import debug from 'debug'
import { InputDS, LabelDS, MenuDS, MenuPlaceholderDS, SelectContainerDS } from '../../CarlaSelect'
import { El, FlexEl } from '../../layout'
import { selectSearchArgs } from '../../../redux/selectors'
import { filterDropOffLocations, formatItem, loadLocationItems } from '../../../util/searchArgs'
import Loading from '../../Loading'
import { Location, Plane } from '../../svg'
import {
  setNearMePicked,
  setNearMeLatitude,
  setNearMeLongitude,
  setSearchDropOffLocation,
  setSearchPickupLocation,
  setLocationSearchItems,
  startLocationSearch,
  setSameLocation
} from '../../../redux/slices'
import GA from '../../../services/GA'
import debounce from 'lodash/debounce'
import trim from 'lodash/trim'
import styled from 'styled-components'
import OrangeCheck from '../../../util/images/icon-check-stroke.svg'
import LocationIcon from '../../../util/images/icon-location-oneway-pickup.svg'
import NoLocationFoundImg from '../../../util/images/icon-lamb.svg'
import API from '../../../services/API'
import { themeGet } from 'styled-system'
import { idDataMap } from '../../../util/dynamicLandingPageData'

const log = debug('carla:search')

const InputArea = styled(InputDS)`
  width: 100% !important;
  border: none !important;
  background-color: ${(props) => (props.shadowEffect ? '#EFF1F8 !important' : '')};
  padding-left: 44px !important;
`

const CheckBoxWrapper = styled.div`
  margin-top: 10px;
  display: ${(props) => (props.doesPopUpTranslatesUp ? 'none' : '')};
`

const CheckBox = styled.div`
  height: 30px;
  width: 30px;
  background-color: ${(props) => (props.isPageDynamic ? '#eff1f8' : 'white')};
  border-radius: 5px;
  display: inline-block;
  cursor: pointer;

  // cehckbox has shadow if it is in dynamic landing page
  -webkit-box-shadow: ${(props) => (props.isPageDynamic ? '0px 4px 4px 0px rgba(0,0,0,0.25)' : '')};
  -moz-box-shadow: ${(props) => (props.isPageDynamic ? '0px 4px 4px 0px rgba(0,0,0,0.25)' : '')};
  box-shadow: ${(props) => (props.isPageDynamic ? '0px 4px 4px 0px rgba(0,0,0,0.25)' : '')};
`

const CheckBoxImg = styled.img`
  height: 18px;
  width: auto;
  display: inline-block;
  margin-left: 3px;
  margin-top: 6px;
`

const CloseDifferentLocationButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 8px;
  background: none;
  border: none;
  font-size: 20px;
  color: ${themeGet('colors.textBlack')};
  font-weight: 600;
`

const PickUpLocationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: flex-end;
  border-radius: ${(props) => (props.carSearchPage ? '12px' : '12px 0px 0px 12px')};
  margin-top: 0px;
  background-color: ${(props) => (props.shadowEffect ? '#EFF1F8 !important' : 'white')};
  border: ${(props) => (props.isPageDynamic ? '1px solid #dfdfe7' : 'none')};
  border-right: none;
  overflow: hidden;

  &::before {
    position: absolute;
    width: 100%;
    height: 50px;
    content: '';
    bottom: 0;
    left: 0;
    border-radius: 12px 0px 0px 12px;
    z-index: -5;

    // location input desktop outside shadow
    -webkit-box-shadow: -2px 4px 4px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: -2px 4px 4px 0px rgba(0, 0, 0, 0.25);
    box-shadow: -3px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
`

const DifferentLocationWrapper = styled.div`
  position: relative;
  display: flex;
  display: ${(props) => (props.doesPopUpTranslatesUp ? 'none' : 'flex')};
  flex-direction: row;
  justify-content: flex-end;
  border-radius: 12px;
  margin-top: 10px;
  background: url(${LocationIcon});
  background-repeat: no-repeat;
  background-color: ${(props) => (props.shadowEffect ? '#EFF1F8' : 'white')};
  background-position: 3% 50%;
  border: ${(props) => (props.isPageDynamic ? '1px solid #dfdfe7' : 'none')};
  overflow: hidden;

  // different location input desktop outside shadow
  -webkit-box-shadow: 0px 4.41px 4.41px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 4.41px 4.41px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4.41px 4.41px 0px rgba(0, 0, 0, 0.25);
`

const NoLocationFoundImage = styled.img`
  display: block;
  height: 70px;
  width: auto;
  margin: 10px auto;
  margin-bottom: 30px;
`

const NoLocationFoundText = styled.div`
  width: 80%;
  font-size: 20px;
  text-align: center;
  margin: 0px auto;
`

const PopularLocationsTitle = styled.div`
  font-family: 'Quicksand', 'Quicksand Regular', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: ${themeGet('colors.colorPrimary')};
  margin: 20px;
  margin-bottom: 10px;
`

const InputAreaWithShadow = styled(InputArea)`
  // pickup location select desktop inside shadow
  -webkit-box-shadow: ${(props) => (props.isPageDynamic ? 'none' : 'inset -4px 0px 4px 0px rgba(0,0,0,0.25)')};
  -moz-box-shadow: ${(props) => (props.isPageDynamic ? 'none' : 'inset -4px 0px 4px 0px rgba(0,0,0,0.25)')};
  box-shadow: ${(props) => (props.isPageDynamic ? 'none' : 'inset -4px 0px 4px 0px rgba(0,0,0,0.25)')};
  background-color: ${(props) => (props.shadowEffect ? '#EFF1F8 !important' : 'white')};
  font-size: ${(props) => (props.carSearchPage ? '13px !important' : '16px')};
`

const SameLocationText = styled.div`
  display: inline;
  margin-left: 10px;
  color: ${(props) => (props.isPageDynamic ? 'black' : 'white')};
  font-size: 18px;
  font-weight: 500;
  cursor: default;
`

// Disable near-me
// const FindCarsNearMe = styled.li`
//   display: flex;
//   flex-direction: row;
//   justify-content: flex-start;
//   align-items: center;
//   gap: 0.5rem;
//   color: #ff7d0e;
//   font-weight: 600;
//   font-size: 18px;
//   width: 100%;
//   padding: 0 3%;
//   margin: 0 auto;
//   border-bottom: 1px solid #eff1f8;
//   cursor: pointer;
// `;

const StyledLabelDS = styled(LabelDS)`
  text-transform: none !important;
  font-size: 18px !important;
  font-family: 'Quicksand', 'Quicksand Regular', sans-serif;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-bottom: 5px;
  color: ${(props) => (props.isPageDynamic ? '#525266 !important' : 'white !important')};
`

class RawLocationForm extends Component {
  componentDidMount() {
    const path = window.location.pathname
    path === '/' && this.props.setSameLocation && this.props.setSameLocation(true)
  }

  state = {
    current: null, // current focused field of location range (from|to|null)
    inputValue: '',
    isSameLocation: true,
    popularLocations: null,
    showNoLocationFound: true,
    location: ''
  }

  handleCheckbox = () => {
    this.setState({ isSameLocation: false })
    this.props.setSameLocation(false)
  }

  SameLocationButton = (doesPopUpTranslatesUp) => (
    <CheckBoxWrapper doesPopUpTranslatesUp={doesPopUpTranslatesUp}>
      <CheckBox onClick={this.handleCheckbox} isPageDynamic={this.props.isPageDynamic}>
        <CheckBoxImg src={OrangeCheck} alt='orange-check-icon' />
      </CheckBox>
      <SameLocationText isPageDynamic={this.props.isPageDynamic}>Drop off at the same location</SameLocationText>
    </CheckBoxWrapper>
  )

  isFocused = (key) => this.state.current === key // effects SelectDS

  handleInputFocus = (key) => (e) => {
    this.setState({ current: key, inputValue: '' })
    this.props.isCarSearchPage && this.props.setShowOnMobile(false)
  }
  handleInputBlur = (e) => {
    if (e.relatedTarget && e.relatedTarget.id === 'near-me-button') return
    this.setState({ current: null, inputValue: '' })
  }

  handleLocationChange = async (val) => {
    /*
    if (val.value === 999) {
      try {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            async position => {
              const { latitude, longitude } = position.coords;
              this.props.setNearMeLatitude(latitude);
              this.props.setNearMeLongitude(longitude);
              this.props.setNearMePicked(true);
              const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyAoWOdrpKdDwlCknWreK8awMy9NdZ59-Qc`;
              try {
                const response = await fetch(apiUrl);
                const data = await response.json();

                const city = data.results.filter(item =>
                  item.address_components.find(component =>
                    component.types.includes("locality")
                  )
                );
                const foundCity = city[0].address_components.filter(item =>
                  item.types.includes("locality")
                );
                const cityName = foundCity[0].long_name;
                const location = {
                  label: cityName,
                  value: 999
                };
                this.props.setSearchPickupLocation(location);
                this.props.setSearchDropOffLocation(location);
              } catch (error) {
                console.log("Error:", error);
              }
            },
            error => {
              alert(
                "Please make sure that location permission is allowed for your device to use this feature. \nYou can update location permission through: \nSystem Settings > Location Services"
              );
            }
          );
        } else {
          console.error("not location access.");
        }
      } catch (error) {
        console.error("Error", error);
      }
    }
*/
    if (this.isFocused('from')) this.props.setSearchPickupLocation(val)
    if (this.isFocused('to') || this.state.isSameLocation) this.props.setSearchDropOffLocation(val)
    this.setState({ current: null, inputValue: '' })
    this.pickupInput.blur()
    if (this.dropOffInput) this.dropOffInput.blur()
    this.props.isCarSearchPage && this.props.locationChange(true)
  }

  // @TODO DRY
  handleInputChange = (e) => {
    this.setState({ showNoLocationFound: false })
    const inputValue = e.target.value
    this.setState({ inputValue })
    const input = trim(inputValue)

    this.requestAPI(input)
  }

  // call item loading functions with 300ms delay / @TODO DRY
  requestAPI = debounce(
    (input) => {
      if (!input || !input.trim()) return
      this.props.startLocationSearch()
      loadLocationItems(input)
        .then((items) => {
          if (items && items.length > 0) {
            this.props.setLocationSearchItems(items)
          } else {
            log('no location found', items)
            GA.noLocationFound()
            this.props.setLocationSearchItems(null)
          }
        })
        .catch((err) => {
          log('error during car search', err)
          this.props.setLocationSearchItems(null)
        })

      this.setState({ showNoLocationFound: true })
    },
    500,
    { trailing: true, leading: false }
  )

  getPopularLocations = async () => {
    const response = await API.getPopularLocations()
    this.setState({
      popularLocations:
        response &&
        response.map((item) => {
          const locationObj = {
            value: item.id,
            label: item.prettyName,
            type: item.type
          }
          return locationObj
        })
    })
  }

  getDynamicPopularLocations = async () => {
    const url = window.location.pathname
    const id = idDataMap[url].id
    const response = await API.getDynamicPopularLocations(id)
    this.setState({
      popularLocations:
        response &&
        response.map((item) => {
          const locationObj = {
            value: item.id,
            label: item.prettyName,
            type: item.type
          }
          return locationObj
        })
    })
  }

  render() {
    const {
      pickupDate,
      dropOffDate,
      age,
      locationSearch,
      pickupLocation,
      locationChange,
      dropOffLocation,
      optimize,
      sameLocationField,
      isCarSearchPage,
      ...props
    } = this.props
    const { items, isLoading, error } = locationSearch
    const filteredItems = this.isFocused('to') ? filterDropOffLocations(items) : items
    const isOpen = Boolean(this.state.current)

    if (!this.state.popularLocations) {
      if (this.props.isPageDynamic) this.getDynamicPopularLocations()
      else this.getPopularLocations()
    }

    const PopularLocations = this.state.popularLocations

    const handleCheck = () => {
      this.setState({ isSameLocation: true })
      props.setSameLocation(true)
    }

    return (
      <Downshift
        onChange={this.handleLocationChange}
        items={filteredItems}
        itemToString={(item) => (item ? item.label : '')}
        isOpen={isOpen}
        {...props}
      >
        {({ getRootProps, getMenuProps, getItemProps, getInputProps }) => {
          let doesPopUpTranslatesUp = false
          let pickupInput = ''
          let dropOffInput = ''
          let dropOffLocationInput = ''
          if (pickupLocation) pickupInput = pickupLocation.label
          if (dropOffLocation) dropOffInput = dropOffLocation.label
          if (dropOffLocation) dropOffLocationInput = dropOffLocation.label
          if (
            (pickupLocation && dropOffLocation && pickupLocation.label === dropOffLocation.label) ||
            this.state.isSameLocation
          ) {
            dropOffInput = 'Same location'
          }
          if (this.isFocused('from')) {
            pickupInput = this.state.inputValue
            doesPopUpTranslatesUp = true
          }
          if (this.isFocused('to')) {
            dropOffLocationInput = this.state.inputValue
            dropOffInput = this.state.inputValue
          }

          const pickupFormClass = `form-field left ${this.isFocused('from') ? 'focus' : ''}`
          const dropOffFormClass = `form-field right ${this.isFocused('to') ? 'focus' : ''}`

          return (
            <SelectContainerDS {...getRootProps()}>
              <div className='location-form-container'>
                <FlexEl flexDirection='column' width={1}>
                  <El width={1} className={pickupFormClass}>
                    {!isCarSearchPage && (
                      <StyledLabelDS isPageDynamic={this.props.isPageDynamic}>
                        {this.state.isSameLocation ? 'Pick Up location' : 'Pick up & drop off location'}
                      </StyledLabelDS>
                    )}
                    <PickUpLocationWrapper
                      isPageDynamic={this.props.isPageDynamic}
                      shadowEffect={this.isFocused('from')}
                      carSearchPage={isCarSearchPage}
                    >
                      <img
                        src={LocationIcon}
                        alt={'rent_carla_location_icon'}
                        style={{
                          position: 'absolute',
                          left: '10px',
                          top: '14px',
                          width: '22px',
                          zIndex: 10,
                          transform: sameLocationField && 'scaleX(-1)'
                        }}
                      />
                      <InputAreaWithShadow
                        isPageDynamic={!isCarSearchPage ? this.props.isPageDynamic : true}
                        shadowEffect={this.isFocused('from')}
                        {...getInputProps()}
                        placeholder='Enter city or airport'
                        value={sameLocationField ? dropOffLocationInput : pickupInput}
                        innerRef={(node) => (this.pickupInput = node)}
                        onFocus={this.handleInputFocus(sameLocationField ? 'to' : 'from')}
                        onChange={this.handleInputChange}
                        onBlur={this.handleInputBlur}
                        carSearchPage={isCarSearchPage}
                      />
                    </PickUpLocationWrapper>
                  </El>
                  <El width={1} className={dropOffFormClass}>
                    {!this.state.isSameLocation ? (
                      <DifferentLocationWrapper
                        isPageDynamic={this.props.isPageDynamic}
                        shadowEffect={this.isFocused('to')}
                        doesPopUpTranslatesUp={doesPopUpTranslatesUp}
                      >
                        <InputArea
                          shadowEffect={this.isFocused('to')}
                          {...getInputProps()}
                          placeholder='Enter city or airport'
                          value={dropOffInput}
                          innerRef={(node) => (this.dropOffInput = node)}
                          onFocus={this.handleInputFocus('to')}
                          onChange={this.handleInputChange}
                          onBlur={this.handleInputBlur}
                        />
                        <CloseDifferentLocationButton onClick={handleCheck}>X</CloseDifferentLocationButton>
                      </DifferentLocationWrapper>
                    ) : (
                      !isCarSearchPage && this.SameLocationButton(doesPopUpTranslatesUp)
                    )}
                  </El>
                </FlexEl>
              </div>
              {isOpen && (
                <MenuDS isOpen={isOpen} as='ul' className='items' {...getMenuProps()}>
                  {isLoading && (
                    <MenuPlaceholderDS>
                      <Loading />
                    </MenuPlaceholderDS>
                  )}
                  {this.state.showNoLocationFound &&
                    (!isLoading && !filteredItems && (error || (this.state.inputValue && !items)) ? (
                      <MenuPlaceholderDS>
                        <NoLocationFoundImage src={NoLocationFoundImg} />
                        <NoLocationFoundText>{"We couldn't find the location"}</NoLocationFoundText>
                        <NoLocationFoundText>{'you are searching. Try entering'}</NoLocationFoundText>
                        <NoLocationFoundText>{'a city or an airport.'}</NoLocationFoundText>
                      </MenuPlaceholderDS>
                    ) : !isLoading && filteredItems ? (
                      filteredItems.map((item, index) => (
                        <li className='item' {...getItemProps({ key: item.value, item, index })}>
                          <FlexEl justifyContent='flex-start' style={{ gap: '10px' }}>
                            <El>{item.type === 'airport' ? <Plane /> : <Location />}</El>
                            <El>{formatItem(item, this.state.inputValue)}</El>
                          </FlexEl>
                        </li>
                      ))
                    ) : (
                      PopularLocations &&
                      !isLoading && (
                        <div>
                          {/*
                          You wanna get back "Near Me" you can use this code
                            !isCarSearchPage && (
                            <FindCarsNearMe
                              {...getItemProps({
                                key: "findcarsnearme",
                                item: {
                                  label: `Finding cars near you`,
                                  value: 999
                                },
                                index: "999"
                              })}
                              className="find-cars-near-me"
                            >
                              <img src={iconNearme} alt="" />
                              <p>Find cars near me</p>
                            </FindCarsNearMe>
                          )
                          */}
                          <PopularLocationsTitle>Popular Locations</PopularLocationsTitle>
                          {PopularLocations.map((item, index) => (
                            <li
                              className='item'
                              {...getItemProps({
                                key: item.value,
                                item,
                                index
                              })}
                            >
                              <FlexEl justifyContent='flex-start' style={{ gap: '10px' }}>
                                <El>{item.type === 'airport' ? <Plane /> : <Location />}</El>
                                <El>{formatItem(item, this.state.inputValue)}</El>
                              </FlexEl>
                            </li>
                          ))}
                        </div>
                      )
                    ))}
                </MenuDS>
              )}
            </SelectContainerDS>
          )
        }}
      </Downshift>
    )
  }
}

const LocationField = connect(selectSearchArgs, {
  setSearchPickupLocation,
  setSameLocation,
  setSearchDropOffLocation,
  startLocationSearch,
  setLocationSearchItems,
  setNearMePicked,
  setNearMeLatitude,
  setNearMeLongitude
})(RawLocationForm)

export default LocationField
