import React, { useMemo } from 'react'
import loadable from '@loadable/component'
import util from '../../util'
import styled from 'styled-components'
import GA from '../../services/GA'
import { LoadingScreen } from '../../components/CarListing/LoadingScreen'
import Pickup from '../../util/images/icon-pickup.svg'
import Dropoff from '../../util/images/icon-dropoff.svg'
import Location from '../../util/images/icon-location.svg'
import Calendar from '../../util/images/icon-calendar-light.svg'
import { useTranslation } from 'react-i18next'
import useAreaSearchQuery from './query'
import { MobileHeaderBar, NearMeInfo, NearMeInfoMobile } from './styles'

//Styling
import './AvailableAreas.css'

const GoogleMap = loadable(() => import('./../../components/NewGoogleMap'), {
  ssr: false,
  resolveComponent: (components) => components.NewGoogleMap
})

const AvailableAreas = styled((props) => {
  const { t, i18n } = useTranslation()
  const { downtownCars, loading, pickupLocation, pickupDate, dropOffDate, age, nearMePicked, nearMeCoords } =
    useAreaSearchQuery()

  function getAllCarsOfArea() {
    const allCars = { cars: [] }
    downtownCars.cars.forEach((car) => {
      allCars.cars.push(...car.cars)
    })
    return allCars
  }

  function handleNoCars() {
    let url = `/rental-cars/${pickupLocation.id}/${pickupLocation.id}/${pickupDate}/${dropOffDate}`
    if (age && Number(age) < 25) url += `?age=${age}`
    if (downtownCars.cars.length === 0) {
      props.history.replace(url, { from: 'nocars', cars: [] })
    }
    if (downtownCars.cars.length === 1) {
      const check = downtownCars.cars[0].cars.every(
        (car) => car.dependencies.brokerName === 'Carla-HDNC' || car.dependencies.brokerName === 'Carla-HDSERV'
      )
      if (check) {
        props.history.replace(url, { from: 'availableAreas', cars: downtownCars.cars[0] })
      }
    }
  }

  const handleListAllClick = () => {
    try {
      let url = `/rental-cars/${pickupLocation.id}/${pickupLocation.id}/${pickupDate}/${dropOffDate}`
      if (age && Number(age) < 25) url += `?age=${age}`
      const allCars = getAllCarsOfArea().cars
      props.history.push(url, {
        from: 'availableAreas',
        cars: {
          cars: allCars,
          flexibleCancellationPolicy: undefined,
          strictCancellationPolicy: undefined,
          termsPartOne: undefined,
          termsPartTwo: undefined
        }
      })
    } catch (err) {
      console.error('error while selecting location', err)
    }
  }

  const handleClick = (carIndex) => {
    GA.clickAnother()

    try {
      let url = `/rental-cars/${pickupLocation.id}/${pickupLocation.id}/${pickupDate}/${dropOffDate}`
      if (age && Number(age) < 25) url += `?age=${age}`
      props.history.push(url, { from: 'availableAreas', cars: downtownCars.cars[carIndex] })
    } catch (err) {
      console.error('error while selecting location', err)
    }
  }

  function getTopCarsByArea(cars) {
    const topCarsByArea = []
    if (!cars) return topCarsByArea
    cars.forEach((car, index) => {
      topCarsByArea.push({ area: car.area, topCar: car.cars[0], index })
    })
    return topCarsByArea
  }

  const markers = useMemo(
    () => {
      const locations = getTopCarsByArea(downtownCars?.cars)?.map((car) => ({
        lat: car.area.latitude,
        lng: car.area.longitude,
        title: util.formatMoneyFloored(
          (car.topCar.price.discountedTotal || car.topCar.price.actualTotal) /
            util.howManyDays(pickupDate, dropOffDate),
          car.topCar.price.currency
        ),
        index: car.index
      }))
      if (!!nearMeCoords.lat) locations.push({ lat: nearMeCoords.lat, lng: nearMeCoords.lng, index: locations.length })
      return locations
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [downtownCars?.cars]
  )

  if (loading) return <LoadingScreen />
  if (downtownCars.cars.length <= 1) handleNoCars()

  const locationName = pickupLocation.prettyName

  return (
    <>
      <div className={props.className}>
        <GoogleMap
          markers={markers}
          defaultBounds={util.getBounds(markers.map(({ lat, lng }) => [lat, lng]))}
          handleClick={handleClick}
          nearMePicked={nearMePicked}
        />
        <MobileHeaderBar
          pickupLocation={pickupLocation}
          dropOffLocation={pickupLocation}
          pickupDateTime={pickupDate}
          dropOffDateTime={dropOffDate}
          location={Location}
          calendar={Calendar}
          breakpoint='600px'
          language={i18n.resolvedLanguage}
        />
        {nearMePicked && <NearMeInfoMobile city={pickupLocation.cityName} />}
        <div className='container'>
          {nearMePicked && <NearMeInfo city={pickupLocation.cityName} />}
          {!nearMePicked && (
            <div className='info-card-container'>
              <div className='info-card'>
                <p>
                  <img src={Pickup} alt='Pickup' />
                  {t('homePage:pickUp')}
                </p>
                <p>{locationName}</p>
                <p>{util.formatDateWithoutYearWithDay(pickupDate, i18n.resolvedLanguage)}</p>
              </div>
              <svg xmlns='http://www.w3.org/2000/svg' width='42' height='102' viewBox='0 0 42 102' fill='none'>
                <path d='M1 1L41 51L1 101' stroke='#DFDFE7' />
              </svg>
              <div className='info-card'>
                <p>
                  <img src={Dropoff} alt='Dropoff' />
                  {t('homePage:dropOff')}
                </p>
                <p>{locationName}</p>
                <p>{util.formatDateWithoutYearWithDay(dropOffDate, i18n.resolvedLanguage)}</p>
              </div>
            </div>
          )}
          <button className='button-list-all' onClick={handleListAllClick}>
            {t('carListing:listAll')}
          </button>
        </div>
        <button className='button-mobile-list-all' onClick={handleListAllClick}>
          {t('carListing:listAll')}
        </button>
      </div>
    </>
  )
})`
  .map {
    @media screen and (min-width: 1201px) {
      top: 78px;
      height: calc(100vh - 78px);
    }
    width: 100%;
    height: 100vh;
  }

  button {
    cursor: pointer;
  }

  .button-mobile-list-all {
    @media screen and (min-width: 600px) {
      display: none;
    }
    position: fixed;
    width: 240px;
    word-wrap: nowrap;
    bottom: 40px;
    left: 0;
    right: 0;
    margin: auto;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    border-radius: 999px;
    padding: 12px 24px;
    border: none;
    background: #ff7d0e;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  }

  .container {
    @media screen and (min-width: 1201px) {
      top: 78px;
    }
    @media screen and (max-width: 599px) {
      display: none;
    }
    width: max(576px, 80%);
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: end;
    align-items: start;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding-top: 24px;
    gap: 16px;
  }

  .info-card-container {
    flex-grow: 1;
    border-radius: 12px;
    padding: 0 24px;
    max-width: 752px;
    background-color: white;
    display: flex;
    gap: 32px;
    word-wrap: nowrap;
    align-items: center;
    background: #fff;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  }

  .info-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
    color: #696984;
    font-weight: 500;
    line-height: 20px;
    padding: 16px 0;
    flex-grow: 1;
    margin-left: auto;

    & > p {
      margin: 0;
    }

    & p:nth-child(1) {
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 14px;
      font-weight: 600;
    }

    & p:nth-child(n + 1) {
      font-size: 15px;
    }

    & img {
      width: 20px;
      height: 20px;
      object-fit: none;
    }
  }

  .button-list-all {
    max-width: 240px;
    margin-left: auto;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    border-radius: 8px;
    padding: 16px 24px;
    border: none;
    outline: none;
    background: #ff7d0e;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  }

  .marker-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translate(-50%, -100%);
    position: absolute;

    & img {
      object-fit: none;
    }
  }

  .button-marker {
    color: white;
    border-radius: 4px;
    border: none;
    border-top: 2px solid #0053b2;
    background: #0076fe;
    padding: 10px 12px;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);

    & > span {
      font-size: 10px;
      line-height: 16px;
      font-weight: 500;
    }
  }

  .zoom-controls {
    @media screen and (max-width: 599px) {
      right: 12px;
      bottom: 100px;
    }

    @media screen and (min-width: 600px) and (max-width: 719px) {
      right: calc(72px - (720px - 100%) / 2);
    }

    position: absolute;
    width: min-content;
    display: flex;
    flex-direction: column;
    gap: 8px;
    right: 10%;
    flex-direction: column;
    bottom: 32px;

    & > button {
      margin-left: auto;
      padding: 10px;
      border: none;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1), 0px 2px 8px 0px rgba(0, 0, 0, 0.15),
        0px 0px 4px 0px rgba(0, 0, 0, 0.15) inset;

      & > img {
        width: 24px;
        height: 24px;
        object-fit: none;
      }
    }
  }

  .gmnoprint {
    display: none;
  }

  .gm-control-active {
    display: none !important;
  }
`

export default AvailableAreas
