import React from 'react'
import { DateRange } from '../styles'
import TimeSlider from '../../SearchForm/DesktopForm/TimeSlider'
import { Container, BackButton, Box, Header, Grid, Text, Button } from '../styles'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import IconCalendar from '../assets/icon-calendar.svg'
import IconHour from '../assets/icon-hour.svg'
import { setSearchPickupTime, setSearchDropOffTime } from '../../../redux/slices'
import { useSelector, useDispatch } from 'react-redux'
import useModificationInfo from '../hooks/useModificationInfo'
import useModifyReservation from '../hooks/useModifyReservation'
import util from '../../../util'
import { LoadingScreen } from '../../CarListing/LoadingScreen'
import { setModificationArgs, setModificationResponse } from '../../../redux/slices/modificationSlice'
import handleSearchResponse from '../searchResponseHandler'
import FailedModificationModal from '../modals/FailedModificationModal'

export default function DateTimeDesktop() {
  const { t: TRANSLATOR } = useTranslation()
  const { reservationId, type, carSearchArgs } = useModificationInfo()
  const modifyReservation = useModifyReservation()
  const { pickupDateStr, pickupTime, dropOffDateStr, dropOffTime } = useSelector((state) => state.searchArgs)
  const dispatch = useDispatch()
  const history = useHistory()
  const [open, setOpen] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)

  // TODO: There is a bug in redux, when you change time from the picker, time is reset to 00:00
  function buildDateTime(date, time) {
    const newDate = new Date(date)

    newDate.setHours(time.hour)
    newDate.setMinutes(time.min)

    return util.formatDateForAPI(newDate)
  }

  // TODO: pass this from parent
  function handleClick() {
    setIsLoading(true)
    const newPickupDateTime = buildDateTime(pickupDateStr, pickupTime)
    const newDropOffDateTime = buildDateTime(dropOffDateStr, dropOffTime)

    const newCarSearchArgs = {
      ...carSearchArgs,
      pickupDateTime: newPickupDateTime,
      dropOffDateTime: newDropOffDateTime
    }

    dispatch(
      setModificationArgs({
        carSearchArgs: newCarSearchArgs
      })
    )

    modifyReservation.mutate(
      { reservationId, type, carSearchArgs: newCarSearchArgs },
      {
        onSuccess: (data) => {
          dispatch(setModificationResponse(data))
          handleSearchResponse(data, type, () => setOpen(true), history, dispatch, setModificationArgs)
          if (!!!data) setIsLoading(false)
        },
        onError: () => {
          setIsLoading(false)
          setOpen(true)
        }
      }
    )
  }

  if (isLoading) return <LoadingScreen />

  return (
    <>
      <Container>
        <BackButton onClick={() => history.go(-1)}>{TRANSLATOR('reservations:reserved:back')}</BackButton>
        <Box border='1px solid var(--borderGrey)' borderRadius={12} bgcolor='white' p={6}>
          <Header>{TRANSLATOR('reservations:modification:options:dateTime')}</Header>
          <Grid container wrap='nowrap' style={{ gap: 48, paddingTop: 32 }}>
            <Grid item xs={6}>
              <Box border='1px solid var(--borderGrey)' borderRadius={12} p={3}>
                <Grid container direction='column' style={{ gap: 16 }} justifyContent='end'>
                  <Text ml={0.5} fontWeight={700} color='var(--textGrey)'>
                    {TRANSLATOR('homePage:pickUp')}
                  </Text>
                  <Grid container wrap='nowrap' style={{ gap: 14 }}>
                    <Box flex='1 1 0' p={1.5} borderRadius={12} boxShadow='0px 2px 12px 0px rgba(189, 193, 202, 0.20)'>
                      <Text icon={IconCalendar} fontWeight={700}>
                        {util.formatDateWithoutTime(buildDateTime(pickupDateStr, pickupTime))}
                      </Text>
                    </Box>
                    <Box flex='1 1 0' p={1.5} borderRadius={12} boxShadow='0px 2px 12px 0px rgba(189, 193, 202, 0.20)'>
                      <Text icon={IconHour} fontWeight={700}>
                        {util.formatTime(buildDateTime(pickupDateStr, pickupTime))}
                      </Text>
                    </Box>
                  </Grid>
                  <Text ml={0.5} fontWeight={700} color='var(--textGrey)'>
                    {TRANSLATOR('homePage:dropOff')}
                  </Text>
                  <Grid container wrap='nowrap' style={{ gap: 14 }}>
                    <Box flex='1 1 0' p={1.5} borderRadius={12} boxShadow='0px 2px 12px 0px rgba(189, 193, 202, 0.20)'>
                      <Text icon={IconCalendar}>
                        {util.formatDateWithoutTime(buildDateTime(dropOffDateStr, dropOffTime))}
                      </Text>
                    </Box>
                    <Box flex='1 1 0' p={1.5} borderRadius={12} boxShadow='0px 2px 12px 0px rgba(189, 193, 202, 0.20)'>
                      <Text icon={IconHour} fontWeight={700}>
                        {util.formatTime(buildDateTime(dropOffDateStr, dropOffTime))}
                      </Text>
                    </Box>
                  </Grid>
                  <Button onClick={handleClick}>{TRANSLATOR('reservations:modification:confirm')}</Button>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box border='1px solid var(--borderGrey)' borderRadius={12}>
                <DateRange />
                <TimeSlider
                  label={TRANSLATOR('homePage:pickUpTime')}
                  value={pickupTime}
                  onChange={(value) => dispatch(setSearchPickupTime(value))}
                />
                <TimeSlider
                  label={TRANSLATOR('homePage:dropOffTime')}
                  value={dropOffTime}
                  onChange={(value) => dispatch(setSearchDropOffTime(value))}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <FailedModificationModal open={open} onClick={() => history.replace('/contact')} />
    </>
  )
}
