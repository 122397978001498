import React from 'react'
import { Box, Grid, Text, PriceButton } from '../styles'
import { DriverInfo } from './DriverInfoCard'
import { useTranslation } from 'react-i18next'
import util from '../../../util'
import IconManual from '../../../util/images/icon-manual.svg'
import IconAutomatic from '../../../util/images/icon-automatic.svg'
import IconSeat from '../../../util/images/icon-seat.svg'
import IconInsurance from '../../../util/images/icon-insurance.svg'
import IconLocation from '../assets/icon-location.svg'

export default function CarInfoCard({
  userInfo,
  type,
  supplierUrl,
  imageUrl,
  total,
  currency,
  groupDescription,
  name,
  classCode,
  transmissionDescription,
  seatCount,
  insured,
  stationPair,
  pickupDateTime,
  dropOffDateTime,
  newCar,
  totalDifference
}) {
  const { t: TRANSLATOR } = useTranslation()

  const transmissionIcon = util.getTransmission(classCode) === 'M' ? IconManual : IconAutomatic
  const trasmissionTranslationKey = util.getTransmission(classCode) === 'M' ? 'manual' : 'automatic'

  return (
    <Box
      bgcolor='white'
      p={1.5}
      borderRadius={8}
      border='1px solid'
      borderColor={newCar ? 'var(--colorPrimary)' : 'var(--borderGrey)'}
    >
      <Grid container direction='column' style={{ gap: 24 }}>
        {type === 'DRIVER_CHANGE' && <DriverInfo {...userInfo} TRANSLATOR={TRANSLATOR} />}
        <Grid container direction='column' style={{ gap: 16 }}>
          <Grid item>
            <img src={supplierUrl} alt='supplier-logo' height={28} width='auto' />
          </Grid>
          <Grid item container justifyContent='center' wrap='nowrap'>
            <img src={imageUrl} alt='car' height={128} width='auto' />
          </Grid>
        </Grid>
        <Box p={2} border='1px solid var(--borderGrey)' borderRadius={12}>
          <Grid container justifyContent='space-between'>
            <Text>{TRANSLATOR('xo2:total')}</Text>
            <Text>{util.formatMoney(total, currency)}</Text>
          </Grid>
        </Box>
        <Box>
          <Text color='var(--colorPrimary)' fontSize={20} fontWeight={600}>
            {groupDescription}
          </Text>
          <Text fontSize={14} style={{ textTransform: 'uppercase' }}>
            {name}
          </Text>
        </Box>
        <Grid container style={{ gap: 12 }}>
          <Text icon={transmissionIcon} fontSize={14} fontWeight={400}>
            {transmissionDescription || TRANSLATOR(`reservations:modification:${trasmissionTranslationKey}`)}
          </Text>
          <Text icon={IconSeat} fontSize={14} fontWeight={400}>
            {seatCount}
          </Text>
          {insured && (
            <Text icon={IconInsurance} iconsize={20} fontSize={14} fontWeight={400}>
              {TRANSLATOR('carListing:insurance')}
            </Text>
          )}
        </Grid>
        <Box>
          <Text fontSize={14} fontWeight={700}>
            {TRANSLATOR('homePage:pickUp')}
          </Text>
          <Text icon={IconLocation} fontSize={14} fontWeight={400}>
            {stationPair.pickup.address}
          </Text>
          <Text fontSize={14} fontWeight={400} ml={3}>
            {util.formatDate(pickupDateTime)}
          </Text>
        </Box>
        <Box>
          <Text fontSize={14} fontWeight={700}>
            {TRANSLATOR('homePage:dropOff')}
          </Text>
          <Text icon={IconLocation} fontSize={14} fontWeight={400}>
            {stationPair.dropOff.address}
          </Text>
          <Text fontSize={14} fontWeight={400} ml={3}>
            {util.formatDate(dropOffDateTime)}
          </Text>
        </Box>
        {newCar && Math.abs(totalDifference) >= 1 && (
          <PriceButton>
            <p>
              {util.formatMoneySignPrefix(totalDifference, currency)}
              <span>/{TRANSLATOR('xo2:total').toLowerCase()}</span>
            </p>
          </PriceButton>
        )}
      </Grid>
    </Box>
  )
}
