import React from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as VoucherDetailIcon } from './assets/icon-voucher-detail.svg'
import { WrapperStyled, OpenModalButtonStyled } from './VoucherDetail.styles'
import VoucherDetailDialog from './VoucherModal'

export default function VoucherDetail() {
  const [open, setOpen] = React.useState(false)
  const { t: TRANSLATOR } = useTranslation()

  const handleClickOpen = () => {
    setOpen(true)
  }

  return (
    <WrapperStyled>
      <OpenModalButtonStyled onClick={handleClickOpen}>
        <VoucherDetailIcon /> {TRANSLATOR('reservations:voucherDetail')}
      </OpenModalButtonStyled>
      {open && <VoucherDetailDialog open={open} onClose={() => setOpen(false)} />}
    </WrapperStyled>
  )
}
