import React from 'react'
import styled from 'styled-components'
import IconArrowback from './assets/icon-arrowback.svg'
import IconInfo from './assets/icon-info-orange.svg'
import { InputAdornment } from '@material-ui/core'
import { typography, spacing, palette } from '@material-ui/system'
import QR from '../../util/images/image-success-qr.png'
import badges from '../../util/badges'
import useCheckCountry from '../../hooks/useCheckCountry'
import IconCarlaCash from '../../util/images/icon-carla-cash.svg'
import ImagePhone from '../../util/images/image-phone.png'
import IconSupport from '../../util/images/icon-support.svg'
import IconCancel from '../../util/images/icon-trip-cancel.svg'
import IconSeat from '../../util/images/icon-seat.svg'
import { useTranslation } from 'react-i18next'
import DateFieldWithRange from '../SearchBarHome/MobileFields.js/DateFieldWithRange'
import { DateInputContainer, TimeInputContainer } from '../SearchBarHome/MobileFields.js/DateFieldWithRange'
import DateRangePicker from '../DatePicker/DateRangePicker'
import LocationSelect from '../SearchBarHome/MobileFields.js/LocationSelect'
import { InputAreaWrapper } from '../SearchBarHome/MobileFields.js/LocationSelect'
import LocationField from '../SearchBoxV3/Fields/LocationField'
import DateField from '../SearchBoxV3/Fields/DateField'
import { InputArea, GroupTitle } from '../SearchBoxV3/Fields/LocationFiled.styles'
import { InputArea as DateInputArea, GroupTitle as DateGroupTitle } from '../SearchBoxV3/Fields/DateField.styles'

export { InfoTextWithLeftIcon } from '../styled'
export { Grid, Box, Container, InputAdornment } from '@material-ui/core'
export { TextFieldUI } from '../../UI/TextField'

export const ModificationOption = styled.button`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  gap: 12px;
  border: 1px solid var(--borderGrey);
  outline: none;
  border-radius: 12px;
  background: var(--White, #fff);
  color: #525266;
  font-size: 14px;
  font-weight: 700;
  & ::after {
    content: url(${IconArrowback});
    height: 24px;
    width: 24px;
  }
`

export const MobileHeader = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: white;
  text-align: center;
  height: 50px;
  line-height: 50px;
  color: #ff7d0e;
  font-size: 18px;
  font-weight: 700;
`

export const MobileFixedButton = styled(({ onClick, className, children, ...rest }) => {
  return (
    <div className={className} {...rest}>
      <button type={rest.type} form={rest.form} onClick={onClick}>
        {children}
      </button>
    </div>
  )
})`
  position: fixed;
  bottom: 0;
  width: 100%;
  background: var(--White, #fff);
  box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.1);
  padding: 8px;
  & button {
    width: 100%;
    color: var(--White, #fff);
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    padding: 12px;
    border-radius: 4px;
    border: none;
    outline: none;
    background: ${({ bg }) => bg || 'var(--colorPrimary)'};
    cursor: pointer;
  }
`

export const Header = styled.p`
  margin: 0;
  color: var(--Text-Color, #525266);
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  border-bottom: 1px solid var(--colorHint);
  margin-bottom: 12px;
  padding-bottom: 12px;
  padding-left: 12px;
`

export const BackButton = styled.button`
  cursor: pointer;
  padding: 0;
  border: none;
  background: none;
  color: #ff7d0e;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  gap: 4px;
  margin: 28px 0 16px;
  align-items: center;
  & ::before {
    content: url(${IconArrowback});
    height: 24px;
    widht: 24px;
    transform: rotate(180deg);
  }
`

export const SubHeader = styled.p`
  color: var(--Text-Color, #525266);
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  margin-bottom: 32px;
  margin-left: -16px;
`

export const DriverFormTitle = styled.p`
  margin: 0;
  color: var(--Actions-Primary, #ff7d0e);
  font-size: 16px;
  font-weight: 600;
`

export const InfoButton = styled.button`
  background: url(${IconInfo});
  border: none;
  outline: none;
  height: 24px;
  width: 24px;
  padding: 0;
  cursor: pointer;
`

export const Button = styled.button`
  border: none;
  outline: none;
  padding: 12px;
  border-radius: 4px;
  background: #ff7d0e;
  color: var(--White, #fff);
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  margin: 24px 0 auto auto;
  cursor: pointer;
`

export const InfoAdornment = ({ onClick }) => {
  return (
    <InputAdornment position='end'>
      <InfoButton onClick={onClick} type='button' />
    </InputAdornment>
  )
}

export const DriverInfoCardContainer = styled.div`
  & p {
    color: var(--textBlack);
    margin: 4px 0;
    color: var(--Text-Primary, #525266);
  }

  & p:nth-child(1) {
    font-weight: 400;
  }

  & p:nth-child(2) {
    font-weight: 600;
    margin-left: 8px;
  }
`

export const Text = styled.p`
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 0;
  color: var(--textBlack);
  font-weight: 500;
  & ::before {
    ${({ icon }) => icon && `content: url(${icon});`}
    line-height: 0;
  }
  ${typography}
  ${spacing}
  ${palette}
`

export const PriceButton = styled.div`
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #dfdfe7;
  background: linear-gradient(180deg, #fff 0%, #fafafa 100%);
  text-align: center;
  box-shadow: 0px 0px 2px 0px rgba(82, 82, 102, 0.04), 0px 2px 2px 0px rgba(82, 82, 102, 0.05);
  & p {
    margin: 0;
    color: var(--textBlack);
    font-family: Montserrat;
    font-size: 22px;
    font-weight: 700;
  }
  & span {
    color: #ff7d0e;
    font-size: 18px;
    font-weight: 400;
  }
`

export const AgreeButton = styled.button`
  border-radius: 8px;
  background: #0076ff;
  border: none;
  outline: none;
  color: var(--White, #fff);
  font-family: Quicksand;
  font-size: 16px;
  font-weight: 700;
  text-transform: capitalize;
  padding: 16px 0;
`

export const SuccessInfoRow = styled(({ children, className, icon }) => {
  return (
    <div className={className} icon={icon}>
      <div>{children}</div>
    </div>
  )
})`
  display: flex;
  gap: 6px;
  & p {
    margin: 0;
    color: #525266;
    font-family: Quicksand;
    font-size: 16px;
    font-weight: 600;
  }

  & p:nth-of-type(1) {
    line-height: 24px;
  }

  & p:nth-of-type(n + 2) {
    font-weight: 400;
  }

  & ::before {
    content: url(${({ icon }) => icon});
    line-height: 0;
  }
`

export const SuccessInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const SuccessHeader = styled(SuccessInfoRow)`
  @media screen and (max-width: 600px) {
    justify-content: space-between;
    & h2 {
      margin-bottom: 24px !important;
    }
    & ::before {
      order: 2;
    }
  }
  display: flex;
  gap: 16px;
  & h1,
  h2 {
    margin: 0;
    font-weight: 600;
  }
  & h1 {
    color: var(--colorPrimary);
    font-size: 24px;
    line-height: 30px;
  }
  & h2 {
    color: var(--colorSuccess);
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 32px;
  }
`

const Badges = styled(({ isMobile, isAndroid, lang, className }) => {
  const google = badges.google[lang || 'en']
  const apple = badges.apple[lang || 'en']

  const single = (
    <a href='https://app.adjust.com/1d2i2uyx' target='_blank' rel='noopener noreferrer'>
      <img src={isAndroid ? google : apple} alt='badge' />
    </a>
  )

  const withQR = (
    <div className='with-qr'>
      <div>
        <img src={QR} alt='qrcode' className='qr' />
      </div>
      <div>
        <a href='https://app.adjust.com/1d2i2uyx' target='_blank' rel='noopener noreferrer'>
          <img src={apple} alt='apple' />
        </a>
        <a href='https://app.adjust.com/1d2i2uyx' target='_blank' rel='noopener noreferrer'>
          <img src={google} alt='google' />
        </a>
      </div>
    </div>
  )

  return <div className={className}>{isMobile ? single : withQR}</div>
})`
  @media screen and (min-width: 600px) {
    & div.with-qr {
      justify-content: start;
    }
  }

  & a {
    line-height: 0;
    width: min-content;
    margin: auto;
  }

  & img:not(.qr) {
    height: 48px;
    width: auto;
  }

  & .qr {
    width: 84px;
    height: auto;
  }

  & .with-qr {
    display: flex;
    justify-content: center;
    gap: 8px;
  }

  & .with-qr a > img {
    height: 42px;
  }
`

export const SuccessRedirect = styled(({ className, isMobile, isAndroid, lang }) => {
  const { t: TRANSLATOR } = useTranslation()
  const checkCountry = useCheckCountry()

  if (!checkCountry) return null

  return (
    <div className={className}>
      <div>
        <h1>{TRANSLATOR('success:app')}</h1>
        <p>
          {TRANSLATOR('success:carlaCash:text')}
          <span>{TRANSLATOR('success:carlaCash:span')}</span>
        </p>
        <Badges isMobile={isMobile} isAndroid={isAndroid} lang={lang} />
      </div>
      <img src={ImagePhone} alt='phone' className='phone' />
    </div>
  )
})`
  @media screen and (min-width: 600px) {
    flex-direction: row;
    justify-content: space-between;
    text-align: left;

    & .phone {
      flex: 0 1 50px;
      margin: -24px 0 0 auto;
      max-width: 176px;
      width: 100%;
    }
  }

  display: flex;
  flex-direction: column;
  gap: 24px;
  text-align: center;
  padding: 16px 24px 0;
  border-radius: 12px;
  border: 1px solid var(--colorPrimary);
  background: linear-gradient(90deg, #fff 0%, #ffe3cc 100%);

  & h1,
  p {
    margin: 0;
  }

  & h1 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
  }

  & p {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 24px;
  }

  & span {
    font-weight: 600;
    & ::before {
      content: url(${IconCarlaCash});
      vertical-align: -2px;
      margin-right: 1px;
    }
  }

  & img {
    width: 200px;
    height: auto;
    display: block;
    margin: auto;
  }
`

export const SuccessCarInfo = styled(
  ({
    imageUrl,
    supplierUrl,
    carlaPlusDetails,
    name,
    groupDescription,
    className,
    transmissionDescriptiopn,
    seatCount
  }) => {
    const { t: TRANSLATOR } = useTranslation()

    return (
      <div className={className}>
        <div>
          <img src={supplierUrl} alt='supplier' />
          <img src={imageUrl} alt='car' />
        </div>
        <div className='car'>
          <p>{name}</p>
          <p>{groupDescription}</p>
        </div>
        {carlaPlusDetails && (
          <div className='perks'>
            <p>{TRANSLATOR('xo2:carlaPerks:support')}</p>
            <p>{TRANSLATOR('xo2:carlaPerks:cancel')}</p>
          </div>
        )}
        <div className='info'>
          <p>{transmissionDescriptiopn}</p>
          <p>{seatCount}</p>
        </div>
      </div>
    )
  }
)`
  display: flex;
  flex-direction: column;
  gap: 24px;

  & img:nth-child(1) {
    height: 32px;
  }

  & img:nth-child(2) {
    display: block;
    width: 256px;
    margin: auto;
  }

  & p {
    margin: 0;
  }

  & .car > p:nth-of-type(1) {
    color: var(--colorPrimary);
    font-size: 20px;
    font-weight: 600;
  }

  & .car > p:nth-of-type(2) {
    color: var(--TextBlack);
    font-size: 20px;
    font-weight: 600;
  }

  & .perks {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  & .perks > p {
    display: flex;
    gap: 12px;
    align-items: center;
    color: var(--textBlack);
    font-size: 16px;
    font-weight: 700;
  }

  & .perks > p:nth-of-type(1)::before {
    content: url(${IconSupport});
    line-height: 0;
  }

  & .perks > p:nth-of-type(2)::before {
    content: url(${IconCancel});
    line-height: 0;
  }

  & .info {
    display: flex;
    gap: 16px;
  }

  & .info > p {
    display: flex;
    align-items: center;
    gap: 4px;
    color: var(--textBlack);
    font-size: 16px;
    font-weight: 700;
  }

  & .info > p:nth-of-type(1)::before {
    content: url(${(props) => props.iconTransmission});
    line-height: 0;
  }

  & .info > p:nth-of-type(2)::before {
    content: url(${IconSeat});
    line-height: 0;
  }
`

export const DateTimeField = styled(DateFieldWithRange)`
  width: 100% !important;
  margin: 0 !important;
  ${DateInputContainer} {
    width: 50% !important;
  }

  ${TimeInputContainer} {
    width: 50% !important;
  }
`

export const DateRange = styled(DateRangePicker)`
  background-color: transparent;
`

export const LocationSelectMobile = styled(LocationSelect)`
  ${InputAreaWrapper} {
    width: 100%;
    margin: 0;
  }
`

export const LocationSelectDesktop = styled(LocationField)`
  ${InputArea} {
    font-size: 14px;
    background: #fbfbfd;
    border-radius: 8px;
    border: 1px solid var(--borderGrey);
    padding: 12px 12px 12px 42px;
  }

  ${GroupTitle} {
    color: var(--textBlack);
    font-weight: 500;
  }
`

export const DateFieldDesktop = styled(DateField)`
  ${DateInputArea} {
    font-size: 14px;
    background: #fbfbfd;
    border-radius: 8px;
    border: 1px solid var(--borderGrey);
    padding: 12px 12px 12px 42px;
  }

  ${DateGroupTitle} {
    color: var(--textBlack);
    font-weight: 500;
  }
`
