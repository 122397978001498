import React from 'react'
import styled from 'styled-components'
import { CardFlexEl, El, FlexEl } from '../layout'
import { Form } from 'formik'
import util from '../../util'
import { themeGet } from 'styled-system'
import LocationIcon from '../../util/images/icon-location-grey.svg'
import CalendarIcon2 from '../../util/images/icon-calendar-grey.svg'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { InfoTextWithLeftIcon } from '../styled'
import IconAtTerminal from '../../util/images/icon-at-terminal.svg'
import IconMeetAndGreet from '../../util/images/icon-meet-and-greet.svg'
import IconFreeShuttle from '../../util/images/icon-free-shuttle.svg'
import Instructions from '../../components/Instructions'

const AIRPORT_BRANCH_TYPE = {
  OFFICE: { text: 'At Terminal', icon: IconAtTerminal },
  SHUTTLE: { text: 'Free Shuttle', icon: IconFreeShuttle },
  MEET_GREET: { text: 'Meet & Greet', icon: IconMeetAndGreet }
}

export const BookingInformation = styled(({ carRentalProduct, ancillaryProducts, isBundle = false, ...props }) => {
  const {
    searchArgs,
    stationPair,
    stationPair: {
      pickup: { airport, airportBranchType }
    }
  } = carRentalProduct
  const { isMatchCriteria } = useSelector((state) => state.bundleBooking)
  const { checkInDate, checkOutDate } = useSelector((state) => state.hotelBooking.dateRange)
  const { pickupDateStr, dropOffDateStr } = useSelector((state) => state.searchArgs)
  const { t } = useTranslation()

  const startDate = util.formatDateTimeFromDate(
    isBundle ? (isMatchCriteria ? checkInDate : pickupDateStr) : searchArgs.pickupDateTime
  )
  const endDate = util.formatDateTimeFromDate(
    isBundle ? (isMatchCriteria ? checkOutDate : dropOffDateStr) : searchArgs.dropOffDateTime
  )
  const howManyDays = util.howManyDays(searchArgs.pickupDateStr, searchArgs.dropOffDateStr)

  return (
    <CardFlexEl as={Form} className={props.className}>
      <FlexEl px={['12px', '24px']} className='top-container'>
        <FlexEl className='name-group-container'>
          <El className='card-title'>{t('xo1:bookingInfo')}</El>
        </FlexEl>
      </FlexEl>
      <FlexEl className='body-container'>
        <FlexEl className='pickup-detail' flexDirection='column' flexGrow={1}>
          <FlexEl flexDirection='column'>
            <El className='location-title' flexDirection='row'>
              {t('homePage:pickUp')}
            </El>
            <FlexEl className='location-row'>
              <El>
                <img src={LocationIcon} alt={'rent_carla_location_icon'} className='icons' />
              </El>
              <El className='infos'>{stationPair.pickup.name}</El>
            </FlexEl>
            <FlexEl className='date-row'>
              <El>
                <img src={CalendarIcon2} alt={'rent_carla_calendar_icon'} className='icons' />
              </El>
              <El className='infos'>
                <span>{startDate.split(' ')[0]}</span>
                <span style={{ marginLeft: '16px' }}>
                  {startDate.split(' ')[1]} {startDate.split(' ')[2]}
                </span>
              </El>
            </FlexEl>
          </FlexEl>
        </FlexEl>
        <FlexEl className='dropoff-detail' flexDirection='column' flexGrow={1}>
          <FlexEl flexDirection='column'>
            <El className='location-title'>{t('homePage:dropOff')}</El>
            <FlexEl className='location-row'>
              <El>
                <img src={LocationIcon} alt={'rent_carla_location_icon'} className='icons' />
              </El>
              <El className='infos'>{stationPair.dropOff.name}</El>
            </FlexEl>
            <FlexEl className='date-row'>
              <El>
                <img src={CalendarIcon2} alt={'rent_carla_calendar_icon'} className='icons' />
              </El>
              <El className='infos'>
                <span>{endDate.split(' ')[0]}</span>
                <span style={{ marginLeft: '16px' }}>
                  {endDate.split(' ')[1]} {endDate.split(' ')[2]}
                </span>
              </El>
            </FlexEl>
          </FlexEl>
        </FlexEl>
      </FlexEl>
      <El pl={['12px', '24px']}>
        {airport && airportBranchType && (
          <InfoTextWithLeftIcon mt={2} p={1} icon={AIRPORT_BRANCH_TYPE[airportBranchType]?.icon} $borderRadius={4}>
            {AIRPORT_BRANCH_TYPE[airportBranchType]?.text}
          </InfoTextWithLeftIcon>
        )}
        <Instructions />
      </El>
      <FlexEl className='footer'>
        <El className='card-title'>{t('xo1:dailyPrice')}</El>
        <El mt='2px' className='total-price'>
          {util.formatMoneyFloored(
            carRentalProduct.price.discountedTotal
              ? carRentalProduct.price.discountedTotal / howManyDays
              : carRentalProduct.price.total / howManyDays,
            carRentalProduct.price.currency
          )}
          {''}/{t('carListing:day')}
        </El>
      </FlexEl>
    </CardFlexEl>
  )
})`
  flex-direction: column;
  width: 100%;
  border-radius: 12px;
  padding: 16px 0px 16px 0px;
  color: ${themeGet('colors.textBlack')};
  margin-bottom: 15px;

  .temp {
    max-width: 50%;
    max-height: 40px;
  }
  .card-title {
    color: ${themeGet('colors.colorPrimary')};
    font-size: 16px;
  }

  .total-price {
    flex-flow: column;
    font-size: 16px;
    font-weight: 500;
    text-align: end;
  }

  .body-container {
    padding: 20px 24px 12px;
    display: flex;
    justify-content: space-between;
    color: ${themeGet('colors.textBlack')};
    font-size: 15px;

    @media (max-width: ${themeGet('breakpoints.0')}px) {
      padding: 20px 12px 12px;
    }

    @media (max-width: ${themeGet('breakpoints.1')}px) {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }

  .footer {
    padding: 0px 24px 12px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    color: ${themeGet('colors.textBlack')};
    @media (max-width: ${themeGet('breakpoints.0')}px) {
      padding: 0px 12px 12px;
    }
  }

  .location-row {
    display: flex;
    font-size: 14px;
    text-align: start;
    margin-top: 10px;
  }

  .date-row {
    display: flex;
  }

  .location-title {
    color: ${themeGet('colors.textGrey')};
    text-transform: uppercase;
  }

  .infos {
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .icons {
    width: 20px;
    height: 20px;
    margin-right: 4px;
    //color: ${themeGet('colors.colorHint')};
    //525266
  }
`
