import { BlueGreyLink, El, FlexEl } from '../layout'
import React from 'react'
import { func, string } from 'prop-types'
import { themeGet } from 'styled-system'
import styled from 'styled-components'
import { ModalType } from '../../routes/XO1'
import { CarlinPropTypes } from '../../entities'
import { PeaceOfMindModal } from './modals/PeaceOfMindModal'
import { FadePomModal } from '../PomModal'
import util from '../../util'
import { useTranslation } from 'react-i18next'
import IconChecked from '../../util/images/icon-checkbox.png'
import IconUnchecked from '../../util/images/icon-unchecked.png'

export const AncillarySelectionCard = styled(
  ({
    ancillaryProduct,
    currency,
    updatedSelections,
    modal,
    openModal,
    closeModal,
    setValues,
    selectedAncillaries,
    ...props
  }) => {
    const { t } = useTranslation()

    let check = selectedAncillaries.has(ancillaryProduct.id)

    function handleCardClick() {
      updatedSelections({ [ancillaryProduct.id]: !check })
      check = !check
    }

    return (
      <FlexEl {...props} onClick={handleCardClick}>
        <FlexEl className={check ? 'checked' : 'unchecked'}>
          <FlexEl>
            <FlexEl className='insurance-quote-detail' flexDirection='column' flexGrow={1}>
              <FlexEl className='insurance-quote-title' flexDirection='row'>
                <El>{ancillaryProduct.title}</El>
                {ancillaryProduct.type === 'PeaceOfMind' && (
                  <BlueGreyLink
                    ml='6px'
                    onClick={(e) => {
                      e.stopPropagation()
                      openModal(ModalType.PEACE_OF_MIND)
                    }}
                  >
                    <El className='learn-more'>
                      <El className='learn-more-text'>{t('xo1:learn')}</El>
                    </El>
                  </BlueGreyLink>
                )}
              </FlexEl>
              <El mt='6px' fontSize={14}>
                {ancillaryProduct.priceText}
              </El>
              <El mt='8px' className='insurance-quote-disclaimer'>
                {ancillaryProduct.subtitle}
              </El>
            </FlexEl>
          </FlexEl>
          <img src={check ? IconChecked : IconUnchecked} alt='checkbox' height={24} width={24} />
        </FlexEl>
        {ancillaryProduct.type === 'PeaceOfMind' && (
          <FadePomModal
            isOpen={modal === ModalType.PEACE_OF_MIND}
            price={util.formatMoney(ancillaryProduct.price.price, ancillaryProduct.price.currency)}
            closable={true}
            ok={true}
            width={900}
            onClose={closeModal}
            onSelect={handleCardClick}
            t={t}
          >
            <PeaceOfMindModal />
          </FadePomModal>
        )}
      </FlexEl>
    )
  }
)`
  .checked,
  .unchecked {
    font-size: 16px;
    color: var(--textBlack);
    gap: 12px;
    border-radius: 4px;
    padding: 12px;
    cursor: pointer;
  }

  .checked {
    border: 1px solid var(--colorPrimary);
    background: #fff9f5;
  }

  .unchecked {
    border: 1px solid var(--separatorGrey);
    background: linear-gradient(180deg, #fff 0%, #fafafa 100%);
  }

  .insurance-quote-detail {
    flex-direction: column;
    flex-grow: 1;
    font-weight: normal;

    > .insurance-quote-title {
      font-weight: 600;
    }
  }

  .insurance-quote-disclaimer {
    font-size: 14px;
    @media (min-width: ${themeGet('breakpoints.0')}px) {
      text-align: left;
    }

    a {
      display: inline-block;
      color: ${themeGet('colors.blueGrey.7')};
      &:hover,
      &:focus {
        color: ${themeGet('colors.textBlack')};
      }
    }
  }

  .learn-more {
    border-radius: 10px;
    padding: 2px 6px;
    background-color: #ff7d0e;

    > .learn-more-text {
      font-family: 'Quicksand', 'Quicksand Regular', sans-serif;
      font-size: 11px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
    }
  }
`

AncillarySelectionCard.propTypes = {
  ancillaryProduct: CarlinPropTypes.AncillaryProduct,
  modal: string.isRequired,
  openModal: func,
  closeModal: func.isRequired,
  currency: string.isRequired
}
