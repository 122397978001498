import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  root: {
    '@global': {
      '*': {
        fontFamily: 'Quicksand'
      }
    },
    padding: 12,
    '&:hover:not($disabled) $notchedOutline': {
      borderColor: 'var(--colorPrimary)'
    },
    '&$focused $notchedOutline': {
      borderColor: 'var(--colorPrimary)'
    },
    '&$disabled $notchedOutline': {
      borderColor: 'var(--borderGrey)'
    },
    backgroundColor: '#fbfbfd'
  },
  input: {
    padding: 0,
    color: '#525266',
    fontWeight: 500
  },
  notchedOutline: {
    borderColor: 'var(--borderGrey)',
    borderRadius: 8
  },
  focused: {},
  shrink: {},
  disabled: {
    '& $input': {
      color: '#9F9F9F',
      fontWeight: 500
    }
  },
  label: {
    transform: 'translate(14px, 14px)',
    fontFamily: 'Quicksand',
    color: 'var(--colorHint)',
    '&$shrink': {
      color: 'var(--colorHint)'
    },
    '&$focused$shrink': {
      color: 'var(--colorPrimary)'
    }
  }
})
