import React, { useEffect, useState } from 'react'
import loadable from '@loadable/component'
import { useTranslation } from 'react-i18next'
import { El } from '../../components/layout'
import { ContentContainer } from './TermsAndConditions.styles'

import DefaultTermAndConditions from './TermsAndConditionsEN'

export default function TermsAndConditions() {
  const { i18n } = useTranslation()
  const [terms, setTerms] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    let Component
    switch (i18n.resolvedLanguage) {
      case 'fr':
        Component = loadable(() => import('./TermsAndConditionsFR'))
        break
      case 'de':
        Component = loadable(() => import('./TermsAndConditionsDE'))
        break
      case 'it':
        Component = loadable(() => import('./TermsAndConditionsIT'))
        break
      case 'es':
        Component = loadable(() => import('./TermsAndConditionsES'))
        break
      default:
        Component = DefaultTermAndConditions
    }

    if (Component && Component.load) {
      Component.load().then(() => {
        setTerms(<Component />)
        setLoading(false)
      })
    }
  }, [i18n.resolvedLanguage])

  return (
    <ContentContainer>
      <El p={15}>{loading ? <DefaultTermAndConditions /> : terms}</El>
    </ContentContainer>
  )
}
