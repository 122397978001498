import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { CardFlexEl, FlexEl } from '../layout'
import LocationLine from '../../util/images/icon-location-line.svg'

export const OuterContainer = styled(CardFlexEl)`
  max-width: 784px;
  flex-direction: column;
  font-family: 'Quicksand', 'Quicksand Regular', sans-serif;
  color: #525266;
  padding: 0;
  position: relative;

  @media (max-width: ${themeGet('breakpoints.0')}px) {
    > .book-button {
      width: 50%;
      font-size: 18px !important;
    }
  }

  .book-button {
    font-size: 18px;
    font-weight: 700;
    flex-grow: 1;
    color: white;
    border-width: 0.75px 0.75px 0.75px 0px;
    border-style: solid;
    border-color: #ed8508;
    border-radius: 0px 8px 8px 0px;
    background: linear-gradient(180deg, #ff963d 0%, #ff7d0e 100%);
    box-shadow: 0px 2px 2px 0px #5252660d, 0px 0px 2px 0px #5252660a;

    &.book-button-with-href {
      text-align: center;
      text-decoration: none;
      line-height: 50px;
    }

    & :hover {
      background: linear-gradient(90deg, #ff963d 0%, #ff7d0e 33.33%);
    }
  }

  @media (min-width: ${themeGet('breakpoints.0')}px) {
    padding: 0;
  }
`

export const InfoContainer = styled(FlexEl)`
  .icon {
    margin-right: 3px;
    @media (min-width: ${themeGet('breakpoints.0')}px) {
      margin-right: 3px;
    }
  }
  .description {
    color: ${themeGet('colors.textBlack')};
    display: flex;
    align-items: center;
    font-weight: normal;
    font-size: 14px;

    // with ellipsis
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: flex;
  }
`

export const InnerContainer = styled(FlexEl)`
  display: flex;
  align-items: center;
  justify-content: start;
  margin: 0;

  @media (max-width: ${themeGet('breakpoints.0')}px) {
    flex-direction: column;
  }
  @media (min-width: ${themeGet('breakpoints.0')}px) {
    flex-direction: row;
    padding: 0;
    position: relative;
  }

  & :nth-child(3) {
    justify-content: end;
  }
`

export const ImageContainer = styled(FlexEl)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: none;
  width: 30%;
  padding: 5px;
  overflow: hidden;

  img {
    margin: 5px;
    align-items: center;
    object-fit: contain;
  }

  .supplier-img {
    height: 35px;
    margin: 11px 0px 0px 11px;
    align-self: flex-start;
    object-fit: contain;
  }

  .car-img {
    max-width: 100%;
    &.car-img-with-get-around {
      border-radius: 12px;
      object-fit: cover;
    }
    height: 128px;
    object-fit: contain;
  }

  @media (max-width: ${themeGet('breakpoints.0')}px) {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-template-rows: auto auto;
    padding: 0px;

    .supplier-img {
      grid-row-start: 1;
      grid-column-start: 1;
    }

    .highlight-mobile {
      display: flex;
    }

    .car-img {
      grid-column: span 2;
      justify-self: center;
      grid-row-start: 2;
    }
  }
`

export const InfoFirst = styled(FlexEl)`
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  flex-grow: 1;
  align-items: flex-start;
  margin-left: 0%;

  min-width: 0; // important

  @media (min-width: ${themeGet('breakpoints.1')}px) {
    flex-basis: 70%;
  }

  .pickup-container {
    overflow: hidden;
  }

  > :not(.station-container) {
    margin: 0px 5px;
  }

  .car-group-description {
    font-size: 20px;
    font-weight: 600;
    color: #ff7d0e;
  }

  .car-name {
    color: #525266;
    font-size: 14px;
    font-weight: 500;
  }

  .station-container {
    flex-direction: column;
    margin-left: 5px;

    width: 100%;

    .left-frame-tablet {
      @media (min-width: ${themeGet('breakpoints.1')}px) {
        display: none;
      }

      @media (max-width: ${themeGet('breakpoints.1')}px) {
        display: flex;
        margin-top: 10px;
        > * {
          margin: 0px 2.5px;
        }
      }
      @media (max-width: ${themeGet('breakpoints.0')}px) {
        display: none;
      }
    }
  }

  @media (max-width: ${themeGet('breakpoints.0')}px) {
    margin: 0px 5px;
    width: 100%;
    align-self: center;
  }
`

export const AmountText = styled.div`
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 700;
  line-height: 32px;
  color: #525266;
  border-width: 0.75px 0px 0.75px 0.75px;
  border-style: solid;
  border-color: #dfdfe7;
  padding: 8px 24px 8px 24px;
  border-radius: 8px 0px 0px 8px;
  box-shadow: 0px 2px 2px 0px #5252660d, 0px 0px 2px 0px #5252660a;

  & span {
    font-size: 16px;
    font-weight: 400;
    color: #737375;
  }
`
export const DiscountText = styled(AmountText)`
  display: flex;
  align-items: end;
  flex-direction: column;
  padding: 0 24px 4px;

  & span:nth-child(1) {
    color: #b4b4c2;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;

    & s {
      font-family: Montserrat;

      & span {
        font-size: 13px;
        font-weight: 400;
      }
    }
  }

  & span:nth-child(2) {
    font-family: Montserrat;
    font-size: 22px;
    font-weight: 700;
    color: #009655;
    line-height: 0;

    & span {
      font-size: 16px;
      font-weight: 400;
    }
  }
`

export const LocationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  max-width: 60vw;

  @media (max-width: ${themeGet('breakpoints.1')}px) {
    max-width: inherit;
    width: 100%;
  }

  & .drop-off-container::before {
    content: '';
    top: 17px;
    left: 9px;
    width: 2px;
    height: 24px;
    position: absolute;
    background: url(${LocationLine});
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
  }
`

export const InfoColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  flex-wrap: wrap;
  align-items: center;
  padding: 16px 0px;
  gap: 12px;

  & svg {
    display: block;
  }
`
export const PriceAndBookButtonWrapper = styled.div`
  display: flex;
  margin: 0 16px 16px 16px;
  width: 360px;

  @media (max-width: ${themeGet('breakpoints.0')}px) {
    width: calc(100% - 32px);
  }

  & .modification {
    color: #009655;
  }
`
