import React from 'react'
import styled from 'styled-components'
import IconInfoOrange from '../../util/images/icon-info-orange.svg'
import ImageEuropcarLogo from '../../util/images/image-europcar-logo.png'
import ImageThriftyLogo from '../../util/images/image-thrifty-logo.png'
import ImageDollarLogo from '../../util/images/image-dollar-logo.png'
import ImageGoldcarLogo from '../../util/images/image-goldcar-logo.png'
import ImageLocalSuppliersLogo from '../../util/images/image-local-suppliers.png'
import ExclusiveDealsModal from './modals/ExclusiveDealsModal'
import { useTranslation } from 'react-i18next'

const Conatiner = styled.div`
  border-bottom: solid 1px #dfdfe7;
  padding: 12px 24px;
`

const InfoText = styled.p`
  margin: 0;
  color: #ff7d0e;
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  white-space: nowrap;
`

export const InfoIcon = styled.img`
  line-height: ${(props) => props.lineheight};
  object-fit: none;
  cursor: pointer;
`

const InfoTextIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  width: min-content;
  margin-bottom: 4px;
`

const Text = styled.p`
  margin: 0 0 18px;
  color: #525266;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
`

export const Logo = styled.img`
  height: ${(props) => props.height || 'auto'};
  width: ${(props) => props.width || 'auto'};
`

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin-bottom: 12px;
  flex-wrap: wrap;
`

const FooterText = styled.p`
  margin: 0;
  color: #525266;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
`

export default function ExclusiveDeals() {
  const [isOpen, setIsOpen] = React.useState(false)
  const { t: TRANSLATOR } = useTranslation()

  function handleClick() {
    setIsOpen(!isOpen)
  }

  return (
    <Conatiner>
      <ExclusiveDealsModal isOpen={isOpen} handleClick={handleClick} />
      <InfoTextIconContainer>
        <InfoText>{TRANSLATOR('xo1:exclusive:header')}</InfoText>
        <InfoIcon src={IconInfoOrange} alt='info icon' onClick={handleClick} />
      </InfoTextIconContainer>
      <Text>{TRANSLATOR('xo1:exclusive:text')}</Text>
      <LogoContainer>
        <Logo src={ImageEuropcarLogo} alt='europcar logo' height={32} />
        <Logo src={ImageThriftyLogo} alt='thrifty logo' height={23} />
        <Logo src={ImageDollarLogo} alt='dollar logo' height={32} />
        <Logo src={ImageGoldcarLogo} alt='goldcarlogo' height={32} />
        <Logo src={ImageLocalSuppliersLogo} alt='local suppliers logo' height={36} />
      </LogoContainer>
      <FooterText>{TRANSLATOR('xo1:exclusive:footer')}</FooterText>
    </Conatiner>
  )
}
