import { El, FlexEl, SearchButton } from '../../layout'
import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { CarlaMobileSelect } from '../../CarlaSelect'
import DateTimePicker from './DateTimePicker'
import LocationSelect from './LocationSelect'
import ReactLoading from 'react-loading'
import { ageItems, filterDropOffLocations } from '../../../util/searchArgs'
import { setSearchPickupDate, setSearchDropOffDate } from '../../../redux/slices'
import {
  setSearchAge,
  setSearchDropOffLocation,
  setSearchDropOffTime,
  setSearchPickupLocation,
  setSearchPickupTime
} from '../../../redux/slices'
import GA from '../../../services/GA'
import { selectSearchArgs } from '../../../redux/selectors'

// @TODO auto click drop off
// const clickDropOffDateButton = () => {
//   setTimeout(() => {
//     console.log('ananna', document.getElementById('drop-off-datetime-select').getElementsByClassName('date-select')[0].click())
//     document.getElementById('drop-off-datetime-select').getElementsByClassName('date-select')[0].click()
//   })
// }
const RawMobileForm = styled(
  ({
    pickupLocation,
    dropOffLocation,
    pickupDate,
    dropOffDate,
    pickupTime,
    dropOffTime,
    ageOption,
    onSubmit,
    locationSearch,
    setSearchPickupLocation,
    setSearchDropOffLocation,
    setSearchAge,
    setSearchPickupDate,
    setSearchDropOffDate,
    setSearchPickupTime,
    setSearchDropOffTime,
    ...props
  }) => {
    const isSearchPossible = () => {
      if (
        dropOffDate < pickupDate ||
        (dropOffDate.getTime() === pickupDate.getTime() && dropOffTime.hour < pickupTime.hour) ||
        (dropOffDate.getTime() === pickupDate.getTime() &&
          dropOffTime.hour === pickupTime.hour &&
          dropOffTime.min <= pickupTime.min)
      )
        return false // dropoff date/time should be later than pickup date
      if (
        pickupLocation === undefined ||
        pickupLocation === null ||
        dropOffLocation === undefined ||
        dropOffLocation === null
      )
        return false // locations should be valid

      return true
    }

    //Search button loading animation, hide the first button and show the second button.
    const startLoadingAnimation = () => {
      if (!isSearchPossible()) return // check if search is possible before starting spinner on the button

      var nodes = document.querySelectorAll('.car-search-button')
      nodes[0].style.display = 'none' //hide first button
      nodes[1].style.display = 'block' //show second button
    }

    return (
      <El as='form' onSubmit={onSubmit} {...props}>
        {/* TITLE */}
        <FlexEl className='form-field-container'>
          <El className='form-title'>Find your car</El>
        </FlexEl>
        {/* PICKUP LOCATION */}
        <FlexEl className='form-field-container'>
          <LocationSelect
            value={pickupLocation}
            onChange={(value) => {
              GA.selectPickupLocation(value.label)
              setSearchPickupLocation(value)
            }}
            label='Pickup location'
            inputId='pick-up-location-input'
            {...locationSearch}
          />
        </FlexEl>
        {/* DROP OFF LOCATION */}
        <FlexEl className='form-field-container'>
          <LocationSelect
            value={dropOffLocation}
            onChange={(value) => {
              GA.selectDropOffLocation(value.label)
              setSearchDropOffLocation(value)
            }}
            label='Drop off location'
            compareIfSameWith={pickupLocation}
            {...locationSearch}
            items={filterDropOffLocations(locationSearch.items)}
          />
        </FlexEl>
        {/* DATE RANGE */}
        <FlexEl className='form-field-container'>
          <El width={0.5} pr='8px'>
            <DateTimePicker
              date={pickupDate}
              time={pickupTime}
              label='Pickup time'
              onDateChange={(value) => {
                GA.selectedPickupDateOnMobile()
                setSearchPickupDate(value)
              }}
              onTimeChange={(value) => {
                GA.selectedPickupTimeOnMobile()
                setSearchPickupTime(value)
              }}
            />
          </El>
          <El width={0.5} pl='8px'>
            <DateTimePicker
              // id="drop-off-datetime-select" // @TODO automatic open
              date={dropOffDate}
              time={dropOffTime}
              label='Drop off time'
              onDateChange={(value) => {
                GA.selectedDropOffDateOnMobile()
                setSearchDropOffDate(value)
              }}
              onTimeChange={(value) => {
                GA.selectedDropOffTimeOnMobile()
                setSearchDropOffTime(value)
              }}
              after={pickupDate}
            />
          </El>
        </FlexEl>
        {/* AGE SELECT */}
        <El mb={15}>
          <CarlaMobileSelect
            value={ageOption}
            onChange={(opt) => setSearchAge(opt.value)}
            items={ageItems}
            label='Driver age'
            placeholder='Age'
          />
        </El>
        {/* SEARCH BUTTON */}
        <FlexEl className='form-field-container'>
          <SearchButton className='car-search-button' type='submit' onClick={startLoadingAnimation}>
            <El as='span'>Search</El>
          </SearchButton>
          <SearchButton className='car-search-button' style={{ display: 'none' }} type='submit'>
            <ReactLoading className='loading-animation' type={'spin'} height={30} width={30} />
          </SearchButton>
        </FlexEl>
      </El>
    )
  }
)`
  width: 100%;
  .form-field-container {
    width: 100%;
    margin-bottom: 8px;
  }
  .form-title {
    font-size: 18px;
    color: white;
  }
  .car-search-button {
    width: 100%;
    font-size: 18px;
    border-radius: 4px;
    padding: 12px;
    margin-bottom: -8px;
  }
  .loading-animation {
    position: relative;
    left: 46%;
  }
`

const MobileForm = connect(selectSearchArgs, {
  setSearchPickupLocation,
  setSearchDropOffLocation,
  setSearchAge,
  setSearchPickupDate,
  setSearchDropOffDate,
  setSearchPickupTime,
  setSearchDropOffTime
})(RawMobileForm)

export default MobileForm
