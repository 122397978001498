import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ReactLoading from 'react-loading'
import {
  DialogContentStyled,
  DialogTitleStyled,
  CloseButton,
  ButtonGroupsStyled,
  ButtonStyled,
  GreenTitleStyled,
  EmailFormWrapperStyled,
  SuccessDialogContentStyled
} from './VoucherModal.styles'
import GetAppIcon from '@material-ui/icons/GetApp'
import EmailIcon from '@material-ui/icons/Email'
import ClearIcon from '@material-ui/icons/Clear'
import useVoucherPDF from '../../hooks/useVoucherPDF'

import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormControl from '@material-ui/core/FormControl'
import CreateIcon from '@material-ui/icons/Create'
import { FormHelperText } from '@material-ui/core'
import { ReactComponent as IconSuccess } from './../../util/images/icon-successful.svg'

const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  margin: {
    marginBlock: theme.spacing(1)
  },
  withoutLabel: {
    marginTop: theme.spacing(3)
  },
  textField: {
    width: '100%',
    color: '#525266',
    textOverflow: 'ellipsis',
    fontSize: '15px',
    fontWeight: 700,
    lineHeight: '20px',
    borderRadius: '8px',
    background: '#FBFBFD',
    outline: 'none',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(82, 82, 102, 0.5)'
      },
      '&:hover fieldset': {
        borderColor: 'rgba(82, 82, 102, 0.5)'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#FF7D0E'
      }
    },
    '& .MuiOutlinedInput-inputAdornedEnd': {
      outline: 'none'
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #FF7D0E',
      outline: 'none'
    }
  },
  editButton: {
    border: '1px solid #FF7D0E',
    marginRight: '-6px',
    padding: '4px',
    borderRadius: '4px',
    background: '#FF7D0E',
    color: '#fff',
    '&:hover': {
      color: '#FF7D0E'
    }
  },
  editButtonActive: {
    background: '#fff',
    color: '#FF7D0E'
  }
}))

function SendEmailForm({ userEmail, onSendEmail }) {
  const classes = useStyles()
  const [values, setValues] = useState(userEmail)
  const [error, setError] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const { t: TRANSLATOR } = useTranslation()

  const handleChange = (event) => {
    setValues(event.target.value?.trim())
  }
  const handleMouseDown = (event) => {
    event.preventDefault()
  }

  const handleSendEmail = () => {
    if (isValid(values)) {
      onSendEmail(values)
    }
  }

  const isValid = (email) => {
    if (EMAIL_REGEX.test(email)) {
      setError(false)
      return true
    }
    setError(true)
    return false
  }

  const handleOnBlur = (event) => {
    if (isValid(values)) {
      setEditMode(false)
    }
  }

  return (
    <EmailFormWrapperStyled>
      <GreenTitleStyled>
        {!editMode ? TRANSLATOR('reservations:choseEmailAddress') : TRANSLATOR('reservations:newEmailAddress')}
      </GreenTitleStyled>
      <>
        <FormControl className={clsx(classes.margin, classes.textField)} variant='outlined'>
          <OutlinedInput
            name='email'
            type='text'
            value={values}
            onChange={handleChange}
            readOnly={!editMode}
            onBlur={handleOnBlur}
            onMouseDown={() => setEditMode(true)}
            error={!!error}
            placeholder={TRANSLATOR('xo1:driverInfo:inputEmailError')}
            endAdornment={
              <InputAdornment position='end'>
                <IconButton
                  variant='outlined'
                  onMouseDown={handleMouseDown}
                  edge='end'
                  className={clsx(classes.editButton, editMode && classes.editButtonActive)}
                  onClick={() => setEditMode(true)}
                >
                  <CreateIcon />
                </IconButton>
              </InputAdornment>
            }
            labelWidth={0}
          />
          {error && (
            <FormHelperText component='span' error={error}>
              {TRANSLATOR('xo1:driverInfo:validEmail')}
            </FormHelperText>
          )}
        </FormControl>
      </>
      <p>{TRANSLATOR('reservations:sendEmailMessage')}</p>
      <ButtonStyled
        fullWidth
        variant='contained'
        color='primary'
        style={{ marginTop: 12 }}
        disabled={!!error}
        onClick={handleSendEmail}
      >
        {TRANSLATOR('reservations:sendButtonText')}
      </ButtonStyled>
    </EmailFormWrapperStyled>
  )
}

function VoucherDetailDialog({ open, onClose }) {
  const { t: TRANSLATOR } = useTranslation()
  const { email: userEmail } = useSelector((state) => state.reservationInfoRetrieval?.response?.userInfo)
  const reservationId = useSelector((state) => state.reservationInfoRetrieval?.response?.reservationId)
  const {
    data: pdfFile,
    isLoading,
    error,
    sendEmail,
    sendEmailLoading,
    sendEmailResponse
  } = useVoucherPDF(reservationId, userEmail)
  const [step, setStep] = useState(0)

  const handleSendEmail = () => {
    console.log('download file')
    setStep(1)
  }

  const handleClose = () => {
    onClose()
  }

  const onSendEmail = (emailValue) => {
    if (!sendEmailLoading && !sendEmailResponse) {
      try {
        sendEmail(emailValue)
      } catch (e) {
      } finally {
        setStep(2)
      }
    }
  }

  const hasPDFFile = !error && !!pdfFile?.data && !isLoading

  return (
    <>
      <Dialog
        maxWidth={step === 0 ? 'sm' : 'xs'}
        fullWidth
        onClose={handleClose}
        aria-labelledby='voucher-dialog-title'
        open={open}
      >
        {step === 0 && (
          <DialogTitleStyled id='voucher-dialog-title'>{TRANSLATOR('reservations:receiveVoucher')}</DialogTitleStyled>
        )}
        <DialogContentStyled>
          {step === 0 && (
            <ButtonGroupsStyled>
              <ButtonStyled fullWidth variant='outlined' startIcon={<EmailIcon />} onClick={handleSendEmail}>
                {TRANSLATOR('reservations:sendEmail')}
              </ButtonStyled>

              {isLoading && (
                <ButtonStyled fullWidth variant='contained' color='primary'>
                  <ReactLoading className='loading-animation' type={'spin'} height={30} width={30} color={'#fff'} />
                </ButtonStyled>
              )}
              {hasPDFFile && (
                <a href={pdfFile?.data} target='_blank' rel='noreferrer' style={{ width: '100%' }}>
                  <ButtonStyled fullWidth variant='contained' color='primary' startIcon={<GetAppIcon />}>
                    {TRANSLATOR('reservations:downloadPDF')}
                  </ButtonStyled>
                </a>
              )}
            </ButtonGroupsStyled>
          )}
          {step === 1 && <SendEmailForm onSendEmail={onSendEmail} userEmail={userEmail} />}
        </DialogContentStyled>
        <CloseButton aria-label='close' color='primary' onClick={handleClose}>
          <ClearIcon />
        </CloseButton>
      </Dialog>

      {step === 2 && <SuccessDialog resetStep={handleClose} />}
    </>
  )
}

function SuccessDialog({ resetStep }) {
  const [open, setOpen] = useState(true)
  const { t: TRANSLATOR } = useTranslation()

  const onClose = () => {
    setOpen(false)
    resetStep()
  }

  return (
    <Dialog maxWidth={'xs'} fullWidth onClose={onClose} aria-labelledby='voucher-dialog-title' open={open}>
      <SuccessDialogContentStyled>
        <IconSuccess style={{ marginBottom: 18 }} />
        <h6>{TRANSLATOR('reservations:successMessage')}</h6>
        <p>{TRANSLATOR('reservations:sendVoucherMessage')}</p>
        <ButtonStyled variant='contained' color='primary' style={{ marginTop: 32, minWidth: 145 }} onClick={onClose}>
          {TRANSLATOR('reservations:OKButtonText')}
        </ButtonStyled>
      </SuccessDialogContentStyled>
    </Dialog>
  )
}

export default VoucherDetailDialog
