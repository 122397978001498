import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { El, FlexEl } from '../layout'
import { func, string } from 'prop-types'
import { CarlinPropTypes } from '../../entities'
import util from '../../util'
import { ReactComponent as Airport } from '../../util/images/icon-airport.svg'
import { ReactComponent as Location } from '../../util/images/icon-location.svg'
import { ReactComponent as Automatic } from '../../util/images/icon-automatic.svg'
import { ReactComponent as Manuel } from '../../util/images/icon-manual.svg'
import { ReactComponent as Seat } from '../../util/images/icon-seat.svg'
import { ReactComponent as Insurance } from '../../util/images/icon-insurance.svg'
import Default from '../../util/images/placeholder-car.png'
import {
  OuterContainer,
  InnerContainer,
  ImageContainer,
  InfoFirst,
  AmountText,
  DiscountText,
  LocationWrapper,
  InfoColumnWrapper,
  PriceAndBookButtonWrapper,
  InfoContainer
} from './CarCard.styles'
import { useTranslation } from 'react-i18next'
import GA from '../../services/GA'
import API from '../../services/API'

// TODO: refactor (BookButton, ...)
export const Info = styled(({ icon, description, ...props }) => (
  <FlexEl {...props}>
    <El className='icon'>{icon}</El>
    <El className='description'>{description}</El>
  </FlexEl>
))`
  .icon {
    margin-right: 3px;
    @media (min-width: ${themeGet('breakpoints.0')}px) {
      margin-right: 3px;
    }
  }
  .description {
    color: ${themeGet('colors.textBlack')};
    display: flex;
    align-items: center;
    /* font-size: 15px; */
    font-weight: normal;
    font-size: 14px;
  }
`

export const BookButton = styled(Info)`
  margin-left: auto;
  margin-right: auto;
  margin-top: 8px;
  .description {
    color: white;
    font-size: 16px;
    font-weight: bold;
    padding: 0;
  }
`

const icon = '🚀'

const Highlight = styled(({ text, ...props }) => (
  <FlexEl {...props}>
    <span className='h-icon'>{icon}</span>
    {text}
  </FlexEl>
))`
  position: absolute;
  display: block;
  top: 0px;
  right: 0px;
  padding: 5px;
  color: #fa5b94;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  border: 2px solid #fa5b94;
  border-radius: 0px 8px 0px 8px;
  background-color: #fff2f4;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media (min-width: ${themeGet('breakpoints.0')}px) {
    max-width: 75%;
  }

  @media (max-width: ${themeGet('breakpoints.0')}px) {
    display: flex;
    max-width: 50%;
    max-height: 42px;
    white-space: normal;
  }

  > .h-icon {
    margin: 0px 8px;
  }
`

Highlight.propTypes = {
  text: string
}

const CarCard = ({ refreshAffirmUiOnce, setRefreshAffirmUiOnce, carRentalProduct, howManyDays, onBook, ...props }) => {
  const [monthlyPayOption, setMonthlyPayOption] = useState(null)
  const [affirmMonthlyAmount, setAffirmMonthlyAmount] = useState(null)
  const affirmElementRef = useRef()
  const upliftElementRef = useRef()
  const imgElement = useRef()
  const { t } = useTranslation()
  const { car, supplier, stationPair, extras } = carRentalProduct
  const totalPriceAmountAsCent = Math.ceil(
    carRentalProduct.price?.discountedTotal
      ? carRentalProduct.price?.discountedTotal * 100
      : carRentalProduct.price?.actualTotal * 100
  )
  const { modificationPriceDifference } = carRentalProduct // only exists in modification flow

  const isSixtInsured = supplier.name === 'Sixt' && extras?.insured === true

  // This is needed for correct icon for transmisssion because it is based on english string matching
  const transmissonTranslateMap = ['Manuelle', 'Manual', 'Manuale']

  useEffect(() => {
    checkMonthlyPay()

    if (totalPriceAmountAsCent <= 5000)
      if (monthlyPayOption === 'affirm') getAmountAffirm(0)
      else if (monthlyPayOption === 'uplift') getUpliftAmount(0)
  }, [monthlyPayOption])

  const checkMonthlyPay = () => {
    if (!monthlyPayOption && extras.alternativePaymentTypes.includes('uplift')) {
      if (totalPriceAmountAsCent <= 5000) setMonthlyPayOption('affirm')
      else setMonthlyPayOption('uplift')
    } else if (!monthlyPayOption && extras.alternativePaymentTypes.includes('affirm')) {
      setMonthlyPayOption('affirm')
      if (refreshAffirmUiOnce !== true) {
        setRefreshAffirmUiOnce(true)
      }
    }
  }

  const getUpliftAmount = (counter) => {
    if (counter >= 10) {
      return
    } else if (upliftElementRef.current && upliftElementRef.current.innerText) {
      const str = upliftElementRef.current.innerText
      const regex = /[\d]*/
      const amount = str.match(regex)
      setAffirmMonthlyAmount(amount)
    } else {
      setTimeout(() => getUpliftAmount(counter + 1), 750)
    }
  }

  const getAmountAffirm = (counter) => {
    if (counter >= 10) {
      return
    } else if (affirmElementRef.current && affirmElementRef.current.innerText) {
      const str = affirmElementRef.current.innerText
      const regex = /[$][\d]*/
      const regexNumber = /[0-9]{1,6}/
      const temp = str.match(regex)
      let amount = null
      if (temp) amount = temp[0].match(regexNumber)
      setAffirmMonthlyAmount(amount)
    } else {
      setTimeout(() => getAmountAffirm(counter + 1), 750)
    }
  }

  const handleError = () => {
    imgElement.current.src = Default
  }

  const handleDeepLinkClick = async (e) => {
    e.preventDefault()
    API.getSelectedCar(carRentalProduct)
    GA.selectGetAround()
    window.open(extras?.deepLink, '_blank', 'noopener, noreferrer')
  }

  const getModificationPriceDiff = () => {
    return modificationPriceDifference.shouldCharge
      ? modificationPriceDifference.totalDifference
      : modificationPriceDifference.remainderDifference
  }

  const isActiveDeepLink = !!extras && !!extras.deepLink
  const isAirport = stationPair.pickup.airport

  return (
    <OuterContainer {...props} data-testid='car-card-item'>
      <ImageContainer className='img-container'>
        <img className='supplier-img' src={util.toHttps(supplier.logoUrl)} alt={supplier.name} />
        {extras.highlight && <Highlight className='highlight-mobile' text={extras.highlight} />}
      </ImageContainer>
      <InnerContainer {...props}>
        <ImageContainer className='img-container'>
          <img
            className={`car-img ${isActiveDeepLink ? 'car-img-with-get-around' : ''}`}
            src={util.toHttps(car.imageUrl)}
            alt={car.name}
            ref={imgElement}
            onError={handleError}
          />
        </ImageContainer>
        <InfoFirst>
          <FlexEl as='p' className='car-group-description'>
            {car.groupDescription}
          </FlexEl>
          <FlexEl as='h3' className='car-name'>
            {car.name}
          </FlexEl>
          <InfoColumnWrapper>
            <InfoContainer>
              <El className='icon'>
                {transmissonTranslateMap.includes(car.transmissionDescription) ? <Manuel /> : <Automatic />}
              </El>
              <El className='description'>{car.transmissionDescription}</El>
            </InfoContainer>
            <InfoContainer>
              <El className='icon'>{<Seat />}</El>
              <El className='description'>{`${car.seatCount}`}</El>
            </InfoContainer>
            {carRentalProduct.extras.insured && (
              <InfoContainer>
                <El className='icon'>{<Insurance />}</El>
                <El className='description'>
                  {isSixtInsured ? t('carListing:protection') : t('carListing:insurance')}
                </El>
              </InfoContainer>
            )}
          </InfoColumnWrapper>
          <FlexEl className='station-container'>
            {!isAirport && (
              <LocationWrapper data-testid='location-wrapper'>
                <FlexEl className='pickup-container'>
                  <InfoContainer style={{ width: '100%' }}>
                    <El className='icon'>
                      <Location />
                    </El>
                    <El style={{ display: 'block' }} className='description'>
                      {util.failSafeLocation(stationPair.pickup)}
                    </El>
                  </InfoContainer>
                </FlexEl>
                {stationPair.dropOff.address !== stationPair.pickup.address && (
                  <FlexEl style={{ display: 'flex' }} className='drop-off-container'>
                    <InfoContainer style={{ width: '100%' }}>
                      <El className='icon'>{stationPair.dropOff.airport ? <Airport /> : <Location />}</El>
                      <El style={{ display: 'block' }} className='description'>
                        {util.failSafeLocation(stationPair.dropOff)}
                      </El>
                    </InfoContainer>
                  </FlexEl>
                )}
              </LocationWrapper>
            )}
          </FlexEl>
        </InfoFirst>
      </InnerContainer>
      <InnerContainer>
        {modificationPriceDifference && (
          <PriceAndBookButtonWrapper>
            <AmountText data-testid='card-total-price' className={getModificationPriceDiff() <= 0 && 'modification'}>
              {util.formatMoneySignPrefix(getModificationPriceDiff(), modificationPriceDifference.currency)}
              <span style={{ color: 'var(--colorPrimary)' }}>/{t('carListing:total')}</span>
            </AmountText>
            <button className={'book-button'} onClick={() => onBook(carRentalProduct)}>
              {t('carListing:bookNow')}
            </button>
          </PriceAndBookButtonWrapper>
        )}
        {!modificationPriceDifference && (
          <PriceAndBookButtonWrapper>
            {carRentalProduct.price.discountedTotal ? (
              <DiscountText>
                <span data-testid='card-total-price'>
                  <s>
                    {util.formatMoneyFloored(
                      carRentalProduct.price.total / howManyDays,
                      carRentalProduct.price.currency
                    )}
                    <span>/{t('carListing:day')}</span>
                  </s>
                </span>
                <span>
                  {util.formatMoneyFloored(
                    carRentalProduct.price.discountedTotal / howManyDays,
                    carRentalProduct.price.currency
                  )}
                  <span>/{t('carListing:day')}</span>
                </span>
              </DiscountText>
            ) : (
              <AmountText data-testid='card-total-price'>
                {util.formatMoneyFloored(
                  carRentalProduct.price.actualTotal / howManyDays,
                  carRentalProduct.price.currency
                )}
                <span>/{t('carListing:day')}</span>
              </AmountText>
            )}
            {isActiveDeepLink ? (
              <button className='book-button book-button-with-href' onClick={handleDeepLinkClick}>
                {t('carListing:bookNow')}
              </button>
            ) : (
              onBook && (
                <button className={'book-button'} onClick={() => onBook(carRentalProduct)}>
                  {t('carListing:bookNow')}
                </button>
              )
            )}
          </PriceAndBookButtonWrapper>
        )}
      </InnerContainer>
    </OuterContainer>
  )
}

CarCard.propTypes = {
  carRentalProduct: CarlinPropTypes.CarRentalProduct.isRequired,
  onBook: func.isRequired
}

export default CarCard
