import React from 'react'
import styled from 'styled-components'
import { themeGet } from 'styled-system'
import Downshift from 'downshift'
import { format } from 'date-fns'
import { CheckboxDownDS, LabelDS, MenuDS, MobileMenuDS } from '../../CarlaSelect'
import { ButtonEl, El, InputEl } from '../../layout'
import util from '../../../util'
import { FadeModal } from '../../Modal'
import DatePicker from '../../DatePicker'

/**
 * finds 30min slots in a day
 * @returns {[{ label, value, hour, min}]}
 */
let timeOptions
const getTimeOptions = () => {
  if (timeOptions) return timeOptions
  timeOptions = []
  const start = 0
  const end = 24 * 60 - 30
  for (let i = start; i <= end; i += 30) {
    const timeObj = { hour: Math.floor(i / 60), min: i % 60 }
    const label = util.formatTimeObjUS(timeObj)
    timeOptions.push({ label, value: i, ...timeObj })
  }
  return timeOptions
}

const getTimeOption = (hour, min) => {
  return getTimeOptions().find(({ hour: nextHour, min: nextMin }) => hour === nextHour && min === nextMin)
}

/**
 * @param date {Date}
 * @param time {{hour, min}}
 * @param onDateChange {function}
 * @param onTimeChange {function}
 * @param label {string} pickup time|drop off time
 * @param label {after} DatePicker parameter
 */
const DateTimePicker = styled(
  class extends React.Component {
    state = {
      // date picker modal is controlled input because it closes on any click on mobile
      isDateOpen: false
    }

    openDateMenu = () => this.setState({ isDateOpen: true })
    closeDateMenu = () => this.setState({ isDateOpen: false })

    render() {
      const { date, onDateChange, time, onTimeChange, label, after, ...props } = this.props
      const { hour, min } = time
      const selectedTime = getTimeOption(hour, min)

      const dateButtonBigText = date ? date.getDate() : '-' // 23
      const dateButtonSmallText = date ? format(date, 'EEE | MMMM') : 'Choose date' // Thu | April

      return (
        <El {...props}>
          <LabelDS>{label}</LabelDS>
          <El className='buttons'>
            {/* MOBILE DATE SELECT AND PICKER */}
            <Downshift onChange={onDateChange} isOpen={this.state.isDateOpen}>
              {({ getRootProps, getMenuProps, getToggleButtonProps }) => (
                <El {...getRootProps()}>
                  <ButtonEl className='date-select' {...getToggleButtonProps({ onClick: this.openDateMenu })}>
                    <El fontSize={24}>{dateButtonBigText}</El>
                    <El fontSize={14} mt={1}>
                      {dateButtonSmallText}
                    </El>
                  </ButtonEl>
                  <MenuDS {...getMenuProps()} isOpen={this.state.isDateOpen}>
                    <FadeModal isOpen={this.state.isDateOpen} onClose={this.closeDateMenu}>
                      <DatePicker
                        label='Pickup date'
                        value={date}
                        onChange={(val) => {
                          onDateChange(val)
                          this.closeDateMenu()
                        }}
                        closeMenu={this.closeDateMenu}
                        after={after}
                      />
                    </FadeModal>
                  </MenuDS>
                </El>
              )}
            </Downshift>
            {/* MOBILE TIME SELECT AND PICKER */}
            <Downshift
              initialSelectedItem={selectedTime}
              onChange={onTimeChange}
              items={getTimeOptions()}
              itemToString={(item) => item.label || ''}
            >
              {({
                isOpen,
                closeMenu,
                selectedItem,
                getRootProps,
                getInputProps,
                getToggleButtonProps,
                getMenuProps,
                getItemProps
              }) => (
                <El {...getRootProps()}>
                  <El className='time-select' {...getToggleButtonProps()}>
                    <InputEl type='button' className='time-select' {...getInputProps()} />
                    <CheckboxDownDS className='checkbox' />
                  </El>
                  <MobileMenuDS
                    {...{ isOpen, selectedItem, label, getMenuProps, getItemProps, closeMenu }}
                    items={getTimeOptions()}
                    placeholder={label}
                  />
                </El>
              )}
            </Downshift>
          </El>
        </El>
      )
    }
  }
)`
  .buttons {
    width: 100%;
    padding: 0;
    color: ${themeGet('colors.grey')};
    background-color: white;
    border: 1px solid ${themeGet('colors.blueGrey.2')};
    border-radius: 3px;
    .date-select {
      width: 100%;
      padding: 8px;
      color: #525266;
      background-color: white;
      border-bottom: 1px solid ${themeGet('colors.blueGrey.2')};
    }
    .time-select {
      position: relative;
      height: 40px;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      color: #525266;
      background-color: white;
      input {
        background-color: white;
        width: 100%;
        border: none;
      }
      &:hover {
        cursor: pointer;
      }
      .checkbox {
        bottom: 7px;
        left: 85%;
        height: 18px;
      }
    }
  }
`

export default DateTimePicker
