import styled from 'styled-components'
import { CardFlexEl, Container, FlexEl, El, ButtonEl } from '../layout'
import { theme } from '../../styles'
import { themeGet } from 'styled-system'

export const GlobalWrapper = styled(FlexEl)`
  display: flex;
  flex-direction: column;
  width: 100vw;
  overflow: hidden;
  margin-top: 5%;
  gap: 300px;

  @media (max-width: ${themeGet('breakpoints.0')}px) {
    gap: 50px;
    margin-top: 2%;
  }
`

export const Wrapper = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-self: center;
  color: ${theme.colors.textBlack};
  border: solid 1px ${theme.colors.separatorGrey};
  border-radius: 12px;
  max-width: 999px;

  @media (max-width: ${themeGet('breakpoints.0')}px) {
    max-width: 90vw;
    margin: auto;
    align-self: center;
  }
`

export const CustomContainer = styled(CardFlexEl)`
  border: 1px solid ${theme.colors.separatorGrey};
  flex-direction: column;
  min-width: 50vw;

  @media (max-width: ${themeGet('breakpoints.0')}px) {
    min-width: 90vw;
  }
`

export const ImgContainer = styled(FlexEl)`
  justify-content: center;
  margin-top: 24px;
  svg {
    max-width: 80%;
    max-height: 80%;
  }
`

export const InnerContainer = styled(FlexEl)`
  flex-direction: column;
  margin-top: 0;
  margin-left: 28px;
  margin-right: 28px;
`

export const Title = styled(El)`
  font-family: 'Montserrat';
  font-size: 20px;
  font-weight: bold;
  color: ${theme.colors.textBlack};
  text-align: left;
  margin-bottom: 16px;
`

export const Message = styled(El)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  font-family: 'Quicksand', 'Quicksand Regular', sans-serif;
  font-size: 18px;
  font-weight: 500;
  gap: 5px;

  > div:first-child: {
    color: ${theme.colors.textBlack};
  }

  > div:last-child {
    color: ${theme.colors.colorHint};
  }
`

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
`

export const InputContainer = styled(FlexEl)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 24px;
  margin-bottom: 16px;
  margin-right: 70px;
  gap: 25px;

  @media (max-width: ${themeGet('breakpoints.0')}px) {
    flex-wrap: wrap;
    margin-right: auto;
  }
`

export const SingleInputContainer = styled(El)`
  display: flex;
  flex-direction: column;
  width: 100%;

  span {
    font-size: 18px;
    color: ${theme.colors.textBlack};
    margin-bottom: 8px;
  }

  input[type='text'],
  input[type='number'],
  textarea {
    background-color: ${theme.colors.gray[0]};
    border: 1px solid ${theme.colors.separatorGrey};
    font-size: 20px;
    -moz-appearance: textfield;
    padding: 10px 5%;
    font-weight: light;
    border-radius: 4px;
    box-shadow: 3px 4px 10px 0 rgba(0, 0, 0, 0.09);
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input::placeholder {
    color: rgba(180, 180, 194, 0.7);
    font-size: 16px;
  }
`

export const SubmitButtonContainer = styled(FlexEl)`
  justify-content: flex-end;
  margin-right: 70px;
  margin-bottom: 32px;

  @media (max-width: ${themeGet('breakpoints.0')}px) {
    margin-right: 0;
  }
`

export const SubmitButton = styled(ButtonEl)`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: end;
  font-size: 16px;
  font-weight: bold;
  max-height: 35px;
  min-width: 20%;
  padding: 10px 50px;
  border: none;
  outline: none;
  border-radius: 4px;
  background-image: radial-gradient(circle at 2% 54%, #fe9661, #ff7d0e 47%, #ffb369 90%);
  color: white;

  &:hover {
    cursor: pointer;
  }

  @media (max-width: ${themeGet('breakpoints.0')}px) {
    display: flex;
    align-self: center;
    min-width: 100%;
  }
`

export const IconText = styled.div`
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 4px;
  border-radius: 999px;
  background: ${(props) => props.bg};
  width: ${(props) => props.width ?? 'auto'};
`
