export default function handleResponse(data, modificationType, openFailModal, history, dispatch, setModificationArgs) {
  if (!!!data) {
    openFailModal()
    return
  }

  const { sameCarFound } = data

  if (sameCarFound) {
    const { modificationPriceDifference, carRentalProduct } = data.cars[0]
    const { remainderDifference, shouldCharge } = modificationPriceDifference

    dispatch(setModificationArgs({ product: carRentalProduct, modificationPriceDifference }))

    if (modificationType === 'DRIVER_CHANGE' && remainderDifference === 0.0 && !shouldCharge) {
      history.push('/my-reservations/modify-success')
      return
    }

    history.push('/my-reservations/modify-car-confirm')
    return
  }

  history.push('/my-reservations/modified-car-search')
}
