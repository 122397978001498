import { makeStyles } from '@material-ui/core'
import styled from 'styled-components'
import IconInfo from './assets/icon-info.svg'
import IconCritical from './assets/icon-critical.svg'
import IconClose from '../../util/images/icon-close-cancel.svg'
import IconInfoBlue from './assets/icon-info-blue.svg'

export { RadioUI } from '../../UI/Radio'
export { RadioGroup } from '@material-ui/core'

export const useStyles = makeStyles({
  root: {
    padding: 0
  }
})

export const Text = styled.p`
  margin: 0;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
`

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px;
  cursor: pointer;
  ${({ $checked }) => $checked && `border-color: var(--colorPrimary) !important; !important;background: #FFF2E8;`}
  ${({ $isError }) => $isError && `border-color: #C1272D !important;`}
`

export const RowWrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 8px;
`

export const InsuranceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  & button {
    cursor: pointer;
    padding: 0;
    border: none;
    outline: none;
    background: url(${IconInfo});
    height: 20px;
    width: 20px;
  }
`

export const PriceInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
`

export const PriceText = styled(Text)`
  font-weight: 500;
  line-height: 23px;
`

export const OldPriceText = styled(Text)`
  color: var(--Text-Hint, #b4b4c2);
  font-weight: 500;
  line-height: 23px;
  text-decoration: line-through;
`

export const Subtitle = styled(Text)`
  font-size: 14px;
  font-weight: 500;
`

export const Discount = styled(Text)`
  color: #0076ff;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  border-radius: 4px;
  border: 1px solid rgba(0, 118, 255, 0.15);
  background: rgba(0, 118, 255, 0.1);
  padding: 2px 5px;
`

export const BestValue = styled(Discount)`
  border-radius: 4px;
  border: 1px solid rgba(255, 125, 14, 0.25);
  background: #fff8f3;
  color: #ff7d0e;
`

export const InsuranceListWrapper = styled.div`
  & > div {
    border: 1px solid var(--borderGrey);
  }

  & > div:nth-child(1) {
    border-radius: 12px 12px 0 0;
  }

  & > div:nth-child(4) {
    border-radius: 0 0 12px 12px;
  }
`

export const Status = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  flex-wrap: wrap;
  & > p {
    margin: 0;
    color: ${({ $status }) => ($status > 1 ? 'var(--colorSuccess)' : '#C1272D')};
    font-size: 18px;
    font-weight: 700;
    line-height: 23px;
  }

  & > button {
    cursor: pointer;
    padding: 0;
    border: none;
    outline: none;
    background: url(${IconCritical});
    height: 20px;
    width: 20px;
  }

  & > div {
    flex-grow: 1;
    text-align: end;
    line-height: 0;
  }
`

export const StatusWrapper = styled.div`
  @media screen and (max-width: 575px) {
    margin-left: 12px;
    margin-right: 12px;
  }
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 12px;
  border: 1px solid var(--Border-Primary, #dfdfe7);
  background: #fff;
  padding: 12px;
  margin: 12px 24px;
`

export const Header = styled.h1`
  margin: 0;
  color: #fff;
  font-size: ${({ $mobile }) => ($mobile ? '20px' : '32px')};
  font-weight: 700;
  padding: ${({ $mobile }) => ($mobile ? '16px 40px 16px 12px' : '32px')};
  background-color: var(--colorPrimary);
  border-radius: 12px 12px 0 0;

  & button {
    border: none;
    outline: none;
    position: absolute;
    background: url(${IconClose}) no-repeat center;
    height: 24px;
    width: 24px;
    top: 16px;
    right: 12px;
    cursor: pointer;
  }
`

export const TextContent = styled.div`
  background: white;
  border-radius: 0 0 12px 12px;
  padding: ${({ $mobile }) => ($mobile ? '12px' : '16px 32px 32px')};
  color: var(--Text-Primary, #525266);
  font-size: 16px;
  font-weight: 400;
  column-count: ${({ $mobile }) => ($mobile ? 1 : 2)};
  column-gap: 64px;
  > h3 {
    color: var(--Actions-Primary, #ff7d0e);
    font-family: Quicksand;
    font-size: ${({ $mobile }) => ($mobile ? 14 : 20)}px;
    font-weight: 700;
  }

  > ul {
    padding-left: 16px;
    font-size: ${({ $mobile }) => ($mobile ? 14 : 16)}px;
    & li:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  > p {
    font-size: ${({ $mobile }) => ($mobile ? 14 : 18)}px;
    font-weight: 600;
    break-before: ${({ $mobile }) => ($mobile ? 'auto' : 'column')};
  }
`

export const ButtonAddProtection = styled.button`
  border-radius: 4px;
  background: linear-gradient(143deg, #ffab64 -1.15%, #ff7d0e 100%);
  color: #fff;
  font-size: 15px;
  font-weight: 700;
  height: ${({ $mobile }) => ($mobile ? 40 : 44)}px;
  text-align: center;
  border: none;
  outline: none;
  margin: auto 0 auto auto;
  padding: 0 24px;
`

export const AncillaryInfo = styled.p`
  @media screen and (max-width: 576px) {
    margin-left: 12px;
    margin-right: 12px;
  }
  display: flex;
  padding: 8px;
  margin: 12px 24px;
  gap: 8px;
  line-height: 19px;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--Actions-Secondary, #0076ff);
  background: #eff1f8;
  color: var(--Text-Primary, #525266);
  font-size: 14px;
  font-weight: 500;
  &::before {
    content: url(${IconInfoBlue});
    line-height: 0;
  }
`
