import { useMediaQuery } from 'react-responsive'
import { theme } from '../../styles'

export const DesktopScreen = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: theme.breakpoints[1] })
  return isDesktop ? children : null
}

export const TabletScreen = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: theme.breakpoints[0], maxWidth: theme.breakpoints[1] - 1 })
  return isTablet ? children : null
}

export const OverMobileScreen = ({ children, customBreakpoint }) => {
  const isActive = useMediaQuery({ minWidth: customBreakpoint || theme.breakpoints[0] })
  return isActive ? children : null
}

export const MobileScreen = ({ children, customBreakpoint }) => {
  const isMobile = useMediaQuery({ maxWidth: customBreakpoint - 1 || theme.breakpoints[0] - 1 })
  return isMobile ? children : null
}

export const DefaultScreen = ({ children }) => {
  const isDefault = useMediaQuery({ maxWidth: theme.breakpoints[1] - 1 })
  return isDefault ? children : null
}

export const useScreen = () => {
  const isDefaultScreen = useMediaQuery({ maxWidth: theme.breakpoints[1] - 1 })
  const isMobileScreen = useMediaQuery({ maxWidth: theme.breakpoints[0] - 1 })

  return {
    isDefaultScreen,
    isMobileScreen
  }
}

export const useBreakpoint = (maxWidth = 600) => {
  const isMobileScreen = useMediaQuery({ maxWidth: maxWidth })

  return isMobileScreen
}
