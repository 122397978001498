import React, { useState } from 'react'
import IconArrowDown from '../../util/images/icon-arrow-down.png'
import { useSelector, useDispatch } from 'react-redux'
import { setSortingOption } from './../../redux/slices'
import './../../components/CarListing/CarSearchResults.css'
import { useTranslation } from 'react-i18next'

import { SortDropdownContainer, SortDropdownPopup, SortDropdownOption } from './CarSortDropdown.styles'

export const SortingOptionMap = {
  LH: 'Price (low to high)',
  HL: 'Price (high to low)',
  RECOMMENDED: 'Recommended'
}

function CarSortDropdown() {
  const [isSortingMenuOpen, setIsSortingMenuOpen] = useState(false)
  const sortingOption = useSelector((state) => state.sortingOption)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  // This is needed but a refactor would be nice
  const languageMapping = {
    Recommended: 'carListing:sort:recommended',
    'Price (low to high)': 'carListing:sort:priceLowToHigh',
    'Price (high to low)': 'carListing:sort:priceHighToLow'
  }

  const onSortingOptionClick = (newOption) => {
    if (SortingOptionMap[newOption] !== sortingOption[0]) dispatch(setSortingOption(newOption))
    setIsSortingMenuOpen(false)
  }

  return (
    <SortDropdownContainer>
      <div
        className='sort-container-controller'
        data-testid='sorting-select'
        onClick={() => setIsSortingMenuOpen(!isSortingMenuOpen)}
      >
        <div className='sort-container-left'>
          <div className='sort-container-header'>{t('carListing:sort:header')}</div>
          <div className='sort-container-main'>{t(`${languageMapping[sortingOption[0]]}`)}</div>
        </div>
        <div className='sort-container-right'>
          <img
            className='sort-container-arrow-icon'
            src={IconArrowDown}
            style={{
              transform: isSortingMenuOpen ? 'rotate(180deg)' : 'rotate(0deg)'
            }}
            alt='arrow-icon'
          />
        </div>
      </div>
      {isSortingMenuOpen && (
        <SortDropdownPopup>
          {Object.keys(SortingOptionMap).map((op) => (
            <SortDropdownOption key={op} onClick={() => onSortingOptionClick(op)}>
              {t(`${languageMapping[SortingOptionMap[op]]}`)}
            </SortDropdownOption>
          ))}
        </SortDropdownPopup>
      )}
    </SortDropdownContainer>
  )
}

export default CarSortDropdown
