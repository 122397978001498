import React from 'react'
import { TextField } from '@material-ui/core'
import { useStyles } from './styles'

export function TextFieldUI({ endadornment, ...rest }) {
  const classes = useStyles()

  return (
    <TextField
      variant='outlined'
      InputProps={{
        classes: {
          root: classes.root,
          input: classes.input,
          notchedOutline: classes.notchedOutline,
          focused: classes.focused,
          disabled: classes.disabled
        },
        endAdornment: endadornment
      }}
      InputLabelProps={{
        classes: { root: classes.label, focused: classes.focused, shrink: classes.shrink }
      }}
      {...rest}
    />
  )
}
