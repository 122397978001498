import React from 'react'
import { useSelector } from 'react-redux'
import util from './../../util'
import { useTranslation } from 'react-i18next'

import { CarInfoCardContainer, CarImage } from './CarInfoCard.styled'

function CarInfoCard({ isBundle = false }) {
  const { t } = useTranslation()
  const { checkInDate, checkOutDate } = useSelector((state) => state.hotelBooking.dateRange)
  const { isMatchCriteria } = useSelector((state) => state.bundleBooking)
  const { pickupLocation, dropOffLocation, pickupDateStr, dropOffDateStr } = useSelector((state) => state.searchArgs)
  const { car = {} } = useSelector((state) => state.bundleBooking.bundleSelected.carProduct)
  const language = useSelector((state) => state.user.language)

  const startDate = isBundle && isMatchCriteria ? checkInDate : pickupDateStr
  const endDate = isBundle && isMatchCriteria ? checkOutDate : dropOffDateStr

  return (
    <CarInfoCardContainer>
      <div className='info-card show-on-CarSearchContainertablet show-on-desktop'>
        <div
          className='info-card-half'
          style={{
            width: isBundle ? '35%' : '45%'
          }}
        >
          <div className='info-card-header'>{t('homePage:pickUp')}</div>
          <div className='info-card-location'>{pickupLocation?.label}</div>
          <div className='info-card-datetime'>{util.formatDateAndDayTimeFromDate(new Date(startDate), language)}</div>
        </div>
        <div className='info-card-separator'>
          <svg xmlns='http://www.w3.org/2000/svg' width='42' height='80' viewBox='0 0 42 80'>
            <path
              fill='none'
              fillRule='evenodd'
              stroke='#DFDFE7'
              d='M868 124L908 164 868 204'
              transform='translate(-867 -124)'
            />
          </svg>
        </div>
        <div
          className='info-card-half'
          style={{
            width: isBundle ? '35%' : '45%'
          }}
        >
          <div className='info-card-header'>{t('homePage:dropOff')}</div>
          <div className='info-card-location'>{dropOffLocation?.label}</div>
          <div className='info-card-datetime'>{util.formatDateAndDayTimeFromDate(new Date(endDate), language)}</div>
        </div>
        {isBundle && !!car.imageUrl && <CarImage width={190} src={car.imageUrl} alt='car bundle' />}
      </div>
    </CarInfoCardContainer>
  )
}

export default CarInfoCard
