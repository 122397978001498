import Client from '@sanity/client'

const isSanityDevEnv = process.env.SANITY_ENV === 'dev'

export default new Client({
  projectId: 'vn3a86xe',
  dataset: 'production',
  apiVersion: 'v2022-03-07',
  useCdn: isSanityDevEnv ? false : true,
  perspective: isSanityDevEnv ? 'previewDrafts' : 'published'
})
