import React from 'react'
import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { BlueGreyLink, Container, El } from '../../components/layout'
import util from '../../util'
import { BlogCard, BlogList, BlogSearchButton } from './index'
import DownloadAppSection from '../../components/DownloadAppSection'
import PageNotFound from './../PageNotFound'
import BlogAuthor from '../../components/BlogAuthor'
import SocialShare from './SocialShare'
import { ArticleContent } from './article.styles'
import { useArticlesBySlug } from '../../hooks/useArticlesBySlug'
import PortableText from './../../components/PortableText/PortableText'
import { getFromCache } from '../../sanity/cache'
import useCurrentLang from '../../hooks/useCurrentLang'
import { isEmpty } from 'lodash'

const RecentArticles = styled(({ recentArticles, otherArticles, ...props }) => (
  <Container {...props}>
    <h2
      style={{
        margin: 'auto',
        padding: '15px',
        fontSize: '24px',
        fontWeight: 'normal',
        textAlign: 'center',
        color: '#D84315'
      }}
    >
      Recently Published
    </h2>
    <BlogList>
      {recentArticles.map((article) => (
        <BlogCard key={article.slug} as={Link} to={article.canonicalLink} width={[1, 0.42, 0.3]} article={article}>
          <El className='blog-post-title'>{article.title}</El>
          <El className='blog-post-date'>{article.datePublishedFormatted}</El>
          <El className='blog-post-description'>{article.description}</El>
        </BlogCard>
      ))}
    </BlogList>
    <BlogList>
      {otherArticles.map((article) => (
        <BlueGreyLink
          key={article.slug}
          as={Link}
          to={article.canonicalLink}
          width={[1, 0.5, 0.33]}
          className='blog-post-link'
        >
          {article.title}
        </BlueGreyLink>
      ))}
    </BlogList>
  </Container>
))`
  h1 {
    margin: auto;
    padding: 15px;
    font-size: 24px;
    font-weight: normal;
    text-align: center;
    color: ${themeGet('colors.deepOrange.8')};
  }
  .blog-post-link {
    padding: 16px 15px;
    @media (min-width: ${themeGet('breakpoints.1')}px) {
      padding: 8px 15px;
    }
    overflow: hidden;
    white-space: nowrap;
    font-size: 14px;
    text-overflow: ellipsis;
  }
`

const articleMap = util.getArticleMap()

function Article({ match, staticContext = {}, ...rest }) {
  const lang = useCurrentLang()
  const slug = match.params.slug
  let article = {}
  article = articleMap[slug]
  if (isEmpty(article) && staticContext.data) {
    article = staticContext.data[lang] ? staticContext.data[lang] : staticContext.data['en']
  }
  const { data: sanityArticlesMap, isLoading } = useArticlesBySlug(slug)
  if (!article && !!sanityArticlesMap) {
    article = sanityArticlesMap[lang] || sanityArticlesMap['en']
  }
  const { recent: recentArticles = null, other: otherArticles = null } = util.getOtherArticles({ slug })
  if (isEmpty(article) && isLoading) return null
  if (isEmpty(article) && !isLoading) return <PageNotFound />

  return (
    <El bg='white' {...rest}>
      <Helmet>
        <script type='application/ld+json'>{util.getArticleStructure(article, false)}</script>
        <script type='application/ld+json'>{util.getBreadCrumbStructure(article, false)}</script>
        <title>{article.browserTitle || article.title}</title>
        <link rel='amphtml' href={article.ampUrl} />
        <link rel='canonical' href={article.canonicalUrl} />
        <meta name='description' content={article.description} />
        <meta name='keywords' content={article.keywords} />
        <meta property='og:url' content={article.canonicalUrl} />
        <meta property='og:type' content='article' />
        <meta property='og:title' content={article.title} />
        <meta property='og:description' content={article.description} />
        <meta property='og:image' content={article.imageUrl} />
        <meta property='og:image:width' content={article.imageWidth} />
        <meta property='og:image:height' content={article.imageHeight} />
        <meta name='twitter:title' content={article.title} />
        <meta name='twitter:description' content={article.description} />
        <meta name='twitter:image' content={article.imageUrl} />
      </Helmet>
      {!article.isSanityContent ? (
        <>
          <ArticleContent dangerouslySetInnerHTML={{ __html: article.getContent(util.formatCanonicalContent) }} />
          {article.article && <BlogAuthor content={article.article} />}
        </>
      ) : (
        <>
          <ArticleContent>
            <PortableText {...article} />
          </ArticleContent>
          {article.authors && <BlogAuthor content={article.authors} isSanityContent={true} />}
        </>
      )}
      {article.title !== 'Can you rent a car amid the pandemic?' && <BlogSearchButton />}
      <SocialShare url={article.canonicalUrl} />
      <RecentArticles recentArticles={recentArticles} otherArticles={otherArticles} />
      <DownloadAppSection />
    </El>
  )
}

Article.propTypes = {}

Article.load = async (match) => {
  try {
    const slug = match.params.slug
    return await getFromCache(slug)
  } catch (error) {
    console.error(error)
  }
}

export default Article
