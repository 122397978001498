import React, { useState } from 'react'
import { Box, MobileHeader, MobileFixedButton, BackButton, Container, Header, Button } from '../styles'
import { EmailWarningModal } from '../modals/EmailWarningModal'
import { MobileScreen, OverMobileScreen } from '../../common/Screens'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import useReservationInfo from '../hooks/useReservationInfo'
import DriverForm from './DriverForm'

export default function () {
  const { response } = useReservationInfo()
  const { t: TRANSLATOR } = useTranslation()
  const [open, setOpen] = useState(false)
  const history = useHistory()

  return (
    response && (
      <>
        <MobileScreen>
          <MobileHeader>{TRANSLATOR('reservations:modification:modifyDriverDetails')}</MobileHeader>
          <Box bgcolor='white' px={1.5} py={2} mt={3} mx={2} border='1px solid var(--borderGrey)' borderRadius={12}>
            <DriverForm
              {...response}
              id='driverForm'
              setOpen={setOpen}
              onSubmit={() => history.push('/my-reservations/modify-driver-confirm')}
            />
          </Box>
          <MobileFixedButton type='submit' form='driverForm'>
            {TRANSLATOR('reservations:modification:continue')}
          </MobileFixedButton>
        </MobileScreen>
        <OverMobileScreen>
          <Container>
            <BackButton onClick={() => history.go(-1)}>{TRANSLATOR('reservations:reserved:back')}</BackButton>
            <Box border='1px solid var(--borderGrey)' borderRadius={12} bgcolor='white' p={6}>
              <Header>{TRANSLATOR('reservations:modification:modifyDriverDetails')}</Header>
              <Box
                mt={4}
                maxWidth={625}
                bgcolor='white'
                border='1px solid var(--borderGrey)'
                borderRadius={12}
                px={1.5}
                py={2}
                m='auto'
              >
                <DriverForm
                  {...response}
                  id='driverForm'
                  setOpen={setOpen}
                  onSubmit={() => history.push('/my-reservations/modify-driver-confirm')}
                />
                <Button type='submit' form='driverForm'>
                  {TRANSLATOR('reservations:modification:continue')}
                </Button>
              </Box>
            </Box>
          </Container>
        </OverMobileScreen>
        <EmailWarningModal open={open} onClose={() => setOpen(false)} href='/contact' />
      </>
    )
  )
}
