import React, { useState, useEffect } from 'react'
import promoLogo from '../../util/images/promocode.svg'
import close from '../../util/images/promo-close.svg'
import './promocodemodal.css'
import { setPromoCode, setPromoCodeOpen } from '../../redux/slices'
import { connect } from 'react-redux'
import API from '../../services/API'
import useDeepLinkConverter from '../../util/deepLinkConverter'

const mapStateToProps = (state) => ({
  promoCode: state.searchArgs.promoCode,
  promoCodeIsOpen: state.promoCodeIsOpen
})

function PromoCodeModal({ onClose, promoCodeIsOpen, promoCode, setPromoCode, setPromoCodeOpen, ...props }) {
  const [input, setInput] = useState('promo-code-modal-input')
  const [success, setSuccess] = useState(false)
  const [warning, setWarning] = useState(false)
  const [button, setButton] = useState('promo-code-modal-button')
  const [codePromo, setCodePromo] = useState(promoCode ? promoCode : null)
  const [link, setLink] = useState(false)
  const checkValue = 0.0

  useEffect(() => {
    if (promoCode) {
      setWarning(false)
      setSuccess(true)
      setInput('promo-code-modal-success-input')
      setButton('promo-code-modal-success-button')
    }
  }, [])

  useEffect(() => {
    if (promoCode === 'MEMORIAL10') {
      setTimeout(() => {
        setPromoCodeOpen(true)
      }, 250)
    }
    if (promoCode) {
      setWarning(false)
      setSuccess(true)
      setInput('promo-code-modal-success-input')
      setButton('promo-code-modal-success-button')
    }
  }, [promoCode])

  useEffect(() => {
    if (warning) {
      setTimeout(() => {
        setInput('promo-code-modal-input')
        setButton('promo-code-modal-button')
        setCodePromo(null)
        setWarning(false)
      }, 1600)
    }
  }, [warning])

  const deepLink = useDeepLinkConverter()

  useEffect(() => {
    deepLink && setLink(true)
  }, [deepLink])

  const promoCodeUpdate = () => {
    setTimeout(() => {
      setPromoCode(codePromo)
      setPromoCodeOpen(false)
      setLink(false)
    }, 1000)
  }

  const handlePromoCodeChange = (event) => {
    setCodePromo(event.target.value)
  }

  const handlePromoCodeSubmit = async (event) => {
    event.preventDefault()
    try {
      const res = await API.getPromoCode(codePromo)
      // eslint-disable-next-line
      if (res.discountRate !== checkValue) {
        setWarning(false)
        setSuccess(true)
        setInput('promo-code-modal-success-input')
        setButton('promo-code-modal-success-button')
        promoCodeUpdate()
      } else {
        setSuccess(false)
        setWarning(true)
        setInput('promo-code-modal-error-input')
        setButton('promo-code-modal-button')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleCloseModal = () => {
    setPromoCodeOpen(false)
    setLink(false)
    if (!success) {
      setSuccess(false)
      setWarning(false)
      setInput('promo-code-modal-input')
      setButton('promo-code-modal-button')
      setCodePromo(null)
    }
  }

  if (link || promoCodeIsOpen) {
    return (
      <div className='promo-code-modal-bg'>
        <div className='promo-code-modal-outside'>
          <button className='promo-code-close-button' onClick={handleCloseModal}>
            <img src={close} alt='' />
          </button>
          <div className='promo-code-modal-inside'>
            <img className='promo-code-modal-img' src={promoLogo} alt='' />
            <div className='promo-code-modal-text-settings'>
              <p className='promo-code-modal-text'>Enter your promo code</p>
              <p className='promo-code-modal-subtext'>Please enter your promo code in the field below.</p>
            </div>
            <div className='promo-code-modal-bottom'>
              <div className='promo-code-modal-input-text'>
                <input
                  disabled={success}
                  type='text'
                  className={input}
                  value={promoCode ? promoCode : null}
                  placeholder={'Promo Code'}
                  onChange={handlePromoCodeChange}
                />
                {success && <p className='promo-code-modal-success-text'>Promo code is successfully applied!</p>}
                {warning && <p className='promo-code-modal-warning-text'>This promo code is expired or invalid</p>}
              </div>
              <button id='applyButton' disabled={success} onClick={handlePromoCodeSubmit} className={button}>
                {success ? 'Applied' : 'Apply'}
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
  return <></>
}

export const PromoCode = connect(mapStateToProps, { setPromoCodeOpen, setPromoCode })(PromoCodeModal)
